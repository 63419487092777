import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
// Components
import ObjectContent from 'components/GameBoard/ReusableComponents/Objects/ObjectContent';
import EmotionsPanel from 'components/GameBoard/Emotion/Solo/Enigma/EmotionsPanel';
import NarratorTextHeader from 'components/GameBoard/Emotion/Solo/Enigma/NarratorTextHeader';

// Style
import styles from 'components/GameBoard/Emotion/Solo/Enigma/InterpretationMachineCalibration.module.scss';

// Selector functions
import {
  selectEmoticonObjects,
  selectSelectedEmoticon,
  selectValidatedEmoticons,
} from 'components/Reducers/GameData/fetchObjects';
import {
  selectWrongIntensities,
  selectAnswersState,
} from 'components/Reducers/emotion';

// Constants
import {
  enigmas,
  narratorTextHeaderHeight,
} from 'components/GameBoard/Emotion/Solo/constants';

const { emoticonsPanelHeight } = enigmas.InterpretationMachineCalibration;

// CSS in JS
const EmoticonesStyle = styled.div`
  position: absolute;
  top: ${({ viewportHeight, bubbleHeight }) =>
    (viewportHeight - emoticonsPanelHeight) / 2 + bubbleHeight / 2}px;
  left: 10px;
  width: 191px;
  height: 326px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.4);
  border-radius: 30px;
  background: linear-gradient(80.62deg, #c4e1d2 3.36%, #85ccc9 98.14%) padding-box,
    linear-gradient(289.59deg, #427e89 19.8%, #224e52 91.69%) border-box,
    linear-gradient(80.62deg, #c4e1d2 3.36%, #85ccc9 98.14%) border-box;
  border: 5px solid transparent;
  padding: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  border-radius: 30px;
  align-items: center;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;

    > button {
      display: inherit;
      width: 68px;
      height: 67px;
      padding: 0;
      border: none;
      background: transparent;
      cursor: pointer;
    }
  }
`;

function InterpretationMachineCalibration() {
  // `t` is a function coming from the i18next library used to translate text
  /* Load the `common` namespace = the one containing translations that are common between games
  and the `emotion` one (see public-> locales -> fr-FR) */
  const { t } = useTranslation('emotion');

  // Select from the store
  const emoticons = useSelector(selectEmoticonObjects);
  const selectedEmoticon = useSelector(selectSelectedEmoticon);
  const validatedEmoticons = useSelector(selectValidatedEmoticons);
  const answersState = useSelector((state) =>
    selectAnswersState(state, selectedEmoticon?.id)
  );
  const wrongIntensities = useSelector((state) =>
    selectWrongIntensities(state, selectedEmoticon?.id)
  );

  // Concatenate the name of the wrong intensities
  const concatenateWrongIntensities = () => {
    let text = '';
    if (wrongIntensities.length) {
      wrongIntensities.forEach((intensityName, index) => {
        text += index ? ` / ${t(intensityName)}` : `${t(intensityName)}`;
      });
    }
    return text;
  };

  // Access the i18next object about the messages displayed at the top
  const messages = t('enigma1.rules.messages', {
    returnObjects: true,
    /* this object depends on values. Use count from i18next to handle singular
      and plural versions of the values */
    val1: `${t('the', {
      count: wrongIntensities.length,
    })}`,
    val2: `${t('emotion', {
      count: wrongIntensities.length,
    })}`,
    val3: `${concatenateWrongIntensities()}`,
    val4: `${t('match', {
      count: wrongIntensities.length,
    })}`,
  });

  const calculateMessageIndex = () => {
    if (!selectedEmoticon) return 0;
    if (emoticons.length === validatedEmoticons.length) return 4;
    switch (answersState) {
      case 'unanswered':
        return 1;
      case 'right':
        return 2;
      case 'wrong':
        return 3;
      default:
        return 0;
    }
  };

  return (
    <div className={styles['enigma-container']}>
      <NarratorTextHeader message={messages[calculateMessageIndex()]} />

      {selectedEmoticon && <EmotionsPanel />}

      {/* Emoticons panel */}
      <EmoticonesStyle
        bubbleHeight={narratorTextHeaderHeight}
        viewportHeight={window.innerHeight}
      >
        {emoticons?.map((emoticon) => (
          <div key={emoticon.id}>
            <button type="button">
              <ObjectContent object={emoticon} />
            </button>
          </div>
        ))}
      </EmoticonesStyle>
    </div>
  );
}

export default InterpretationMachineCalibration;
