/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// TO REFACTO link with Modal, when we refacto Modal we can move this
import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// components
import DrawerInputs from 'components/GameBoard/ReusableComponents/Enigma/Drawer/DrawerInputs';

// selectors
import { selectModalZoom } from 'components/Reducers/Modal/Modal';
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';
import {
  selectObject,
  selectObjectIndex,
} from 'components/Reducers/GameData/fetchObjects';
import { selectSession } from 'components/Reducers/session';

// constants
import { codeDrawerOfficeEmotion } from 'components/GameBoard/Emotion/Multi/constant';
import {
  addInventory,
  changeStatusOfObject,
  checkedObject,
  clickCount,
  clickCountUser,
} from 'utils/utilityFunctions';
import {
  chargerOfficeMultiEmotion,
  paper2OfficeMultiEmotion,
  sheetVisioOfficeMultiEmotion,
  emailOfficeMultiEmotion,
} from 'components/GameBoard/ReusableComponents/Objects/constants';

// assets
import 'components/GameBoard/Emotion/Multi/Enigma/Drawer/Drawer.css';
import { selectUser } from 'components/Reducers/user';

const Drawer = () => {
  const arrayCodeEmpty = Array(codeDrawerOfficeEmotion.length).fill('');
  const [code, setCode] = useState(arrayCodeEmpty);
  const [errorMessage, setErrorMessage] = useState(null);
  const [drawerStatus, setDrawerStatus] = useState(null);
  const drawerStatusOpenContent = 'open';
  const drawerStatusEmptyContent = 'empty';

  const { t } = useTranslation(['emotion-multi', 'common']);
  const session = useSelector(selectSession);
  const { socket } = useSelector(infoGameUser);
  const room = session.id;
  const { description } = useSelector(selectModalZoom);
  const { idSessionHasRoom } = useSelector(infoGameUser);
  const { participants } = useSelector(selectUser);

  // charger
  const chargerOfficeMultiEmotionIndex = useSelector((state) =>
    selectObjectIndex(state, chargerOfficeMultiEmotion)
  );
  const chargerOfficeMultiEmotionObject = useSelector((state) =>
    selectObject(state, chargerOfficeMultiEmotion)
  );

  // paper 2
  const paper2OfficeMultiEmotionIndex = useSelector((state) =>
    selectObjectIndex(state, paper2OfficeMultiEmotion)
  );
  const paper2OfficeMultiEmotionObject = useSelector((state) =>
    selectObject(state, paper2OfficeMultiEmotion)
  );

  // sheet visio
  const sheetVisioOfficeMultiEmotionIndex = useSelector((state) =>
    selectObjectIndex(state, sheetVisioOfficeMultiEmotion)
  );
  const sheetVisioOfficeMultiEmotionObject = useSelector((state) =>
    selectObject(state, sheetVisioOfficeMultiEmotion)
  );

  // email
  const emailOfficeMultiEmotionIndex = useSelector((state) =>
    selectObjectIndex(state, emailOfficeMultiEmotion)
  );
  const emailOfficeMultiEmotionObject = useSelector((state) =>
    selectObject(state, emailOfficeMultiEmotion)
  );

  const dispatch = useDispatch();

  useEffect(() => {
    socket?.on('receive_error_messaging', (data) => {
      setErrorMessage(data.errorContent);
    });
    socket?.on('receive_emotion_multi_status_drawer', (data) => {
      setDrawerStatus(data.status);
    });

    if (description.objectContent.status === drawerStatusOpenContent) {
      setDrawerStatus(drawerStatusOpenContent);
    }
    if (description.objectContent.status === drawerStatusEmptyContent) {
      setDrawerStatus(drawerStatusEmptyContent);
    }
  }, [description.objectContent.status, dispatch, socket]);

  const handleMessage = (descriptionElement) => {
    let message;

    if (drawerStatus === 'empty') {
      const objectIndex = descriptionElement.images.findIndex(
        (object) => object.type === 'empty'
      );
      message = descriptionElement.images[objectIndex].message;
    }

    if (drawerStatus === 'open') {
      const objectIndex = descriptionElement.images.findIndex(
        (object) => object.type === 'open' || object.type === 'clicked'
      );
      message = descriptionElement.images[objectIndex].message;
    }

    if (descriptionElement?.images) {
      const objectIndex = descriptionElement.images.findIndex(
        (object) => object.type === 'clicked'
      );
      message = descriptionElement.images[objectIndex].message;
    }

    return message;
  };

  const handleImage = () => {
    let imageDrawer;
    if (description?.objectContent.images) {
      const objectIndex = description?.objectContent.images.findIndex(
        (object) => object.type === 'clicked'
      );
      imageDrawer = description?.objectContent.images[objectIndex].image;
    }

    if (drawerStatus === 'open') {
      const objectIndex = description?.objectContent.images.findIndex(
        (object) => object.type === 'open'
      );
      imageDrawer = description?.objectContent.images[objectIndex].image;
    }

    if (drawerStatus === 'empty') {
      const objectIndex = description?.objectContent.images.findIndex(
        (object) => object.type === 'empty'
      );
      imageDrawer = description?.objectContent.images[objectIndex].image;
    }
    return imageDrawer;
  };

  const handleClickObject = (event) => {
    let responsesSocket = {
      status: drawerStatusEmptyContent,
      room,
    };
    socket?.emit('send_emotion_multi_status_drawer', responsesSocket);
    setDrawerStatus(drawerStatusEmptyContent);
    changeStatusOfObject(
      description.objectContent,
      drawerStatusEmptyContent,
      idSessionHasRoom,
      dispatch,
      socket,
      room
    );

    // TO REFACTO
    // i need a list of the objects of the object of the zoom object to retrieve auto
    // objects and to push him un reusablecomponents

    const objectsInDrawer = [
      {
        id: chargerOfficeMultiEmotion,
        index: chargerOfficeMultiEmotionIndex,
        description: chargerOfficeMultiEmotionObject,
      },
      {
        id: paper2OfficeMultiEmotion,
        index: paper2OfficeMultiEmotionIndex,
        description: paper2OfficeMultiEmotionObject,
      },
      {
        id: sheetVisioOfficeMultiEmotion,
        index: sheetVisioOfficeMultiEmotionIndex,
        description: sheetVisioOfficeMultiEmotionObject,
      },
    ];

    objectsInDrawer.map((objectElement) => {
      const objectContent = {
        index: objectElement.index,
        isClicked:
          objectElement.description.isClicked !== undefined
            ? objectElement.description.isClicked + 1
            : 1,
        type: 'clicked',
      };

      const descriptionModal = {};
      descriptionModal.message = handleMessage(objectElement.description);
      descriptionModal.image = handleImage(objectElement.description);
      responsesSocket = {
        ...objectContent,
        descriptionModal,
        room,
      };
      socket?.emit('send_clicked_object', responsesSocket);
      dispatch({
        type: 'CLICKED_OBJECT',
        payload: { ...objectContent },
      });

      if (!objectElement.description.wrongobject) {
        descriptionModal.buttonDescription = {
          title: 'Inventaire',
          onClick: () => {
            checkedObject(
              emailOfficeMultiEmotionObject,
              emailOfficeMultiEmotionIndex,
              idSessionHasRoom,
              dispatch,
              socket,
              room,
              'not-message'
            );
            objectsInDrawer.map((object) => {
              return addInventory(
                dispatch,
                idSessionHasRoom,
                object.description,
                object.index,
                event,
                socket,
                room,
                participants,
                session
              );
            });
          },
        };
      }
      socket?.emit('send_open_modal', responsesSocket);
      return dispatch({
        type: 'OPEN_MODAL',
        payload: descriptionModal,
      });
    });
  };

  const handleText = () => {
    let objectIndex;
    if (description.objectContent.status) {
      objectIndex = description.objectContent.images.findIndex(
        (object) => object.type === description.objectContent.status
      );
      return description.objectContent.images[objectIndex].message;
    }
    if (drawerStatus === 'open') {
      objectIndex = description.objectContent.images.findIndex(
        (object) => object.type === 'open'
      );
      return description.objectContent.images[objectIndex].message;
    }
    if (drawerStatus === 'empty') {
      objectIndex = description.objectContent.images.findIndex(
        (object) => object.type === 'empty'
      );
      return description.objectContent.images[objectIndex].message;
    }
    const isClickedCode = description.objectContent.images.filter(
      (contentImage) => contentImage.type === 'clicked'
    );
    return isClickedCode[0].message;
  };

  const submitCode = (event) => {
    let responsesSocket = { room };
    clickCount(dispatch, event, socket, responsesSocket);
    if (socket) {
      clickCountUser(participants, socket, dispatch, session);
    }
    if (code.join('') === codeDrawerOfficeEmotion) {
      responsesSocket = {
        status: drawerStatusOpenContent,
        room,
      };
      socket?.emit('send_emotion_multi_status_drawer', responsesSocket);
      setDrawerStatus(drawerStatusOpenContent);
      changeStatusOfObject(
        description.objectContent,
        'open',
        idSessionHasRoom,
        dispatch,
        socket,
        room
      );
    } else {
      const errorContent = t('drawer.error');
      responsesSocket = {
        errorContent,
        room,
      };
      socket?.emit('send_error_messaging', responsesSocket);
      setErrorMessage(errorContent);
    }
  };

  const handleDrawerInputs = () => {
    return (
      <div className="drawer-inputs">
        <DrawerInputs
          handleGoodCode={codeDrawerOfficeEmotion}
          setCode={setCode}
          submitCode={submitCode}
          code={code}
          title={t('buttonFunction.open', { ns: 'common' })}
        />
      </div>
    );
  };

  // TO REFACTO
  return (
    <div className="drawer Modal_description">
      <div className="Modal_description-text">
        <p>
          {handleText()}
          {errorMessage}
        </p>
      </div>
      {drawerStatus === null ? (
        handleDrawerInputs()
      ) : (
        <img
          className="drawer-image"
          src={handleImage()}
          alt={description.objectContent.title}
          onClick={(event) => drawerStatus === 'open' && handleClickObject(event)}
        />
      )}
    </div>
  );
};

export default Drawer;
