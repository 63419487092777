import { useSelector } from 'react-redux';

import { selectModalEnigma } from 'components/Reducers/Modal/Modal';

// scss
import styles from 'components/GameBoard/Emotion/Multi/Enigma/Table/FramePicture/FramePicture.module.scss';

const FramePicture = () => {
  const { description } = useSelector(selectModalEnigma);

  const handleImage = (descriptionContent) => {
    let imageDrawer;
    if (descriptionContent?.images) {
      const objectIndex = descriptionContent.images.findIndex(
        (object) => object.type === 'clicked'
      );
      imageDrawer = descriptionContent.images[objectIndex].image;
    }

    if (descriptionContent?.isChecked) {
      const objectIndex = descriptionContent.images.findIndex(
        (object) => object.type === 'open'
      );
      imageDrawer = descriptionContent.images[objectIndex].image;
    }
    return imageDrawer;
  };

  const handleMessage = (descriptionContent) => {
    let message;
    if (descriptionContent?.images) {
      const objectIndex = descriptionContent.images.findIndex(
        (object) => object.type === 'clicked'
      );
      message = descriptionContent.images[objectIndex].message;
    }
    if (descriptionContent?.isChecked) {
      const objectIndex = descriptionContent.images.findIndex(
        (object) => object.type === 'open'
      );
      message = descriptionContent.images[objectIndex].message;
    }

    return message;
  };

  return (
    <div className={styles.frame}>
      <p>{handleMessage(description.content)}</p>
      <img src={handleImage(description.content)} alt="frame" />
    </div>
  );
};

export default FramePicture;
