import PropTypes from 'prop-types';
import { motion, useAnimation } from 'framer-motion';

//  Components
import Button from 'components/GameBoard/ReusableComponents/Actions/Button';

// styles
import styles from 'components/GameBoard/OffreSocle/Enigmas/OffreSocleModal.module.scss';
import { useEffect } from 'react';

const OffreSocleModal = ({
  objectImage,
  objectMessage,
  onClick,
  buttonTitle,
  isAnimate,
}) => {
  const getRandomTransformOrigin = () => {
    const valueX = (16 + 40 * Math.random()) / 100;
    const valueY = (15 + 36 * Math.random()) / 100;
    return {
      originX: valueX,
      originY: valueY,
    };
  };

  const getRandomDelay = () => -(Math.random() * 0.7 + 0.05);
  const randomDuration = () => Math.random() * 0.07 + 0.23;
  const variants = {
    start: (i) => ({
      rotate: i % 2 === 0 ? [-1, 1.3, 0] : [1, -1.4, 0],
      transition: {
        delay: getRandomDelay(),
        repeat: Infinity,
        duration: randomDuration(),
      },
    }),
    reset: {
      rotate: 0,
    },
  };

  const controls = useAnimation();

  useEffect(() => {
    if (isAnimate) {
      controls.start('start');
    }
  }, [controls, isAnimate]);

  return (
    <div className={styles['modal-container']}>
      <div className={styles.modal}>
        {objectImage && (
          <motion.img
            style={{
              ...getRandomTransformOrigin(),
            }}
            src={objectImage}
            draggable="false"
            alt="object"
            width="80%"
            height="80%"
            variants={variants}
            animate={controls}
          />
        )}
        {objectMessage && <p id={styles['object-text']}>{objectMessage}</p>}
      </div>
      <Button
        onClick={() => onClick()}
        title={buttonTitle}
        buttonType="offreSocle"
      />
    </div>
  );
};

OffreSocleModal.propTypes = {
  objectImage: PropTypes.string,
  objectMessage: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  isAnimate: PropTypes.bool,
};

OffreSocleModal.defaultProps = {
  objectImage: undefined,
  objectMessage: undefined,
  isAnimate: false,
};

export default OffreSocleModal;
