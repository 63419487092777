const initialState = {
  list: [],
  currentStep: null,
  introStep: false,
  error: false,
  loading: false,
  startMessage: 0,
  startQuestionnary: 0,
  isAdvice: false,
  isScoreBoard: false,
  isEvaluation: false,
  isPedagogy: false,
  isTutoFinish: 0,
  messages: null,
};

const Steps = (state = initialState, action) => {
  switch (action.type) {
    case 'RESET_STEPS': {
      return {
        ...state,
        currentStep: null,
      };
    }
    case 'ALL_STEPS': {
      return {
        ...state,
        list: action.payload,
      };
    }
    case 'ALL_MESSAGES_OF_GAME': {
      return {
        ...state,
        messages: action.payload,
      };
    }
    case 'CURRENT_STEP': {
      let object;
      let objectIndex;

      if (action.payload !== undefined) {
        object = state.list.find((step) => step.id === action.payload);
        objectIndex = state.list.findIndex((step) => step.id === action.payload);
      } else {
        object = state.list.find((step) => !step.isFound);
        objectIndex = state.list.findIndex((step) => !step.isFound);
      }

      if (object) {
        return {
          ...state,
          currentStep: {
            ...object,
            level: objectIndex,
          },
        };
      }

      return { ...state };
    }
    case 'STEP_INTRO': {
      return {
        ...state,
        introStep: action.payload,
      };
    }
    case 'START_MESSAGE_PREVENTION': {
      return {
        ...state,
        startMessage: 1,
        startQuestionnary: 0,
      };
    }
    case 'START_QUESTIONNARY': {
      return {
        ...state,
        startQuestionnary: 1,
      };
    }
    case 'STOP_QUESTIONNARY': {
      return {
        ...state,
        startQuestionnary: 0,
        currentStep: null,
      };
    }
    case 'END_MESSAGE_PREVENTION': {
      return {
        ...state,
        startMessage: 0,
        currentStep: {
          ...state.currentStep,
          startMessage: 0,
        },
      };
    }
    case 'NEXT_MESSAGE': {
      const newList = [...state.list];
      const stepChangeIndex = newList.findIndex(
        (step) => step.id === action.payload.id
      );
      newList[stepChangeIndex].isFound = 1;

      return {
        ...state,
        list: newList,
      };
    }
    case 'START_ADVICE': {
      return {
        ...state,
        isAdvice: true,
      };
    }
    case 'STOP_ADVICE': {
      return {
        ...state,
        isAdvice: false,
      };
    }
    case 'START_SCOREBOARD': {
      return {
        ...state,
        isScoreBoard: true,
      };
    }
    case 'STOP_SCOREBOARD': {
      return {
        ...state,
        isScoreBoard: false,
      };
    }
    case 'RESET_TUTO': {
      return {
        ...state,
        isTutoFinish: action.payload,
      };
    }
    case 'START_EVALUATION': {
      return {
        ...state,
        isEvaluation: true,
      };
    }
    case 'STOP_EVALUATION': {
      return {
        ...state,
        isEvaluation: false,
      };
    }

    case 'START_PEDAGOGY': {
      return {
        ...state,
        isPedagogy: true,
      };
    }
    case 'STOP_PEDAGOGY': {
      return {
        ...state,
        isPedagogy: false,
      };
    }
    default:
      return state;
  }
};

// Define the selector functions
export const selectSteps = (state) => state.Steps;

export default Steps;
