import { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import Audio from 'components/GameBoard/ReusableComponents/Actions/Audio';
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';
import { useDispatch, useSelector } from 'react-redux';
import { volumePersonna } from 'components/GameBoard/OffreSocle/constants';
import { selectModalEnigma } from 'components/Reducers/Modal/Modal';
import { directorId } from 'components/GameBoard/ReusableComponents/Objects/constants';

const DialogueRowStyle = styled(motion.div)`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  align-items: ${({ direction }) =>
    direction === 'row' ? 'flex-start' : 'flex-end'};
  justify-content: flex-start;
  padding: 15px;
  width: 100%;
  ref: ${({ ref }) => ref};

  p {
    padding: 20px;
    margin: 10px 20px 0;
    background: ${({ direction, theme }) =>
      direction === 'row' ? '#FFF' : theme.grapeJelly};
    color: ${({ direction }) => (direction === 'row-reverse' ? '#FFF' : 'black')};
    box-shadow: -12px 15px 12px rgba(65, 36, 116, 0.12);
    border-radius: ${({ direction }) =>
      direction === 'row' ? '0px 20px 20px 20px' : '20px 20px 0px 20px'};
    font-weight: 400;
    font-size: ${({ smallView }) => (smallView ? '1rem' : '1.25rem')};
    line-height: 23px;
    min-width: 0;
    word-wrap: break-word;
    white-space: pre-wrap;
  }

  img {
    width: ${({ smallView }) => (smallView ? '128px' : '160px')};
    height: ${({ smallView }) => (smallView ? '108px' : '135px')};
  }
`;

const DialogueRow = forwardRef(function DialogueRow(
  { characterImage, direction, dialogueText, smallView },
  ref
) {
  const { t } = useTranslation('offreSocle');

  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const { soundtrack } = useSelector(infoGameUser);
  const dispatch = useDispatch();
  const { description } = useSelector(selectModalEnigma);

  // animation bounce
  const cardVariants = {
    offscreen: {
      y: 50,
    },
    onscreen: {
      y: 0,
      transition: {
        type: 'spring',
        bounce: 0.4,
        duration: 0.8,
      },
    },
  };

  useEffect(() => {
    if (description?.content?.id !== directorId) {
      dispatch({ type: 'MUTE_MUSIC', payload: false });
    }
    return () => {
      if (description?.content?.id !== directorId) {
        dispatch({ type: 'MUTE_MUSIC', payload: true });
      }
    };
  }, [description?.content?.id, dispatch]);

  return (
    <DialogueRowStyle
      direction={direction}
      ref={ref}
      smallView={smallView}
      animate={{ scale: 1 }}
      transition={{
        type: 'spring',
        stiffness: 260,
        damping: 40,
      }}
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0.8 }}
    >
      <motion.img
        src={characterImage}
        alt={t('character')}
        onLoad={() => setIsImageLoaded(true)}
        variants={cardVariants}
      />
      {dialogueText.audio && (
        <Audio
          sound={dialogueText.audio}
          id="MULTI-Theme"
          condition={soundtrack}
          volume={volumePersonna}
        />
      )}
      {isImageLoaded && <p>{dialogueText.text}</p>}
    </DialogueRowStyle>
  );
});

DialogueRow.propTypes = {
  characterImage: PropTypes.string.isRequired,
  direction: PropTypes.string.isRequired,
  dialogueText: PropTypes.string.isRequired,
  smallView: PropTypes.bool,
};

DialogueRow.defaultProps = {
  smallView: false,
};

export default DialogueRow;
