import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Components
import OffreSocleModal from 'components/GameBoard/OffreSocle/Enigmas/OffreSocleModal';
import VideoConference from 'components/GameBoard/OffreSocle/Enigmas/VideoConference';
// Selector functions
import {
  selectObject,
  selectObjectIndex,
} from 'components/Reducers/GameData/fetchObjects';
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';
import { selectSteps } from 'components/Reducers/Steps/Steps';

// API
import { putSessionsHasRoomHasObject } from 'API/sessionsHasRoomHasObject';
// Constants & style
import { selectOffreSocle } from 'components/Reducers/offreSocle';
import { doctorComputer } from 'components/GameBoard/ReusableComponents/Objects/constants';

const AnswerVideoConference = () => {
  const { t } = useTranslation('common');

  const dispatch = useDispatch();

  const computer = useSelector((state) => selectObject(state, doctorComputer));
  const computerIndex = useSelector((state) =>
    selectObjectIndex(state, computer.id)
  );
  const { list } = useSelector(selectSteps);
  const firstMessageFound = list?.[0]?.isFound;
  const videoConf = computer.images.find((object) => object.type === 'clicked');
  const videoConfAfterEnigma = computer.images.find(
    (object) => object.type === 'enigma-ended'
  );
  const { idSessionHasRoom } = useSelector(infoGameUser);
  const { isVideoConfAnswered } = useSelector(selectOffreSocle);

  // When we refresh the game
  useEffect(() => {
    if (computer.isChecked) {
      dispatch({ type: 'ANSWER_VIDEO_CONF' });
      dispatch({ type: 'MUTE_MUSIC', payload: false });
    }
    return () => {
      dispatch({ type: 'MUTE_MUSIC', payload: true });
    };
  }, [computer.isChecked, dispatch]);

  const handleEnigmaDisplay = () => {
    if (firstMessageFound) {
      return (
        <OffreSocleModal
          objectImage={videoConfAfterEnigma.image}
          objectMessage={videoConfAfterEnigma.message}
          buttonTitle={t('buttonFunction.return')}
          onClick={() => {
            dispatch({ type: 'CLOSE_MODAL_ENIGMA' });
          }}
        />
      );
    }

    if (!isVideoConfAnswered) {
      return (
        <OffreSocleModal
          objectImage={videoConf.image}
          objectMessage={videoConf.message}
          onClick={async () => {
            const clickOnVideo = new Audio(videoConf.sound);
            clickOnVideo.volume = 0.1;
            clickOnVideo.play();
            dispatch({
              type: 'CLICKED_OBJECT',
              payload: {
                type: 'checked',
                index: computerIndex,
                isChecked: 1,
              },
            });
            await putSessionsHasRoomHasObject(idSessionHasRoom, {
              object_id: computer.id,
              isChecked: 1,
            });
            dispatch({ type: 'ANSWER_VIDEO_CONF' });
          }}
          buttonTitle={t('buttonFunction.rejoin')}
        />
      );
    }

    return <VideoConference />;
  };

  return handleEnigmaDisplay();
};

export default AnswerVideoConference;
