import PropTypes from 'prop-types';
import { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { gsap } from 'gsap';
import { Draggable } from 'gsap/Draggable';
// Components
import AudioComponent from 'components/GameBoard/ReusableComponents/Actions/Audio';
// Style
import styled from 'styled-components';
import styles from 'components/GameBoard/Emotion/Solo/Enigma/WiresPanel.module.scss';
// Constants & utils
import { enigmas } from 'components/GameBoard/Emotion/Solo/constants';
import { calculatePoints, clickCount } from 'utils/utilityFunctions';
// Assets
import connectedSound from 'sound/wire-connection.mp3';
import toggleSound from 'sound/Vaccination/Electrical/toggle_003.ogg';
import successSound from 'sound/Success.mp3';
import failSound from 'sound/fail.mp3';

// Selector functions
import {
  selectBehaviourMachineCalibrationEnigma,
  selectRightWires,
} from 'components/Reducers/emotion';
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';

// CSS in JS
const SpanStyle = styled.span`
  position: absolute;
  top: ${({ wire }) => wire.coordinates.start.y}px;
  left: ${({ wire }) => wire.coordinates.start.x}px;
  // size of the element that launches the drag
  height: 13px;
  width: 22px;
  z-index: 20;
`;

const PathStyle = styled.path`
  position: absolute;
  fill: none;
  stroke: ${({ wire }) => wire.color};
  stroke-width: 15;
`;

const {
  validatedRadius,
  snapRadius,
  bezierWeight,
  connectorEndUrl,
  connectorEndCoordinates,
  wires,
  wiresPanelUrl,
  yOffset,
  basePoints,
  decreasePoints,
  lightsUrl,
} = enigmas.BehaviourMachineCalibration;

const WiresPanel = ({ shuffledBehaviours, wrongAnswersCount }) => {
  const { t } = useTranslation('emotion');

  const dispatch = useDispatch();

  // This ref is connected to the <span> that will be dragged
  const spanRef = useRef([]);
  // This ref is connected to <path> in <PathStyle/>
  const pathRef = useRef([]);
  // This ref is connected to <img> of each connector end
  const endWireRef = useRef([]);

  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [wiresState, setWiresState] = useState(wires);
  const [wrongBehaviourIndex, setWrongBehaviourIndex] = useState(0);

  const { behaviourIndex, wrongBehaviours, connectedWires, headerMessageStatus } =
    useSelector(selectBehaviourMachineCalibrationEnigma);

  const rightWires = useSelector(selectRightWires);

  const { soundtrack } = useSelector(infoGameUser);
  /**
   * Update the SVG element <path> in order to a draw a Bezier curve
   * @param {Object} startingCoordinates
   * @param {number} startingCoordinates.x
   * @param {number} startingCoordinates.y
   * @param {function} currentCoordinates
   * @param {SVGSVGElement} path
   */
  const updatePath = (startingCoordinates, currentCoordinates, path) => {
    // Starting point coordinates
    const x1 = startingCoordinates.x;
    const y1 = startingCoordinates.y + yOffset;

    // Ending point coordinates
    const x4 = currentCoordinates('x');
    const y4 = currentCoordinates('y');

    /* Formula to calculate the control points coordinates.
    The control points essentially describe the slope of the line starting at each point */
    const dx = Math.abs(x4 - x1) * bezierWeight;

    // Coordinates of the control point 1
    const x2 = x1 + dx;

    // Coordinates of the control point 2
    const x3 = x4 - dx;

    // Bezier curve definition
    const data = `M${x1} ${y1} C ${x2} ${y1} ${x3} ${y4 + y1} ${x4 + x1} ${
      y4 + y1
    }`;

    // Set `d` parameter of the `path` SVG element with data
    path.setAttribute('d', data);
  };

  /**
   * Return the closest position in snapPoints from currentHandle
   * @param {Object[]} snapPoints coordinates of all the possible targets
   * @param {HTMLElement} currentHandle handle being dragged
   * @returns {Object | undefined} undefined if none of the targets are in the range of the handle
   */
  const closestPosition = (snapPoints, currentHandle, radius) => {
    const foundPosition = snapPoints.find(
      (snapPoint) =>
        Math.abs(snapPoint.x - currentHandle.getBoundingClientRect().x) <= radius &&
        Math.abs(snapPoint.y - currentHandle.getBoundingClientRect().y) <= radius
    );

    return foundPosition;
  };

  /**
   * Reset the path and the span elements of a wire
   * @param {HTMLElement} pathElement - <path> element
   * @param {HTMLElement} handleElement - <span> element
   */
  const resetWire = (pathElement, handleElement) => {
    pathElement.setAttribute('d', '');
    gsap.set(handleElement, {
      x: 0,
      y: 0,
    });
  };

  // Store if an answer is right or wrong
  const validateAnswer = useCallback(
    (wire) => {
      const newWires = [...wiresState];
      const wireIndex = newWires.findIndex((newWire) => newWire.name === wire.name);
      // Check if the connected wire is the expected one
      if (shuffledBehaviours[behaviourIndex].wireName === wire.name) {
        newWires[wireIndex].isValidated = true;
        setWiresState(newWires);
        /* Once a wire is right at the nth try, remove it from wrongBehaviours.
      The dispatch will only happen at the end of the function so at row 278, it hasn't been removed yet.
      Same for setWrongBehaviourIndex. */
        if (connectedWires.length) {
          dispatch({
            type: 'REMOVE_WRONG_BEHAVIOUR',
            payload: { index: wrongBehaviourIndex },
          });
          setWrongBehaviourIndex((prev) => prev - 1);
        }
      } else {
        newWires[wireIndex].isValidated = false;
        setWiresState(newWires);
        if (!connectedWires.length)
          dispatch({
            type: 'ADD_WRONG_BEHAVIOUR',
            payload: shuffledBehaviours[behaviourIndex],
          });
      }
    },
    [
      behaviourIndex,
      connectedWires.length,
      dispatch,
      shuffledBehaviours,
      wiresState,
      wrongBehaviourIndex,
    ]
  );

  // Disconnect the wrong wires
  const disconnectWrongWires = useCallback(
    (wrongWiresState) => {
      wrongWiresState?.forEach((wrongWire) => {
        const newWires = [...wiresState];
        const foundIndex = newWires.findIndex(
          (newWire) => newWire.name === wrongWire.name
        );
        resetWire(pathRef.current[foundIndex], spanRef.current[foundIndex]);

        newWires[foundIndex].isConnected = false;
        setWiresState(newWires);
      });
    },
    [wiresState]
  );

  //  Display next behaviour or do the combinaison validation if there is no behaviour left to display
  const nextBehaviourOrValidation = useCallback(
    (disconnectedWiresState, rightWiresState, wrongWiresState) => {
      // Check if we still have behaviours left to display
      if (disconnectedWiresState.length) {
        if (connectedWires.length) {
          const findWrongBehaviourIndex = shuffledBehaviours.findIndex(
            (shuffledBehaviour) =>
              shuffledBehaviour.id === wrongBehaviours[wrongBehaviourIndex + 1].id
          );
          dispatch({
            type: 'SET_BEHAVIOUR_INDEX',
            payload: findWrongBehaviourIndex,
          });
          setWrongBehaviourIndex((prev) => prev + 1);
        } else {
          dispatch({
            type: 'SET_BEHAVIOUR_INDEX',
            payload: behaviourIndex + 1,
          });
        }
        dispatch({
          type: 'SET_HEADER_MESSAGE_STATUS',
          payload: 'current-connexion',
        });
      } else {
        // All wires are connected with the right behaviours
        if (rightWiresState.length === shuffledBehaviours.length) {
          dispatch({
            type: 'SET_HEADER_MESSAGE_STATUS',
            payload: 'right-answers-1',
          });
          if (soundtrack) new Audio(successSound).play();
          // Update the score
          dispatch({
            type: 'UPDATE_GAME_SCORE',
            payload: calculatePoints(basePoints, wrongAnswersCount, decreasePoints),
          });
        } else {
          // At least one wire is connected with the wrong behaviour
          dispatch({
            type: 'SET_HEADER_MESSAGE_STATUS',
            payload: 'wrong-answers',
          });
        }

        // Disconnect the wrong wires
        disconnectWrongWires(wrongWiresState);

        // Reset wrongBehaviourIndex in case there are still wrong behaviours to display
        setWrongBehaviourIndex(0);

        dispatch({
          type: 'SET_CONNECTED_WIRES',
          payload: wiresState,
        });
      }
    },
    [
      behaviourIndex,
      connectedWires.length,
      disconnectWrongWires,
      dispatch,
      shuffledBehaviours,
      wiresState,
      wrongAnswersCount,
      wrongBehaviourIndex,
      wrongBehaviours,
      soundtrack,
    ]
  );

  useEffect(() => {
    // Drag possible only after we display the 1st two messages
    if (headerMessageStatus.includes('intro')) return;

    // Create a Draggable instance for each wire
    wiresState.forEach((wire, index) => {
      const newWires = [...wiresState];
      const wireIndex = newWires.findIndex((newWire) => newWire.name === wire.name);

      const currentPath = pathRef.current[index];
      const currentHandle = spanRef.current[index];

      const currentCoordinates = gsap.getProperty(currentHandle);

      const dragInstance = Draggable.create(currentHandle, {
        onDrag: () => {
          updatePath(wire.coordinates.start, currentCoordinates, currentPath);
        },
        onDragEnd: async () => {
          const foundPosition = closestPosition(
            endWireRef.current,
            currentHandle,
            validatedRadius
          );
          const foundPositionIndex = endWireRef.current.findIndex(
            (endWireElement) =>
              endWireElement.x === foundPosition?.x &&
              endWireElement.y === foundPosition?.y
          );
          // Fail to connect a wire

          // Not close to one of the wire end or not linked to the current behaviour
          if (!foundPosition || foundPositionIndex !== behaviourIndex) {
            resetWire(currentPath, currentHandle);
            if (soundtrack) new Audio(toggleSound).play();
            dispatch({
              type: 'SET_HEADER_MESSAGE_STATUS',
              payload: !foundPosition ? 'no-wire-end' : 'no-current-behaviour',
            });
          } else {
            // Succeed in connecting a wire
            newWires[wireIndex].isConnected = true;

            clickCount(dispatch, {});

            setWiresState(newWires);
            const disconnectedWiresState = wiresState.filter(
              (wireElement) => !wireElement.isConnected
            );
            if (disconnectedWiresState.length && soundtrack)
              new Audio(connectedSound).play();

            // Store if an answer is right or wrong
            validateAnswer(wire);

            const wrongWiresState = wiresState
              .filter((wireElement) => wireElement.isValidated === false)
              .sort(
                (firstElement, secondElement) =>
                  firstElement.behaviourIndex - secondElement.behaviourIndex
              );

            const rightWiresState = wiresState.filter(
              (wireElement) => wireElement.isValidated === true
            );

            nextBehaviourOrValidation(
              disconnectedWiresState,
              rightWiresState,
              wrongWiresState
            );
          }
        },
        /* Snap the wires to any behaviour.The behaviour once the mouse is released is described in onDragEnd() */
        liveSnap: {
          points: connectorEndCoordinates,
          radius: snapRadius,
        },
      });
      // Disable the drag once a wire is connected
      if (wiresState[wireIndex].isConnected) dragInstance[0].disable();
      // Disable the drag before launching the retry of combination
      if (headerMessageStatus === 'wrong-answers') dragInstance[0].disable();
    });
  }, [
    behaviourIndex,
    dispatch,
    headerMessageStatus,
    nextBehaviourOrValidation,
    validateAnswer,
    wiresState,
    soundtrack,
  ]);

  /**
   * Store the coordinates of each end of wire image in a ref
   * @param {HTMLElement} element - referenced DOM element
   * @param {number} index
   */
  const storeCoordinates = (element, index) => {
    if (headerMessageStatus === 'current-connexion' && element) {
      endWireRef.current[index] = {
        x: element.getBoundingClientRect().x,
        y: element.getBoundingClientRect().y,
      };
    }
  };

  // Define the small light url
  const defineLightStatus = (wire) => {
    const isRight = rightWires.find((rightWire) => rightWire.name === wire.name);

    return isRight ? lightsUrl.right : lightsUrl.wrong;
  };

  return (
    <div className={styles['wires-panel']}>
      <AudioComponent
        condition={headerMessageStatus === 'right-answers-1' && soundtrack}
        sound={successSound}
      />
      <AudioComponent
        condition={headerMessageStatus === 'wrong-answers' && soundtrack}
        sound={failSound}
      />
      <img
        src={wiresPanelUrl}
        alt={t('panel')}
        onLoad={() => setIsImageLoaded(true)}
      />
      {isImageLoaded && (
        <>
          <div className={styles['wires-lights']}>
            {wiresState.map((wire) =>
              headerMessageStatus === 'wrong-answers' ||
              headerMessageStatus === 'right-answers-1' ? (
                <img
                  src={defineLightStatus(wire)}
                  alt={t('warningLight')}
                  key={wire.name}
                />
              ) : (
                ''
              )
            )}
          </div>
          <div className={styles['wires-start-container']}>
            {wiresState.map((wire, index) => (
              <div key={wire.name}>
                <SpanStyle
                  ref={(spanElement) => (spanRef.current[index] = spanElement)}
                  wire={wire}
                />
                <img src={wire.urlStart} alt={`${t('wireStart')} ${index + 1}`} />
                <svg>
                  <PathStyle
                    ref={(pathElement) => (pathRef.current[index] = pathElement)}
                    wire={wire}
                  />
                </svg>
              </div>
            ))}
          </div>
          <div className={styles['wires-end-container']}>
            {wiresState.map((wire, index) => (
              <img
                src={connectorEndUrl}
                alt={t('connectorEnd')}
                key={wire.name}
                id={index}
                ref={(imageElement) => storeCoordinates(imageElement, index)}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

WiresPanel.propTypes = {
  shuffledBehaviours: PropTypes.arrayOf(
    PropTypes.shape({
      wireName: PropTypes.string.isRequired,
    })
  ),
  wrongAnswersCount: PropTypes.number.isRequired,
};

WiresPanel.defaultProps = {
  shuffledBehaviours: undefined,
};

gsap.registerPlugin(Draggable);

export default WiresPanel;
