import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// components
import Button from 'components/GameBoard/ReusableComponents/Actions/Button';

// scss
import styles from 'components/GameBoard/ReusableComponents/Multi/Shared/Questionnary/Type/Choice/Choice.module.scss';

// api
import { getResponses } from 'API/questionnary';

// selectors
import { selectSession } from 'components/Reducers/session';
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';

const Choice = ({ question, activeIndex, handleAnswer, handleReturn }) => {
  const { t } = useTranslation('common');

  const session = useSelector(selectSession);
  const { socket } = useSelector(infoGameUser);
  const room = session.id;

  const [answer, setAnswer] = useState(null);

  const [arrayResponses, setArrayResponses] = useState([]);

  useEffect(() => {
    async function fetchResponses() {
      const data = await getResponses(question.id);
      setArrayResponses(data);
    }
    fetchResponses();
  }, [question.id]);

  useEffect(() => {
    socket?.on('receive_reset_answer', () => {
      setAnswer(null);
    });
  }, [socket]);

  return (
    <div className={styles.choice}>
      <h4 className={styles.question}>{question.title}</h4>
      <div className={styles['list-response']}>
        {arrayResponses.map((response, index) => (
          <Button
            key={response.id}
            buttonType="return"
            title={`${t('buttonFunction.choice')} ${index + 1}`}
            onClick={() => setAnswer(response)}
          />
        ))}
      </div>
      {answer && (
        <div className={styles.answer}>
          <p>{answer.title} </p>
        </div>
      )}
      <div className={styles.buttons}>
        {activeIndex !== 0 && (
          <Button
            buttonType="action"
            title={t('buttonFunction.return')}
            onClick={() => handleReturn()}
          />
        )}
        <Button
          buttonType="action"
          title={t('buttonFunction.next')}
          onClick={() => {
            const responsesSocket = {
              room,
            };
            socket?.emit('send_reset_answer', responsesSocket);
            setAnswer(null);
            handleAnswer(answer, question);
          }}
        />
      </div>
    </div>
  );
};

Choice.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    image: PropTypes.string,
    is_debriefing: PropTypes.number,
    level: PropTypes.number,
    maximum: PropTypes.string,
    minimum: PropTypes.string,
    response_type: PropTypes.string,
    responses: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        level: PropTypes.number,
        weighting: PropTypes.number,
      })
    ),
  }).isRequired,
  activeIndex: PropTypes.number.isRequired,
  handleAnswer: PropTypes.func.isRequired,
  handleReturn: PropTypes.func.isRequired,
};

export default Choice;
