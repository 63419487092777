import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL;

/**
 * Get the prevention messages for a particular room id
 * @param {number} currentRoom
 * @returns {Promise<object[]>} Response of the API call if successful
 */
const getRoomHasMessages = async (currentRoomId) => {
  const { data } = await axios.get(
    `${baseURL}/api/roomHasMessages/${currentRoomId}`
  );
  return data;
};

/**
 * Update the session message of prevention
 * @param {number} idSessionHasRoom
 * @param {number} idMessage
 * @returns {Promise<object[]>} Response of the API call if successful
 */
const updateCurrentStep = async (idSessionHasRoom, idMessage) => {
  const { data } = await axios.put(
    `${baseURL}/api/sessionsHasRoom/${idSessionHasRoom}/modifications`,
    {
      current_step: idMessage,
    }
  );
  return data;
};

export { getRoomHasMessages, updateCurrentStep };
