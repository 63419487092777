import { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DatePicker from 'react-date-picker';
import axios from 'axios';
import Button from '../../../ReusableComponents/Actions/Button';
import '../../../../../assets/css/components/GameBoard/Obesity/Enigma/BoardSun/History.css';

const BASE_URL = process.env.REACT_APP_BASE_URL;

function History({ allObjects, dispatch, idSessionHasRoom }) {
  const [value, onChange] = useState(null);
  const dateString = new Date(value);

  const month = dateString.getMonth() + 1;
  const day = dateString.getDate();
  const year = dateString.getFullYear();

  const idTimeOne = 200;
  const idTimeTwo = 201;
  const idTimeThree = 202;

  const stringDate = `${day}/${month}/${year}`;

  const goodDates = {
    '2/1/2017': {
      start: '08H00',
      end: '19H16',
      passed: '11H16',
      id: idTimeOne,
    },
    '2/6/2017': {
      start: '06H10',
      end: '20H19',
      passed: '14H09',
      id: idTimeTwo,
    },
    '2/12/2017': {
      start: '05H45',
      end: '17H12',
      passed: '11H27',
      id: idTimeThree,
    },
  };

  const addInventory = (objectElement, index) => {
    if (!objectElement.isInventory) {
      axios
        .put(`${BASE_URL}/api/sessionsHasRoomHasObject/${idSessionHasRoom}`, {
          object_id: objectElement.id,
          isInventory: true,
        })
        .then(() => {
          dispatch({
            type: 'CLICKED_OBJECT',
            payload: {
              index,
              isInventory: true,
              type: 'inventory',
            },
          });
          dispatch({
            type: 'ADD_INVENTORY',
            payload: objectElement,
          });
          dispatch({
            type: 'CLOSE_MODAL_ENIGMA',
          });
          dispatch({
            type: 'INCREMENT_COUNTER',
          });
        });
    }
  };

  const handleObjectTimeInventory = (date) => {
    const timeObject = allObjects.find(
      (objectContent) => objectContent.id === date.id
    );
    const timeIndex = allObjects.findIndex(
      (objectContent) => objectContent.id === date.id
    );

    addInventory(timeObject, timeIndex);
  };

  return (
    <div className="History">
      <div className="boardsun-enigma-left-left">
        <p>Historique</p>
      </div>
      <div className="History_top">
        <div className="History_top-left">
          <p>Insérez une date</p>
          <DatePicker
            calendarClassName="Date"
            onChange={onChange}
            value={null || value}
            disableCalendar
          />
        </div>
        <div className="History_top-right">
          <p>Temps total: </p>
          <p>{goodDates[stringDate] && goodDates[stringDate].passed}</p>
        </div>
      </div>
      <div className="History_bottom">
        <div className="History_bottom-top">
          <p>
            Heure d&apos;allumage:
            {goodDates[stringDate] && goodDates[stringDate].start}
          </p>
          <p>
            Heure d&apos;extinction:
            {goodDates[stringDate] && goodDates[stringDate].end}
          </p>
        </div>
        <div className="History_bottom-bottom">
          <Button
            buttonType="action"
            title="Enregistrer"
            onClick={() =>
              goodDates[stringDate] !== undefined &&
              handleObjectTimeInventory(goodDates[stringDate])
            }
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  allObjects: state.Objects.AllObjects,
  idSessionHasRoom: state.GameUsers.idSessionHasRoom,
});

History.propTypes = {
  dispatch: PropTypes.func.isRequired,
  allObjects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
  idSessionHasRoom: PropTypes.number.isRequired,
};

export default connect(mapStateToProps)(History);
