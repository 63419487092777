import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// selectors
import { selectUser } from 'components/Reducers/user';
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';

// utils
import { animationConfetti, filterObject } from 'utils/utilityFunctions';
import { initMainUser } from 'components/Action';

// components
import Participant from 'components/GameBoard/ReusableComponents/Multi/Participants/Participant/Participant';

import styles from 'components/GameBoard/ReusableComponents/Multi/Participants/Participants.module.scss';

const Participants = ({ setStatusCheckRoom }) => {
  const { t } = useTranslation('common');

  const videoRefCurrentParticipant = useRef(null);
  const videoRefParticipants = useRef([]);
  const useOneTimeEffect = useRef(false);
  const { participants, mainStream, currentUser } = useSelector(selectUser);
  const { socket } = useSelector(infoGameUser);
  const participantKey = Object.keys(participants);
  const dispatch = useDispatch();

  const notCurrentParticipants = filterObject(
    participants,
    (obj) => !obj.currentUser
  );

  useEffect(() => {
    if (!useOneTimeEffect.current) {
      // for some actions (like click / add inventory) of main user we have to send
      // this actions to the others player in the room to see what the main user do
      // initialisation of the main user

      // ROUND BY ROUND
      socket.on('receive_init_main_user', (data) => {
        dispatch(initMainUser(data.mainUserId));
      });
      socket.on('receive_introduction_finish', () => {
        setStatusCheckRoom('introduction-finish');
      });
      socket.on('receive_increment_counter', () => {
        dispatch({ type: 'INCREMENT_COUNTER' });
      });
      socket.on('receive_increment_counter_user', (data) => {
        dispatch({
          type: 'UPDATE_COUNT_USER',
          payload: {
            mainUserId: data.mainUserId,
            nextCounter: data.nextCounter,
          },
        });
      });
      socket.on('receive_change_main_user', (data) => {
        dispatch({
          type: 'CHANGE_MAIN_USER',
          payload: {
            mainUserId: data.mainUserId,
            nextMainUserId: data.nextMainUserId,
          },
        });
      });

      // MODAL
      socket.on('receive_open_modal', (data) => {
        dispatch({
          type: 'OPEN_MODAL',
          payload: data.descriptionModal,
        });
      });
      socket.on('receive_close_modal', () => {
        dispatch({
          type: 'CLOSE_MODAL',
        });
      });
      socket.on('receive_open_modal_zoom', (data) => {
        dispatch({
          type: 'OPEN_MODAL_ZOOM',
          payload: data.descriptionModal,
        });
      });
      socket.on('receive_close_modal_zoom', () => {
        dispatch({
          type: 'CLOSE_MODAL_ZOOM',
        });
      });
      socket.on('receive_open_zoom', (data) => {
        dispatch({
          type: 'OPEN_ZOOM',
          payload: data.descriptionModal,
        });
      });
      socket.on('receive_close_zoom', () => {
        dispatch({
          type: 'CLOSE_ZOOM',
        });
      });
      socket.on('receive_open_modal_enigma', (data) => {
        dispatch({
          type: 'OPEN_MODAL_ENIGMA',
          payload: data.descriptionModal,
        });
      });

      socket.on('receive_close_modal_enigma', () => {
        dispatch({
          type: 'CLOSE_MODAL_ENIGMA',
        });
      });

      // OBJECTS
      socket.on('receive_clicked_object', (data) => {
        dispatch({
          type: 'CLICKED_OBJECT',
          payload: {
            index: data.index,
            isInventory: data.isInventory,
            isClicked: data.isClicked,
            isChecked: data.isChecked,
            type: data.type,
            status: data.status,
          },
        });
      });
      socket.on('receive_update_object', (data) => {
        dispatch({
          type: 'UPDATE_OBJECT',
          payload: {
            id: data.id,
            status: data.status,
          },
        });
      });

      // INVENTORY
      socket.on('receive_add_inventory', (data) => {
        dispatch({
          type: 'ADD_INVENTORY',
          payload: data.objectElement,
        });
      });
      socket.on('receive_remove_of_inventory', (data) => {
        dispatch({
          type: 'REMOVE_OF_INVENTORY',
          payload: data.idObjectToRemove,
        });
      });

      // HUD
      socket.on('receive_update_game_score', (data) => {
        dispatch({
          type: 'UPDATE_GAME_SCORE',
          payload: data.scoreAddInventory,
        });
      });

      // STEPS
      socket.on('receive_start_message_prevention', () => {
        dispatch({
          type: 'START_MESSAGE_PREVENTION',
        });
      });

      socket.on('receive_current_step', (data) => {
        dispatch({
          type: 'CURRENT_STEP',
          payload: data.id,
        });
      });
      socket.on('receive_end_message_prevention', () => {
        dispatch({
          type: 'END_MESSAGE_PREVENTION',
        });
      });
      socket?.on('receive_start_questionnary', () => {
        dispatch({
          type: 'START_QUESTIONNARY',
        });
      });
      socket.on('receive_stop_questionnary', () => {
        dispatch({
          type: 'STOP_QUESTIONNARY',
        });
      });
      socket.on('receive_next_message', (data) => {
        dispatch({
          type: 'NEXT_MESSAGE',
          payload: data.currentStep,
        });
      });

      socket.on('receive_reset_answer', () => {
        dispatch({
          type: 'RESET_ANSWER',
        });
      });
      socket.on('receive_room_active', (data) => {
        dispatch({
          type: 'ROOM_ACTIVE',
          payload: {
            room: data.roomSlug,
            active: data.isActive,
          },
        });
      });

      // GENERAL
      socket.on('receive_confetti', () => {
        animationConfetti();
      });
      socket.on('receive_users_in_game', () => {
        dispatch({ type: 'USERS_IN_GAME' });
      });
    }
    return () => {
      useOneTimeEffect.current = true;
    };
  }, [dispatch, setStatusCheckRoom, socket, t]);

  useEffect(() => {
    if (videoRefCurrentParticipant.current) {
      videoRefCurrentParticipant.current.srcObject = mainStream;
      videoRefCurrentParticipant.current.onloadedmetadata = () => {
        videoRefCurrentParticipant.current.play();
      };
    }
  }, [currentUser, mainStream]);

  const currentUserObject = currentUser
    ? participants[`${Object.keys(currentUser)[0]}`]
    : null;

  const participantsArray = Object.keys(notCurrentParticipants).map((element) => {
    const currentParticipant = participants[element];
    const pc = currentParticipant.peerConnection;
    const remoteStream = new MediaStream();
    const currentIndex = element;
    if (pc) {
      pc.ontrack = (event) => {
        event.streams[0].getTracks().forEach((track) => {
          remoteStream.addTrack(track);
        });

        const videoElement = videoRefParticipants.current[currentIndex];
        if (videoElement) videoElement.srcObject = remoteStream;
      };
    }

    return (
      <Participant
        key={currentIndex}
        videoRef={videoRefParticipants}
        currentParticipant={currentParticipant}
        currentIndex={currentIndex}
        showAvatar={currentParticipant.name && !currentParticipant.video}
      />
    );
  });

  return (
    <div className={styles.participants}>
      {participantsArray}
      <Participant
        currentParticipant={currentUserObject}
        currentIndex={participantKey.length}
        videoRef={videoRefCurrentParticipant}
        showAvatar={currentUserObject && !currentUserObject.video}
        currentUser
      />
    </div>
  );
};

Participants.propTypes = {
  setStatusCheckRoom: PropTypes.func.isRequired,
};

export default Participants;
