import InterpretationMachineCalibrationRoom from 'components/GameBoard/Emotion/Solo/Rooms/InterpretationMachineCalibrationRoom';
import EmotionGenerationRoom from 'components/GameBoard/Emotion/Solo/Rooms/EmotionGenerationRoom';
import BehaviourMachineCalibrationRoom from 'components/GameBoard/Emotion/Solo/Rooms/BehaviourMachineCalibrationRoom';
import ComplexSituationRoom from 'components/GameBoard/Emotion/Solo/Rooms/ComplexSituationRoom';
import KitchenLivingRoom from 'components/GameBoard/Obesity/Rooms/KitchenLivingRoom';
import WelcomeRPS from 'components/GameBoard/RPS/Rooms/WelcomeRPS';
import BreakRoomRPS from 'components/GameBoard/RPS/Rooms/BreakRoomRPS';
import TabletRPS from 'components/GameBoard/RPS/Rooms/TabletRPS';
import WelcomeRPSCongres from 'components/GameBoard/RPSCongres/Rooms/WelcomeRPS';
import BedroomTDE from 'components/GameBoard/TDE/Rooms/BedroomTDE';
import ChangingRoomTMS from 'components/GameBoard/TMS/Rooms/ChangingRoomTMS';
import CorridorTMS from 'components/GameBoard/TMS/Rooms/CorridorTMS';
import RestaurantTMS from 'components/GameBoard/TMS/Rooms/RestaurantTMS';
import CorridorTMSCongres from 'components/GameBoard/TMSCongres/Rooms/CorridorTMSCongres';
import FrontDeskOffice from 'components/GameBoard/OffreSocle/Rooms/FrontDeskOffice';
import ReferringDoctorOffice from 'components/GameBoard/OffreSocle/Rooms/ReferringDoctorOffice';
import ErgonomistMeeting from 'components/GameBoard/OffreSocle/Rooms/ErgonomistMeeting';
import DesktopV2 from 'components/GameBoard/Emotion/Multi/Rooms/DesktopV2';
import LivingRoomEmotionMulti from 'components/GameBoard/Emotion/Multi/Rooms/LivingRoomEmotionMulti';
import BedroomEmotionMulti from 'components/GameBoard/Emotion/Multi/Rooms/BedroomEmotionMulti';

// List of the rooms for each game - put the name of the game folder in the key
const RoomsComponents = {
  Emotion: {
    list: {
      InterpretationMachineCalibrationRoom,
      EmotionGenerationRoom,
      BehaviourMachineCalibrationRoom,
      ComplexSituationRoom,
    },
    theme: {
      characterBubbleColor:
        'linear-gradient(221.48deg, #FFEFBC -2.32%, #FEEBBA 21.31%, #FADFB4 45.15%, #F4CBAB 69.1%, #EBAF9D 92.98%, #E8A699 99.22%)',
      tealBlue: '#107679',
      peacockBlue: '#224e52',
      green: '#3C9948',
      red: '#EF5043',
    },
  },
  OffreSocle: {
    list: { FrontDeskOffice, ReferringDoctorOffice, ErgonomistMeeting },
    theme: {
      characterBubbleColor: '#FFF',
      purpleHeart: '#764CEF',
      grapeJelly: '#331A7C',
      whitePurple: '#E2D8F3',
    },
  },
  Obesity: { list: { KitchenLivingRoom } },
  RPS: { list: { WelcomeRPS, BreakRoomRPS, TabletRPS } },
  RPSCongres: {
    type: 'demo',
    list: { WelcomeRPS },
    WelcomeRPS: { WelcomeRPSCongres },
  },
  TDE: { list: { BedroomTDE } },
  TMS: { list: { ChangingRoomTMS, CorridorTMS, RestaurantTMS } },
  TMSCongres: {
    type: 'demo',
    list: { CorridorTMS },
    CorridorTMS: { CorridorTMSCongres },
  },
  EmotionMulti: {
    list: { DesktopV2, LivingRoomEmotionMulti, BedroomEmotionMulti },
  },
};

export default RoomsComponents;
