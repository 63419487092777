import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// components
import Button from 'components/GameBoard/ReusableComponents/Actions/Button';

// selectors
import { selectSession } from 'components/Reducers/session';
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';
import { selectModalEnigma } from 'components/Reducers/Modal/Modal';

// styles
import styles from 'components/GameBoard/Emotion/Multi/Enigma/DrawerTvStand/Books.module.scss';

// constants
import {
  listBooks,
  resetTypeBook,
  resetIndex,
  emptyInventoy,
} from 'components/GameBoard/Emotion/Multi/constant';

// utils
import { animationConfetti, checkedObject } from 'utils/utilityFunctions';
import { selectObjectIndex } from 'components/Reducers/GameData/fetchObjects';
import { tvStandLivingRoomMultiEmotion } from 'components/GameBoard/ReusableComponents/Objects/constants';

const Books = () => {
  const { t } = useTranslation(['emotion-multi', 'common']);
  const dispatch = useDispatch();

  const session = useSelector(selectSession);
  const { socket, idSessionHasRoom } = useSelector(infoGameUser);
  const { description } = useSelector(selectModalEnigma);
  const room = session.id;

  const [books, setBooks] = useState(listBooks);
  const [currentTypeBook, setCurrentTypeBook] = useState(resetTypeBook);
  const [indexTypeBook, setIndexTypeBook] = useState(resetIndex);
  const [inventory, setInventory] = useState(emptyInventoy);
  const [errorMessage, setErrorMessage] = useState(null);

  const tvStandLivingRoomMultiEmotionObject = useSelector((state) =>
    selectObjectIndex(state, tvStandLivingRoomMultiEmotion)
  );

  useEffect(() => {
    socket?.on('receive_emotion_multi_set_books', (data) => {
      setBooks(data.newBooks);
    });
    socket?.on('received_emotion_multi_set_index_current_book_books', (data) => {
      setIndexTypeBook(data.indexBook);
    });
    socket?.on('receive_emotion_multi_set_type_current_book_books', (data) => {
      setCurrentTypeBook(data.typeBook);
    });
    socket?.on('receive_emotion_multi_set_inventory_books', (data) => {
      setInventory(data.newInventory);
    });
    socket?.on('receive_error_messaging', (data) => {
      setErrorMessage(data.errorContent);
    });
  }, [socket]);

  const addBook = (id) => {
    const newBooks = books;
    newBooks[currentTypeBook].map((book) => {
      const newBook = book;
      if (book.id === id) {
        const newInventory = [...inventory];
        let isAlreadyAdd = false;
        if (newInventory.length > 0) {
          isAlreadyAdd = newInventory.find(
            (bookContent) => bookContent.id === book.id
          );
        }
        if (!isAlreadyAdd) {
          newInventory.push(book);
        }
        const responsesSocket = {
          newInventory,
          room,
        };
        socket?.emit('send_emotion_multi_set_inventory_books', responsesSocket);

        setInventory(newInventory);
        newBook.isChecked = true;
      } else {
        newBook.isChecked = false;
      }
      return newBook;
    });

    const nextIndexBook = indexTypeBook + 1;

    const typeNextTypeBook = Object.keys(books)[nextIndexBook]
      ? Object.keys(books)[nextIndexBook]
      : resetTypeBook;

    const responsesSocket = {
      newBooks,
      indexBook: nextIndexBook,
      typeBook: typeNextTypeBook,
      room,
    };
    socket?.emit(
      'send_emotion_multi_set_index_current_book_books',
      responsesSocket
    );
    socket?.emit('send_emotion_multi_set_type_current_book_books', responsesSocket);
    socket?.emit('send_emotion_multi_set_books', responsesSocket);
    setIndexTypeBook(nextIndexBook);
    setCurrentTypeBook(typeNextTypeBook);
    setBooks(newBooks);
  };

  const removeBooks = () => {
    const responsesSocket = {
      newInventory: emptyInventoy,
      indexBook: resetIndex,
      typeBook: resetTypeBook,
      newBooks: listBooks,
      errorContent: null,
      room,
    };
    socket?.emit(
      'send_emotion_multi_set_index_current_book_books',
      responsesSocket
    );
    socket?.emit('send_emotion_multi_set_type_current_book_books', responsesSocket);
    socket?.emit('send_emotion_multi_set_books', responsesSocket);
    socket?.emit('send_emotion_multi_set_inventory_books', responsesSocket);
    socket?.emit('send_error_messaging', responsesSocket);
    setBooks(listBooks);
    setIndexTypeBook(resetIndex);
    setCurrentTypeBook(resetTypeBook);
    setInventory(emptyInventoy);
    setErrorMessage(null);
  };

  const handleBook = (book, handleClick) => {
    return (
      <button
        type="button"
        className={styles.content}
        onClick={() => handleClick && handleClick(book.id)}
      >
        <img src={book.url} alt={`book-${book.id}`} />
        {handleClick && <p>{book.id}</p>}
      </button>
    );
  };

  const handleDescription = (typeDescription) => {
    let message;
    if (description.content.images) {
      const objectIndex = description.content.images.findIndex(
        (objectElement) => objectElement.type === typeDescription
      );

      message = description.content.images[objectIndex].message;
    }

    return message;
  };

  const submitBooks = () => {
    const goodBooksCombinaison = inventory.find((book) => !book.isGood);
    if (goodBooksCombinaison === undefined) {
      animationConfetti();

      return checkedObject(
        description.content,
        tvStandLivingRoomMultiEmotionObject,
        idSessionHasRoom,
        dispatch,
        socket,
        room
      );
    }
    const errorContent = t('books.error');

    const responsesSocket = {
      errorContent,
      room,
    };
    socket?.emit('send_error_messaging', responsesSocket);
    setTimeout(() => {
      removeBooks();
    }, 2000);
    return setErrorMessage(errorContent);
  };

  return (
    <div className={styles.books}>
      {indexTypeBook < Object.keys(books).length && (
        <>
          <p>{handleDescription('background')}</p>
          <div className={styles.contents}>
            {books[currentTypeBook].map((book) => {
              return handleBook(book, addBook);
            })}
          </div>
        </>
      )}
      <div className={styles.inventory}>
        {inventory?.map((bookContent) => handleBook(bookContent))}
      </div>
      {indexTypeBook === Object.keys(books).length && (
        <div className={styles.actions}>
          <p>{errorMessage || handleDescription('clicked')}</p>
          <div>
            <Button
              onClick={() => removeBooks()}
              buttonType="action"
              title={t('buttonFunction.retry', { ns: 'common' })}
            />
            <Button
              onClick={() => submitBooks()}
              buttonType="action"
              title={t('buttonFunction.validate', { ns: 'common' })}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Books;
