import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

// components
import { listApplication } from 'components/GameBoard/Emotion/Multi/constant';

// scss
import styles from 'components/GameBoard/Emotion/Multi/Enigma/Desktop/FooterApplication.module.scss';
import { selectSession } from 'components/Reducers/session';
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';

const FooterApplication = ({ setTypeApplication }) => {
  const session = useSelector(selectSession);
  const { socket } = useSelector(infoGameUser);
  const room = session.id;

  useEffect(() => {
    socket.on('receive_emotion_multi_type_application_computer', (data) => {
      setTypeApplication(data.typeApplication);
    });
  }, [setTypeApplication, socket]);

  return (
    <div className={styles.footer}>
      {listApplication.map((application) => (
        <button
          key={application.id}
          className={styles.icon}
          type="button"
          onClick={() => {
            const responsesSocket = {
              typeApplication: application.type,
              room,
            };
            socket?.emit(
              'send_emotion_multi_type_application_computer',
              responsesSocket
            );
            setTypeApplication(application.type);
          }}
        >
          <img src={application.icon} alt="icon application" />
        </button>
      ))}
    </div>
  );
};

FooterApplication.propTypes = {
  setTypeApplication: PropTypes.func.isRequired,
};

export default FooterApplication;
