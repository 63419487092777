import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AiFillWarning } from 'react-icons/ai';

// components
import Button from 'components/GameBoard/ReusableComponents/Actions/Button';

// utils
import { checkedObject, clickCount, clickCountUser } from 'utils/utilityFunctions';

// selectors
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';
import {
  selectObject,
  selectObjectIndex,
} from 'components/Reducers/GameData/fetchObjects';
import { selectSession } from 'components/Reducers/session';
import { selectUser } from 'components/Reducers/user';
// constant
import {
  codeVisio,
  listPersona,
  colorsPersona,
  iconsActionVisio,
  iconVisio,
  videoRoundRed,
  videoRoundYellow,
  videoRoundGreen,
} from 'components/GameBoard/Emotion/Multi/constant';
import {
  computerWithNoteRoomEmotionMultiEnigma,
  noteOfficeMultiEmotion,
  paperFusionOfficeMultiEmotion,
} from 'components/GameBoard/ReusableComponents/Objects/constants';

// scss
import styles from 'components/GameBoard/Emotion/Multi/Enigma/Desktop/PageScreen/VideoApplication.module.scss';

const VideoApplication = () => {
  const { t } = useTranslation(['emotion-multi', 'common']);

  const arrayCode = Array(codeVisio.length).fill('');
  const [code, setCode] = useState(arrayCode);
  const [error, setError] = useState(null);
  const [isLock, setIsLock] = useState(true);
  const [listUser, setListUser] = useState(listPersona);
  const session = useSelector(selectSession);
  const { participants } = useSelector(selectUser);
  const room = session.id;
  const dispatch = useDispatch();
  const { idSessionHasRoom, socket } = useSelector(infoGameUser);

  const computerWithNoteRoomEmotionMultiIndex = useSelector((state) =>
    selectObjectIndex(state, computerWithNoteRoomEmotionMultiEnigma)
  );

  const computerWithNoteRoomEmotionMultiObject = useSelector((state) =>
    selectObject(state, computerWithNoteRoomEmotionMultiEnigma)
  );

  const noteVisioIndex = useSelector((state) =>
    selectObjectIndex(state, noteOfficeMultiEmotion)
  );

  const noteVisioObject = useSelector((state) =>
    selectObject(state, noteOfficeMultiEmotion)
  );

  const paperFusionIndex = useSelector((state) =>
    selectObjectIndex(state, paperFusionOfficeMultiEmotion)
  );

  const paperFusionObject = useSelector((state) =>
    selectObject(state, paperFusionOfficeMultiEmotion)
  );

  useEffect(() => {
    socket?.on('receive_error_messaging', (data) => {
      setError(data.errorContent);
    });
    socket?.on('receive_emotion_multi_is_lock_application', (data) => {
      setIsLock(data.status);
    });
    socket?.on('receive_emotion_multi_code_application', (data) => {
      setCode(data.arrayCode);
    });
    socket?.on('receive_emotion_multi_list_user_application', (data) => {
      setListUser(data.newList);
    });
  }, [socket]);

  useEffect(() => {
    if (computerWithNoteRoomEmotionMultiObject.isChecked) {
      const responsesSocket = {
        status: false,
        room,
      };
      socket?.emit('send_emotion_multi_is_lock_application', responsesSocket);
      setIsLock(false);
    }
  }, [computerWithNoteRoomEmotionMultiObject.isChecked, room, socket]);

  const submitCodeVisio = (event) => {
    let responsesSocket = {
      status: false,
      room,
    };
    clickCount(dispatch, event, socket, responsesSocket);
    if (socket) {
      clickCountUser(participants, socket, dispatch, session);
    }
    if (code.join('') === codeVisio) {
      responsesSocket = {
        status: false,
        ...responsesSocket,
      };
      socket?.emit('send_emotion_multi_is_lock_application', responsesSocket);
      setIsLock(false);
      checkedObject(
        computerWithNoteRoomEmotionMultiObject,
        computerWithNoteRoomEmotionMultiIndex,
        idSessionHasRoom,
        dispatch,
        socket,
        room
      );
      responsesSocket = {
        errorContent: null,
        ...responsesSocket,
      };
      socket?.emit('send_error_messaging', responsesSocket);
      return setError(null);
    }
    responsesSocket = {
      errorContent: t('desktop.visio.error'),
      ...responsesSocket,
    };
    socket?.emit('send_error_messaging', responsesSocket);
    return setError(t('desktop.visio.error'));
  };

  const submitVisio = (event) => {
    const listUserWithNotGoodCombinaison = listUser.find(
      (persona) => persona.color !== persona.goodColor
    );

    let responsesSocket = { room };

    clickCount(dispatch, event, socket, responsesSocket);
    if (socket) {
      clickCountUser(participants, socket, dispatch, session);
    }

    if (listUserWithNotGoodCombinaison !== undefined) {
      responsesSocket = {
        errorContent: t('desktop.visio.error'),
        room,
      };
      socket?.emit('send_error_messaging', responsesSocket);
      return setError(t('desktop.visio.error'));
    }

    checkedObject(
      noteVisioObject,
      noteVisioIndex,
      idSessionHasRoom,
      dispatch,
      socket,
      room,
      'not-message'
    );
    checkedObject(
      paperFusionObject,
      paperFusionIndex,
      idSessionHasRoom,
      dispatch,
      socket,
      room
    );
    return setError(null);
  };

  const handleFooter = (type) => {
    return (
      <div className={styles.footer}>
        <p>
          {type === 'lock'
            ? t('desktop.visio.titleLock')
            : t('desktop.visio.titleUnlock')}
        </p>
        {type === 'lock' && (
          <>
            <img src={iconsActionVisio} alt="action visio" />
            <img src={iconVisio} alt="icon visio" />
          </>
        )}
      </div>
    );
  };

  const handleError = () => {
    return (
      <div className={styles.error}>
        {error && <AiFillWarning size={20} style={{ color: '#EB5781' }} />}
        <p>{error}</p>
      </div>
    );
  };

  const handleChangeLock = (e) => {
    let responsesSocket = {
      room,
    };
    responsesSocket = {
      errorContent: null,
      ...responsesSocket,
    };
    socket?.emit('send_error_messaging', responsesSocket);
    setError(null);
    let arrayValue = e.target.value.split('');
    // TO REFACTO
    const arrayDifferenceEmpty = Array(code.length - e.target.value.length).fill(
      ''
    );

    if (arrayDifferenceEmpty) {
      arrayValue = arrayValue.concat(arrayDifferenceEmpty);
    }
    responsesSocket = {
      arrayCode: arrayValue,
      ...responsesSocket,
    };
    socket?.emit('send_emotion_multi_code_application', responsesSocket);
    setCode(arrayValue);
  };

  const handleLock = () => {
    // TO REFACTO
    return (
      <div className={`${styles.tab} ${styles['tab-close']}`}>
        <p>{t('desktop.visio.enterCode')}</p>
        <div className={styles.inputs}>
          <input
            type="text"
            maxLength={codeVisio.length}
            onChange={(e) => handleChangeLock(e)}
          />
          <div className={styles.forms}>
            {code.map((number) => (
              <div className={styles.number}>{number}</div>
            ))}
          </div>
        </div>
        {handleError()}
        <Button
          title={t('desktop.visio.join')}
          buttonType="action"
          onClick={(event) => submitCodeVisio(event)}
        />
        {handleFooter('lock')}
      </div>
    );
  };

  const handleUnlock = () => {
    const handleChange = (objectColor) => {
      setError(null);
      const newList = [...listUser];

      const index = newList.findIndex(
        (objectElement) => objectElement.id === objectColor.id
      );
      const nextColorUserIndex =
        colorsPersona.findIndex(
          (colorPersonna) => colorPersonna === objectColor.color
        ) + 1;
      newList[index].color = colorsPersona[nextColorUserIndex];
      const responsesSocket = {
        newList,
        room,
      };
      socket?.emit('send_emotion_multi_list_user_application', responsesSocket);
      setListUser(newList);
    };

    return (
      <div className={`${styles.tab} ${styles['tab-open']}`}>
        <div className={styles.list}>
          {listUser.map((objectColor) => (
            <button
              key={objectColor.id}
              style={{
                backgroundColor: `${objectColor.color}`,
              }}
              className={styles.persona}
              type="button"
              onClick={() => handleChange(objectColor)}
            >
              <img
                src={objectColor.imageUrl}
                alt={`persona-${objectColor.id}`}
                draggable="false"
              />
            </button>
          ))}
        </div>
        <div>
          {handleError()}
          <Button
            buttonType="action"
            title={t('buttonFunction.validate', { ns: 'common' })}
            onClick={(event) => submitVisio(event)}
          />
        </div>
        {handleFooter('unlock')}
      </div>
    );
  };

  return (
    <div className={styles['video-application']}>
      <div className={styles.header}>
        <img src={videoRoundRed} alt="ellipse red" />
        <img src={videoRoundYellow} alt="ellipse yellow" />
        <img src={videoRoundGreen} alt="ellipse green" />
      </div>
      {isLock ? handleLock() : handleUnlock()}
    </div>
  );
};

export default VideoApplication;
