import { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

// components
import Timer from 'components/GameBoard/Obesity/Enigma/Television/Timer';
import Audio from 'components/GameBoard/ReusableComponents/Actions/Audio';
import DrawerInputs from 'components/GameBoard/ReusableComponents/Enigma/Drawer/DrawerInputs';

// selectors
import { selectModalEnigma } from 'components/Reducers/Modal/Modal';
import { selectObject } from 'components/Reducers/GameData/fetchObjects';
import { selectSession } from 'components/Reducers/session';
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';

// constants
import {
  televisionLivingRoomMultiEmotion,
  remoteTvLivingRoomMultiEmotion,
} from 'components/GameBoard/ReusableComponents/Objects/constants';
import {
  arrayButtonRemote,
  codeTelevision,
} from 'components/GameBoard/Emotion/Multi/constant';

// utils
import { animationConfetti, changeStatusOfObject } from 'utils/utilityFunctions';

// css
import 'assets/css/components/GameBoard/Obesity/Enigma/Television.css';

// assets
import * as images from '../../../../../img/obesity/index';
import rickRoll from '../../../../../sound/Obesity/rickroll.ogg';
import click from '../../../../../sound/iphone-click.mp3';

const BASE_URL = process.env.REACT_APP_BASE_URL;

// TO REFACTO
function Television({ dispatch, allObjects, idSessionHasRoom, game }) {
  const session = useSelector(selectSession);
  const { socket } = useSelector(infoGameUser);
  const room = session.id;

  const { description } = useSelector(selectModalEnigma);

  // TO REFACTO TO One state status remote
  const [isRemoteInventory, setIsRemoteInventory] = useState(null);
  const [isRemoteOpen, setIsRemoteOpen] = useState(null);

  const arrayCodeEmpty = Array(codeTelevision.length).fill('');
  const [code, setCode] = useState(arrayCodeEmpty);

  const idTv = description.content.id;
  const remoteTvLivingRoomMultiEmotionObject = useSelector((state) =>
    selectObject(state, remoteTvLivingRoomMultiEmotion)
  );
  const televisionLivingRoomMultiEmotionObject = useSelector((state) =>
    selectObject(state, televisionLivingRoomMultiEmotion)
  );
  const [isTelevisionLock, setIsTelevisionLock] = useState(true);
  const [televisionOpen, setTelevisionOpen] = useState(false);
  const [gifOpen, setGifOpen] = useState(false);
  const [channel, setChannel] = useState(null);
  const [currentChannel, setCurrentChannel] = useState(0);
  const firstChannel = 0;
  const previousChannel = currentChannel - 1;
  const nextChannel = currentChannel + 1;
  const [valueDate, onChangeDate] = useState(null);
  const [soundClick, setSoundClick] = useState(false);
  const channelsArray = ['picture', 'sound', 'tv', 'channel', 'timer', 'source'];
  const screenVersions = allObjects.filter((item) => item.id === idTv);
  const [screen, setScreen] = useState(
    screenVersions[0].images.filter((item) => item.type === 'closed')[0].image
  );

  useEffect(() => {
    if (idTv === televisionLivingRoomMultiEmotion) {
      setIsRemoteInventory(remoteTvLivingRoomMultiEmotionObject.isInventory);
      setIsRemoteOpen(remoteTvLivingRoomMultiEmotionObject.status === 'open');
      if (televisionLivingRoomMultiEmotionObject.status === 'open') {
        setIsTelevisionLock(false);
      }
    } else {
      setIsTelevisionLock(false);
      setIsRemoteInventory(allObjects.find((remote) => remote.isInventory));
      setIsRemoteOpen(allObjects.find((remote) => remote.status === 'open'));
    }
  }, [
    allObjects,
    idTv,
    remoteTvLivingRoomMultiEmotionObject,
    televisionLivingRoomMultiEmotionObject?.status,
  ]);

  useEffect(() => {
    socket?.on('receive_emotion_multi_turn_on_tv', (data) => {
      setTelevisionOpen(data.isOpen);
    });
    socket?.on('receive_emotion_multi_gif_open_tv', (data) => {
      setGifOpen(data.isOpen);
    });
    socket?.on('receive_emotion_multi_set_channel_tv', (data) => {
      setChannel(data.channelType);
    });
    socket?.on('receive_emotion_multi_current_channel_tv', (data) => {
      setCurrentChannel(data.currentChannel);
    });
    socket?.on('receive_emotion_multi_screen_tv', (data) => {
      setScreen(data.screenType);
    });
    socket?.on('receive_emotion_multi_sound_click_tv', (data) => {
      setSoundClick(data.isSoundClick);
    });
    socket?.on('receive_emotion_multi_set_code_tv', (data) => {
      setCode(data.newArray);
    });
    socket?.on('receive_emotion_multi_television_lock_tv', (data) => {
      setIsTelevisionLock(data.isLock);
    });
  }, [socket]);

  useEffect(() => {
    let responsesSocket = {
      room,
    };
    // TO REFACTO
    if (televisionOpen) {
      if (channel !== null) {
        responsesSocket = {
          screenType: screenVersions[0].images.filter(
            (item) => item.type === channel
          )[0].image,
          ...responsesSocket,
        };
        socket?.emit('send_emotion_multi_screen_tv', responsesSocket);
        setScreen(
          screenVersions[0].images.filter((item) => item.type === channel)[0].image
        );
      } else {
        responsesSocket = {
          screenType: screenVersions[0].images.filter(
            (item) => item.type === 'open'
          )[0].image,
          ...responsesSocket,
        };
        socket?.emit('send_emotion_multi_screen_tv', responsesSocket);
        setScreen(
          screenVersions[0].images.filter((item) => item.type === 'open')[0].image
        );
      }
    } else {
      responsesSocket = {
        screenType: screenVersions[0].images.filter(
          (item) => item.type === 'closed'
        )[0].image,
        ...responsesSocket,
      };
      socket?.emit('send_emotion_multi_screen_tv', responsesSocket);
      setScreen(
        screenVersions[0].images.filter((item) => item.type === 'closed')[0].image
      );
    }
    return () => {
      responsesSocket = {
        screenType: screenVersions[0].images.filter(
          (item) => item.type === 'closed'
        )[0].image,
        ...responsesSocket,
      };
      socket?.emit('send_emotion_multi_screen_tv', responsesSocket);
      setScreen(
        screenVersions[0].images.filter((item) => item.type === 'closed')[0].image
      );
    };
  }, [channel, room, screenVersions, socket, televisionOpen]);

  const tvObject = allObjects.find((objectContent) => objectContent.id === idTv);
  const tvIndex = allObjects.findIndex(
    (objectContent) => objectContent.id === idTv
  );

  const handleGif = () => {
    const responsesSocket = {
      isOpen: true,
      room,
    };
    socket?.emit('send_emotion_multi_gif_open_tv', responsesSocket);
    setGifOpen(true);
    setTimeout(() => {
      const newResponsesSocket = {
        isOpen: false,
        room,
      };
      socket?.emit('send_emotion_multi_gif_open_tv', newResponsesSocket);
      setGifOpen(false);
    }, 2000);
  };

  const handleMessage = () => {
    return 'Cliquez sur les boutons de la télécommande pour pouvoir l’utiliser.';
  };
  const usedObject = (collectObject, index) => {
    axios
      .put(`${BASE_URL}/api/sessionsHasRoomHasObject/${idSessionHasRoom}`, {
        object_id: collectObject.id,
        status: 'open',
      })
      .then(() => {
        const clickedContent = {
          index,
          status: 'open',
          type: 'used',
        };
        const responsesSocket = {
          ...clickedContent,
          room,
        };
        socket?.emit('send_clicked_object', responsesSocket);
        dispatch({
          type: 'CLICKED_OBJECT',
          payload: clickedContent,
        });
      });
  };

  useEffect(() => {
    if (
      idTv !== televisionLivingRoomMultiEmotion &&
      game.music &&
      game.soundtrack
    ) {
      if (channel === 'tv' && televisionOpen) {
        document.getElementById('Obesity-Theme').volume = 0;
        document.getElementById('Obesity-RickRoll').volume = 0.1;
      } else {
        document.getElementById('Obesity-Theme').volume = 0.2;
        document.getElementById('Obesity-RickRoll').volume = 0;
      }
    } else {
      document.getElementById('Obesity-RickRoll').volume = 0;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel, televisionOpen]);

  const handleSoundClick = () => {
    let responsesSocket = {
      isSoundClick: true,
      room,
    };
    socket?.emit('send_emotion_multi_sound_click_tv', responsesSocket);
    setSoundClick(true);
    setTimeout(() => {
      responsesSocket = {
        isSoundClick: false,
        ...responsesSocket,
      };
      socket?.emit('send_emotion_multi_sound_click_tv', responsesSocket);
      setSoundClick(false);
    });
  };

  const handleLockTelevision = () => {
    return (
      <div className="television-enigma-icons-grid">
        <p>Pas de signal</p>
        <div className="television-enigma-inputs">
          <DrawerInputs
            handleGoodCode={codeTelevision}
            setCode={isRemoteOpen && setCode}
            code={code}
          />
        </div>
      </div>
    );
  };

  const handleStartTv = () => {
    return (
      <div className="television-enigma-container">
        <Audio sound={rickRoll} loop id="Obesity-RickRoll" muted={!game.music} />
        <div className="television-enigma-container-images">
          <img
            src={screen}
            alt="Télévision allumée"
            className="television-enigma-container-tvbackground"
          />
          {gifOpen && (
            <img
              alt="open-tv"
              src={images.switchAnimation}
              className="television-enigma-animation"
            />
          )}
          {!televisionOpen && isTelevisionLock && handleLockTelevision()}
          {televisionOpen && !gifOpen && channel === null && (
            <div className="television-enigma-icons-grid">
              <img
                src={images.TvLogo}
                alt="Bienvenue"
                className="television-enigma-tv-welcome"
              />
              <button
                type="button"
                className={`television-enigma-tv-picture-app${
                  channelsArray[currentChannel] === 'picture' ? ' active' : ''
                }`}
                onClick={() => {
                  const responsesSocket = {
                    channelType: 'picture',
                    room,
                  };
                  socket?.emit(
                    'send_emotion_multi_set_channel_tv',
                    responsesSocket
                  );
                  setChannel('picture');
                  handleSoundClick();
                }}
              >
                <img
                  src={
                    channelsArray[currentChannel] === 'picture'
                      ? images.pictureSelect
                      : images.picture
                  }
                  alt="Current channel"
                  className="television-enigma-tv-app-images"
                />
              </button>
              <button
                type="button"
                className={`television-enigma-tv-sound-app${
                  channelsArray[currentChannel] === 'sound' ? ' active' : ''
                }`}
                onClick={() => {
                  const responsesSocket = {
                    channelType: 'sound',
                    room,
                  };
                  socket?.emit(
                    'send_emotion_multi_set_channel_tv',
                    responsesSocket
                  );
                  setChannel('sound');
                  handleSoundClick();
                }}
              >
                <img
                  src={
                    channelsArray[currentChannel] === 'sound'
                      ? images.musicSelect
                      : images.music
                  }
                  alt="Current channel"
                  className="television-enigma-tv-app-images"
                />
              </button>
              <button
                type="button"
                className={`television-enigma-tv-tv-app${
                  channelsArray[currentChannel] === 'tv' ? ' active' : ''
                }`}
                onClick={() => {
                  const responsesSocket = {
                    channelType: 'tv',
                    room,
                  };
                  socket?.emit(
                    'send_emotion_multi_set_channel_tv',
                    responsesSocket
                  );
                  setChannel('tv');
                  handleSoundClick();
                }}
              >
                <img
                  src={
                    channelsArray[currentChannel] === 'tv'
                      ? images.tvSelect
                      : images.tv
                  }
                  alt="Current channel"
                  className="television-enigma-tv-app-images"
                />
              </button>
              <button
                type="button"
                className={`television-enigma-tv-channel-app${
                  channelsArray[currentChannel] === 'channel' ? ' active' : ''
                }`}
                onClick={() => {
                  const responsesSocket = {
                    channelType: 'channel',
                    room,
                  };
                  socket?.emit(
                    'send_emotion_multi_set_channel_tv',
                    responsesSocket
                  );
                  setChannel('channel');
                  handleSoundClick();
                }}
              >
                <img
                  src={
                    channelsArray[currentChannel] === 'channel'
                      ? images.channelSelect
                      : images.channel
                  }
                  alt="Current channel"
                  className="television-enigma-tv-app-images"
                />
              </button>
              <button
                type="button"
                className={`television-enigma-tv-timer-app${
                  channelsArray[currentChannel] === 'timer' ? ' active' : ''
                }`}
                onClick={() => {
                  const responsesSocket = {
                    channelType: 'timer',
                    room,
                  };
                  socket?.emit(
                    'send_emotion_multi_set_channel_tv',
                    responsesSocket
                  );
                  setChannel('timer');
                  handleSoundClick();
                }}
              >
                <img
                  src={
                    channelsArray[currentChannel] === 'timer'
                      ? images.timerSelect
                      : images.timer
                  }
                  alt="Current channel"
                  className="television-enigma-tv-app-images"
                />
              </button>
              <button
                type="button"
                className={`television-enigma-tv-source-app${
                  channelsArray[currentChannel] === 'source' ? ' active' : ''
                }`}
                onClick={() => {
                  const responsesSocket = {
                    channelType: 'source',
                    room,
                  };
                  socket?.emit(
                    'send_emotion_multi_set_channel_tv',
                    responsesSocket
                  );
                  setChannel('source');
                  handleSoundClick();
                }}
              >
                <img
                  src={
                    channelsArray[currentChannel] === 'source'
                      ? images.sourceSelect
                      : images.source
                  }
                  alt="Current channel"
                  className="television-enigma-tv-app-images"
                />
              </button>{' '}
            </div>
          )}
          {channel === 'tv' && televisionOpen && (
            <img
              src="https://c.tenor.com/_4YgA77ExHEAAAAC/rick-roll.gif"
              className="television-enigma-animation"
              alt="You got rick rolled"
            />
          )}
          {channel === 'timer' && (
            <div className="television-enigma-timer-grid">
              <Timer value={valueDate} onChange={onChangeDate} />
            </div>
          )}
        </div>
      </div>
    );
  };

  /**
   * Handle the TV navigation with the remote control
   * @param {string} position - which button of the remote control is pressed
   */
  const handleCurrentChannel = (position) => {
    const numberTotalOfChannel = channelsArray.length - 1;
    let responsesSocket = {
      room,
    };
    // TO REFACTO
    if (position === 'left') {
      responsesSocket = {
        ...responsesSocket,
        currentChannel: previousChannel,
      };
      socket?.emit('send_emotion_multi_current_channel_tv', responsesSocket);
      setCurrentChannel(previousChannel);
    }

    if (position === 'right') {
      responsesSocket = {
        ...responsesSocket,
        currentChannel: nextChannel,
      };
      socket?.emit('send_emotion_multi_current_channel_tv', responsesSocket);
      setCurrentChannel(nextChannel);
    }

    if (position === 'bottom') {
      if (currentChannel < 3) {
        responsesSocket = {
          ...responsesSocket,
          currentChannel: currentChannel + 3,
        };
        socket?.emit('send_emotion_multi_current_channel_tv', responsesSocket);
        setCurrentChannel(currentChannel + 3);
      }
    }

    if (position === 'top') {
      if (currentChannel >= 3) {
        responsesSocket = {
          ...responsesSocket,
          currentChannel: currentChannel - 3,
        };
        socket?.emit('send_emotion_multi_current_channel_tv', responsesSocket);
        setCurrentChannel(currentChannel - 3);
      }
    }

    if (
      (currentChannel === 0 && position === 'top') ||
      (currentChannel === 0 && position === 'left')
    ) {
      responsesSocket = {
        ...responsesSocket,
        currentChannel: numberTotalOfChannel,
      };
      socket?.emit('send_emotion_multi_current_channel_tv', responsesSocket);
      setCurrentChannel(numberTotalOfChannel);
    }

    if (
      (currentChannel === numberTotalOfChannel && position === 'right') ||
      (currentChannel === numberTotalOfChannel && position === 'bottom')
    ) {
      responsesSocket = {
        ...responsesSocket,
        currentChannel: firstChannel,
      };
      socket?.emit('send_emotion_multi_current_channel_tv', responsesSocket);
      setCurrentChannel(firstChannel);
    }
  };

  const checkChannel = () => {
    const responsesSocket = {
      channelType: channelsArray[currentChannel],
      room,
    };
    socket?.emit('send_emotion_multi_set_channel_tv', responsesSocket);
    setChannel(channelsArray[currentChannel]);
  };

  /**
   * Handle the television turn on
   */
  const turnOnTelevision = () => {
    const responsesSocket = {
      isOpen: true,
      isLock: false,
      room,
      channelType: null,
      currentChannel: firstChannel,
    };
    socket?.emit('send_emotion_multi_turn_on_tv', responsesSocket);
    socket?.emit('send_emotion_multi_television_lock_tv', responsesSocket);
    socket?.emit('send_emotion_multi_set_channel_tv', responsesSocket);
    socket?.emit('send_emotion_multi_current_channel_tv', responsesSocket);
    setIsTelevisionLock(false);
    setTelevisionOpen(true);
    handleGif();
    usedObject(tvObject, tvIndex);
    setChannel(null);
    setCurrentChannel(firstChannel);
  };

  /**
   * Handle the television turn off
   */
  const turnOffTelevision = () => {
    const responsesSocket = {
      isOpen: false,
      isLock: false,
      channelType: null,
      room,
    };
    socket?.emit('send_emotion_multi_television_lock_tv', responsesSocket);
    socket?.emit('send_emotion_multi_television_lock_tv', responsesSocket);
    socket?.emit('send_emotion_multi_turn_on_tv', responsesSocket);
    socket?.emit('send_emotion_multi_set_channel_tv', responsesSocket);
    setTelevisionOpen(false);
    setChannel(null);
  };

  const submitCode = () => {
    let responsesSocket = {
      room,
    };
    if (code.join('') === codeTelevision) {
      socket?.emit('send_confetti', responsesSocket);
      socket?.emit('send_close_modal_enigma', responsesSocket);
      animationConfetti();
      turnOnTelevision();
      dispatch({
        type: 'CLOSE_MODAL_ENIGMA',
      });
      changeStatusOfObject(
        description.content,
        'open',
        idSessionHasRoom,
        dispatch,
        socket,
        room
      );
      setTimeout(() => {
        socket?.emit('send_start_message_prevention', responsesSocket);
        dispatch({
          type: 'START_MESSAGE_PREVENTION',
        });
      }, [1000]);
    } else {
      responsesSocket = {
        newArray: arrayCodeEmpty,
        ...responsesSocket,
      };
      socket?.emit('send_emotion_multi_set_code_tv', responsesSocket);
      setCode(arrayCodeEmpty);
    }
  };

  const handleClick = (idButton) => {
    const newCode = code;
    const indexCode = newCode.findIndex((placeCode) => placeCode === '');
    newCode[indexCode] = idButton;
    const responsesSocket = {
      newArray: newCode,
      room,
    };
    socket?.emit('send_emotion_multi_set_code_tv', responsesSocket);
    setCode(newCode);
  };

  return (
    <>
      <Audio condition={soundClick && game.soundtrack} sound={click} />
      {handleStartTv()}
      {isRemoteOpen ? (
        <div className="television-enigma-remote">
          {isRemoteInventory && (
            <p className="television-enigma-text">{handleMessage()}</p>
          )}
          <div className="television-enigma-remote-object">
            <button
              type="button"
              className="television-enigma-remote-arrows-switch"
              onClick={() => {
                handleSoundClick();
                if (!televisionOpen && !isTelevisionLock) {
                  turnOnTelevision();
                } else {
                  turnOffTelevision();
                }
              }}
            >
              <img src={images.switch} alt="Bouton allumage" />
            </button>
            <div className="television-enigma-remote-arrows-numbers">
              {arrayButtonRemote.map((idButton) => {
                return (
                  <button
                    key={idButton}
                    className="television-enigma-remote-arrows-number"
                    onClick={() => {
                      handleSoundClick();
                      handleClick(idButton);
                    }}
                    type="button"
                  >
                    {idButton}
                  </button>
                );
              })}
            </div>
            <div className="television-enigma-remote-arrows">
              <button
                className="television-enigma-remote-arrows-left"
                onClick={() => {
                  handleCurrentChannel('left');
                  handleSoundClick();
                }}
                type="button"
              >
                <img
                  src={images.left}
                  alt="flèche de gauche"
                  className="television-enigma-remote-arrow"
                />
              </button>
              <button
                className="television-enigma-remote-arrows-up"
                onClick={() => {
                  handleCurrentChannel('top');
                  handleSoundClick();
                }}
                type="button"
              >
                <img
                  src={images.up}
                  alt="flèche du haut"
                  className="television-enigma-remote-arrow"
                />
              </button>
              <button
                className="television-enigma-remote-arrows-ok"
                onClick={() => {
                  if (televisionOpen) {
                    checkChannel();
                  } else {
                    submitCode();
                  }
                  handleSoundClick();
                }}
                type="button"
              >
                <img
                  src={images.ok}
                  alt="Bouton ok"
                  className="television-enigma-remote-arrow"
                />
              </button>
              <button
                className="television-enigma-remote-arrows-right"
                onClick={() => {
                  handleCurrentChannel('right');
                  handleSoundClick();
                }}
                type="button"
              >
                <img
                  src={images.right}
                  alt="flèche de droite"
                  className="television-enigma-remote-arrow"
                />
              </button>
              <button
                className="television-enigma-remote-arrows-down"
                onClick={() => {
                  handleCurrentChannel('bottom');
                  handleSoundClick();
                }}
                type="button"
              >
                <img
                  src={images.down}
                  alt="flèche du bas"
                  className="television-enigma-remote-arrow"
                />
              </button>
            </div>
            <img
              src={images.pad}
              alt="Pad"
              className="television-enigma-remote-pad"
            />
            <button
              className="television-enigma-remote-menu"
              onClick={() => {
                const responsesSocket = {
                  channelType: null,
                  room,
                };
                socket?.emit('send_emotion_multi_set_channel_tv', responsesSocket);
                setChannel(null);
                handleSoundClick();
              }}
              type="button"
            >
              <img src={images.menu} alt="Menu" />
            </button>
          </div>
        </div>
      ) : (
        <p className="television-enigma-remote-text">
          {isRemoteInventory
            ? "Utiliser la télécommande dans l'inventaire"
            : 'Il vous manque une télécommande pour allumer la télévision'}
        </p>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  contents: state.Modal.modalEnigma.description.content,
  allObjects: state.Objects.AllObjects,
  idSessionHasRoom: state.GameUsers.idSessionHasRoom,
  game: state.GameUsers,
});

Television.propTypes = {
  dispatch: PropTypes.func.isRequired,
  allObjects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      type: PropTypes.string,
    })
  ).isRequired,
  idSessionHasRoom: PropTypes.number.isRequired,
  game: PropTypes.shape({
    soundtrack: PropTypes.bool,
    music: PropTypes.bool,
  }).isRequired,
};

export default connect(mapStateToProps)(Television);
