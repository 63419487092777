import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styles from 'components/GameBoard/OffreSocle/Enigmas/VelledaBoardZoom.module.scss';
// Components
import Button from 'components/GameBoard/ReusableComponents/Actions/Button';
// Selector functions
import { selectAllObjects } from 'components/Reducers/GameData/fetchObjects';
// Utils & constants
import { firstObjectImageContent } from 'components/GameBoard/OffreSocle/constants';
import { clickCount } from 'utils/utilityFunctions';
import { velledaOffreSocle } from 'components/GameBoard/ReusableComponents/Objects/constants';

const VelledaBoardZoom = ({ setObjectClicked }) => {
  const { t } = useTranslation('common');

  const dispatch = useDispatch();

  const allObjects = useSelector(selectAllObjects);
  const zoomedObjects = allObjects.filter(
    (object) => object?.images[firstObjectImageContent]?.type === 'zoom'
  );
  const [zoomedObjectClicked, setZoomedObjectClicked] = useState(null);

  return (
    <div className={styles['modal-container']}>
      <div className={styles.modal}>
        {!zoomedObjectClicked ? (
          zoomedObjects.map((zoomedObject, index) => {
            return (
              <img
                key={zoomedObject.id}
                src={zoomedObject?.images[firstObjectImageContent].image}
                alt={zoomedObject.name}
                className={styles[`img-${index}`]}
                onClick={(event) => {
                  const soundObject = zoomedObject?.images.find(
                    (imageContent) => imageContent.type === 'clicked'
                  );
                  if (soundObject) {
                    const viewPaperSound = new Audio(soundObject.sound);
                    viewPaperSound.volume = 0.1;
                    viewPaperSound.play();
                  }

                  if (zoomedObject.id !== velledaOffreSocle) {
                    if (!zoomedObjectClicked) setZoomedObjectClicked(zoomedObject);
                  }

                  clickCount(dispatch, event);
                }}
                aria-hidden="true"
              />
            );
          })
        ) : (
          <img
            src={zoomedObjectClicked?.images[firstObjectImageContent].image}
            alt={zoomedObjectClicked.name}
            className={styles['zoomed-img']}
          />
        )}
        <Button
          onClick={() =>
            !zoomedObjectClicked
              ? setObjectClicked(false)
              : setZoomedObjectClicked(false)
          }
          title={t('buttonFunction.return')}
          buttonType="offreSocle"
        />
      </div>
    </div>
  );
};

VelledaBoardZoom.propTypes = {
  setObjectClicked: PropTypes.func.isRequired,
};

export default VelledaBoardZoom;
