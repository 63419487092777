import styles from 'components/Joyride/Steps.module.scss';

const chatbotStep = (t) => {
  const step = {
    target: '.ChatBot-img',
    content: (
      <div className={styles['main-content']}>
        <h2>{t('joyride.chatbot.title')}</h2>
        <p>{t('joyride.chatbot.description')}</p>
      </div>
    ),
    placement: 'top',
  };
  return step;
};

export default chatbotStep;
