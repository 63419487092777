import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// components
import DrawerInputs from 'components/GameBoard/ReusableComponents/Enigma/Drawer/DrawerInputs';

// selectors
import { selectSession } from 'components/Reducers/session';
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';
import { selectModalEnigma } from 'components/Reducers/Modal/Modal';
import {
  selectObject,
  selectObjectIndex,
} from 'components/Reducers/GameData/fetchObjects';
import { selectUser } from 'components/Reducers/user';

// constants
import { codeTableBedroom } from 'components/GameBoard/Emotion/Multi/constant';
import { noticeBedroomMultiEmotion } from 'components/GameBoard/ReusableComponents/Objects/constants';

// utils
import {
  addInventory,
  animationConfetti,
  changeStatusOfObject,
  clickCount,
  clickCountUser,
} from 'utils/utilityFunctions';

// scss
import styles from 'components/GameBoard/Emotion/Multi/Enigma/Table/TableBedroom.module.scss';
import Button from 'components/GameBoard/ReusableComponents/Actions/Button';

const TableBedroom = () => {
  const { t } = useTranslation(['emotion-multi', 'common']);

  const [drawerStatus, setDrawerStatus] = useState('lock');

  const arrayCodeEmpty = Array(codeTableBedroom.length).fill('');
  const [code, setCode] = useState(arrayCodeEmpty);
  const [errorMessage, setErrorMessage] = useState(null);

  const session = useSelector(selectSession);
  const { socket, idSessionHasRoom } = useSelector(infoGameUser);
  const room = session.id;
  const { description } = useSelector(selectModalEnigma);
  const { participants } = useSelector(selectUser);
  const dispatch = useDispatch();

  const noticeBedroomMultiEmotionObject = useSelector((state) =>
    selectObject(state, noticeBedroomMultiEmotion)
  );
  const noticeBedroomMultiEmotionIndex = useSelector((state) =>
    selectObjectIndex(state, noticeBedroomMultiEmotion)
  );

  useEffect(() => {
    if (description.content.status === 'open') {
      setDrawerStatus('open');
    }
    socket?.on('receive_error_messaging', (data) => {
      setErrorMessage(data.errorContent);
    });
    socket?.on('receive_emotion_multi_status_drawer', (data) => {
      setDrawerStatus(data.status);
    });
  }, [description.content.status, socket]);

  const submitCode = (event) => {
    let responsesSocket = { room };
    clickCount(dispatch, event, socket, responsesSocket);
    if (socket) {
      clickCountUser(participants, socket, dispatch, session);
    }
    if (code.join('') === codeTableBedroom) {
      animationConfetti();

      responsesSocket = {
        status: 'open',
        room,
      };
      socket?.emit('send_emotion_multi_status_drawer', responsesSocket);
      socket?.emit('send_confetti', responsesSocket);
      socket?.on('send_emotion_multi_status_drawer', responsesSocket);
      setDrawerStatus('open');
      changeStatusOfObject(
        description.content,
        'open',
        idSessionHasRoom,
        dispatch,
        socket,
        room
      );
    } else {
      const errorContent = t('table.error');
      responsesSocket = {
        errorContent,
        room,
      };
      socket?.emit('send_error_messaging', responsesSocket);
      setErrorMessage(errorContent);
    }
  };

  const handleLockDrawer = () => {
    return (
      <>
        <p>{t('tvStand.introduction')}</p>
        {errorMessage && <p>{errorMessage}</p>}
        <DrawerInputs
          handleGoodCode={codeTableBedroom}
          setCode={setCode}
          submitCode={submitCode}
          code={code}
          title={t('buttonFunction.open', { ns: 'common' })}
        />
      </>
    );
  };

  const handleImage = (descriptionContent) => {
    let imageDrawer;
    if (descriptionContent && descriptionContent.images) {
      const objectIndex = descriptionContent.images.findIndex(
        (object) => object.type === 'clicked'
      );
      imageDrawer = descriptionContent.images[objectIndex].image;
    }
    if (
      noticeBedroomMultiEmotionObject.isInventory ||
      noticeBedroomMultiEmotionObject.isChecked
    ) {
      const objectIndex = descriptionContent.images.findIndex(
        (object) => object.type === 'inventory'
      );
      imageDrawer = descriptionContent.images[objectIndex].image;
    }
    return imageDrawer;
  };

  const handleMessage = (descriptionContent) => {
    let message;
    if (descriptionContent?.images) {
      const objectIndex = descriptionContent.images.findIndex(
        (object) => object.type === 'clicked'
      );
      message = descriptionContent.images[objectIndex].message;
    }
    if (
      noticeBedroomMultiEmotionObject.isInventory ||
      noticeBedroomMultiEmotionObject.isChecked
    ) {
      const objectIndex = descriptionContent.images.findIndex(
        (object) => object.type === 'inventory'
      );
      message = descriptionContent.images[objectIndex].message;
    }

    return message;
  };

  const handleActionObject = (content, index, event) => {
    changeStatusOfObject(content, 'open', idSessionHasRoom, dispatch, socket, room);
    addInventory(
      dispatch,
      idSessionHasRoom,
      content,
      index,
      event,
      socket,
      room,
      participants,
      session
    );
  };

  return (
    <div className={styles.table}>
      {drawerStatus !== 'open' ? (
        handleLockDrawer()
      ) : (
        <div className={styles.contents}>
          <p>{handleMessage(noticeBedroomMultiEmotionObject)}</p>
          {!noticeBedroomMultiEmotionObject.isInventory && (
            <div className={styles['object-content']}>
              <img
                src={handleImage(noticeBedroomMultiEmotionObject)}
                alt="notice"
              />
              <Button
                buttonType="action"
                onClick={(event) =>
                  handleActionObject(
                    noticeBedroomMultiEmotionObject,
                    noticeBedroomMultiEmotionIndex,
                    event
                  )
                }
                title={t('buttonFunction.addInventory', { ns: 'common' })}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TableBedroom;
