import styles from 'components/Joyride/Steps.module.scss';
import * as imageNext from 'img/vaccination-solo/PuckLab';

// Define a function to use the translation function as parameter
const commonSteps = (t, val, val2, mission) => {
  const content = (title, description) => (
    <div className={styles['main-content']}>
      <h2>{title}</h2>
      <p>{description}</p>
    </div>
  );

  const steps = [
    {
      target: 'body',
      content: (
        <div className={styles['main-content']}>
          <h2>
            {t('joyride.welcome', {
              val,
            })}
          </h2>
          {mission && <p>{mission}</p>}
        </div>
      ),
      placement: 'center',
    },
    {
      target: '.Steps',
      content: content(t('joyride.steps.title'), t('joyride.steps.description')),
      placement: 'bottom',
    },
    {
      target: '.Hud-Item-Text-time',
      content: (
        <div className={styles['main-content']}>
          <h2>{t('joyride.time.title')}</h2>
          <p>{t('joyride.time.description')}</p>
          <img src={imageNext.time} alt="Tricky" />
          <p>{t('joyride.time.score')}</p>
        </div>
      ),
      placement: 'bottom',
    },
    {
      target: '.Hud-Item-Text-click',
      content: (
        <div className={styles['main-content']}>
          <h2>{t('joyride.hits.title')}</h2>
          <p>{t('joyride.hits.description')}</p>
          <img src={imageNext.cursor} alt="Tricky" />{' '}
          <p>{t('joyride.hits.score')}</p>
        </div>
      ),
      placement: 'bottom',
    },
    {
      target: '.Hud-Item-Text-score',
      content: (
        <div className={styles['main-content']}>
          <h2>{t('joyride.points.title')}</h2>
          <p>{t('joyride.points.description')}</p>
          <img className={styles.coin} src={imageNext.coinScore} alt="Tricky" />
        </div>
      ),
      placement: 'bottom',
    },
    {
      target: '.Hud-Item-Text-sound',
      content: (
        <div className={styles['main-content']}>
          <h2>{t('joyride.sound.title')}</h2>
          <p>{t('joyride.sound.description')}</p>
          <img src={imageNext.notMuteIcon} alt="Tricky" />
        </div>
      ),
      placement: 'bottom',
    },
    {
      target: '.Hud-Item-Text-music',
      content: (
        <div className={styles['main-content']}>
          <h2>{t('joyride.music.title')}</h2>
          <p>{t('joyride.music.description')}</p>
          <img src={imageNext.unMuteMusic} alt="Tricky" />
        </div>
      ),
      placement: 'bottom',
    },
    {
      target: 'body',
      content: (
        <div className={styles['main-content']}>
          <h2>{t('joyride.goodLuck.title')}</h2>
          <p>
            {t('joyride.goodLuck.description', {
              val: val2,
            })}
          </p>
        </div>
      ),
      placement: 'center',
    },
  ];
  return steps;
};
export default commonSteps;
