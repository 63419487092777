const PlutchikEmotions = [
  {
    name: 'serenity',
    color: '#ffea9b',
    path: 'M212.7,86.9C224.2,40.9,250,0,250,0h0s25.8,40.8,37.3,86.9A169.89,169.89,0,0,0,250,82.7,164.05,164.05,0,0,0,212.7,86.9Z',
    textPosition: 'translate(250 69)',
    id: 2169,
  },
  {
    name: 'joy',
    color: '#ffd678',
    path: 'M250,155.2a95.15,95.15,0,0,0-36.3,7.2h0c-9.4-22.7-7.4-49.9-1-75.5A169.89,169.89,0,0,1,250,82.7a164.05,164.05,0,0,1,37.3,4.2c6.4,25.6,8.4,52.8-1,75.5h0A93.3,93.3,0,0,0,250,155.2Z',
    textPosition: 'translate(250 122)',
    id: 6432,
  },
  {
    name: 'ecstasy',
    color: '#ffc231',
    path: 'M250,250l-36.3-87.6h0a95.11,95.11,0,0,1,72.6,0Z',
    textPosition: 'translate(250 178)',
    id: 1674,
  },
  {
    name: 'acceptance',
    color: '#c0da87',
    path: 'M338.9,108.3c40.7-24.4,87.8-35,87.8-35h0s-10.6,47.1-35,87.8a175.62,175.62,0,0,0-23.4-29.4A162.47,162.47,0,0,0,338.9,108.3Z',
    textPosition: 'translate(397 107) rotate(-45)',
    id: 2110,
  },
  {
    name: 'trust',
    color: '#9acc68',
    path: 'M317,183a94.15,94.15,0,0,0-30.8-20.6h0c9.4-22.7,30-40.5,52.6-54.1a175.62,175.62,0,0,1,29.4,23.4,162.47,162.47,0,0,1,23.4,29.4c-13.6,22.6-31.4,43.3-54.1,52.6h0A91.91,91.91,0,0,0,317,183Z',
    textPosition: 'translate(345 159) rotate(-45)',
    id: 2168,
  },
  {
    name: 'admiration',
    color: '#75be52',
    path: 'M250,250l36.3-87.6h0A92.72,92.72,0,0,1,317,183a96.43,96.43,0,0,1,20.6,30.7Z',
    textPosition: 'translate(291 211) rotate(-45)',
    id: 1676,
  },
  {
    name: 'fear',
    color: '#00ac6e',
    path: 'M344.8,250a95.15,95.15,0,0,0-7.2-36.3h0c22.7-9.4,49.9-7.4,75.5-1a169.89,169.89,0,0,1,4.2,37.3,164.05,164.05,0,0,1-4.2,37.3c-25.6,6.4-52.8,8.4-75.5-1h0A93.3,93.3,0,0,0,344.8,250Z',
    textPosition: 'translate(379 253)',
    id: 1538,
  },
  {
    name: 'apprehension',
    color: '#63bf8f',
    path: 'M413.1,212.7C459.1,224.2,500,250,500,250h0s-40.8,25.8-86.9,37.3a169.89,169.89,0,0,0,4.2-37.3A164.05,164.05,0,0,0,413.1,212.7Z',
    textPosition: 'translate(450 253)',
    id: 1837,
  },
  {
    name: 'terror',
    color: '#009b4e',
    path: 'M250,250l87.6-36.3h0a95.11,95.11,0,0,1,0,72.6Z',
    textPosition: 'translate(308 253)',
    id: 1673,
  },
  {
    name: 'distraction',
    color: '#75c0de',
    path: 'M391.7,338.9c24.4,40.7,35,87.8,35,87.8h0s-47.1-10.6-87.8-35a175.62,175.62,0,0,0,29.4-23.4A162.47,162.47,0,0,0,391.7,338.9Z',
    textPosition: 'translate(393 397) rotate(45)',
    id: 2171,
  },
  {
    name: 'surprise',
    color: '#05a5ce',
    path: 'M317,317a94.15,94.15,0,0,0,20.6-30.8h0c22.7,9.4,40.5,30,54.1,52.6a175.62,175.62,0,0,1-23.4,29.4,162.47,162.47,0,0,1-29.4,23.4c-22.6-13.6-43.3-31.4-52.6-54.1h0A91.91,91.91,0,0,0,317,317Z',
    textPosition: 'translate(341 345) rotate(45)',
    id: 2844,
  },
  {
    name: 'amazement',
    color: '#008fc3',
    path: 'M250,250l87.6,36.3h0A92.72,92.72,0,0,1,317,317a96.43,96.43,0,0,1-30.7,20.6Z',
    textPosition: 'translate(289 293) rotate(45)',
    id: 1672,
  },
  {
    name: 'pensiveness',
    color: '#92b7df',
    path: 'M287.3,413.1C275.8,459.1,250,500,250,500h0s-25.8-40.8-37.3-86.9a169.89,169.89,0,0,0,37.3,4.2A164.05,164.05,0,0,0,287.3,413.1Z',
    textPosition: 'translate(250 436)',
    id: 2732,
  },
  {
    name: 'sadness',
    color: '#639fd1',
    path: 'M250,344.8a95.15,95.15,0,0,0,36.3-7.2h0c9.4,22.7,7.4,49.9,1,75.5a169.89,169.89,0,0,1-37.3,4.2,164.05,164.05,0,0,1-37.3-4.2c-6.4-25.6-8.4-52.8,1-75.5h0A93.3,93.3,0,0,0,250,344.8Z',
    textPosition: 'translate(250 384)',
    id: 3959,
  },
  {
    name: 'grief',
    color: '#1278ba',
    path: 'M250,250l36.3,87.6h0a95.11,95.11,0,0,1-72.6,0Z',
    textPosition: 'translate(250 327)',
    id: 1700,
  },
  {
    name: 'boredom',
    color: '#b2a0cb',
    path: 'M161.1,391.7c-40.7,24.4-87.8,35-87.8,35h0s10.6-47.1,35-87.8a175.62,175.62,0,0,0,23.4,29.4A162.47,162.47,0,0,0,161.1,391.7Z',
    textPosition: 'translate(107 397) rotate(-45)',
    id: 2842,
  },
  {
    name: 'disgust',
    color: '#9b85ba',
    path: 'M183,317a94.15,94.15,0,0,0,30.8,20.6h0c-9.4,22.7-30,40.5-52.6,54.1a175.62,175.62,0,0,1-29.4-23.4,162.47,162.47,0,0,1-23.4-29.4c13.6-22.6,31.4-43.3,54.1-52.6h0A91.91,91.91,0,0,0,183,317Z',
    textPosition: 'translate(159 345) rotate(-45)',
    id: 2740,
  },
  {
    name: 'loathing',
    color: '#8168a7',
    path: 'M250,250l-36.3,87.6h0A92.72,92.72,0,0,1,183,317a96.43,96.43,0,0,1-20.6-30.7Z',
    textPosition: 'translate(210 293) rotate(-45)',
    id: 1747,
  },
  {
    name: 'anger',
    color: '#fa6764',
    path: 'M155.2,250a95.15,95.15,0,0,0,7.2,36.3h0c-22.7,9.4-49.9,7.4-75.5,1A169.89,169.89,0,0,1,82.7,250a164.05,164.05,0,0,1,4.2-37.3c25.6-6.4,52.8-8.4,75.5,1h0A93.3,93.3,0,0,0,155.2,250Z',
    textPosition: 'translate(119 253)',
    id: 2908,
  },
  {
    name: 'annoyance',
    color: '#fb8177',
    path: 'M86.9,287.3C40.9,275.8,0,250,0,250H0s40.8-25.8,86.9-37.3A169.89,169.89,0,0,0,82.7,250,164.05,164.05,0,0,0,86.9,287.3Z',
    textPosition: 'translate(55 253)',
    id: 2735,
  },
  {
    name: 'rage',
    color: '#f84f57',
    path: 'M250,250l-87.6,36.3h0a95.11,95.11,0,0,1,0-72.6Z',
    textPosition: 'translate(192 253)',
    id: 1716,
  },
  {
    name: 'interest',
    color: '#ffbc81',
    path: 'M108.3,161.1c-24.4-40.7-35-87.8-35-87.8h0s47.1,10.6,87.8,35a175.62,175.62,0,0,0-29.4,23.4A162.47,162.47,0,0,0,108.3,161.1Z',
    textPosition: 'translate(98 107) rotate(45)',
    id: 2836,
  },
  {
    name: 'anticipation',
    color: '#ffa263',
    path: 'M183,183a94.15,94.15,0,0,0-20.6,30.8h0c-22.7-9.4-40.5-30-54.1-52.6a175.62,175.62,0,0,1,23.4-29.4,162.47,162.47,0,0,1,29.4-23.4c22.6,13.6,43.3,31.4,52.6,54.1h0A91.91,91.91,0,0,0,183,183Z',
    textPosition: 'translate(150 159) rotate(45)',
    id: 2745,
  },
  {
    name: 'vigilance',
    color: '#fd8740',
    path: 'M250,250l-87.6-36.3h0A92.72,92.72,0,0,1,183,183a96.43,96.43,0,0,1,30.7-20.6Z',
    textPosition: 'translate(202 212) rotate(45)',
    id: 1824,
  },
];

export default PlutchikEmotions;
