import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { motion } from 'framer-motion';
import { useEffect } from 'react';

// selectors
import { selectSteps } from 'components/Reducers/Steps/Steps';

// components
import OffreSocleModal from 'components/GameBoard/OffreSocle/Enigmas/OffreSocleModal';
import Button from 'components/GameBoard/ReusableComponents/Actions/Button';
import DialogueRow from 'components/GameBoard/OffreSocle/Enigmas//DialogueRow';

// utils
import { referringDoctorOffice } from 'components/GameBoard/OffreSocle/constants';
import { playSound } from 'utils/utilityFunctions';

// scss
import styles from 'components/GameBoard/OffreSocle/Enigmas/Notebook.module.scss';

const { doctorFace } = referringDoctorOffice;

const Notebook = ({ objectContent }) => {
  const { t } = useTranslation('offreSocle', 'common');
  const dispatch = useDispatch();

  const { list } = useSelector(selectSteps);
  const firstMessageFound = list?.[0]?.isFound;

  const notebookClicked = objectContent.images.find(
    (object) => object.type === 'clicked'
  );
  const notebookOpen = objectContent.images.find(
    (object) => object.type === 'open'
  );

  useEffect(() => {
    if (firstMessageFound) {
      setTimeout(() => {
        playSound(notebookClicked.sound);
      }, 500);
    }
  }, [firstMessageFound, notebookClicked.sound]);

  return !firstMessageFound ? (
    <OffreSocleModal
      objectImage={notebookClicked.image}
      objectMessage={t('notebookNotNeeded')}
      buttonTitle={t('buttonFunction.return', { ns: 'common' })}
      onClick={() =>
        dispatch({
          type: 'CLOSE_MODAL_ENIGMA',
        })
      }
    />
  ) : (
    <motion.div className={styles['enigma-container']}>
      <DialogueRow
        characterImage={doctorFace}
        direction="row"
        dialogueText={t('notebookOpen', { returnObjects: true })}
        smallView
      />
      <img src={notebookOpen.image} alt={t('notebook')} width="60%" height="60%" />
      <Button
        title={t('buttonFunction.return', { ns: 'common' })}
        buttonType="offreSocle"
        onClick={() => {
          playSound(notebookOpen.sound);
          dispatch({
            type: 'CLOSE_MODAL_ENIGMA',
          });
        }}
      />
    </motion.div>
  );
};

Notebook.propTypes = {
  objectContent: PropTypes.shape({
    images: PropTypes.shape({
      find: PropTypes.func,
    }),
  }).isRequired,
};

export default Notebook;
