import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';

// selectors
import { selectUser } from 'components/Reducers/user';
import { selectSession } from 'components/Reducers/session';
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';

// utils
import { initRoundByRound, retrieveQuestionnary } from 'utils/utilityFunctions';

// scss
import styles from 'components/GameBoard/ReusableComponents/Multi/CheckRoom/WaitingRoomParticipants.module.scss';

// components
import MainScreen from 'components/GameBoard/ReusableComponents/Multi/MainScreen/MainScreen';
import PreventMessage from 'components/GameBoard/ReusableComponents/Multi/Shared/PreventMessage/PreventMessage';
import Button from 'components/GameBoard/ReusableComponents/Actions/Button';
import {
  maxOfUser,
  minOfUser,
} from 'components/GameBoard/ReusableComponents/Multi/constants';
import Questionnary from 'components/GameBoard/ReusableComponents/Multi/Shared/Questionnary/Questionnary';
import Debriefing from 'components/GameBoard/ReusableComponents/Multi/Shared/Questionnary/Debriefing/Debriefing';

// config
import firepadRef, {
  db,
} from 'components/GameBoard/ReusableComponents/Multi/server/firebase';

// actions
import {
  setMainStream,
  addParticipant,
  setUserVisio,
  removeParticipant,
  updateParticipant,
  addResponsesUserVisio,
  updateStatusQuestionnaryOfUser,
} from 'components/Action';

function Participants({ setStatusCheckRoom, inGame, statusCheckRoom }) {
  const dispatch = useDispatch();

  const { t } = useTranslation(['common', 'emotion-multi']);
  const connectedRef = db.database().ref('.info/connected');
  const participantRef = firepadRef.child('participants');

  // TO REFACTO when we change the name of params
  const [urlParams] = useSearchParams();
  const userId = urlParams.get('user') || urlParams.get('admin');
  const isUserType = urlParams.get('user');

  const { userName, mainStream, currentUser, participants } =
    useSelector(selectUser);
  const session = useSelector(selectSession);
  const { socket } = useSelector(infoGameUser);

  const isUserSet = !!currentUser;
  const isStreamSet = !!mainStream;
  const useOneTimeEffect = useRef(false);

  const [message, setMessage] = useState(null);
  const [introductionStatus, setIntroductionStatus] = useState({
    status: 'starter',
    isFinish: false,
  });

  useEffect(() => {
    const messageId = t('introMessage.messageId', { ns: 'emotion-multi' });
    retrieveQuestionnary(messageId, dispatch, 'start');
  }, [dispatch, t]);

  useEffect(() => {
    socket.on('receive_response_user_of_question', (data) => {
      dispatch(addResponsesUserVisio(data));
    });
    socket.on('receive_finish_questionnary_user', (data) => {
      dispatch(updateStatusQuestionnaryOfUser(data));
    });
  }, [dispatch, socket]);

  useEffect(() => {
    // this useEffect run Twice
    // init ref visio
    if (!useOneTimeEffect.current) {
      const getUserStream = async () => {
        const localStream = await navigator.mediaDevices.getUserMedia({
          audio: {
            echoCancellation: true,
            noiseSuppression: true,
          },
          video: {
            frameRate: {
              max: 15,
            },
            width: {
              max: 320,
            },
            height: {
              max: 240,
            },
          },
        });
        localStream.getVideoTracks()[0].enabled = true;
        dispatch(setMainStream(localStream));

        return localStream;
      };
      getUserStream();
      connectedRef.on('value', (snap) => {
        if (snap.val()) {
          const defaultPreference = {
            audio: {
              echoCancellation: true,
              noiseSuppression: true,
            },
            video: {
              frameRate: {
                max: 15,
              },
              width: {
                max: 320,
              },
              height: {
                max: 240,
              },
            },
          };
          const typeOfUser = isUserType ? 'user' : 'admin';

          const userStatusRef = participantRef.push({
            userName,
            userId,
            preferences: defaultPreference,
            type: typeOfUser,
          });
          dispatch(
            setUserVisio({
              [userStatusRef.key]: {
                name: userName,
                id: userId,
                type: typeOfUser,
                ...defaultPreference,
              },
            })
          );
          userStatusRef.onDisconnect().remove();
        }
      });
    }

    return () => {
      useOneTimeEffect.current = true;
    };
  }, [
    connectedRef,
    participantRef,
    userId,
    userName,
    dispatch,
    isUserType,
    session.isTutoFinish,
    introductionStatus,
    inGame,
    participants,
  ]);

  useEffect(() => {
    if (isStreamSet && isUserSet) {
      // init participants
      participantRef.on('child_added', (snap) => {
        const preferenceUpdateEvent = participantRef
          .child(snap.key)
          .child('preferences');
        preferenceUpdateEvent.on('child_changed', (preferenceSnap) => {
          dispatch(
            updateParticipant({
              [snap.key]: {
                [preferenceSnap.key]: preferenceSnap.val(),
              },
            })
          );
        });

        const { userName: name, type, preferences = {} } = snap.val();
        dispatch(
          addParticipant({
            [snap.key]: {
              name,
              type,
              id: userId,
              ...preferences,
            },
          })
        );
      });
      participantRef.on('child_removed', (snap) => {
        dispatch(removeParticipant(snap.key));
      });
    }
    // TO REFACTO
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStreamSet, isUserSet]);

  useEffect(() => {
    initRoundByRound(participants, session, socket, dispatch);
  }, [dispatch, participants, session, socket]);

  useEffect(() => {
    // init a message to begin the game (in condition of number of users and mediators)
    let newMessage;
    const isMediator = Object.keys(participants)
      .map((participant) => participants[participant].type === 'admin')
      .includes(true);

    const isAllUsersAndMediator =
      isMediator && Object.keys(participants).length > maxOfUser;
    const isMediatorAndMinimunOfUser =
      isMediator && Object.keys(participants).length > minOfUser;
    const isMinimumOfUser =
      !isMediator && Object.keys(participants).length >= minOfUser;

    const handleButtonNextIntroduction = () => {
      if (session.isTutoFinish) {
        return (
          <Button
            onClick={() => {
              const room = session.id;
              const responses = {
                room,
              };
              socket?.emit('send_introduction_finish', responses);
              socket?.emit('send_users_in_game', responses);

              setStatusCheckRoom('introduction-finish');
              dispatch({ type: 'USERS_IN_GAME' });
            }}
            title={t('buttonFunction.rejoinGroup')}
            buttonType="action"
          />
        );
      }
      return (
        <Button
          onClick={() =>
            setIntroductionStatus({
              ...introductionStatus,
              status: !isUserType ? 'debriefing' : 'introduction',
            })
          }
          title={t('buttonFunction.begin')}
          buttonType="action"
        />
      );
    };

    if (isAllUsersAndMediator) {
      newMessage = handleButtonNextIntroduction();
    } else if (isMinimumOfUser || isMediatorAndMinimunOfUser) {
      newMessage = (
        <div className={styles.message}>
          {handleButtonNextIntroduction()}
          <p>{t('multi.visio.beginWithoutAllUsers')}</p>
        </div>
      );
    } else {
      newMessage = <p> {t('multi.visio.wait')} </p>;
    }

    setMessage(newMessage);
  }, [
    introductionStatus,
    isUserType,
    participants,
    t,
    session,
    setStatusCheckRoom,
    dispatch,
    socket,
    currentUser,
  ]);

  const handleIntroduction = () => {
    if (introductionStatus.status === 'introduction' && isUserType) {
      // Access the i18next arrays
      const messages = t('introMessage.messages', {
        returnObjects: true,
        ns: 'emotion-multi',
      });
      return (
        <PreventMessage
          setIntroductionStatus={setIntroductionStatus}
          introductionStatus={introductionStatus}
          messages={messages}
        />
      );
    }
    if (introductionStatus.status === 'questionnary' && isUserType) {
      return (
        <Questionnary
          setIntroductionStatus={setIntroductionStatus}
          introductionStatus={introductionStatus}
        />
      );
    }

    if (introductionStatus.status === 'debriefing') {
      return <Debriefing setStatusCheckRoom={setStatusCheckRoom} />;
    }

    return <div className={styles.footer}>{message}</div>;
  };

  return (
    <div className={styles.checkroom}>
      {statusCheckRoom !== 'introduction-finish' && handleIntroduction()}
      <MainScreen setStatusCheckRoom={setStatusCheckRoom} />
    </div>
  );
}

Participants.propTypes = {
  setStatusCheckRoom: PropTypes.func.isRequired,
  inGame: PropTypes.bool,
  statusCheckRoom: PropTypes.string.isRequired,
};

Participants.defaultProps = {
  inGame: false,
};

export default Participants;
