import PropTypes from 'prop-types';
import styled from 'styled-components';

const ProgressBarStyle = styled.div`
  width: 100%;
  border-radius: 10px;
  margin: 5px;

  div {
    height: 21px;
    width: ${({ completed }) => completed}%;
    background-color: ${({ completed }) => completed > 0 && '#eaeaea'};
    border-radius: inherit;
    text-align: center;
    transition: width 1s ease-in-out;
    padding: 1px;
  }
  span {
    color: black;
    font-weight: bold;
  }
`;

const ProgressBar = ({ completed }) => {
  return (
    <ProgressBarStyle completed={completed}>
      <div>
        <span>{completed > 0 && `${completed}%`}</span>
      </div>
    </ProgressBarStyle>
  );
};

ProgressBar.propTypes = {
  completed: PropTypes.number.isRequired,
};

export default ProgressBar;
