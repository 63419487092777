import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

import HudItem from 'components/GameBoard/ReusableComponents/HUD/HudItem';
import 'assets/css/components/GameBoard/ReusableComponents/Hud/Inventory.css';

import * as images from '../../../../img/vaccination-solo/PuckLab/index';

function Hud({ gameData, Muted, Music }) {
  const HudElement = [
    {
      id: 1,
      title: 'time',
      image: images.time,
    },
    {
      id: 2,
      title: 'click',
      text: 'coup(s)',
      image: images.cursor,
    },
    {
      id: 3,
      title: 'score',
      text: ' pts',
      image: images.coinScore,
    },
    {
      id: 4,
      title: 'sound',
      text: 'sound',
      image: !Muted ? images.muteIcon : images.notMuteIcon,
    },
    {
      id: 5,
      title: 'music',
      text: 'music',
      image: !Music ? images.muteMusic : images.unMuteMusic,
    },
  ];
  return (
    <motion.div
      className={gameData.scoreAnim ? 'Hud-Container-Anim' : 'Hud-Container'}
    >
      {HudElement.map((item) => (
        <HudItem
          key={item.id}
          title={item.title}
          text={item.text}
          image={item.image}
        />
      ))}
    </motion.div>
  );
}

Hud.propTypes = {
  gameData: PropTypes.shape({
    scoreAnim: PropTypes.bool,
  }).isRequired,
  Muted: PropTypes.bool.isRequired,
  Music: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  gameData: state.GameUsers,
  Muted: state.GameUsers.soundtrack,
  Music: state.GameUsers.music,
});

export default connect(mapStateToProps)(Hud);
