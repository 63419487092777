import { useTranslation } from 'react-i18next';

import { useEffect, useState } from 'react';
import styles from 'components/Introduction/StartPage.module.scss';
import ProgressBar from './ProgressBar';

const Loading = () => {
  const { t } = useTranslation('common');

  const [completed, setCompleted] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCompleted((oldValue) => {
        let newValue = oldValue + 25;
        if (newValue >= 100) {
          newValue = 100;
        }
        return newValue;
      });
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className={styles.loading}>
      <div className={styles.loadingBox}>
        <p>{t('loading')}</p>
        <ProgressBar completed={completed} />
      </div>
    </div>
  );
};

export default Loading;
