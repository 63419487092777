import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import axios from 'axios';
import confetti from 'canvas-confetti';

import Audio from 'components/GameBoard/ReusableComponents/Actions/Audio';
import Modal from 'components/GameBoard/ReusableComponents/Modal/Modal';
import Steps from 'components/GameBoard/ReusableComponents/Steps';
import ListObjects from 'components/GameBoard/ReusableComponents/Objects/ListObjects';
import Zoom from 'components/GameBoard/ReusableComponents/Modal/Zoom';
import ModalZoom from 'components/GameBoard/ReusableComponents/Modal/ModalZoom';
import ModalEnigma from 'components/GameBoard/ReusableComponents/Modal/ModalEnigma';
import ObjectInInventory from 'components/GameBoard/ReusableComponents/Actions/ObjectInInventory';
import Hud from 'components/GameBoard/ReusableComponents/HUD/Hud';
import Intro from 'components/GameBoard/ReusableComponents/Intro';
import stepsRPS from 'components/Joyride/StepsRPS';
import InterfaceGame from 'components/GameBoard/RPS/Enigma/InterfaceGame';
import Patients from 'components/GameBoard/RPS/Enigma/Patients';
import Scoreboard from 'components/GameBoard/ReusableComponents/Scoreboard/Scoreboard';

// selector functions
import { selectSession } from 'components/Reducers/session';

// utils
import useInitGame from 'utils/useInitGame';
import { useStartMessageOrQuestionary } from 'utils/utilityFunctions';
import { startedMessage } from 'components/constants';

import '../../GameBoard.css';
import '../../../../assets/css/components/GameBoard/RPS/Rooms/WelcomeRPS.css';
import rpsTheme from '../../../../sound/hospital-emergency.mp3';
import click from '../../../../sound/click-ongame.mp3';
// Sound for successful answers
import successSound from '../../../../sound/Success.mp3';
// Sound for wrong answers
import failSound from '../../../../sound/fail.mp3';

const BASE_URL = process.env.REACT_APP_BASE_URL;

function WelcomeRPS({
  GameUsers,
  modalIsOpen,
  currentRoom,
  dispatch,
  startMessage,
  currentStep,
  startQuestionnary,
  idSessionHasRoom,
  zoomIsOpen,
  modalZoomIsOpen,
  count,
  modalEnigmaIsOpen,
  listRooms,
  MouseClicked,
  pourcentStress,
  indexPatient,
  allObjects,
  isTutoFinish,
  isScoreBoard,
  isStarted,
}) {
  const session = useSelector(selectSession);

  useInitGame(currentRoom, session.id, listRooms);

  const [stepGame, setStepGame] = useState({
    run: GameUsers.gameTour,
    steps: stepsRPS,
    stepIndex: 0,
  });

  const immediatlyAction = 1;
  const stayAction = 2;
  const evaluationAction = 3;

  const allPatients = [
    {
      id: 0,
      url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Fcouloir%2Fportes%2Falbert.svg?alt=media&token=6dd198f4-3569-4a9c-a320-63dfb5f57b5c',
      firstname: 'Patient',
      constant: { 'T°': 38, FC: 95, FR: 22, Oxy: 96 },
    },
    {
      id: 1,
      url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FRPS%2Fassets%2Frooms%2FWelcome%2Fusers%2Fpicto-gael.svg?alt=media&token=49e3be1a-cfd5-4878-9fb2-b644db9552ea',
      firstname: 'Gaël',
      constant: { 'T°': 38, FC: 95, FR: 22, Oxy: 96 },
      choice: stayAction,
      walking: false,
      position: -203.25,
      backgroundPosition: '203.25px',
      stepNumber: 1,
      finish: false,
      image:
        'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FRPS%2Fassets%2Frooms%2FWelcome%2Fusers%2FP1_Gael_bon.svg?alt=media&token=177be538-f5c5-4a91-8d0f-67973e5c72cc',
      description:
        'Bonjour, Je suis Gaël. Je suis tombé dans mes escaliers. Depuis j’ai une forte douleurs au bras et impossible de travailler.  ',
    },
    {
      id: 2,
      url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FRPS%2Fassets%2Frooms%2FWelcome%2Fusers%2Fpicto-camille.svg?alt=media&token=4162e0da-bfab-4ec6-81f4-49cc23163b6f',
      firstname: 'Camille',
      constant: { 'T°': 38, FC: 103, FR: 24, Oxy: 97 },
      choice: evaluationAction,
      walking: false,
      position: -173.57,
      backgroundPosition: '173.57px',
      stepNumber: 1,
      finish: false,
      image:
        'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FRPS%2Fassets%2Frooms%2FWelcome%2Fusers%2FP2_Camille_bon.svg?alt=media&token=26f02381-48ac-4b1f-ac68-15d875001001',
      description:
        'Bonjour, je suis Camille. Je viens vous voir car j’ai une forte douleur au thorax. Ça m’inquiète un peu pour tout vous dire.',
    },

    {
      id: 3,
      url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FRPS%2Fassets%2Frooms%2FWelcome%2Fusers%2Fpicto-victor.svg?alt=media&token=6e60160c-a636-4621-8240-5f35935ef1cd',
      firstname: 'Victor',
      constant: { 'T°': 39, FC: 111, FR: 25, Oxy: 95 },
      choice: evaluationAction,
      walking: false,
      position: -250,
      backgroundPosition: '250px',
      stepNumber: 1,
      finish: false,
      image:
        'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FRPS%2Fassets%2Frooms%2FWelcome%2Fusers%2FP3_Victor_bon.svg?alt=media&token=0b4dcf81-7c47-487b-9d45-c5c302601850',
      description:
        'Bonjour, je suis Victor. J’ai un mal de tête continu depuis quelques jours. Alors que je rentre d’un voyage au Sénégal, je devrais être reposé.',
    },

    {
      id: 4,
      url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FRPS%2Fassets%2Frooms%2FWelcome%2Fusers%2Fpicto-moha.svg?alt=media&token=af4f7229-a357-489e-a131-a6c6ea82934b',
      firstname: 'Mohammed',
      constant: { 'T°': 39, FC: 103, FR: 42, Oxy: 95 },
      choice: immediatlyAction,
      walking: false,
      position: -220.69,
      backgroundPosition: '220.69px',
      stepNumber: 1,
      finish: false,
      image:
        'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FRPS%2Fassets%2Frooms%2FWelcome%2Fusers%2FP4_Moha.svg?alt=media&token=aee4378f-78d4-45c4-8db8-8cc19b0505c2',
      description:
        'Bonjour, je suis Mohamed. En ce moment je dors mal et je suis très fatigué. Tout à l’heure j’ai fait un petit malaise mais pas de quoi s’affoler.',
    },
    {
      id: 5,
      url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FRPS%2Fassets%2Frooms%2FWelcome%2Fusers%2Fpicto-sabine.svg?alt=media&token=4d57b938-9d8a-4caf-9c8b-d4089fc9e023',
      firstname: 'Sabrine',
      constant: { 'T°': 37, FC: 106, FR: 35, Oxy: 99 },
      choice: evaluationAction,
      walking: false,
      position: -174.27,
      backgroundPosition: '174.27px',
      stepNumber: 1,
      finish: false,
      image:
        'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FRPS%2Fassets%2Frooms%2FWelcome%2Fusers%2FP6_Sabine.svg?alt=media&token=94fe1e2c-6871-4e93-8a25-fe4559cfa298',
      description:
        'Bonjour je suis Sabrine. Je suis une personne asthmatique mais en général une petite bouffée de ventoline et ça passe mais pas cette fois. J’ai du mal à respirer.',
    },

    {
      id: 6,
      url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FRPS%2Fassets%2Frooms%2FWelcome%2Fusers%2Fpicto-josette.svg?alt=media&token=6a6e3490-9357-421f-b1c5-36f2be058781',
      firstname: 'Josette',
      constant: { 'T°': 39, FC: 97, FR: 22, Oxy: 89 },
      choice: evaluationAction,
      walking: false,
      position: -166.23,
      backgroundPosition: '166.23px',
      stepNumber: 1,
      finish: false,
      image:
        'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FRPS%2Fassets%2Frooms%2FWelcome%2Fusers%2FP7_Josette.svg?alt=media&token=653324fc-e0bf-460c-95a8-527804ee61d6',
      description:
        "Bonjour je suis Josette. J’ai des quintes de toux qui me gênent énormément et qui me font très mal à la gorge et ce depuis que je suis rentrée d'Amérique du Nord.",
    },

    {
      id: 7,
      url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FRPS%2Fassets%2Frooms%2FWelcome%2Fusers%2Fpicto-joseph.svg?alt=media&token=9429ad6b-e554-4456-bb20-844965a0dd04',
      firstname: 'Joseph',
      constant: { 'T°': 39, FC: 128, FR: 24, Oxy: 95 },
      choice: evaluationAction,
      walking: false,
      position: -219.55,
      backgroundPosition: '219.55px',
      stepNumber: 1,
      finish: false,
      image:
        'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FRPS%2Fassets%2Frooms%2FWelcome%2Fusers%2FP8_Joseph.svg?alt=media&token=782f70fc-3c14-4b70-986e-22521f6ec3da',
      description:
        'Bonjour je suis Joseph. Je me sens pas très bien depuis ce matin. J’ai des douleurs au thorax et je sens des douleurs dans ma poitrine.',
    },
    {
      id: 8,
      url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FRPS%2Fassets%2Frooms%2FWelcome%2Fusers%2Fpicto-aurelien.svg?alt=media&token=4356fb2a-a86d-47a3-ab41-d2c102895d39',
      firstname: 'Aurélien',
      constant: { 'T°': 37, FC: 102, FR: 22, Oxy: 95 },
      choice: stayAction,
      walking: false,
      position: -211.91,
      backgroundPosition: '211.91px',
      stepNumber: 1,
      finish: false,
      image:
        'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FRPS%2Fassets%2Frooms%2FWelcome%2Fusers%2FP10_Aurelien.svg?alt=media&token=88d0d87c-dc3f-4467-ab8c-bfa2587336d5',
      description:
        'Bonjour je suis Aurélien. Je reviens d’un voyage en Tunisie. Depuis je me sens fatigué et j’ai un peu mal à la tête. J’ai peur d’avoir attrapé une saleté là-bas.',
    },
    {
      id: 9,
      url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FRPS%2Fassets%2Frooms%2FWelcome%2Fusers%2Fpicto-gael.svg?alt=media&token=49e3be1a-cfd5-4878-9fb2-b644db9552ea',
      firstname: 'Yohan',
      constant: { 'T°': 31, FC: 98, FR: 24, Oxy: 93 },
      choice: immediatlyAction,
      walking: false,
      position: -203.29,
      backgroundPosition: '203.29px',
      stepNumber: 1,
      finish: false,
      image:
        'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FRPS%2Fassets%2Frooms%2FWelcome%2Fusers%2FP1_Gael_bon.svg?alt=media&token=177be538-f5c5-4a91-8d0f-67973e5c72cc',
      description:
        'Bonjour je suis Yohan. Je ressens une sensation de froid très forte j’en ai des frissons. J’ai dû attraper un petit virus mais j’ai quand même bien la tête qui tourne.',
    },
    {
      id: 10,
      url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FRPS%2Fassets%2Frooms%2FWelcome%2Fusers%2Fpicto-louise.svg?alt=media&token=caebf085-4948-44b1-8547-2287c3435e93',
      firstname: 'Louise',
      constant: { 'T°': 41, FC: 106, FR: 25, Oxy: 90 },
      choice: evaluationAction,
      walking: false,
      position: -178.57,
      backgroundPosition: '178.57px',
      stepNumber: 1,
      finish: false,
      image:
        'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FRPS%2Fassets%2Frooms%2FWelcome%2Fusers%2FP9_Louise.svg?alt=media&token=52dc0646-03a1-4601-a692-dd649e56b9cf',
      description:
        'Bonjour, je suis Louise. Je tousse et j’ai la gorge très douloureuse. J’ai peut-être un peu de fièvre en tout cas j’ai vraiment des maux de tête très douloureux.',
    },
  ];

  const [isInformationOpen, setIsInformationOpen] = useState(false);
  const [patients, setPatients] = useState(allPatients);
  const [informations, setInformations] = useState([
    {
      id: 1,
      isActive: true,
      title: 'Cardiologie',
      description:
        'Si le patient mentionne une douleur thoracique, alors faites une évaluation complète prioritaire.',
      contents: [
        {
          title: 'Prise en charge immédiate',
          elements: [{ titleCount: 'FC', count: ' < 120 bpm' }],
          color: '#ff876b',
        },
        {
          title: 'Evaluation complémentaire',
          elements: [{ titleCount: 'FC', count: ' > 120 bpm' }],
          color: '#f2af38',
        },
        {
          title: "Patientez pour l'instant",
          elements: [{ titleCount: 'FC', count: ' > 120 bpm' }],
          color: '#2b9d8f',
        },
      ],
    },
    {
      id: 2,
      isActive: false,
      title: 'Maladies infectiueuses',
      description:
        'Si le patient mentionne un voyage dans un pays à risque, alors faites une évaluation complète prioritaire dès 39°C.',
      contents: [
        {
          title: 'Prise en charge immédiate',
          elements: [{ titleCount: 'T°', count: ' > 32°C' }],
          color: '#ff876b',
        },
        {
          title: 'Evaluation complémentaire',
          elements: [{ titleCount: 'T°', count: ' < 40°C' }],
          color: '#f2af38',
        },
        {
          title: "Patientez pour l'instant",
          elements: [{ titleCount: 'T°', count: ' > 39°C' }],
          color: '#2b9d8f',
        },
      ],
    },
    {
      id: 3,
      isActive: false,
      title: 'Pneumologie',
      contents: [
        {
          title: 'Prise en charge immédiate',
          elements: [
            { titleCount: 'FR', count: ' > 40/min' },
            { titleCount: 'Oxy', count: ' > 90%' },
          ],
          color: '#ff876b',
        },
        {
          title: 'Evaluation complémentaire',
          elements: [
            { titleCount: 'FR', count: ' > 32/min' },
            { titleCount: 'Oxy', count: ' > 94%' },
          ],
          color: '#f2af38',
        },
        {
          title: "Patientez pour l'instant",
          elements: [
            { titleCount: 'FR', count: ' > 25/min' },
            { titleCount: 'Oxy', count: ' < 94%' },
          ],
          color: '#2b9d8f',
        },
      ],
    },
  ]);

  const [countHelp, setCountHelp] = useState([0, 1, 2]);
  const [countRest, setCountRest] = useState([0, 1]);
  const [clocktime] = useState(32400);
  const [showButton, setShowButton] = useState(true);

  // Audio element for successful answers
  const audioSuccess = new Audio(successSound);
  // Audio element for wrong answers
  const audioFail = new Audio(failSound);

  const handleHelp = () => {
    const newCountHelp = [...countHelp];

    if (newCountHelp.length !== 0) {
      dispatch({ type: 'INCREMENT_COUNTER' });

      const lastIndex = newCountHelp.length - 1;
      newCountHelp.splice(lastIndex, 1);
      setCountHelp(newCountHelp);
      dispatch({ type: 'NEXT_PATIENT' });
      const newPatients = [...patients];
      newPatients[indexPatient].finish = true;
      setPatients(newPatients);
    }
  };

  const handleRest = () => {
    const newCountRest = [...countRest];
    if (newCountRest.length !== 0) {
      dispatch({ type: 'INCREMENT_COUNTER' });

      const lastIndex = newCountRest.length - 1;
      newCountRest.splice(lastIndex, 1);
      setCountRest(newCountRest);
      const decreaseStressCount = 30;
      const decreaseStress = pourcentStress - decreaseStressCount;

      if (decreaseStress < 0) {
        dispatch({ type: 'RESET_STRESS' });
      } else {
        dispatch({ type: 'DECREASE_STRESS', payload: decreaseStress });
      }
    }
  };

  const handleConfetti = () => {
    const duration = 1.5 * 1000;
    const animationEnd = Date.now() + duration;
    const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

    function randomInRange(min, max) {
      return Math.random() * (max - min) + min;
    }

    // eslint-disable-next-line consistent-return
    const interval = setInterval(() => {
      const timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
        return clearInterval(interval);
      }

      const particleCount = 500 * (timeLeft / duration);
      // since particles fall down, start a bit higher than random
      confetti({
        ...defaults,
        particleCount,
        origin: { x: randomInRange(0.1, 0.4), y: Math.random() - 0.2 },
      });
    }, 250);
  };

  const [buttonsAction, setButtonsAction] = useState([
    {
      id: 1,
      title: 'Prise en charge immédiate',
      color: '#ff876b',
      click: {},
    },
    {
      id: 2,
      title: "Patientez pour l'instant",
      color: '#f2af38',
      click: {},
    },
    {
      id: 3,
      title: 'Evaluation complèmentaire',
      color: '#2b9d8f',
      click: {},
    },
  ]);

  /**
   * Dispatch actions depending on the bottom button clicked
   * @param {number} buttonId - id of the button clicked
   */
  const handleChoice = (buttonId) => {
    if (buttonId !== null) {
      if (buttonId === patients[indexPatient].choice) {
        dispatch({ type: 'NEXT_PATIENT' });
        handleConfetti();
        // Play method on the audio element
        audioSuccess.play();
        // Reset buttonsAction click object
        let newButtonsAction = [...buttonsAction];
        newButtonsAction = newButtonsAction.map((buttonAction) => {
          const newButtonAction = buttonAction;
          newButtonAction.click = {};
          return newButtonAction;
        });
        setButtonsAction(newButtonsAction);
        const newPatients = [...patients];
        newPatients[indexPatient].finish = true;
        setPatients(newPatients);
        dispatch({ type: 'UPDATE_GAME_SCORE', payload: 100 });
      } else {
        dispatch({
          type: 'ADD_STRESS',
          payload: { stress: 10, type: 'positive' },
        });
        setTimeout(() => {
          dispatch({
            type: 'INIT_NUMBER_STRESS',
          });
        }, 1000);
        audioFail.play();
      }
    }
  };

  const callback = (data) => {
    const { action, index, type, status } = data;
    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // const stepsOpenInformation = 6;

      // if (index === stepsOpenInformation) {
      //   setIsInformationOpen(!isInformationOpen);
      // }
      setStepGame({
        ...stepGame,
        stepIndex: index + (action === ACTIONS.NEXT ? 1 : -1),
      });
    } else if (
      [STATUS.FINISHED, STATUS.SKIPPED, STATUS.FINISHED].includes(status)
    ) {
      // Need to set our running state to false, so we can restart if we click start again.
      setStepGame({
        ...stepGame,
        run: false,
      });

      axios
        .put(`${BASE_URL}/api/sessions/${session.id}/modification`, {
          isTutoFinish: 1,
        })
        .then(() => {
          dispatch({ type: 'RESET_TUTO', payload: 1 });
        });
    }
    if (action === 'reset' || action === 'close') {
      setStepGame({
        ...stepGame,
        run: false,
      });
      axios
        .put(`${BASE_URL}/api/sessions/${session.id}/modification`, {
          isTutoFinish: 1,
        })
        .then(() => {
          dispatch({ type: 'RESET_TUTO', payload: 1 });
        });
    }
  };

  // COUNTER
  useEffect(() => {
    if (idSessionHasRoom) {
      axios.put(
        `${BASE_URL}/api/sessionsHasRoom/${idSessionHasRoom}/modifications`,
        {
          count,
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  useStartMessageOrQuestionary(
    currentStep,
    idSessionHasRoom,
    startMessage,
    startQuestionnary
  );

  const clickOnGame = () => {
    dispatch({ type: 'INCREMENT_COUNTER' });
  };

  useEffect(() => {
    dispatch({
      type: 'CLICKED_MOUSE_DOWN',
    });
    setTimeout(() => {
      dispatch({
        type: 'CLICKED_MOUSE_UP',
      });
    }, 1000);
  }, [GameUsers.count, dispatch]);

  const updateMousePosition = (ev) => {
    const offset = document
      .querySelector('#ChangingRoom-BKG')
      .getBoundingClientRect();
    dispatch({
      type: 'PLACE_CURSOR',
      payload: {
        x: ev.pageX - offset.left,
        y: ev.pageY - offset.top,
      },
    });
  };

  useEffect(() => {
    document
      .getElementById('ChangingRoom-BKG')
      .addEventListener('mouseup', updateMousePosition);
    return () => window.removeEventListener('mouseup', updateMousePosition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkedObject = (collectObject, index, isMessage) => {
    if (!isMessage) {
      dispatch({
        type: 'START_MESSAGE_PREVENTION',
      });
    }
  };

  useEffect(() => {
    const lastIndexPatient = 11;
    const objectPatient = 243;
    if (indexPatient === lastIndexPatient) {
      const collectObject = allObjects.find(
        (objectElement) => objectElement.id === objectPatient
      );
      const collectObjectIndex = allObjects.findIndex(
        (objectElement) => objectElement.id === objectPatient
      );

      const idMessage = 124;

      axios
        .put(
          `${process.env.REACT_APP_BASE_URL}/api/sessionsHasRoom/${idSessionHasRoom}/modifications`,
          {
            current_step: idMessage,
            start_message: startedMessage,
          }
        )
        .then(() =>
          dispatch({
            type: 'CURRENT_STEP',
            payload: idMessage,
          })
        );
      checkedObject(collectObject, collectObjectIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexPatient]);

  const handleIntro = () => {
    if (!isStarted && isTutoFinish) {
      return <Intro translationFileName="rps" modalAfterIntro />;
    }
    return null;
  };

  const urlRoom = listRooms.WelcomeRPS.url;

  return (
    <div className="WelcomeRPS my-first-step" id="ChangingRoom-BKG">
      <Audio sound={rpsTheme} loop id="RPS-Theme" muted={!GameUsers.music} />
      <Audio sound={click} condition={!MouseClicked && GameUsers.soundtrack} />
      {!isTutoFinish && (
        <Joyride
          callback={callback}
          steps={stepGame.steps}
          run={stepGame.run}
          stepIndex={stepGame.stepIndex}
          showSkipButton
          continuous
          disableCloseOnEsc
          disableOverlayClose
          hideBackButton
          hideCloseButton
          locale={{
            close: 'Fermer',
            last: 'Commencer',
            next: 'Suivant',
            skip: 'Passer',
          }}
          styles={{
            options: {
              overlayColor: 'rgba(0,0,0, 0.5)',
              arrowColor: '#fff',
            },
            buttonNext: {
              backgroundColor: '#4ed199',
              border: '1px solid #48c48e',
              padding: '15px 25px',
              fontWeight: '700',
            },
            buttonBack: {
              marginRight: 10,
              color: '#000',
              border: '1px solid #9fa2b4',
              backgroundColor: '#fff',
            },
            buttonLast: {
              marginRight: 20,
            },
            buttonSkip: {
              color: '#9fa2b4',
              fontWeight: '700',
            },
          }}
        />
      )}
      <div
        className={MouseClicked ? 'cursor-clicked' : 'Hide'}
        style={{ top: GameUsers.cursor.y, left: GameUsers.cursor.x }}
      >
        <p>+1</p>
      </div>
      <header>
        <Steps />
        <Hud />
      </header>
      <div
        className="WelcomeRPS-img-div"
        onClick={() => clickOnGame()}
        onKeyDown={() => {}}
        role="presentation"
      >
        <img
          className="WelcomeRPS_background"
          src={urlRoom}
          alt="Fond TMS"
          draggable="false"
        />
      </div>
      {modalIsOpen && <Modal />}
      {modalZoomIsOpen && <ModalZoom />}
      {zoomIsOpen && <Zoom />}
      {modalEnigmaIsOpen && <ModalEnigma />}
      {isScoreBoard && <Scoreboard />}
      {handleIntro()}
      <ObjectInInventory />
      <ListObjects />
      <div>
        <InterfaceGame
          setButtonsAction={setButtonsAction}
          isInformationOpen={isInformationOpen}
          setIsInformationOpen={setIsInformationOpen}
          patients={patients}
          informations={informations}
          setInformations={setInformations}
          buttonsAction={buttonsAction}
          countHelp={countHelp}
          countRest={countRest}
          handleHelp={handleHelp}
          handleRest={handleRest}
          handleChoice={handleChoice}
          showButton={showButton}
          setShowButton={setShowButton}
        />
        <Patients
          stepGame={stepGame}
          patients={patients}
          setPatients={setPatients}
          clocktime={clocktime}
          setShowButton={setShowButton}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  modalIsOpen: state.Modal.modal.isOpen,
  currentRoom: state.Room.currentRoomId,
  idSessionHasRoom: state.GameUsers.idSessionHasRoom,
  GameUsers: state.GameUsers,
  startMessage: state.Steps.startMessage,
  currentStep: state.Steps.currentStep,
  startQuestionnary: state.Steps.startQuestionnary,
  zoomIsOpen: state.Modal.zoom.isOpen,
  modalZoomIsOpen: state.Modal.modalZoom.isOpen,
  allObjects: state.Objects.AllObjects,
  isAdvice: state.Steps.isAdvice,
  count: state.GameUsers.count,
  modalEnigmaIsOpen: state.Modal.modalEnigma.isOpen,
  listRooms: state.Room.listRooms,
  MouseClicked: state.GameUsers.clicked,
  pourcentStress: state.RPS.dataGame.pourcentStress,
  indexPatient: state.RPS.dataGame.indexPatient,
  isTutoFinish: state.Steps.isTutoFinish,
  isScoreBoard: state.Steps.isScoreBoard,
  isStarted: state.GameUsers.gameStarted,
});

WelcomeRPS.propTypes = {
  MouseClicked: PropTypes.bool.isRequired,
  GameUsers: PropTypes.shape({
    soundtrack: PropTypes.bool,
    music: PropTypes.bool,
    count: PropTypes.number,
    cursor: PropTypes.arrayOf(
      PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number,
      })
    ),
    gameTour: PropTypes.bool,
  }).isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  currentRoom: PropTypes.number.isRequired,
  idSessionHasRoom: PropTypes.number.isRequired,
  startMessage: PropTypes.bool.isRequired,
  currentStep: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  startQuestionnary: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  zoomIsOpen: PropTypes.bool.isRequired,
  modalZoomIsOpen: PropTypes.bool.isRequired,
  count: PropTypes.number.isRequired,
  modalEnigmaIsOpen: PropTypes.bool.isRequired,
  listRooms: PropTypes.shape({
    WelcomeRPS: PropTypes.shape({
      url: PropTypes.string,
    }),
  }).isRequired,
  pourcentStress: PropTypes.number.isRequired,
  indexPatient: PropTypes.number.isRequired,
  allObjects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
  isTutoFinish: PropTypes.number.isRequired,
  isScoreBoard: PropTypes.bool.isRequired,
  isStarted: PropTypes.number.isRequired,
};
export default connect(mapStateToProps)(WelcomeRPS);
