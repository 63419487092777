/* eslint-disable import/no-mutable-exports */
import firebase from 'firebase/app';
import 'firebase/database';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  databaseURL: process.env.REACT_APP_FIREBASE_URL,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT,
};

console.log(firebaseConfig);

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
let firepadRef;
export const db = firebase;
const urlparams = new URLSearchParams(window.location.search);
const roomName = urlparams.get('RoomName');
const userId = urlparams.get('user') || urlparams.get('admin');
const userType = urlparams.get('user') ? 'user' : 'admin';
const companyId = urlparams.get('company');
const gameId = urlparams.get('game');
const token = urlparams.get('token');
if (roomName && userId && gameId && companyId && token && userType) {
  firepadRef = firebase.database().ref(roomName);
  window.history.replaceState(
    null,
    'Meet',
    `?id=${firepadRef.key}&RoomName=${roomName}&${userType}=${userId}&company=${companyId}&game=${gameId}&token=${token}`
  );
} else {
  window.history.replaceState(null, 'Meet', '/404');
}
export default firepadRef;
