/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// STYLE
import styles from 'components/GameBoard/TDE/Enigma/RemoteControl.module.scss';
// IMAGES
import court from '../../../../img/tde/court-white.svg';
import long from '../../../../img/tde/long-white.svg';
import { remoteMorseImages as images } from './constants';

function RemoteControl({ objectContent, description }) {
  const dispatch = useDispatch();
  const [chaining, setChaining] = useState([]);
  const [morseOn, setMorseOn] = useState(false);
  useEffect(() => {
    dispatch({
      type: 'CLOSE_ZOOM',
    });
  }, [dispatch]);
  const handleText = () => {
    let textContent;
    if (objectContent.isInventory) {
      const isInventoryCode = objectContent.images.filter(
        (contentImage) => contentImage.type === 'inventory'
      );
      textContent = isInventoryCode[0].message;
    }

    textContent = description.message;

    return textContent;
  };

  const handleChaining = (time) => {
    const newChaining = [...chaining];
    newChaining.push(time);
    setChaining(newChaining);
  };

  const handleDelete = () => {
    const newChaining = [...chaining];
    newChaining.pop();
    setChaining(newChaining);
  };

  const sendChaining = () => {
    setMorseOn(!morseOn);
    dispatch({
      type: 'CHAINING_GARLAND',
      payload: chaining,
    });
  };

  return (
    <div className={styles.morseModal}>
      <div className={styles['content-right-text-morse']}>
        <div>
          {chaining.map((item, index) => {
            return (
              <img
                key={`${item}-${index}`}
                src={item === 500 ? court : long}
                alt="Enchainement"
              />
            );
          })}
        </div>
        <p>{handleText()}</p>
      </div>
      <div className={styles['content-left-remote']}>
        <div className={styles['background-remote']}>
          <img src={images.background} alt="telecommande" />
          <div
            className={styles['remoteControl-image-court']}
            onClick={() => handleChaining(500)}
            role="presentation"
          >
            <img src={images.short} alt="Bouton court" />
          </div>
          <div
            className={styles['remoteControl-image-long']}
            onClick={() => handleChaining(2000)}
            role="presentation"
          >
            <img src={images.long} alt="Bouton long" />
          </div>
          <div
            className={styles['remoteControl-image-validate']}
            onClick={() => sendChaining()}
            role="presentation"
          >
            <img src={images.validate} alt="Bouton valider" />
          </div>
          <div
            className={styles['remoteControl-image-erase']}
            onClick={() => handleDelete()}
            role="presentation"
          >
            <img src={images.erase} alt="Bouton effacer" />
          </div>
        </div>
      </div>
    </div>
  );
}

RemoteControl.propTypes = {
  objectContent: PropTypes.shape({
    title: PropTypes.string,
    isInventory: PropTypes.number,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
      })
    ),
  }).isRequired,
  description: PropTypes.shape({
    message: PropTypes.string,
  }).isRequired,
};

export default RemoteControl;
