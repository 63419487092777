import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// Selector functions
import { selectAnswerState, selectAnswersState } from 'components/Reducers/emotion';
import { selectSelectedEmoticon } from 'components/Reducers/GameData/fetchObjects';
// Constants & utils
import { enigmas } from 'components/GameBoard/Emotion/Solo/constants';
import organizeEmotionsData from 'components/GameBoard/Emotion/Solo/utilityFunctions';
// CSS
import styled from 'styled-components';
import styles2 from 'components/GameBoard/Emotion/Solo/Enigma/EmotionsPanel.module.scss';
import styles from 'components/GameBoard/Emotion/Solo/Enigma/EmotionsSelection.module.scss';

const { intensityImageUrl } = enigmas.InterpretationMachineCalibration;

const applyColorStyle = (
  selectedEmotion,
  emotionIndex,
  answerState,
  theme,
  isBorder
) => {
  if (selectedEmotion !== emotionIndex)
    return isBorder ? theme.peacockBlue : 'transparent';
  switch (answerState) {
    case 'unanswered':
      return theme.tealBlue;
    case 'right':
      return theme.green;
    case 'wrong':
      return theme.red;
    default:
      return isBorder ? theme.peacockBlue : 'transparent';
  }
};

// CSS in JS
const ButtonEmotionStyle = styled.button`
  height: 32px;
  width: 188px;
  border-radius: 10px;
  border: 1px solid;
  border-color: ${({ selectedEmotion, emotionIndex, answerState, theme }) =>
    applyColorStyle(selectedEmotion, emotionIndex, answerState, theme, true)};
  color: ${({ selectedEmotion, emotionIndex, answerState }) =>
    selectedEmotion === emotionIndex && answerState !== 'unanswered' && '#fdf6f5'};
  background-color: ${({ selectedEmotion, emotionIndex, answerState, theme }) =>
    applyColorStyle(selectedEmotion, emotionIndex, answerState, theme)};
  margin: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: 600;
  cursor: ${({ answersState }) => answersState !== 'unanswered' && 'not-allowed'};
  &:hover {
    background-color: ${({ answerState }) =>
      answerState === 'unanswered' && 'rgba(16, 118, 121, 0.568627451)'};
  }
`;

const EmotionsSelection = ({
  intensityIndex,
  selectedEmotions,
  setSelectedEmotions,
  intensityName,
}) => {
  const { t } = useTranslation('emotion');

  const plutchikEmotions = t('plutchikEmotions', {
    returnObjects: true,
  });

  // Selector functions
  const selectedEmoticon = useSelector(selectSelectedEmoticon);

  const answerState = useSelector((state) =>
    selectAnswerState(state, selectedEmoticon?.id, intensityName)
  );

  const answersState = useSelector((state) =>
    selectAnswersState(state, selectedEmoticon?.id)
  );

  const emotionsPerIntensity = organizeEmotionsData(plutchikEmotions);

  const handleEmotionClick = (e) => {
    if (answersState === 'unanswered') {
      // Deselect an emotion
      if (selectedEmotions[intensityName] === Number(e.target.id)) {
        setSelectedEmotions({ ...selectedEmotions, [intensityName]: undefined });
      } else {
        // Select an emotion
        setSelectedEmotions({
          ...selectedEmotions,
          [intensityName]: Number(e.target.id),
        });
      }
    }
  };

  return (
    <>
      <div className={`${styles['intensity-title']} ${styles[intensityName]}`}>
        <img src={intensityImageUrl[intensityName]} alt="intensity" />
        <p className={styles2[`intensity-${intensityIndex}`]}>
          {`${t('intensity')} ${t(`intensityName.${intensityName}`)}`.toUpperCase()}
        </p>
      </div>
      {/* Map the emotions names
          emotionsPerIntensity contains 1 array per emotion intensity
          each array contains the emotions names already sorted */}
      <div className={styles2[`intensity-${intensityIndex}-container`]}>
        {emotionsPerIntensity[intensityIndex].map((emotion, emotionIndex) => (
          <ButtonEmotionStyle
            selectedEmotion={selectedEmotions[intensityName]}
            emotionIndex={emotionIndex}
            answersState={answersState}
            answerState={answerState}
            key={emotion}
            onClick={(e) => handleEmotionClick(e)}
            type="button"
            id={emotionIndex}
          >
            {emotion.toUpperCase()}
          </ButtonEmotionStyle>
        ))}
      </div>
    </>
  );
};

EmotionsSelection.propTypes = {
  selectedEmotions: PropTypes.objectOf(PropTypes.number).isRequired,
  intensityIndex: PropTypes.number.isRequired,
  intensityName: PropTypes.string.isRequired,
  setSelectedEmotions: PropTypes.func.isRequired,
};

export default EmotionsSelection;
