import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';

// components
import DialogueRow from 'components/GameBoard/OffreSocle/Enigmas/DialogueRow';
import Button from 'components/GameBoard/ReusableComponents/Actions/Button';
import OffreSocleModal2 from 'components/GameBoard/OffreSocle/Enigmas/OffreSocleModal2';

// Constants & style
import {
  referringDoctorOffice,
  questionErgonomist,
  maxAnswers,
} from 'components/GameBoard/OffreSocle/constants';
import styles from 'components/GameBoard/OffreSocle/Enigmas/QuestionnaryErgonomist.module.scss';

// selectors & API
import { selectOffreSocle } from 'components/Reducers/offreSocle';
import { directorId } from 'components/GameBoard/ReusableComponents/Objects/constants';
import { selectObject } from 'components/Reducers/GameData/fetchObjects';

const QuestionnaryErgonomist = () => {
  const dispatch = useDispatch();

  const { employeeFace } = referringDoctorOffice;
  const { t } = useTranslation('offreSocle', 'common');
  const [answers, setAnswer] = useState([]);

  const [currentAnswer, setCurrentAnswer] = useState(null);
  const { modalMessage } = useSelector(selectOffreSocle);

  const firstAnswerId = 0;
  const secondAnswerId = 1;
  const firstAnswer = questionErgonomist.responses.find(
    (response) => response.id === answers[firstAnswerId]
  );
  const secondAnswer = questionErgonomist.responses.find(
    (response) => response.id === answers[secondAnswerId]
  );

  const doctorObject = useSelector((state) => selectObject(state, directorId));
  useEffect(() => {
    let currentAnswerDisplayed;
    if (answers.length === 2) {
      currentAnswerDisplayed = secondAnswer;
    }
    if (answers.length === 1) {
      currentAnswerDisplayed = firstAnswer;
    }

    if (currentAnswer !== null) {
      if (answers.length === 2 && currentAnswer === firstAnswer?.id) {
        setTimeout(() => {
          dispatch({
            type: 'OPEN_MODAL_MESSAGE_RESPONSE',
            payload: {
              text: 'Ce bouton a déjà eu son moment de gloire. Essayons une autre réponse',
              subText: 'wrongResponse',
            },
          });
          setAnswer([answers[0]]);
          setCurrentAnswer(null);
        }, 500);
      } else {
        setTimeout(() => {
          const displayMessageSound = new Audio(currentAnswerDisplayed.sound);
          displayMessageSound.volume = 0.1;
          displayMessageSound.play();
          dispatch({
            type: 'OPEN_MODAL_MESSAGE_RESPONSE',
            payload: {
              text: currentAnswerDisplayed.messageText.text,
              subText: currentAnswerDisplayed.goodResponse
                ? 'congratulation'
                : 'wrongResponse',
            },
          });
          setCurrentAnswer(null);
        }, 500);
      }
    }
  }, [answers, currentAnswer, dispatch, firstAnswer, secondAnswer]);

  const handleResponse = (id) => {
    const newAnswers = [...answers];
    if (answers.length <= 1) {
      newAnswers.push(id);
      setAnswer(newAnswers);
      setCurrentAnswer(id);
    }
  };

  const handleTitle = (title) => {
    if (title === 'congratulation') {
      return t('congratulation');
    }
    if (title === 'wrongResponse') {
      return t('wrongResponse');
    }
    return '';
  };

  if (!doctorObject.isClicked) {
    return (
      <div>
        <div className={styles['bubble-and-button-container']}>
          <motion.div className={styles['bubble-container']}>
            <DialogueRow
              characterImage={employeeFace}
              direction="row"
              dialogueText={t('employeErgonomist', { returnObjects: true })}
              smallView
            />
          </motion.div>
        </div>
        <Button
          buttonType="return"
          title={t('buttonFunction.return', { ns: 'common' })}
          onClick={() =>
            dispatch({
              type: 'CLOSE_MODAL_ENIGMA',
            })
          }
          additionalStyle={{
            position: 'absolute',
            top: '25%',
            left: '10px',
          }}
        />
      </div>
    );
  }

  return (
    <div>
      {modalMessage.isOpen && (
        <OffreSocleModal2
          text={handleTitle(modalMessage.subText)}
          subText={modalMessage.text}
          buttonTitle={t('buttonFunction.continue', { ns: 'common' })}
          onClick={() => {
            setTimeout(() => {
              if (answers.length === 1 && !firstAnswer?.goodResponse) {
                setAnswer([]);
              }
              if (answers.length === 2 && !secondAnswer?.goodResponse) {
                setAnswer([answers[0]]);
              }
              dispatch({
                type: 'CLOSE_MODAL_MESSAGE_RESPONSE',
              });
              if (
                firstAnswer?.id === answers[firstAnswerId] &&
                firstAnswer?.goodResponse &&
                secondAnswer?.id === answers[secondAnswerId] &&
                secondAnswer?.goodResponse
              ) {
                dispatch({
                  type: 'CLOSE_MODAL_ENIGMA',
                });
                dispatch({
                  type: 'START_MESSAGE_PREVENTION',
                });
                setAnswer([]);
              }
            }, 500);
          }}
        />
      )}
      <div className={styles['bubble-and-button-container']}>
        <motion.div className={styles['bubble-container']}>
          <DialogueRow
            characterImage={employeeFace}
            direction="row"
            dialogueText={t('employeeText', { returnObjects: true })}
            smallView
          />
        </motion.div>
      </div>
      <div className={styles['content-questions']}>
        <div className={styles.header}>
          <p className={styles.title}>{questionErgonomist.title}</p>
          <p className={styles['number-answers']}>
            {answers.length} / {maxAnswers}
          </p>
        </div>
        <div className={styles.responses}>
          {questionErgonomist.responses.map((responseContent) => {
            return (
              <Button
                title={responseContent.text}
                buttonType="offreSocle"
                onClick={async () => {
                  handleResponse(responseContent.id);
                }}
              />
            );
          })}
        </div>
      </div>
      <Button
        buttonType="return"
        title={t('buttonFunction.return', { ns: 'common' })}
        onClick={() =>
          dispatch({
            type: 'CLOSE_MODAL_ENIGMA',
          })
        }
        additionalStyle={{
          position: 'absolute',
          top: '25%',
          left: '10px',
        }}
      />
    </div>
  );
};

export default QuestionnaryErgonomist;
