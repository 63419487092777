import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL;

/**
 * Get the images of an object
 * @param {number} objectId
 * @returns {Promise<object[]>} Response of the API call if successful
 */
const getObjectImages = async (objectId) => {
  const { data } = await axios.get(`${baseURL}/api/objects/${objectId}/images`);
  return data;
};

/**
 * Put an object in the inventory
 * @param {number} objectId
 * @param {number} idSessionHasRoom
 * @returns {Promise<object[]>} Response of the API call if successful
 */
const putObjectInInventory = async (idSessionHasRoom, objectId) => {
  const { data } = await axios.put(
    `${baseURL}/api/sessionsHasRoomHasObject/${idSessionHasRoom}`,
    {
      object_id: objectId,
      isInventory: true,
    }
  );
  return data;
};

/**
 * Get the objects link to an object
 * @param {number} objectId
 * @returns
 */
const getObjectsOfObject = async (objectId) => {
  const { data } = await axios.get(`${baseURL}/api/objects/${objectId}/objects`);
  return data;
};

export { getObjectImages, putObjectInInventory, getObjectsOfObject };
