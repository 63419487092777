import {
  gameIdSoloTDE,
  gameIdSoloObesity,
  gameIdSoloEmotion,
  gameIdSoloOffreSocle,
} from 'components/constants';

// Expected background width per game id
// TODO refactor when all the backgrounds for the start page have the same size
const startPageBackground = {
  [gameIdSoloTDE]: { width: '161vh', isBackgroundFixed: true },
  [gameIdSoloObesity]: { width: '267vh', isBackgroundFixed: false },
  [gameIdSoloEmotion]: { width: '267vh', isBackgroundFixed: false },
  [gameIdSoloOffreSocle]: { width: '181vh', isBackgroundFixed: true },
};

export default startPageBackground;
