// Emotion multi
const phoneEmotionMultiEnigma = 297;
const drawerEmotionMultiEnigma = 300;
const computerRoomEmotionEnigma = 303;
const computerWithNoteRoomEmotionMultiEnigma = 307;
const computerLowBatRoomEmotionMultiEnigma = 309;
const drawerOfficeMultiEmotionEnigma = 300;
const deskOfficeMultiEmotionEnigma = 310;
const chargerOfficeMultiEmotion = 311;
const paper2OfficeMultiEmotion = 312;
const sheetVisioOfficeMultiEmotion = 313;
const paper1OfficeMultiEmotion = 302;
const paperFusionOfficeMultiEmotion = 314;
const rebusOfficeMultiEmotion = 296;
const robotObesity = 205;
const emailOfficeMultiEmotion = 303;
const plugInOfficeMultiEmotion = 315;
const noteOfficeMultiEmotion = 316;
const todoListLivingRoomMultiEmotion = 326;
const televisionLivingRoomMultiEmotion = 333;
const remoteTvLivingRoomMultiEmotion = 331;
const crosswordLivingRoomMultiEmotion = 330;
const tvStandLivingRoomMultiEmotion = 332;
const tableBedroomMultiEmotion = 337;
const frameBedroomMultiEmotion = 339;
const pictureBedroomMultiEmotion = 343;
const noticeBedroomMultiEmotion = 345;
const keyBedroomMultiEmotion = 341;
const boxBedroomMultiEmotion = 340;
const wardrobeBedroomMultiEmotion = 338;
const posterBedroomMultiEmotion = 336;
const puzzleBedroomMultiEmotion = 342;
const remoteSpotBedroomEmotionMultiEnigma = 334;
const fortuneCookieLivingRoomEmotionMulti = 325;
// Emotion solo
const faceAngerSoloEmotion = 314;
const faceDisgustSoloEmotion = 324;
// Offre socle
const receptionistPhone = 306;
const doctorComputer = 346;
const greenNotebook = 355;
const doctorPhone = 356;
const velledaOffreSocle = 359;
const offreSocleLocker1Id = 360;
const offreSocleLocker2Id = 361;
const offreSocleLocker3Id = 362;
const duerpId = 369;
const visioConfObjectId = 370;
const directorId = 373;
const employeId = 374;

export {
  phoneEmotionMultiEnigma,
  drawerEmotionMultiEnigma,
  computerRoomEmotionEnigma,
  computerWithNoteRoomEmotionMultiEnigma,
  computerLowBatRoomEmotionMultiEnigma,
  drawerOfficeMultiEmotionEnigma,
  deskOfficeMultiEmotionEnigma,
  chargerOfficeMultiEmotion,
  paper2OfficeMultiEmotion,
  sheetVisioOfficeMultiEmotion,
  paper1OfficeMultiEmotion,
  paperFusionOfficeMultiEmotion,
  rebusOfficeMultiEmotion,
  robotObesity,
  emailOfficeMultiEmotion,
  plugInOfficeMultiEmotion,
  noteOfficeMultiEmotion,
  todoListLivingRoomMultiEmotion,
  televisionLivingRoomMultiEmotion,
  remoteTvLivingRoomMultiEmotion,
  crosswordLivingRoomMultiEmotion,
  tvStandLivingRoomMultiEmotion,
  tableBedroomMultiEmotion,
  frameBedroomMultiEmotion,
  pictureBedroomMultiEmotion,
  noticeBedroomMultiEmotion,
  keyBedroomMultiEmotion,
  boxBedroomMultiEmotion,
  wardrobeBedroomMultiEmotion,
  posterBedroomMultiEmotion,
  puzzleBedroomMultiEmotion,
  remoteSpotBedroomEmotionMultiEnigma,
  fortuneCookieLivingRoomEmotionMulti,
  faceAngerSoloEmotion,
  faceDisgustSoloEmotion,
  receptionistPhone,
  doctorComputer,
  greenNotebook,
  doctorPhone,
  offreSocleLocker1Id,
  offreSocleLocker2Id,
  offreSocleLocker3Id,
  duerpId,
  visioConfObjectId,
  directorId,
  employeId,
  velledaOffreSocle,
};
