import { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Components
import Modal from 'components/GameBoard/ReusableComponents/Modal/Modal';
import BackgroundStyle from 'components/GameBoard/ReusableComponents/BackgroundStyle';
import Steps from 'components/GameBoard/ReusableComponents/Steps';
import Hud from 'components/GameBoard/ReusableComponents/HUD/Hud';
import ListObjects from 'components/GameBoard/ReusableComponents/Objects/ListObjects';
import Chevrons from 'components/GameBoard/ReusableComponents/Actions/Chevrons';
import WarningLights from 'components/GameBoard/Emotion/Solo/WarningLights';
import ModalEnigmaEmotion from 'components/GameBoard/ReusableComponents/Modal/ModalEnigmaEmotion';
import RoomContainer from 'components/GameBoard/ReusableComponents/RoomContainer';
import Audio from 'components/GameBoard/ReusableComponents/Actions/Audio';

// Constant
import {
  backgroundStyle,
  textPaddingLeft,
  volume,
  warningLightUrl,
} from 'components/GameBoard/Emotion/Solo/constants';

// Selector functions
import { selectModal } from 'components/Reducers/Modal/Modal';
import { selectSession } from 'components/Reducers/session';
import { selectRoom } from 'components/Reducers/Room';
import {
  selectEmotion,
  selectEmotionGenerationEnigma,
  selectStimulusAnswer,
} from 'components/Reducers/emotion';
import { selectSteps } from 'components/Reducers/Steps/Steps';
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';

// Utils
import {
  clickCount,
  centerPoint,
  getRightCorner,
  shuffleArray,
  useStartMessageOrQuestionary,
} from 'utils/utilityFunctions';
import useInitGame from 'utils/useInitGame';
import useWindowResize from 'utils/useWindowResize';
// Assets
import mainTheme from 'sound/Emotion_solo/chapter_2_theme.mp3';
import click from 'sound/click-ongame.mp3';

let isMounted = false;

function EmotionGenerationRoom() {
  // `t` is a function coming from the i18next library used to translate text
  /* Load the `common` namespace = the one containing translations that are common between games
  and the `emotion` one (see public-> locales -> fr-FR) */
  const { t } = useTranslation('emotion');

  // Access the i18next object about the messages displayed at the top
  const stimuli = t('enigma2.stimuli', {
    returnObjects: true,
  });

  const dispatch = useDispatch();

  // This ref is connected to the image background
  const backgroundImageRef = useRef();

  // Select data from the store
  const session = useSelector(selectSession);
  const { stimulusIndex, stimulusPlayState } = useSelector(
    selectEmotionGenerationEnigma
  );
  const modalIsOpen = useSelector(selectModal).modal.isOpen;
  const modalEnigmaEmotionIsOpen =
    useSelector(selectModal).modalEnigmaEmotion.isOpen;
  const { currentRoomId, listRooms } = useSelector(selectRoom);
  const { backgroundDimensions } = useSelector(selectEmotion);
  const { startMessage, startQuestionnary, currentStep } = useSelector(selectSteps);
  const { idSessionHasRoom, clicked, cursor, soundtrack, music } =
    useSelector(infoGameUser);

  const answerState = useSelector((state) =>
    selectStimulusAnswer(state, stimulusIndex, 'EmotionGeneration')
  );

  const currentRoom = Object.values(listRooms).find(
    (roomObject) => roomObject.id === currentRoomId
  );

  const backgroundUrl = currentRoom.url;

  const [backgroundPosition, setBackgroundPosition] = useState('center');
  const [lightState, setLightState] = useState('green');

  // Make the light blink
  useEffect(() => {
    let intervalId;
    if (!modalEnigmaEmotionIsOpen && !modalIsOpen) {
      intervalId = setInterval(
        () => setLightState((prev) => (prev === 'green' ? 'inactive' : 'green')),
        1000
      );
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [modalEnigmaEmotionIsOpen, modalIsOpen]);

  const handleResize = useCallback(() => {
    dispatch({
      type: 'SET_BACKGROUND_DIMENSIONS',
      payload: {
        width: backgroundImageRef.current.offsetWidth,
        height: backgroundImageRef.current.offsetHeight,
      },
    });
  }, [dispatch]);

  useWindowResize(backgroundImageRef, handleResize);

  // Define variables used for the translation of the background
  const screenWidth = window.innerWidth;
  const imgWidth = backgroundDimensions.width;
  /* Number of pixels needed to center a point of interest in the background */
  const pointOfInterestCentered =
    centerPoint(backgroundStyle.pointOfInterest, imgWidth, screenWidth) >= 0
      ? `-${centerPoint(backgroundStyle.pointOfInterest, imgWidth, screenWidth)}px`
      : '0px';

  // Initiate the session, the room, the objects in the room and the prevention messages
  useInitGame(currentRoomId, session.id);

  // Save the prevention message/questionary step in database and store and launch it
  useStartMessageOrQuestionary(
    currentStep,
    idSessionHasRoom,
    startMessage,
    startQuestionnary
  );

  /**
   * Define a url for a warning light if it changes during the enigma
   * @param {Object} warningLight - the targeted warning light
   * @param {string} warningLight.id - the id of the warning light
   * @returns
   */
  const handleWarningLight = (warningLight) => {
    if (warningLight.id === 'center') {
      if (answerState === 'wrong') return warningLightUrl.red;
      if (stimulusPlayState === 'paused') return warningLightUrl.orange;
    }

    if (warningLight.id === 'left' && !modalEnigmaEmotionIsOpen && !modalIsOpen) {
      if (lightState === 'inactive') return warningLightUrl.inactive;
      return warningLightUrl.green;
    }

    return undefined;
  };

  // Launch a modal to explain how to start the enigma
  useEffect(() => {
    /* TODO this modal is launched when we reload, until the modal for the prevention message is launched
    In the future if we dispatch info related to the prevention message or the questionary before, we could
    prevent launching this modal */
    dispatch({
      type: 'OPEN_MODAL',
      payload: t('enigma2', { returnObjects: true }),
    });
  }, [dispatch, t]);

  /* Ensure the player is on the right part of the background when launching the enigma */
  useEffect(() => {
    if (modalEnigmaEmotionIsOpen && backgroundPosition !== 'right-from-center')
      // The position of the conveyor belt only works when the background is at the right part
      setBackgroundPosition('right-from-center');
  }, [backgroundPosition, modalEnigmaEmotionIsOpen]);

  useEffect(() => {
    if (!isMounted) {
      dispatch({
        type: 'SHUFFLE_STIMULI',
        payload: {
          room: 'EmotionGeneration',
          shuffledStimuli: shuffleArray(stimuli),
        },
      });
      isMounted = true;
    }
  }, [dispatch, stimuli]);

  return (
    <RoomContainer id="container">
      <Audio sound={mainTheme} loop muted={!music} volume={volume} />
      <Audio
        sound={click}
        condition={!clicked && soundtrack && !modalEnigmaEmotionIsOpen}
      />
      <div
        aria-hidden="true"
        className={clicked ? 'cursor-clicked' : 'Hide'}
        style={{ top: cursor.y, left: cursor.x }}
      >
        <p>+1</p>
      </div>
      {/* Display the header with the HUD and the Steps */}
      <header>
        <Steps />
        <Hud />
      </header>
      {/* Display the background */}
      <BackgroundStyle
        position={backgroundPosition}
        start={pointOfInterestCentered}
        right={imgWidth && getRightCorner(imgWidth, screenWidth)}
        left={backgroundStyle.imgLeft}
        duration={backgroundStyle.duration}
        timing={backgroundStyle.timing}
        fillMode={backgroundStyle.fillMode}
        backgroundWidth={backgroundStyle.width}
        onClick={(event) => {
          clickCount(dispatch, event);
        }}
        type="button"
        onAnimationStart={() =>
          dispatch({ type: 'SET_IS_BACKGROUND_MOVING', payload: true })
        }
        onAnimationEnd={() =>
          dispatch({ type: 'SET_IS_BACKGROUND_MOVING', payload: false })
        }
      >
        <img
          src={backgroundUrl}
          width="100%"
          height="100%"
          alt={t('lab')}
          id="img-background"
          ref={backgroundImageRef}
        />
        <ListObjects />
        <WarningLights
          handleWarningLight={(warningLight) => handleWarningLight(warningLight)}
        />
      </BackgroundStyle>
      <Chevrons
        position={backgroundPosition}
        setPosition={setBackgroundPosition}
        modalEnigmaIsOpen={modalEnigmaEmotionIsOpen}
      />
      {modalIsOpen && <Modal textPaddingLeft={textPaddingLeft} />}
      {modalEnigmaEmotionIsOpen && <ModalEnigmaEmotion />}
    </RoomContainer>
  );
}

export default EmotionGenerationRoom;
