import styles from 'components/Joyride/Steps.module.scss';
import chatbotStep from './constants/chatbotStep';
import commonSteps from './constants/commonSteps';
import inventoryStep from './constants/inventoryStep';
/**
 * Tutorial steps for TMS game
 * @param {func} t -translation function from i18next
 * @returns {Object[]}
 */
const stepsTMS = (t) => {
  // get constants steps from constants files
  const chatbotImportedStep = chatbotStep(t);
  const inventoryImportedStep = inventoryStep(t);
  const commonImportedSteps = commonSteps(
    t,
    `dans les ${t('room1', { ns: 'tms' })}`,
    null,
    null
  );

  // Insert Inventory step into commons steps
  commonImportedSteps.splice(1, 0, inventoryImportedStep);

  // Insert Chatbot Step into commons steps
  commonImportedSteps.splice(
    commonImportedSteps.length - 1,
    0,
    chatbotImportedStep
  );

  // Insert good luck message modified into commons steps
  commonImportedSteps.splice(commonImportedSteps.length - 1, 1, {
    target: '.ChangingRoom',
    content: (
      <div className={styles['main-content']}>
        <h2>{t('joyride.goodLuck.title')}</h2>
        <p>{t('joyride.goodLuck.description', { ns: 'tms' })}</p>
      </div>
    ),
    placement: 'center',
  });

  return commonImportedSteps;
};
export default stepsTMS;
