const CombinedPlutchikEmotions = [
  {
    name: 'joy',
    color: '#ffd678',
    path: 'M80.1,87C68.6,40.9,42.8,0.1,42.8,0.1S17,41,5.5,87l0,0c-6.4,25.6-8.4,52.8,1,75.5l36.3,87.6l36.3-87.6l0,0  C88.5,139.8,86.5,112.6,80.1,87z',
    textPosition: 'translate(250 122)',
    pathPosition: 'translate(207 6)',
    id: 6432,
  },
  {
    name: 'trust',
    color: '#9acc68',
    path: 'M317,183a94.15,94.15,0,0,0-30.8-20.6h0c9.4-22.7,30-40.5,52.6-54.1a175.62,175.62,0,0,1,29.4,23.4,162.47,162.47,0,0,1,23.4,29.4c-13.6,22.6-31.4,43.3-54.1,52.6h0A91.91,91.91,0,0,0,317,183Z M338.9,108.3c40.7-24.4,87.8-35,87.8-35h0s-10.6,47.1-35,87.8a175.62,175.62,0,0,0-23.4-29.4A162.47,162.47,0,0,0,338.9,108.3Z M250,250l36.3-87.6h0A92.72,92.72,0,0,1,317,183a96.43,96.43,0,0,1,20.6,30.7Z',
    textPosition: 'translate(345 159) rotate(-45)',
    pathPosition: 'translate(1 3)',
    id: 2168,
  },
  {
    name: 'fear',
    color: '#00ac6e',
    path: 'M250,42.7c0,0-40.9-25.8-86.9-37.3c0,0,0,0,0,0C137.5-1,110.3-3,87.6,6.4L0,42.7L87.6,79c22.7,9.4,49.9,7.4,75.5,1  c0,0,0,0,0,0c0,0,0,0,0,0C209.2,68.5,250,42.7,250,42.7z',
    textPosition: 'translate(379 253)',
    pathPosition: 'translate(251 210)',
    id: 1538,
  },
  {
    name: 'surprise',
    color: '#05a5ce',
    path: 'M176.7,176.7c0,0-10.6-47.1-35-87.8v-0.1c-13.6-22.6-31.4-43.2-54.1-52.6c0,0,0,0.1,0,0.1L0,0l36.3,87.6c0,0,0,0,0,0  c9.3,22.7,30,40.4,52.6,54v0.1C129.6,166.1,176.7,176.7,176.7,176.7z',
    textPosition: 'translate(341 345) rotate(45)',
    pathPosition: 'translate(250 252)',
    id: 2844,
  },
  {
    name: 'sadness',
    color: '#639fd1',
    path: 'M79,87.6L42.7,0L6.4,87.6l0,0c-9.4,22.7-7.4,49.9-1,75.5h0C16.9,209.2,42.7,250,42.7,250s25.8-40.9,37.3-86.9l0,0  C86.4,137.5,88.4,110.3,79,87.6z',
    textPosition: 'translate(250 384)',
    pathPosition: 'translate(208 251)',
    id: 3959,
  },
  {
    name: 'disgust',
    color: '#9b85ba',
    path: 'M176.7,0L89.1,36.3c0,0,0,0,0,0c-22.7,9.3-40.4,30-54,52.6H35c-24.4,40.7-35,87.8-35,87.8s47.1-10.6,87.8-35h0.1  c22.6-13.6,43.2-31.4,52.6-54.1c0,0-0.1,0-0.1,0L176.7,0z',
    textPosition: 'translate(159 345) rotate(-45)',
    pathPosition: 'translate(74 253)',
    id: 2740,
  },
  {
    name: 'anger',
    color: '#fa6764',
    path: 'M162.4,6.4L162.4,6.4C139.7-3,112.5-1,86.9,5.4C40.8,16.9,0,42.7,0,42.7S40.9,68.5,86.9,80l0,0c25.6,6.4,52.8,8.4,75.5-1  L250,42.7L162.4,6.4z',
    textPosition: 'translate(119 253)',
    pathPosition: 'translate(2 210)',
    id: 2908,
  },
  {
    name: 'anticipation',
    color: '#ffa263',
    path: 'M140.4,89.1C140.4,89.1,140.4,89.1,140.4,89.1c-9.4-22.6-30-40.4-52.6-54V35C47.1,10.6,0,0,0,0s10.6,47.1,35,87.8  c0,0.1,0,0.1,0,0.1c13.6,22.6,31.4,43.2,54.1,52.6c0,0,0-0.1,0-0.1l87.6,36.3L140.4,89.1z',
    textPosition: 'translate(150 159) rotate(45)',
    pathPosition: 'translate(74 76)',
    id: 2745,
  },
];

export default CombinedPlutchikEmotions;
