import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

// components
import Inventory from 'components/GameBoard/ReusableComponents/Inventory';
import Modal from 'components/GameBoard/ReusableComponents/Modal/Modal';
import Steps from 'components/GameBoard/ReusableComponents/Steps';
import ListObjects from 'components/GameBoard/ReusableComponents/Objects/ListObjects';
import Zoom from 'components/GameBoard/ReusableComponents/Modal/Zoom';
import ModalZoom from 'components/GameBoard/ReusableComponents/Modal/ModalZoom';
import ObjectInInventory from 'components/GameBoard/ReusableComponents/Actions/ObjectInInventory';
import Hud from 'components/GameBoard/ReusableComponents/HUD/Hud';
import ModalEnigma from 'components/GameBoard/ReusableComponents/Modal/ModalEnigma';
import ChatBot from 'components/GameBoard/ReusableComponents/ChatBot/ChatBot';
import Audio from 'components/GameBoard/ReusableComponents/Actions/Audio';
import Evaluation from 'components/GameBoard/ReusableComponents/Multi/Shared/Evaluation/Evaluation';

// selector functions
import { selectSession } from 'components/Reducers/session';
import { selectSteps } from 'components/Reducers/Steps/Steps';
import { selectUser } from 'components/Reducers/user';
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';
import { selectRoom } from 'components/Reducers/Room';
import { selectModal } from 'components/Reducers/Modal/Modal';

// utils
import useInitGame from 'utils/useInitGame';
import {
  clickCount,
  clickCountUser,
  useStartMessageOrQuestionary,
} from 'utils/utilityFunctions';

// css
import 'assets/css/components/GameBoard/TMS/Rooms/ChangingRoomTMS.css';
import 'components/GameBoard/Emotion/Multi/Rooms/DesktopV2.css';

// sound
import musicTheme from 'assets/sound/musicTheme/musicTheme';
// constants
import {
  volumeMulti,
  modalBackgroundColor,
} from 'components/GameBoard/Emotion/Multi/constant';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const musicThemeLivingRoom = musicTheme['pensees-your-lips'];

const LivingRoomEmotionMulti = ({ statusCheckRoom }) => {
  const session = useSelector(selectSession);
  const {
    startMessage,
    startQuestionnary,
    currentStep,
    isScoreBoard,
    isPedagogy,
    isTutoFinish,
  } = useSelector(selectSteps);
  const { participants, currentUser, inGame } = useSelector(selectUser);
  const room = session.id;

  const { socket, idSessionHasRoom, count, cursor, clicked, music } =
    useSelector(infoGameUser);

  const dispatch = useDispatch();
  const { currentRoomId, listRooms } = useSelector(selectRoom);
  const { modal, modalZoom, zoom, modalEnigma } = useSelector(selectModal);

  useInitGame(currentRoomId, session.id);

  useEffect(() => {
    if (!currentStep?.secondary_emotion && currentStep !== null) {
      const responsesSocket = { room };
      socket?.emit('send_start_message_prevention', responsesSocket);
      dispatch({
        type: 'START_MESSAGE_PREVENTION',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!currentStep?.secondary_emotion]);

  useStartMessageOrQuestionary(
    currentStep,
    idSessionHasRoom,
    startMessage,
    startQuestionnary,
    session,
    socket
  );
  // COUNTER
  useEffect(() => {
    if (idSessionHasRoom) {
      axios.put(
        `${BASE_URL}/api/sessionsHasRoom/${idSessionHasRoom}/modifications`,
        {
          count,
        }
      );
    }
  }, [count, idSessionHasRoom]);

  const clickOnGame = (event) => {
    const responsesSocket = { room };
    clickCount(dispatch, event, socket, responsesSocket);
    if (socket) {
      clickCountUser(participants, socket, dispatch, session);
    }
  };
  const isNotYourTurn =
    currentUser &&
    statusCheckRoom === 'introduction-finish' &&
    participants[Object.keys(currentUser)[0]] &&
    !participants[Object.keys(currentUser)[0]].isMainUser;

  const urlRoom = listRooms.LivingRoomEmotionMulti.url;

  useEffect(() => {
    if (Object.keys(participants).length > 0 && inGame) {
      dispatch({
        type: 'START_PEDAGOGY',
      });
    }
  }, [dispatch, inGame, participants]);
  return (
    <div className="Bedroom" id="BedroomRoom-BKG img-background">
      <Audio
        sound={musicThemeLivingRoom}
        loop
        id="MULTI-Theme"
        muted={!music}
        condition={isTutoFinish}
        volume={volumeMulti}
      />
      <div
        className={clicked ? 'cursor-clicked' : 'Hide'}
        style={{ top: cursor.y - 30, left: cursor.x }}
      >
        <p>{isNotYourTurn ? "Ce n'est pas votre tour" : '+1'}</p>
      </div>
      {!isScoreBoard && statusCheckRoom === 'introduction-finish' && (
        <header>
          <Inventory />
          <Steps />
          <Hud />
        </header>
      )}
      <button
        aria-label="background-living-room"
        type="button"
        className="Bedroom-img-div"
        style={{
          backgroundImage: `${
            statusCheckRoom === 'introduction-finish' && `url(${urlRoom})`
          }`,
        }}
        onClick={(event) =>
          statusCheckRoom === 'introduction-finish' && clickOnGame(event)
        }
      />
      {modal.isOpen && (
        <Modal
          modalBackgroundColor={modalBackgroundColor}
          textPaddingLeft="9.5rem"
        />
      )}
      {modalZoom.isOpen && <ModalZoom />}
      {zoom.isOpen && <Zoom />}
      {modalEnigma.isOpen && <ModalEnigma />}
      {isPedagogy && <Evaluation content="evaluationDesktop" isPedagogy />}
      {statusCheckRoom === 'introduction-finish' && (
        <>
          <ObjectInInventory />
          <ListObjects />
          <ChatBot />
        </>
      )}
      {/* round by round the user cannot click on the game */}
      {!isPedagogy && isNotYourTurn && (
        <div
          className="ModalTricky-NoClick2"
          role="presentation"
          aria-label="Not Your Turn"
        />
      )}
    </div>
  );
};

LivingRoomEmotionMulti.propTypes = {
  statusCheckRoom: PropTypes.string.isRequired,
};

export default LivingRoomEmotionMulti;
