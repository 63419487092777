import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';

// Components
import BackgroundStyle from 'components/GameBoard/ReusableComponents/BackgroundStyle';
import Chevrons from 'components/GameBoard/ReusableComponents/Actions/Chevrons';
import DialogueRow from 'components/GameBoard/OffreSocle/Enigmas/DialogueRow';
import RoomContainer from 'components/GameBoard/ReusableComponents/RoomContainer';
import Locker from 'components/GameBoard/OffreSocle/Enigmas/Locker';
import VelledaBoardZoom from 'components/GameBoard/OffreSocle/Enigmas/VelledaBoardZoom';
import OffreSocleModal2 from 'components/GameBoard/OffreSocle/Enigmas/OffreSocleModal2';
import Button from 'components/GameBoard/ReusableComponents/Actions/Button';

// API
import { getObjectsOfObject } from 'API/objects';
// Selector functions
import { selectOffreSocle } from 'components/Reducers/offreSocle';
import {
  selectAllObjects,
  selectObject,
  selectObjectIndex,
} from 'components/Reducers/GameData/fetchObjects';
import { selectSteps } from 'components/Reducers/Steps/Steps';
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';

// Utils & constants
import {
  centerPoint,
  checkedObject,
  clickCount,
  getRightCorner,
} from 'utils/utilityFunctions';
import {
  referringDoctorOffice,
  firstObjectImageContent,
} from 'components/GameBoard/OffreSocle/constants';
import {
  doctorComputer,
  offreSocleLocker3Id,
  duerpId,
} from 'components/GameBoard/ReusableComponents/Objects/constants';

// SCSS
import styles from 'components/GameBoard/OffreSocle/Enigmas/CompanyView.module.scss';
import styles2 from 'components/GameBoard/OffreSocle/Rooms/ReferringDoctorOffice.module.scss';
import styled from 'styled-components';

const winSoundUrl =
  'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FOffre%20Socle%2Fassets%2FRoom%202%2Fsounds%2Fsuccess-fanfare-trumpets-6185.mp3?alt=media&token=f350fc8e-60bb-4b1b-bdb7-2a05d81eac4b';

const { companyBackgroundStyle, companyBackgroundUrl, ergonomistCall } =
  referringDoctorOffice;

const ImgStyle = styled.img`
  position: absolute;
  z-index: ${({ object }) => object.zindex ?? ''};
  height: ${({ object }) => object.height ?? ''};
  width: ${({ object }) => object.width ?? ''};
  left: ${({ object }) => object.left ?? ''};
  right: ${({ object }) => object.right ?? ''};
  bottom: ${({ object }) => object.bottom ?? ''};
  top: ${({ object }) => object.top ?? ''};
  filter: ${({ objectClicked }) => objectClicked && 'blur(5px)'};
  cursor: pointer;
`;

const CompanyView = () => {
  const { t } = useTranslation('offreSocle', 'common');

  const dispatch = useDispatch();
  // This ref is connected to the image background
  const backgroundImageRef = useRef();
  const containerRef = useRef();
  const [backgroundPosition, setBackgroundPosition] = useState('center');
  const [completeObjectsOfObject, setCompletObjectsOfObject] = useState([]);
  const [objectClicked, setObjectClicked] = useState(null);
  const locker3Status =
    useSelector(selectOffreSocle).locker[objectClicked?.id]?.status;

  // Define variables used for the translation of the background
  const screenWidth = containerRef?.current?.offsetWidth;
  const imgWidth = backgroundImageRef?.current?.offsetWidth;
  /* Number of pixels needed to center a point of interest in the background */
  const pointOfInterestCentered =
    centerPoint(companyBackgroundStyle.pointOfInterest, imgWidth, screenWidth) >= 0
      ? `-${centerPoint(
          companyBackgroundStyle.pointOfInterest,
          imgWidth,
          screenWidth
        )}px`
      : '0px';

  const allObjects = useSelector(selectAllObjects);
  const locker3Object = useSelector((state) =>
    selectObject(state, offreSocleLocker3Id)
  );
  const duerpObject = useSelector((state) => selectObject(state, duerpId));
  const duerpObjectIndex = useSelector((state) =>
    selectObjectIndex(state, duerpId)
  );
  const { idSessionHasRoom } = useSelector(infoGameUser);

  const { startMessage } = useSelector(selectSteps);

  useEffect(() => {
    const getCompleteObjectsOfObject = async () => {
      const objectsOfObject = await getObjectsOfObject(doctorComputer);

      const listOfObjects = [];

      objectsOfObject.forEach((object) => {
        const foundObject = allObjects.find((object2) => object2.id === object.id);
        if (foundObject) listOfObjects.push(foundObject);
      });

      setCompletObjectsOfObject(listOfObjects);
    };

    getCompleteObjectsOfObject();
  }, [allObjects]);

  const handleContent = (object) => {
    const dialogueText = t(`${object.name}Text`, {
      ns: 'offreSocle',
      returnObjects: true,
    });
    switch (object?.images[firstObjectImageContent]?.type) {
      case 'bubble-text':
        return (
          <div className={styles2['bubble-and-button-container']}>
            <motion.div className={styles2['bubble-container']}>
              <DialogueRow
                characterImage={referringDoctorOffice[`${object.name}Face`]}
                direction="row"
                dialogueText={dialogueText}
              />
            </motion.div>
            <Button
              title={t('buttonFunction.return', { ns: 'common' })}
              buttonType="offreSocle"
              onClick={() => setObjectClicked(null)}
              additionalStyle={{
                width: 'fit-content',
                margin: '0',
                alignSelf: 'center',
                position: 'absolute',
                bottom: '0',
              }}
            />
          </div>
        );

      case 'enigma':
        return (
          <div className={styles['modal-position']}>
            <Locker
              objectClicked={objectClicked}
              setObjectClicked={setObjectClicked}
            />
          </div>
        );
      case 'zoom':
        return <VelledaBoardZoom setObjectClicked={setObjectClicked} />;
      default:
        return undefined;
    }
  };

  const handleEnigmaEnd = () => {
    const winSound = new Audio(winSoundUrl);
    winSound.volume = 0.1;
    winSound.play();
    return (
      <OffreSocleModal2
        text={t('congratulation')}
        subText={t('duerpFound')}
        buttonTitle={t('buttonFunction.continue', { ns: 'common' })}
        onClick={() => {
          checkedObject(duerpObject, duerpObjectIndex, idSessionHasRoom, dispatch);
          dispatch({ type: 'SET_ENIGMA_STEP', payload: { step: ergonomistCall } });
        }}
      />
    );
  };

  const handleClickImage = (objectElement) => {
    const objectElementImages = objectElement?.images.find(
      (dataObject) =>
        dataObject.type === 'bubble-text' ||
        dataObject.type === 'enigma' ||
        dataObject.type === 'zoom'
    );

    if (objectElementImages) {
      const closedDesktop = new Audio(objectElementImages.sound);
      closedDesktop.volume = 0.1;
      closedDesktop.play();
    }

    if (!objectClicked) {
      setObjectClicked(objectElement);
    } else {
      setObjectClicked(null);
    }
  };

  return (
    <RoomContainer id="container" ref={containerRef}>
      <BackgroundStyle
        position={backgroundPosition}
        start={pointOfInterestCentered}
        right={imgWidth && getRightCorner(imgWidth, screenWidth)}
        left={companyBackgroundStyle.imgLeft}
        duration={companyBackgroundStyle.duration}
        timing={companyBackgroundStyle.timing}
        fillMode={companyBackgroundStyle.fillMode}
        backgroundWidth={companyBackgroundStyle.width}
        onClick={(event) => {
          clickCount(dispatch, event);
        }}
        type="button"
      >
        <img
          src={companyBackgroundUrl}
          width="100%"
          height="100%"
          alt={t('company')}
          id={styles['img-background']}
          ref={backgroundImageRef}
          className={objectClicked ? styles['img-blur'] : undefined}
        />
        <div className={styles['object-container']}>
          {completeObjectsOfObject.map((objectElement) => {
            return (
              <ImgStyle
                key={objectElement.id}
                object={objectElement}
                src={objectElement?.images[firstObjectImageContent].image}
                alt={objectElement.name}
                objectClicked={objectClicked}
                onClick={() => handleClickImage(objectElement)}
              />
            );
          })}
        </div>
      </BackgroundStyle>

      <Chevrons
        position={backgroundPosition}
        setPosition={setBackgroundPosition}
        hide={locker3Status === 'right'}
      />
      {objectClicked && <>{handleContent(objectClicked)}</>}
      {!!locker3Object.isChecked && !startMessage && <>{handleEnigmaEnd()}</>}
    </RoomContainer>
  );
};

export default CompanyView;
