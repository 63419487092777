/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Typewriter from 'typewriter-effect';
import axios from 'axios';

import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import Input from '../Actions/Input';
import Button from '../Actions/Button';
import Audio from '../Actions/Audio';

import '../../../../assets/css/components/GameBoard/ReusableComponents/Modal/ModalRobot.css';

// Sound for successful answers
import successSound from '../../../../sound/Success.mp3';
// Sound for wrong answers
import failSound from '../../../../sound/fail.mp3';

const BASE_URL = process.env.REACT_APP_BASE_URL;

function ModalRobot({
  dispatch,
  messages,
  allObjects,
  idSessionHasRoom,
  type,
  soundtrack,
}) {
  // const idColoric = 193;
  const idBalance = 186;
  const idCardAnniversary = 187;
  const idMark = 188;
  const idApportCaloric = 192;
  const idRobot = 189;
  const idRobotTwo = 199;
  const idEndTimeOne = 196;
  const idEndTimeTwo = 197;
  const idEndTimeThree = 198;
  const idWakeUpTimeOne = 200;
  const idWakeUpTimeTwo = 201;
  const idWakeUpTimeThree = 202;
  const idRobotThree = 205;
  const idPhone = 204;

  const apportCaloricElement = allObjects.find(
    (apportCaloricContent) => apportCaloricContent.id === idApportCaloric
  );
  const apportCaloricElementIndex = allObjects.findIndex(
    (apportCaloricContent) => apportCaloricContent.id === idApportCaloric
  );
  const cardAnniversaryElement = allObjects.find(
    (cardAnniversaryContent) => cardAnniversaryContent.id === idCardAnniversary
  );
  const cardAnniversaryElementIndex = allObjects.findIndex(
    (cardAnniversaryContent) => cardAnniversaryContent.id === idCardAnniversary
  );
  const balanceElement = allObjects.find(
    (balanceContent) => balanceContent.id === idBalance
  );
  const balanceElementIndex = allObjects.findIndex(
    (balanceContent) => balanceContent.id === idBalance
  );
  const markElement = allObjects.find((markContent) => markContent.id === idMark);
  const markElementIndex = allObjects.findIndex(
    (markContent) => markContent.id === idMark
  );

  const robotElement = allObjects.find(
    (robotContent) => robotContent.id === idRobot
  );
  const robotElementIndex = allObjects.findIndex(
    (robotContent) => robotContent.id === idRobot
  );

  const robotTwoElement = allObjects.find(
    (robotContent) => robotContent.id === idRobotTwo
  );
  const robotTwoElementIndex = allObjects.findIndex(
    (robotContent) => robotContent.id === idRobotTwo
  );
  const robotThreeElement = allObjects.find(
    (robotThreeContent) => robotThreeContent.id === idRobotThree
  );
  const robotThreeElementIndex = allObjects.findIndex(
    (robotThreeContent) => robotThreeContent.id === idRobotThree
  );

  const endTimeOneElement = allObjects.find(
    (endTimeOneContent) => endTimeOneContent.id === idEndTimeOne
  );
  const endTimeOneElementIndex = allObjects.findIndex(
    (endTimeOneContent) => endTimeOneContent.id === idEndTimeOne
  );

  const endTimeTwoElement = allObjects.find(
    (endTimeTwoContent) => endTimeTwoContent.id === idEndTimeTwo
  );
  const endTimeTwoElementIndex = allObjects.findIndex(
    (endTimeTwoContent) => endTimeTwoContent.id === idEndTimeTwo
  );

  const endTimeThreeElement = allObjects.find(
    (endTimeThreeContent) => endTimeThreeContent.id === idEndTimeThree
  );
  const endTimeThreeElementIndex = allObjects.findIndex(
    (endTimeThreeContent) => endTimeThreeContent.id === idEndTimeThree
  );

  const wakeUpTimeOneElement = allObjects.find(
    (wakeUpTimeOneContent) => wakeUpTimeOneContent.id === idWakeUpTimeOne
  );
  const wakeUpTimeOneElementIndex = allObjects.findIndex(
    (wakeUpTimeOneContent) => wakeUpTimeOneContent.id === idWakeUpTimeOne
  );

  const wakeUpTimeTwoElement = allObjects.find(
    (wakeUpTimeTwoContent) => wakeUpTimeTwoContent.id === idWakeUpTimeTwo
  );
  const wakeUpTimeTwoElementIndex = allObjects.findIndex(
    (wakeUpTimeTwoContent) => wakeUpTimeTwoContent.id === idWakeUpTimeTwo
  );

  const wakeUpTimeThreeElement = allObjects.find(
    (wakeUpTimeThreeContent) => wakeUpTimeThreeContent.id === idWakeUpTimeThree
  );
  const wakeUpTimeThreeElementIndex = allObjects.findIndex(
    (wakeUpTimeThreeContent) => wakeUpTimeThreeContent.id === idWakeUpTimeThree
  );

  const phoneElement = allObjects.find(
    (phoneContent) => phoneContent.id === idPhone
  );
  const phoneElementIndex = allObjects.findIndex(
    (phoneContent) => phoneContent.id === idPhone
  );

  const [inputs, setInputs] = useState({
    age: '',
    weight: '',
    height: '',
    caloric: '',
  });
  const [alert, setAlert] = useState(null);
  const [failSoundState, setFailSoundState] = useState(false);
  const [successSoundState, setSuccessSoundState] = useState(false);
  const [valueTimeFirst, onChangeTimeFirst] = useState(null);
  const [valueTimeSecond, onChangeTimeSecond] = useState(null);
  const [valueTimeThird, onChangeTimeThird] = useState(null);
  const [valueTimeFourth, onChangeTimeFourth] = useState(null);
  const [valueTimeFifth, onChangeTimeFifth] = useState(null);
  const [valueTimeSixth, onChangeTimeSixth] = useState(null);
  const [activity, setActivity] = useState({
    pourcent: '',
    type: '',
  });
  const [timeScreen, setTimeScreen] = useState({
    pourcent: '',
    type: '',
  });

  const checkedObject = (collectObject, index, isMessage) => {
    const robotId = 205;
    axios
      .put(`${BASE_URL}/api/sessionsHasRoomHasObject/${idSessionHasRoom}`, {
        object_id: collectObject.id,
        isChecked: 1,
      })
      .then(() => {
        dispatch({
          type: 'CLICKED_OBJECT',
          payload: {
            index,
            isChecked: 1,
            type: 'checked',
          },
        });

        if (collectObject.id !== robotId) {
          dispatch({
            type: 'REMOVE_OF_INVENTORY',
            payload: collectObject.id,
          });
        }
        if (!isMessage) {
          dispatch({
            type: 'START_MESSAGE_PREVENTION',
          });
        }
      });
  };

  const handleEnigmaCaloric = () => {
    setAlert(null);
    const goodAge = 34;
    const googWeight = 104.82;
    const goodHeight = 187;

    const { age, weight, height } = inputs;

    if (age && weight && height) {
      const isGoodAge = goodAge === Number(age);
      const isGoodWeight = googWeight === Number(weight);
      const isGoodHeight = goodHeight === Number(height);

      const isValidEnigmaCaloric = isGoodAge && isGoodWeight && isGoodHeight;

      if (isValidEnigmaCaloric) {
        setSuccessSoundState(true);
        setTimeout(() => {
          setSuccessSoundState(false);
        }, 1000);

        setAlert({
          title: 'Félicitation !',
          messages: [
            'Hum… 34 ans, 1m87 pour 104,82 kg.',
            'J’en conclus que le besoin calorique journalier d’Arthur est de 2861 calories.',
            'Pour déterminer si ce qu’il mange correspond à son besoin, il nous faut à présent trouver le nombre de calories qu’il consomme au quotidien',
            'Cette longue liste me semble riche en apport pour une seule personne…',
            'Dites-moi, à combien s’élève le nombre de calories ? ',
          ],
          type: 'good',
        });
        checkedObject(
          cardAnniversaryElement,
          cardAnniversaryElementIndex,
          'not-message'
        );
        checkedObject(balanceElement, balanceElementIndex, 'not-message');
        checkedObject(markElement, markElementIndex, 'not-message');
      } else {
        const newInputs = {};
        newInputs.age = isGoodAge ? goodAge : '';
        newInputs.weight = isGoodWeight ? googWeight : '';
        newInputs.height = isGoodHeight ? goodHeight : '';
        setInputs(newInputs);

        setFailSoundState(true);
        setTimeout(() => {
          setFailSoundState(false);
        }, 1000);

        setAlert({
          title: 'Bien essayé !',
          messages: [
            'Malheureusement, il semblerait que vous ayez inséré une mauvaise information concernant [taille/poids/age], veuillez réessayer.',
          ],
          type: 'danger',
        });
      }
    } else {
      setFailSoundState(true);
      setTimeout(() => {
        setFailSoundState(false);
      }, 1000);

      setAlert({
        messages: ['Insérer toutes les données demandées'],
        type: 'danger',
      });
    }
  };

  const handleMessageRobot = () => {
    if (alert !== null) {
      return (
        <div className="ModalRobot_enigma-text">
          <h4>{alert.title}</h4>
          {alert.messages.map((message) => (
            <p key={message}>{message}</p>
          ))}
        </div>
      );
    }
    if (markElement.isChecked && !apportCaloricElement.isChecked) {
      return (
        <div className="ModalRobot_enigma-text">
          <h4>Félicitation</h4>
          <p>Hum… 34 ans, 1m87 pour 104,82 kg.</p>
          <p>
            J’en conclus que le besoin calorique journalier d’Arthur est de 2861
            calories.
          </p>
          <p>
            Pour déterminer si ce qu’il mange correspond à son besoin, il nous faut
            à présent trouver le nombre de calories qu’il consomme au quotidien
          </p>
          <p>
            Cette longue liste me semble riche en apport pour une seule personne…
            Dites-moi, à combien s’élève le nombre de calories ?
          </p>
        </div>
      );
    }

    if (alert?.type !== 'danger') {
      return (
        <div className="ModalRobot_enigma-text">
          <h4>AIDEZ-MOI !</h4>
          <p>
            Avec tout ce stress accumulé, j’avais remarqué que les habitudes
            alimentaires d’Arthur avaient changées.
          </p>
          <p>
            Les activités physiques sont quant à elles devenues inexistantes, plus
            le temps ni l’envie dit-il ! La fameuse balance des entrées et sorties
            s&apos;est complètement déréglée…
          </p>
          <p>
            Allez commençons simplement ! Il faudrait retrouver quels sont ses
            besoins en calories.
          </p>
        </div>
      );
    }
    return null;
  };

  const handleMessageRobotTwo = () => {
    if (alert !== null) {
      return (
        <div className="ModalRobot_enigma-text">
          <h4>{alert.title}</h4>
          {alert.messages.map((message) => (
            <p key={message}>{message}</p>
          ))}
        </div>
      );
    }

    if (
      endTimeOneElement.isChecked &&
      endTimeTwoElement.isChecked &&
      endTimeThreeElement.isChecked
    ) {
      return (
        <div className="ModalRobot_enigma-text">
          <h4>AIDEZ-MOI !</h4>
          <p>
            Pour vérifier j’aurai besoin de récupérer certaines heures à laquelle il
            s’est levé le 02/01/2017, le 02/06/2017 et le 02/12/2017.
          </p>

          <p>Arthur semblait allumer sa domotique à son réveil</p>
        </div>
      );
    }

    return (
      <div className="ModalRobot_enigma-text">
        <h4>AIDEZ-MOI !</h4>
        <p>
          A partir de 2017, le sommeil d’Arthur s&apos;est considérablement dégradé.
        </p>
        <p>
          Pour vérifier j’aurai besoin de récupérer certaines heures à laquelle il
          s’est couché le 01/01, le 01/06 et le 02/12.
        </p>
        <p>Arthur semblait éteindre sa télévision avant de se coucher</p>
      </div>
    );
  };

  const handleMessageRobotThree = () => {
    if (alert !== null) {
      return (
        <div className="ModalRobot_enigma-text">
          <h4>{alert.title}</h4>
          {alert.messages.map((message) => (
            <p key={message}>{message}</p>
          ))}
        </div>
      );
    }

    return (
      <div className="ModalRobot_enigma-text">
        <h4>AIDEZ-MOI !</h4>
        <p>
          Arthur me dit régulièrement qu’il va voir des amis mais, depuis 2017, j’ai
          l’impression que c’est de moins en moins régulier. J’ai même peur qu’il me
          mente parfois...
        </p>
        <p>
          Puis, je trouve qu’il passe quand même beaucoup de temps sur son
          téléphone, bien plus qu’avant ! Mais pour pouvoir vérifier tout ça, il
          faudrait que je prenne connaissance de l’activité du téléphone d’Arthur et
          que je la compare à celle du mois précédent.
        </p>
        <p>
          Il faudrait aussi que je sache à quelle fréquence il a vu ses amis.
          Pouvez-vous m’aider à retrouver ces informations ?
        </p>
      </div>
    );
  };

  const handleEnigmaApport = () => {
    const goodApport = 4250.5;
    if (goodApport === Number(inputs.caloric)) {
      setSuccessSoundState(true);
      setTimeout(() => {
        setSuccessSoundState(false);
      }, 1000);

      checkedObject(robotElement, robotElementIndex, 'not-message');
      checkedObject(apportCaloricElement, apportCaloricElementIndex, 'not-message');

      axios
        .put(
          `${process.env.REACT_APP_BASE_URL}/api/sessionsHasRoom/${idSessionHasRoom}/modifications`,
          {
            current_step: apportCaloricElement.id_message,
          }
        )
        .then(() =>
          dispatch({
            type: 'CURRENT_STEP',
            payload: apportCaloricElement.id_message,
          })
        );

      checkedObject(apportCaloricElement, apportCaloricElementIndex);

      // TODO success sound doesn't work when the modal is closed -> couldn't fix it
      dispatch({
        type: 'CLOSE_MODAL_ROBOT',
      });
    } else {
      setFailSoundState(true);
      setTimeout(() => {
        setFailSoundState(false);
      }, 1000);
      setAlert({
        title: 'Bien essayé !',
        messages: [
          "Malheureusement, il semblerait que vous ayez inséré une mauvaise information concernant l'apport calorique, veuillez réessayer.",
        ],
        type: 'danger',
      });
    }
  };

  const handleActionsRobot = () => {
    if (markElement.isChecked && !apportCaloricElement.isChecked) {
      return (
        <div>
          <p>Besoin Calorique</p>
          <p>2861 Kcal</p>
          <Input
            text="Apport calorique"
            name="caloric"
            type="number"
            isRequired
            onChange={setInputs}
            inputs={inputs}
          />
          <Button
            title="Valider"
            buttonType="action"
            onClick={() => handleEnigmaApport()}
          />
        </div>
      );
    }
    return (
      <div>
        <Input
          text="Age"
          name="age"
          type="number"
          isRequired
          onChange={setInputs}
          inputs={inputs}
        />
        <Input
          text="Poids"
          name="weight"
          type="number"
          isRequired
          onChange={setInputs}
          inputs={inputs}
        />
        <Input
          text="Taille"
          name="height"
          type="number"
          isRequired
          onChange={setInputs}
          inputs={inputs}
        />

        <Button
          title="Valider"
          buttonType="action"
          onClick={(event) => handleEnigmaCaloric(event)}
        />
      </div>
    );
  };

  const handleEnigmaBedTime = () => {
    const goodFirstTime = valueTimeFirst === '22:45';
    const goodSecondTime = valueTimeSecond === '23:30';
    const goodThirdTime = valueTimeThird === '00:45';

    if (
      valueTimeFirst !== null &&
      valueTimeSecond !== null &&
      valueTimeThird !== null
    ) {
      if (goodFirstTime && goodSecondTime && goodThirdTime) {
        setSuccessSoundState(true);
        setTimeout(() => {
          setSuccessSoundState(false);
        }, 1000);

        setAlert({
          title: 'Félicitation !',
          messages: [
            'Merci à vous, pour vos informations',
            "Maintenant, nous avons besoin de connaitre l'heure à laquelle Arthur s'est levé le 02/01/2017, le 02/06/2017 et le 02/12/2017",
            'Je compte sur vous !',
          ],
          type: 'good',
        });

        checkedObject(endTimeOneElement, endTimeOneElementIndex, 'not-message');
        checkedObject(endTimeTwoElement, endTimeTwoElementIndex, 'not-message');
        checkedObject(endTimeThreeElement, endTimeThreeElementIndex, 'not-message');

        setTimeout(() => {
          dispatch({
            type: 'CLOSE_MODAL_ROBOT',
          });
        }, 5000);
      } else {
        const newTimeFirst = goodFirstTime ? valueTimeFirst : '';
        const newTimeSecond = goodSecondTime ? valueTimeSecond : '';
        const newTimeThird = goodThirdTime ? valueTimeThird : '';

        onChangeTimeFirst(newTimeFirst);
        onChangeTimeSecond(newTimeSecond);
        onChangeTimeThird(newTimeThird);

        setFailSoundState(true);
        setTimeout(() => {
          setFailSoundState(false);
        }, 1000);
        setAlert({
          title: 'Bien essayé !',
          messages: [
            'Malheureusement, il semblerait que vous ayez inséré une ou plusieurs mauvaises informations, veuillez réessayer.',
          ],
          type: 'danger',
        });
      }
    } else {
      setFailSoundState(true);
      setTimeout(() => {
        setFailSoundState(false);
      }, 1000);
      setAlert({
        messages: ['Insérer toutes les données demandées'],
        type: 'danger',
      });
    }
  };

  const handleEnigmaWakeUpTime = () => {
    const goodFirstTime = valueTimeFourth === '08:00';
    const goodSecondTime = valueTimeFifth === '06:10';
    const goodThirdTime = valueTimeSixth === '05:45';

    if (
      valueTimeFourth !== null &&
      valueTimeFifth !== null &&
      valueTimeSixth !== null
    ) {
      if (goodFirstTime && goodSecondTime && goodThirdTime) {
        setSuccessSoundState(true);
        setTimeout(() => {
          setSuccessSoundState(false);
        }, 1000);

        setAlert({
          title: 'Félicitation !',
          messages: [
            'Merci à vous, pour vos informations',
            "Maintenant, nous avons besoin de connaitre l'heure à laquelle Arthur s'est levé le 02/01/2017, le 02/06/2017 et le 02/12/2017",
            'Je compte sur vous !',
          ],
          type: 'good',
        });
        axios
          .put(
            `${process.env.REACT_APP_BASE_URL}/api/sessionsHasRoom/${idSessionHasRoom}/modifications`,
            {
              current_step: wakeUpTimeThreeElement.id_message,
            }
          )
          .then(() =>
            dispatch({
              type: 'CURRENT_STEP',
              payload: wakeUpTimeThreeElement.id_message,
            })
          );

        checkedObject(
          wakeUpTimeOneElement,
          wakeUpTimeOneElementIndex,
          'not-message'
        );
        checkedObject(
          wakeUpTimeTwoElement,
          wakeUpTimeTwoElementIndex,
          'not-message'
        );
        checkedObject(robotTwoElement, robotTwoElementIndex, 'not-message');
        checkedObject(wakeUpTimeThreeElement, wakeUpTimeThreeElementIndex);

        dispatch({
          type: 'CLOSE_MODAL_ROBOT',
        });
      } else {
        const newTimeFirst = goodFirstTime ? valueTimeFirst : '';
        const newTimeSecond = goodSecondTime ? valueTimeSecond : '';
        const newTimeThird = goodThirdTime ? valueTimeThird : '';

        onChangeTimeFirst(newTimeFirst);
        onChangeTimeSecond(newTimeSecond);
        onChangeTimeThird(newTimeThird);

        setFailSoundState(true);
        setTimeout(() => {
          setFailSoundState(false);
        }, 1000);

        setAlert({
          title: 'Bien essayé !',
          messages: [
            'Malheureusement, il semblerait que vous ayez inséré une ou plusieurs mauvaises informations, veuillez réessayer.',
          ],
          type: 'danger',
        });
      }
    } else {
      setFailSoundState(true);
      setTimeout(() => {
        setFailSoundState(false);
      }, 1000);

      setAlert({
        messages: ['Insérer toutes les données demandées'],
        type: 'danger',
      });
    }
  };

  const handleActivities = () => {
    const goodActivityPourcent = activity.pourcent === '45';
    const goodTimeScreenPourcent = timeScreen.pourcent === '27';
    const goodActivityType = activity.type === 'regression';
    const goodTimeScreenType = timeScreen.type === 'augmentation';

    if (
      activity.pourcent !== null &&
      activity.pourcent !== null &&
      activity.type !== null &&
      timeScreen.type !== null
    ) {
      if (
        goodActivityPourcent &&
        goodTimeScreenPourcent &&
        goodActivityType &&
        goodTimeScreenType
      ) {
        setSuccessSoundState(true);
        setTimeout(() => {
          setSuccessSoundState(false);
        }, 1000);

        setAlert({
          title: 'Félicitation !',
          messages: [
            'Merci à vous, pour vos informations',
            'Vous avez finalisé toutes les énigmes',
          ],
          type: 'good',
        });
        axios
          .put(
            `${process.env.REACT_APP_BASE_URL}/api/sessionsHasRoom/${idSessionHasRoom}/modifications`,
            {
              current_step: robotThreeElement.id_message,
            }
          )
          .then(() => {
            dispatch({
              type: 'CURRENT_STEP',
              payload: robotThreeElement.id_message,
            });
            checkedObject(phoneElement, phoneElementIndex, 'not-message');
            checkedObject(robotThreeElement, robotThreeElementIndex);
            dispatch({
              type: 'CLOSE_MODAL_ROBOT',
            });
          });
      } else {
        if (!goodActivityPourcent) {
          setActivity({ ...activity, pourcent: '' });
        }
        if (!goodTimeScreenPourcent) {
          setTimeScreen({ ...timeScreen, pourcent: '' });
        }
        if (!goodActivityType) {
          setActivity({ ...activity, type: '' });
        }
        if (!goodTimeScreenType) {
          setTimeScreen({ ...timeScreen, type: '' });
        }

        setFailSoundState(true);
        setTimeout(() => {
          setFailSoundState(false);
        }, 1000);

        setAlert({
          title: 'Bien essayé !',
          messages: [
            'Malheureusement, il semblerait que vous ayez inséré une ou plusieurs mauvaises informations, veuillez réessayer.',
          ],
          type: 'danger',
        });
      }
    } else {
      setFailSoundState(true);
      setTimeout(() => {
        setFailSoundState(false);
      }, 1000);

      setAlert({
        messages: ['Insérer toutes les données demandées'],
        type: 'danger',
      });
    }
  };

  const handleActionsRobotTwo = () => {
    if (
      endTimeOneElement.isChecked &&
      endTimeTwoElement.isChecked &&
      endTimeThreeElement.isChecked
    ) {
      return (
        <div>
          <p>02/01/2017</p>
          <TimePicker
            onChange={onChangeTimeFourth}
            value={valueTimeFourth}
            disableClock
          />
          <p>02/06/2017</p>
          <TimePicker
            onChange={onChangeTimeFifth}
            value={valueTimeFifth}
            disableClock
          />
          <p>02/12/2017</p>
          <TimePicker
            onChange={onChangeTimeSixth}
            value={valueTimeSixth}
            disableClock
          />
          <Button
            title="Valider"
            buttonType="action"
            onClick={() => handleEnigmaWakeUpTime()}
          />
        </div>
      );
    }
    return (
      <div className="ModalRobot_enigma-actions-right">
        <p>01/01/2017</p>
        <TimePicker
          onChange={onChangeTimeFirst}
          value={valueTimeFirst}
          disableClock
        />
        <p>01/06/2017</p>
        <TimePicker
          onChange={onChangeTimeSecond}
          value={valueTimeSecond}
          disableClock
        />
        <p>02/12/2017</p>
        <TimePicker
          onChange={onChangeTimeThird}
          value={valueTimeThird}
          disableClock
        />
        <Button
          title="Valider"
          buttonType="action"
          onClick={() => handleEnigmaBedTime()}
        />
      </div>
    );
  };

  const handleActivityType = (e, typeContent) => {
    if (typeContent === 'activity') {
      setActivity({ ...activity, type: e.target.value });
    }
    if (typeContent === 'timeScreen') {
      setTimeScreen({ ...timeScreen, type: e.target.value });
    }
  };

  const handleActionsRobotThree = () => {
    return (
      <div className="ModalRobot-enigma-actions-robots-three">
        <Input
          text="Activité physique (%)"
          name="pourcent"
          type="number"
          isRequired
          onChange={setActivity}
          inputs={activity}
        />
        <div className="ModalRobot-enigma-actions-robots-threee-inputs">
          <div className="ModalRobot-enigma-actions-robots-threee-input">
            <input
              type="checkbox"
              id="activityTypeAugmentation"
              name="activityTypeAugmentation"
              value="augmentation"
              checked={activity.type === 'augmentation'}
              onChange={(e) => handleActivityType(e, 'activity')}
            />
            <label htmlFor="activityTypeAugmentation"> Augmentation</label>
          </div>
          <div className="ModalRobot-enigma-actions-robots-threee-input">
            <input
              type="checkbox"
              id="activityTypeRegression"
              name="activityTypeRegression"
              value="regression"
              checked={activity.type === 'regression'}
              onChange={(e) => handleActivityType(e, 'activity')}
            />
            <label htmlFor="activityTypeRegression"> Régression</label>
          </div>
        </div>
        <Input
          text="Temps écran (%)"
          name="pourcent"
          type="number"
          isRequired
          onChange={setTimeScreen}
          inputs={timeScreen}
        />
        <div className="ModalRobot-enigma-actions-robots-threee-inputs">
          <div className="ModalRobot-enigma-actions-robots-threee-input">
            <input
              type="checkbox"
              id="timeScreenTypeAugmentation"
              name="timeScreenTypeAugmentation"
              value="augmentation"
              checked={timeScreen.type === 'augmentation'}
              onChange={(e) => handleActivityType(e, 'timeScreen')}
            />
            <label htmlFor="timeScreenTypeAugmentation"> Augmentation</label>
          </div>
          <div className="ModalRobot-enigma-actions-robots-threee-input">
            <input
              type="checkbox"
              id="timeScreenTypeRegression"
              name="timeScreenTypeRegression"
              value="regression"
              checked={timeScreen.type === 'regression'}
              onChange={(e) => handleActivityType(e, 'timeScreen')}
            />
            <label htmlFor="timeScreenTypeRegression"> Régression</label>
          </div>
        </div>
        <Button
          title="Valider"
          buttonType="action"
          onClick={() => handleActivities()}
        />
      </div>
    );
  };

  const handleContent = () => {
    if (type.id === idRobot) {
      return (
        <div className="ModalRobot_enigma">
          {handleMessageRobot()}
          <div className="ModalRobot_enigma-actions">{handleActionsRobot()}</div>
        </div>
      );
    }

    if (type.id === idRobotTwo) {
      return (
        <div className="ModalRobot_enigma">
          {handleMessageRobotTwo()}
          <div className="ModalRobot_enigma-actions">{handleActionsRobotTwo()}</div>
        </div>
      );
    }

    if (type.id === idRobotThree) {
      return (
        <div className="ModalRobot_enigma">
          {handleMessageRobotThree()}
          <div className="ModalRobot_enigma-actions">
            {handleActionsRobotThree()}
          </div>
        </div>
      );
    }
    return (
      <Typewriter
        onInit={(typewriter) => {
          messages.map((messageContent) =>
            typewriter
              .changeDelay(40)
              .typeString(messageContent)
              .start()
              .pauseFor(2500)
              .deleteAll(-20)
          );
        }}
      />
    );
  };

  if (markElement.isChecked && !apportCaloricElement.isChecked) {
    dispatch({
      type: 'UPDATE_LEVEL',
      payload: 'caloric',
    });
  } else if (
    endTimeOneElement.isChecked &&
    endTimeTwoElement.isChecked &&
    endTimeThreeElement.isChecked
  ) {
    dispatch({
      type: 'UPDATE_LEVEL',
      payload: 'rising',
    });
  }
  return (
    <>
      <Audio sound={successSound} condition={successSoundState && soundtrack} />
      <Audio sound={failSound} condition={failSoundState && soundtrack} />
      <div className="ModalRobot">
        <div className="ModalRobot_blur" />
        <div className="ModalRobot_description">
          <div className="ModalRobot_description-contents">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FObesity%2Fassets%2Fobjects%2Frobot.svg?alt=media&token=30b58f7e-f4f1-4fd4-95bb-936360d3278e"
              alt="robot"
              className="bounceRobot ModalRobot_description-robot"
              id="bounceRobotAnim"
            />
            <div className="ModalRobot_description-content">
              <div className="ModalRobot_description-text">{handleContent()}</div>
            </div>
          </div>
          <button
            className="ButtonAction Return"
            type="button"
            onClick={() => {
              dispatch({
                type: 'CLOSE_MODAL_ROBOT',
              });
            }}
          >
            Retour
          </button>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  messages: state.Modal.modalRobot.description.messages,
  allObjects: state.Objects.AllObjects,
  idSessionHasRoom: state.GameUsers.idSessionHasRoom,
  soundtrack: state.GameUsers.soundtrack,
  type: state.Modal.modalRobot.description.type,
});

ModalRobot.propTypes = {
  dispatch: PropTypes.func.isRequired,
  messages: PropTypes.string,
  allObjects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
  idSessionHasRoom: PropTypes.number.isRequired,
  soundtrack: PropTypes.bool.isRequired,
  type: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};
ModalRobot.defaultProps = {
  messages: null,
};

export default connect(mapStateToProps)(ModalRobot);
