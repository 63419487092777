import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// selectors
import {
  selectUser,
  selectParticipantWithoutMediator,
  selectCurrentUser,
} from 'components/Reducers/user';
import { selectQuestionnaryDebriefing } from 'components/Reducers/questionnary';
import { selectSession } from 'components/Reducers/session';
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';

// components
import Button from 'components/GameBoard/ReusableComponents/Actions/Button';

// scss
import styles from 'components/GameBoard/ReusableComponents/Multi/Shared/Questionnary/Debriefing/Debriefing.module.scss';

const Debriefing = ({ setStatusCheckRoom }) => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  const { participants, currentUser } = useSelector(selectUser);
  const participantsWithoutMediator = useSelector(selectParticipantWithoutMediator);
  const allQuestionsDebriefing = useSelector(selectQuestionnaryDebriefing);
  const currentUserId = useSelector(selectCurrentUser);
  const session = useSelector(selectSession);
  const { socket } = useSelector(infoGameUser);

  const [index, setIndex] = useState(0);

  const isAllUsersReplied =
    participantsWithoutMediator.filter(
      (participant) => !participants[participant].isQuestionnaryFinish
    ).length === 0;

  const nextQuestion = () => {
    if (index + 1 < allQuestionsDebriefing.length) {
      setIndex(index + 1);
    } else {
      // Init round by round
      const room = session.id;
      const responses = {
        room,
      };
      socket?.emit('send_introduction_finish', responses);
      setStatusCheckRoom('introduction-finish');
      socket?.emit('send_users_in_game', responses);

      dispatch({ type: 'USERS_IN_GAME' });
    }
  };

  const handleAnswer = (participant) => {
    let answer = '?';
    if (
      (currentUserId === participant && participants[participant].responses) ||
      isAllUsersReplied
    ) {
      answer = participants[participant].responses[index].answer.title;
    }
    return answer;
  };

  const handleFooter = () => {
    const { isMainUser, type } = participants[Object.keys(currentUser)[0]];
    if (isAllUsersReplied) {
      if (isMainUser || type === 'admin') {
        return (
          <Button
            onClick={() => nextQuestion()}
            title={t('buttonFunction.next')}
            buttonType="action"
          />
        );
      }
      return <p>{t('multi.visio.actionMainUser')}</p>;
    }

    return <p>{t('multi.visio.wait')}</p>;
  };

  return (
    <div className={styles.debriefing}>
      <p>{t('multi.visio.titleDebriefing')}</p>
      <div className={styles.question}>{allQuestionsDebriefing[index].title}</div>
      {allQuestionsDebriefing[index]?.image && (
        <img
          className={styles['question-image']}
          src={allQuestionsDebriefing[index].image}
          alt="emotion react"
        />
      )}
      <div className={styles.participants}>
        {participantsWithoutMediator.map((participant) => (
          <div className={styles.participant} key={participant}>
            <p>{participants[participant].name}</p>
            <p>{handleAnswer(participant)}</p>
          </div>
        ))}
      </div>
      {handleFooter()}
    </div>
  );
};

Debriefing.propTypes = {
  setStatusCheckRoom: PropTypes.func.isRequired,
};

export default Debriefing;
