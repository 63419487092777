const fetchMessage = (messages) => {
  return {
    type: 'FETCH_MESSAGE',
    messages,
  };
};

export const fetchRoom = (payload) => {
  return {
    type: 'FETCH_ROOM',
    payload,
  };
};

export const fetchRooms = (rooms, room) => {
  return {
    type: 'FETCH_ROOMS',
    payload: { rooms, room },
  };
};

export const fetchObjectVaccination = (payload) => {
  return {
    type: 'FETCH_OBJECT_VACC',
    payload,
  };
};

export const fetchObject = (objects) => {
  return {
    type: 'FETCH_OBJECT',
    objects,
  };
};

export const addObject = (payload) => {
  return {
    type: 'ADD_OBJECT',
    payload,
  };
};

export const counterClick = () => {
  return {
    type: 'INCREMENT_COUNTER',
  };
};

export const fetchSteps = (payload) => {
  return {
    type: 'ALL_STEPS',
    payload,
  };
};

export const fetchMessagesOfGame = (payload) => {
  return {
    type: 'ALL_MESSAGES_OF_GAME',
    payload,
  };
};

export const changeCombinaisonTopDresser = (payload) => {
  return {
    type: 'TOP_DRESSER_COMBINAISON',
    payload,
  };
};

// MULTI VISIO PARTICIPANT
export const setMainStream = (stream) => {
  return {
    type: 'SET_MAIN_STREAM',
    payload: {
      mainStream: stream,
    },
  };
};

export const setUserVisio = (user) => {
  return {
    type: 'SET_USER_VISIO',
    payload: {
      currentUser: user,
    },
  };
};

export const addParticipant = (user) => {
  return {
    type: 'ADD_PARTICIPANT',
    payload: {
      newUser: user,
    },
  };
};

export const updateUserVisio = (user) => {
  return {
    type: 'UPDATE_USER_VISIO',
    payload: {
      currentUser: user,
    },
  };
};

export const updateParticipant = (user) => {
  return {
    type: 'UPDATE_PARTICIPANT',
    payload: {
      newUser: user,
    },
  };
};

export const removeParticipant = (userId) => {
  return {
    type: 'REMOVE_PARTICIPANT',
    payload: {
      id: userId,
    },
  };
};

export const updateUser = (userDescription) => {
  return {
    type: 'UPDATE_USER',
    payload: userDescription,
  };
};

export const retrieveCurrentQuestion = (questionId) => {
  return {
    type: 'CURRENT_QUESTION',
    payload: questionId,
  };
};

export const addResponsesUserVisio = (payload) => {
  return {
    type: 'ADD_RESPONSES_USER_VISIO',
    payload,
  };
};

export const updateStatusQuestionnaryOfUser = (payload) => {
  return {
    type: 'UPDATE_STATUS_QUESTIONNARY_OF_USER',
    payload,
  };
};

export const initMainUser = (payload) => {
  return {
    type: 'INIT_MAIN_USER',
    payload,
  };
};

export default fetchMessage;
