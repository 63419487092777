import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { putSessionsHasRoom } from 'API/sessionsHasRoom';

// Components
import NarratorTextHeader from 'components/GameBoard/Emotion/Solo/Enigma/NarratorTextHeader';
import BaseAnimation from 'assets/css/BaseAnimation';
import TranslateAndHideKeyframe from 'assets/css/TranslateAndHideKeyframe';
import FooterPanel from 'components/GameBoard/Emotion/Solo/Enigma/FooterPanel';
import PlutchikSidePanel from 'components/GameBoard/Emotion/Solo/Enigma/PlutchikSidePanel';

// Constants
import {
  stimulusUrl,
  enigmas,
  headerColor,
} from 'components/GameBoard/Emotion/Solo/constants';
import { startedMessage } from 'components/constants';

// Reducer functions
import {
  selectEmotion,
  selectEmotionGenerationEnigma,
  selectStimulusAnswer,
} from 'components/Reducers/emotion';
import { selectSteps } from 'components/Reducers/Steps/Steps';
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';

// CSS
import styles from 'components/GameBoard/Emotion/Solo/Enigma/EmotionGeneration.module.scss';

// CSS in JS
export const StimulusStyle = styled(BaseAnimation)`
  animation-name: ${({ coordinates }) =>
    TranslateAndHideKeyframe(coordinates.startX, coordinates.endX)};
  // Hide the stimulus after each iteration
  visibility: ${({ playState }) => (playState === 'paused' ? 'hidden' : 'visible')};
  position: absolute;
  height: 60px;
  bottom: ${({ initialPositionY }) => initialPositionY}px;
  left: ${({ screenWidth, initialPositionX }) => screenWidth - initialPositionX}px;
`;

export const ConveyorBeltStyle = styled.img`
  visibility: ${({ playState }) => (playState === 'paused' ? 'hidden' : 'visible')};
  position: absolute;
  width: ${({ imgWidth }) => `calc(0.74 * ${imgWidth}px - 0.64 * ${imgWidth}px)`};
  bottom: ${({ initialPositionY }) => initialPositionY}px;
  left: ${({ screenWidth, initialPositionX }) => screenWidth - initialPositionX}px;
`;

const {
  initialStimulusTranslationX,
  initialStimulusTranslationY,
  finalStimulusTranslation,
  initialBeltTranslationX,
  initialBeltTranslationY,
  stimulusDuration,
  timing,
  fillMode,
  rightAnswers,
  generatedStimulus,
  footerStyle,
  conveyorBeltUrl,
} = enigmas.EmotionGeneration;

function EmotionGeneration() {
  const { t } = useTranslation('emotion');
  // Access the i18next object about the messages displayed at the top
  const messages = t('enigma2.rules.messages', {
    returnObjects: true,
  });
  const stimuli = t('enigma2.stimuli', {
    returnObjects: true,
  });

  const [animationCount, setAnimationCount] = useState(0);
  // Select data from the store
  const { backgroundDimensions, isBackgroundMoving } = useSelector(selectEmotion);
  const { idSessionHasRoom } = useSelector(infoGameUser);
  const {
    stimulusIndex,
    stimulusPlayState,
    shuffledStimuli,
    headerMessageStatus,
    clickedPlutchikEmotion,
  } = useSelector(selectEmotionGenerationEnigma);
  const answerState = useSelector((state) =>
    selectStimulusAnswer(state, stimulusIndex, 'EmotionGeneration')
  );
  const preventionMessage = useSelector(selectSteps).list[0];

  const narratorTextRef = useRef();

  // Define variables used for the translation of the stimulus object
  const screenWidth = window.innerWidth;
  const imgWidth = backgroundDimensions.width;
  const imgHeight = backgroundDimensions.height;
  const initialPositionX = (initialStimulusTranslationX / 100) * imgWidth;
  const initialBeltPositionX = (initialBeltTranslationX / 100) * imgWidth;
  const endPositionX = (finalStimulusTranslation / 100) * imgWidth;
  const initialPositionY = (initialStimulusTranslationY / 100) * imgHeight;
  const initialBeltPositionY = (initialBeltTranslationY / 100) * imgHeight;

  const dispatch = useDispatch();

  const wrongEmotiontext =
    'Oups... Il semblerait que cette émotion ne soit pas générée par ce type de stimulus.';

  // Dispatch the correct header message
  useEffect(() => {
    if (answerState === 'wrong') {
      if (narratorTextRef.current.innerText !== wrongEmotiontext) {
        dispatch({
          type: 'SET_HEADER_MESSAGE_STATUS',
          payload: {
            room: 'EmotionGeneration',
            headerMessage: 'wrong-emotion',
            headerColor: headerColor.wrong,
          },
        });
      } else {
        dispatch({
          type: 'SET_HEADER_MESSAGE_STATUS',
          payload: {
            room: 'EmotionGeneration',
            headerMessage: 'wrong-emotion-2',
            headerColor: headerColor.wrong,
          },
        });
      }
    } else if (
      answerState === 'right' &&
      stimulusIndex === rightAnswers.length - 1
    ) {
      dispatch({
        type: 'SET_HEADER_MESSAGE_STATUS',
        payload: {
          room: 'EmotionGeneration',
          headerMessage: 'no-more-stimulus',
          headerColor: headerColor.initial,
        },
      });
    } else if (stimulusPlayState === 'paused') {
      dispatch({
        type: 'SET_HEADER_MESSAGE_STATUS',
        payload: {
          room: 'EmotionGeneration',
          headerMessage: 'emotion-selection',
          headerColor: headerColor.initial,
        },
      });
    } else {
      dispatch({
        type: 'SET_HEADER_MESSAGE_STATUS',
        payload: {
          room: 'EmotionGeneration',
          headerMessage: 'stimulus-creation',
          headerColor: headerColor.right,
        },
      });
    }
  }, [
    answerState,
    dispatch,
    stimulusIndex,
    stimulusPlayState,
    clickedPlutchikEmotion,
  ]);
  /**
   * Behaviour when the button at the end of the enigma is clicked
   */
  const handleClick = async () => {
    dispatch({
      type: 'CLOSE_MODAL_ENIGMA_EMOTION',
    });
    // Save in DB to retrieve the prevention message in case of reload
    await putSessionsHasRoom(idSessionHasRoom, {
      current_step: preventionMessage.id,
      start_message: startedMessage,
    });
    // Launch the prevention message
    dispatch({
      type: 'CURRENT_STEP',
      payload: preventionMessage.id,
    });
    dispatch({
      type: 'START_MESSAGE_PREVENTION',
    });
  };

  const handleNextText = () => {
    if (answerState === 'right' && stimulusIndex !== rightAnswers.length - 1)
      dispatch({
        type: 'SET_STIMULUS_INDEX',
        payload: {
          room: 'EmotionGeneration',
          stimulusIndex: stimulusIndex + 1,
        },
      });
  };

  const getFooterContent = () => {
    const currentStimuli = JSON.parse(JSON.stringify(shuffledStimuli))[
      stimulusIndex
    ];
    if (stimulusPlayState === 'running') {
      currentStimuli.title = '';
      currentStimuli.description = '';
    }
    return currentStimuli;
  };

  const coordinates = {
    startX: '0px',
    endX: `${screenWidth - endPositionX - (screenWidth - initialPositionX)}px`,
  };

  return (
    <>
      <NarratorTextHeader
        message={messages[headerMessageStatus]}
        isButton={answerState === 'right' && stimulusIndex === stimuli.length - 1}
        handleClick={handleClick}
        narratorTextRef={narratorTextRef}
      />
      {!isBackgroundMoving && (
        <>
          <StimulusStyle
            alt={t('generatedStimulus')}
            src={stimulusUrl}
            screenWidth={screenWidth}
            initialPositionX={initialPositionX}
            initialPositionY={initialPositionY}
            coordinates={coordinates}
            duration={stimulusDuration}
            timingFunction={timing}
            iterationCount={stimuli.length}
            fillMode={fillMode}
            playState={stimulusPlayState}
            onAnimationIteration={() => {
              dispatch({
                type: 'SET_STIMULI_ANIMATION',
                payload: {
                  room: 'EmotionGeneration',
                  playState: 'paused',
                },
              });
              setAnimationCount((prev) => prev + 1);
            }}
            onAnimationEnd={() => {
              dispatch({
                type: 'SET_STIMULI_ANIMATION',
                payload: {
                  room: 'EmotionGeneration',
                  playState: 'paused',
                },
              });
            }}
          />

          <ConveyorBeltStyle
            src={conveyorBeltUrl}
            alt={t('conveyorBelt')}
            playState={stimulusPlayState}
            initialPositionX={initialBeltPositionX}
            initialPositionY={initialBeltPositionY}
            screenWidth={screenWidth}
            imgWidth={imgWidth}
          />
          {/* Display panels after the 1st stimulus iteration */}
          {animationCount >= 1 && (
            <div className={styles['enigma-container']}>
              <FooterPanel
                room="EmotionGeneration"
                imageUrl={generatedStimulus}
                imageAlt="generatedStimulus"
                handleNextText={handleNextText}
                content={getFooterContent()}
                style={footerStyle}
              />
              <PlutchikSidePanel room="EmotionGeneration" />
            </div>
          )}
        </>
      )}
    </>
  );
}

export default EmotionGeneration;
