import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// components
import Button from 'components/GameBoard/ReusableComponents/Actions/Button';

// styles
import styles from 'components/GameBoard/ReusableComponents/Multi/Shared/Questionnary/Type/Textarea/Textarea.module.scss';

function Textarea({ question, activeIndex, handleAnswer, handleReturn }) {
  const { t } = useTranslation('common');
  const [response, setResponse] = useState({
    id: null,
    weigthing: null,
    text: '',
  });
  const isNew = true;

  return (
    <div className={styles['content-textarea']}>
      <h4>{question.title}</h4>
      {question.image && <img src={question.image} alt="emotion react" />}
      <textarea
        className={styles.textarea}
        rows="5"
        cols="33"
        onChange={(e) => setResponse({ ...response, text: e.target.value })}
        placeholder="Champs requis"
        value={response.text}
      />
      <div>
        {activeIndex && (
          <Button
            buttonType="action"
            title={t('buttonFunction.return')}
            onClick={() => handleReturn()}
          />
        )}
        <Button
          onClick={() => handleAnswer(response, question, isNew)}
          title={t('buttonFunction.next')}
          buttonType="action"
        />
      </div>
    </div>
  );
}

Textarea.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    image: PropTypes.string,
    is_debriefing: PropTypes.number,
    level: PropTypes.number,
    maximum: PropTypes.string,
    minimum: PropTypes.string,
    response_type: PropTypes.string,
    responses: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        level: PropTypes.number,
        weighting: PropTypes.number,
      })
    ),
  }).isRequired,
  activeIndex: PropTypes.number.isRequired,
  handleAnswer: PropTypes.func.isRequired,
  handleReturn: PropTypes.func.isRequired,
};

export default Textarea;
