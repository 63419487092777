import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// components
import Card from 'components/GameBoard/ReusableComponents/Multi/Shared/Card/Card';

// scss
import styles from 'components/GameBoard/ReusableComponents/Multi/Participants/Participant/Participant.module.scss';
import MeetingFooter from 'components/GameBoard/ReusableComponents/Multi/MeetingFooter/MeetingFooter';

// constants
import { imagesFooter } from 'components/GameBoard/ReusableComponents/Multi/constants';

const Participant = ({
  currentIndex,
  currentParticipant,
  videoRef,
  showAvatar,
  currentUser,
}) => {
  const { t } = useTranslation('common');
  const newVideoRef = videoRef;

  const handleExtraContent = () => {
    let text;
    let classContent;
    if (currentParticipant.type === 'admin') {
      text = t('multi.visio.animator');
      classContent = styles['extra-content-animator'];
    }

    if (currentParticipant.isMainUser) {
      const contentCount = `${currentParticipant.counter} ${t('hud.count', {
        count: currentParticipant.counter,
      })}`;
      text = contentCount;
      classContent = styles['extra-content-current-user'];
    }

    return <div className={classContent}>{text}</div>;
  };

  if (currentParticipant) {
    return (
      <div
        className={`${styles.participant} ${styles[`${currentParticipant.type}`]}`}
      >
        <Card>
          <video
            muted={currentUser && true}
            ref={(el) =>
              currentUser
                ? (newVideoRef.current = el)
                : (newVideoRef.current[currentIndex] = el)
            }
            className={`${styles.video} ${
              currentParticipant.isMainUser ? styles.current : styles['not-current']
            }`}
            id={`participantVideo${currentIndex}`}
            autoPlay
          >
            <track kind="captions" />
          </video>
          {!currentParticipant.audio && (
            <img
              className={styles.muted}
              src={imagesFooter.microSlash}
              alt="Mute Audio"
            />
          )}
          {showAvatar && (
            <div
              style={{ background: currentParticipant.avatarColor }}
              className={styles.avatar}
            >
              {currentParticipant.name[0]}
            </div>
          )}
          {currentUser && <MeetingFooter />}
          <div className={styles.name}>
            {currentParticipant.name}
            {currentUser ? ` (${t('multi.visio.me')})` : ''}
          </div>
          {handleExtraContent()}
        </Card>
      </div>
    );
  }
  return null;
};

Participant.propTypes = {
  currentIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currentParticipant: PropTypes.shape({
    audio: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.objectOf(PropTypes.bool),
    ]),
    avatarColor: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    video: PropTypes.bool,
    type: PropTypes.string,
    isMainUser: PropTypes.bool,
    counter: PropTypes.number,
  }),
  videoRef: PropTypes.shape({
    current: PropTypes.oneOfType([
      PropTypes.instanceOf(HTMLVideoElement),
      PropTypes.instanceOf(Array),
    ]),
  }),
  showAvatar: PropTypes.bool,
  currentUser: PropTypes.bool,
};

Participant.defaultProps = {
  currentIndex: null,
  currentParticipant: null,
  videoRef: undefined,
  showAvatar: null,
  currentUser: null,
};

export default Participant;
