/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// components
import { selectModal } from 'components/Reducers/Modal/Modal';

// constants
import {
  spotlightBlue,
  spotlightGreen,
  spotlightRed,
  spotlightYellow,
  spotlight,
} from 'components/GameBoard/Emotion/Multi/constant';
import { remoteSpotBedroomEmotionMultiEnigma } from 'components/GameBoard/ReusableComponents/Objects/constants';

// selectors
import { selectObject } from 'components/Reducers/GameData/fetchObjects';

// scss
import styles from 'components/GameBoard/Emotion/Multi/Enigma/LightEnigma/LightEnigma.module.scss';

const LightEnigma = ({ clickOnGame }) => {
  const remoteSpotBedroomEmotionMultiEnigmaObject = useSelector((state) =>
    selectObject(state, remoteSpotBedroomEmotionMultiEnigma)
  );

  const { modalZoom } = useSelector(selectModal);

  const handleImage = () => {
    let urlImage;

    if (remoteSpotBedroomEmotionMultiEnigmaObject?.status === 'red') {
      urlImage = spotlightRed;
    } else if (remoteSpotBedroomEmotionMultiEnigmaObject?.status === 'blue') {
      urlImage = spotlightBlue;
    } else if (remoteSpotBedroomEmotionMultiEnigmaObject?.status === 'yellow') {
      urlImage = spotlightYellow;
    } else if (remoteSpotBedroomEmotionMultiEnigmaObject?.status === 'green') {
      urlImage = spotlightGreen;
    } else {
      urlImage = spotlight;
    }

    return urlImage;
  };

  return (
    <img
      onClick={(event) => {
        clickOnGame(event);
      }}
      className={`${modalZoom.isOpen ? styles.light : styles.lightBackground}`}
      src={handleImage()}
      alt="Light"
    />
  );
};

LightEnigma.propTypes = {
  clickOnGame: PropTypes.func,
};

LightEnigma.defaultProps = {
  clickOnGame: () => {},
};

export default LightEnigma;
