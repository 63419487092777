/* eslint-disable consistent-return */
/* eslint-disable func-names */
/* eslint-disable prefer-object-spread */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import confetti from 'canvas-confetti';

import Audio from '../../ReusableComponents/Actions/Audio';
import successSound from '../../../../sound/success-card.mp3';
import '../../../../assets/css/components/GameBoard/TMS/Enigma/LockerTwo.css';

const BASE_URL = process.env.REACT_APP_BASE_URL;

function LockerTwo({
  soundtrack,
  objectContent,
  allObjects,
  dispatch,
  idSessionHasRoom,
}) {
  const [success, setSuccess] = useState(false);
  useEffect(() => {
    const duration = 1.5 * 1000;
    const animationEnd = Date.now() + duration;
    const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

    function randomInRange(min, max) {
      return Math.random() * (max - min) + min;
    }
    if (success) {
      const interval = setInterval(function () {
        const timeLeft = animationEnd - Date.now();

        if (timeLeft <= 0) {
          return clearInterval(interval);
        }

        const particleCount = 500 * (timeLeft / duration);
        // since particles fall down, start a bit higher than random
        confetti(
          Object.assign({}, defaults, {
            particleCount,
            origin: { x: randomInRange(0.1, 0.4), y: Math.random() - 0.2 },
          })
        );
        confetti(
          Object.assign({}, defaults, {
            particleCount,
            origin: { x: randomInRange(0.6, 0.9), y: Math.random() - 0.2 },
          })
        );
      }, 250);
    }
  }, [success]);

  let clickedContent = objectContent.content.images.find(
    (contentImage) => contentImage.type === 'clicked'
  );

  const [inputsLock, setInputsLock] = useState([0, 0, 0]);

  const listLock = [
    {
      name: 'nothing',
      url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Fvestiaires%2Fobjects%2Fcasier%2FverrouL-vide.svg?alt=media&token=f14d3bfc-04c2-49c3-8173-5350db355f70',
    },
    {
      name: 'b',
      url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Fvestiaires%2Fobjects%2Fcasier2%2FverrouL-b.svg?alt=media&token=cce4e20a-87e9-43df-b171-ea67368dd0df',
    },
    {
      name: 'c',
      url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Fvestiaires%2Fobjects%2Fcasier2%2FverrouL-c.svg?alt=media&token=abed0099-d8d9-4a83-bff2-6f0813e3589e',
    },
    {
      name: 'j',
      url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Fvestiaires%2Fobjects%2Fcasier2%2FverrouL-j.svg?alt=media&token=a5d21d62-5391-4d28-b38e-22e31128db0c',
    },
  ];

  const usedObject = (collectObject, index) => {
    axios
      .put(`${BASE_URL}/api/sessionsHasRoomHasObject/${idSessionHasRoom}`, {
        object_id: collectObject.id,
        status: 'open',
      })
      .then(() => {
        dispatch({
          type: 'CLICKED_OBJECT',
          payload: {
            index,
            status: 'open',
            type: 'used',
          },
        });
        axios
          .put(
            `${process.env.REACT_APP_BASE_URL}/api/sessionsHasRoom/${idSessionHasRoom}/modifications`,
            {
              current_step: objectContent.content.id_message,
            }
          )
          .then(() => {
            dispatch({
              type: 'CURRENT_STEP',
              payload: objectContent.content.id_message,
            });
            dispatch({
              type: 'START_MESSAGE_PREVENTION',
            });
          });
      });
  };

  useEffect(() => {
    const goodCombinaison = [1, 3, 2];
    if (goodCombinaison.join('') === inputsLock.join('')) {
      setSuccess(true);
      const collectObjectLocker = allObjects.find(
        (collectContent) => collectContent.id === objectContent.content.id
      );
      const collectObjectLockerIndex = allObjects.findIndex(
        (collectContent) => collectContent.id === objectContent.content.id
      );
      setTimeout(() => {
        usedObject(collectObjectLocker, collectObjectLockerIndex);
        dispatch({ type: 'CLOSE_MODAL_ENIGMA' });
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputsLock]);

  const handleInputLock = (elementLock, index) => {
    const newInputsLock = [...inputsLock];

    if (elementLock === 3) {
      newInputsLock[index] = 1;
    } else {
      newInputsLock[index] = elementLock + 1;
    }

    setInputsLock(newInputsLock);
  };

  if (objectContent.content.status === 'open') {
    clickedContent = objectContent.content.images.find(
      (contentImage) => contentImage.type === 'open'
    );

    return (
      <div className="LockerTwo Modal_description">
        <div className="Modal_description-text">
          <p>{clickedContent.message}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="LockerTwo Modal_description">
      <Audio condition={success && soundtrack} sound={successSound} />
      <div className="Modal_description-text">
        <p>{clickedContent.message}</p>
      </div>
      <img
        className="LockerTwo_image"
        src={clickedContent.image}
        alt={clickedContent.name}
      />
      <div className="LockerTwo_buttons">
        {inputsLock.map((inputLock, index) => (
          <div className="LockerTwo_button">
            <div
              onClick={() => handleInputLock(inputLock, index)}
              className="LockerTwo_button"
              onKeyDown={() => {}}
              role="button"
              tabIndex={0}
            >
              <img src={listLock[inputLock].url} alt={listLock[inputLock].name} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  idSessionHasRoom: state.GameUsers.idSessionHasRoom,
  soundtrack: state.GameUsers.soundtrack,
  allObjects: state.Objects.AllObjects,
});

LockerTwo.propTypes = {
  allObjects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
  objectContent: PropTypes.shape({
    content: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      images: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string,
        })
      ),
      status: PropTypes.bool,
      id_message: PropTypes.number,
    }),
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  idSessionHasRoom: PropTypes.number.isRequired,
  soundtrack: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(LockerTwo);
