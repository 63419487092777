import { useState, useRef, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Selector functions
import { selectSteps } from 'components/Reducers/Steps/Steps';
import { selectRoom } from 'components/Reducers/Room';
import { selectSession } from 'components/Reducers/session';
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';
import { selectModal, selectModalEnigma } from 'components/Reducers/Modal/Modal';

// Components
import Hud from 'components/GameBoard/ReusableComponents/HUD/Hud';
import Intro from 'components/GameBoard/ReusableComponents/Intro';
import Joyride from 'react-joyride';
import ListObjects from 'components/GameBoard/ReusableComponents/Objects/ListObjects';
import Modal from 'components/GameBoard/ReusableComponents/Modal/Modal';
import ModalEnigma from 'components/GameBoard/ReusableComponents/Modal/ModalEnigma';
import RoomContainer from 'components/GameBoard/ReusableComponents/RoomContainer';
import Steps from 'components/GameBoard/ReusableComponents/Steps';

// Utils
import useInitGame from 'utils/useInitGame';
import { useStartMessageOrQuestionary, clickCount } from 'utils/utilityFunctions';
// Constant
import {
  frontDeskOffice,
  frontDeskSoundRoom,
  volume,
} from 'components/GameBoard/OffreSocle/constants';
import commonSteps from 'components/Joyride/constants/commonSteps';
import genericJoyrideCallback from 'components/Joyride/constants/genericJoyrideCallback';
import useWindowResize from 'utils/useWindowResize';
import Audio from 'components/GameBoard/ReusableComponents/Actions/Audio';
import ChatBot from 'components/GameBoard/ReusableComponents/ChatBot/ChatBot';
import RoomName from 'components/GameBoard/ReusableComponents/HUD/RoomName/RoomName';

const { secondBackgroundUrl, backgroundMaxWidth } = frontDeskOffice;

const FrontDeskOffice = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['common', 'offreSocle']);
  // Select data from the store
  const { currentRoomId, listRooms } = useSelector(selectRoom);
  const session = useSelector(selectSession);
  const modalIsOpen = useSelector(selectModal).modal.isOpen;
  const { isTutoFinish, currentStep, startQuestionnary, startMessage } =
    useSelector(selectSteps);
  const { gameStarted, idSessionHasRoom, music } = useSelector(infoGameUser);
  const modalEnigmaIsOpen = useSelector(selectModal).modalEnigma.isOpen;
  const currentRoom = Object.values(listRooms).find(
    (roomObject) => roomObject.id === currentRoomId
  );
  const { isOpen } = useSelector(selectModalEnigma);

  const [backgroundImageDimensions, setBackgroundImageDimensions] = useState({
    width: 0,
    height: 0,
  });
  // This ref is connected to the image background
  const backgroundImageRef = useRef();

  // Display the 2nd background when the modal enigma opens
  const backgroundUrl = modalEnigmaIsOpen ? secondBackgroundUrl : currentRoom.url;

  // Initiate the session, the room, the objects in the room and the prevention messages
  useInitGame(currentRoomId, session.id);

  // Save the prevention message/questionary step in database and store and launch it
  useStartMessageOrQuestionary(
    currentStep,
    idSessionHasRoom,
    startMessage,
    startQuestionnary
  );

  const handleResize = useCallback(() => {
    setBackgroundImageDimensions({
      width: backgroundImageRef.current.offsetWidth,
      height: backgroundImageRef.current.offsetHeight,
    });
  }, []);
  useWindowResize(backgroundImageRef, handleResize);

  const [tutorialSteps, setTutorialSteps] = useState({
    run: true,
    steps: commonSteps(
      t,
      `${t('room1', { ns: 'offreSocle' })}`,
      `de la ${t('narrator', { ns: 'offreSocle' })}`
    ),
    stepIndex: 0,
  });

  useEffect(() => {
    if (isOpen) {
      dispatch({ type: 'MUTE_MUSIC', payload: false });
    } else {
      dispatch({ type: 'MUTE_MUSIC', payload: true });
    }
  }, [dispatch, isOpen]);

  return (
    <RoomContainer
      id="container"
      imgDimensions={backgroundImageDimensions}
      isBackgroundFixed
      maxWidth={backgroundMaxWidth}
      initial={{ opacity: 0.5, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.7 }}
      exit={{ opacity: 0.5, scale: 0 }}
    >
      <Audio sound={frontDeskSoundRoom} loop muted={!music} volume={volume} />
      <header>
        <Steps />
        <Hud />
        <RoomName />
      </header>

      <img
        src={backgroundUrl}
        width="100%"
        height="100%"
        alt={t('frontDeskOffice', { ns: 'offreSocle' })}
        id="img-background"
        onLoad={(event) => {
          setBackgroundImageDimensions({
            width: event.target.width,
            height: event.target.height,
          });
        }}
        ref={backgroundImageRef}
        onClick={(event) => {
          clickCount(dispatch, event);
        }}
        onKeyDown={(event) => {
          clickCount(dispatch, event);
        }}
        role="presentation"
      />

      <ListObjects />
      <ChatBot />
      {!isTutoFinish && (
        <Joyride
          callback={(data) =>
            genericJoyrideCallback(
              data,
              session,
              dispatch,
              tutorialSteps,
              setTutorialSteps
            )
          }
          steps={tutorialSteps.steps}
          run={tutorialSteps.run}
          stepIndex={tutorialSteps.stepIndex}
          showSkipButton
          continuous
          disableCloseOnEsc
          disableOverlayClose
          hideBackButton
          hideCloseButton
          locale={{
            last: t('joyride.locale.last'),
            next: t('joyride.locale.next'),
            skip: t('joyride.locale.skip'),
          }}
          styles={{
            buttonNext: {
              backgroundColor: '#4ed199',
              border: '1px solid #48c48e',
              padding: '15px 25px',
              fontWeight: '700',
            },
            buttonSkip: {
              color: '#9fa2b4',
              fontWeight: '700',
            },
          }}
        />
      )}
      {!gameStarted && isTutoFinish && <Intro translationFileName="offreSocle" />}
      {modalIsOpen && <Modal />}
      {modalEnigmaIsOpen && <ModalEnigma isButtonHidden />}
    </RoomContainer>
  );
};

export default FrontDeskOffice;
