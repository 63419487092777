import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

// selector functions
import { selectSession } from 'components/Reducers/session';
import { selectInfoGame } from 'components/Reducers/game';
import { selectUser } from 'components/Reducers/user';
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';
import { retrieveCurrentQuestion } from 'components/Action';

// utils
import {
  linkUserSurveySession,
  linkAnswerBetweenQuestion,
  playSound,
} from 'utils/utilityFunctions';

// components
import Button from 'components/GameBoard/ReusableComponents//Actions/Button';
import Note from 'components/GameBoard/ReusableComponents/Questionnary/Note';
import {
  nextSound,
  previousSound,
} from 'components/GameBoard/ReusableComponents/constants';

const BASE_URL = process.env.REACT_APP_BASE_URL;

function QuestionnaryContent({
  dispatch,
  questionnary,
  currentQuestionId,
  userId,
  userSurveyId,
  currentStep,
  idSessionHasRoom,
  currentRoomId,
  answers,
  Room,
  listSteps,
}) {
  const { t } = useTranslation('common');

  const session = useSelector(selectSession);
  const game = useSelector(selectInfoGame);
  const { currentUser, participants } = useSelector(selectUser);
  const { socket } = useSelector(infoGameUser);

  const [index, setIndex] = useState(0);
  const previousFirstIndex = questionnary.list.length - 1;

  useEffect(() => {
    let idCurrentQuestion = null;
    if (index === 0) {
      const idFirstQuestion = questionnary.list[0].id;
      idCurrentQuestion = idFirstQuestion;
    } else {
      idCurrentQuestion = questionnary.list[index].id;
    }

    dispatch(retrieveCurrentQuestion(idCurrentQuestion));
  }, [dispatch, index, questionnary.list]);

  useEffect(() => {
    if (questionnary.survey.id) {
      linkUserSurveySession(userId, questionnary.survey.id, session.id, dispatch);
    }
  }, [dispatch, questionnary.survey.id, session.id, userId]);

  const finishMessage = () => {
    axios
      .put(`${BASE_URL}/api/sessionsHasRoomHasMessage/${idSessionHasRoom}`, {
        date: new Date().toISOString().slice(0, 19).replace('T', ' '),
        room_id: currentRoomId,
        message_id: currentStep.id,
        isFound: 1,
      })
      .then((response) => response.data)
      .then(() => {
        const room = session.id;
        const isPauseTimer = false;
        const responsesSocket = { room, currentStep, isPauseTimer };
        socket?.emit('send_close_modal', responsesSocket);
        socket?.emit('send_next_message', responsesSocket);
        socket?.emit('send_update_game_score', responsesSocket);
        socket?.emit('send_stop_questionnary', responsesSocket);
        socket?.emit('send_reset_answer', responsesSocket);
        socket?.emit('send_pause_timer', responsesSocket);
        socket?.emit('send_current_step', responsesSocket);

        dispatch({
          type: 'CLOSE_MODAL',
        });

        dispatch({
          type: 'NEXT_MESSAGE',
          payload: currentStep,
        });
        dispatch({
          type: 'UPDATE_GAME_SCORE',
          payload: 100,
        });
        dispatch({
          type: 'STOP_QUESTIONNARY',
        });
        dispatch({
          type: 'RESET_ANSWER',
        });
        dispatch({
          type: 'PAUSE_TIMER',
          payload: isPauseTimer,
        });
        dispatch({
          type: 'CURRENT_STEP',
        });

        const allStepsFound =
          listSteps.find((steps) => !steps.isFound) === undefined;
        const lastRoomSlug = Object.keys(Room.listRooms).length - 1;
        const lastRoomFinish =
          Room.listRooms[Object.keys(Room.listRooms)[lastRoomSlug]].isActive;

        if (allStepsFound && !lastRoomFinish) {
          const currentRoomIndex = Object.keys(Room.listRooms).findIndex(
            (roomElement) => Room.listRooms[roomElement].id === Room.currentRoomId
          );
          const currentRoomSlug = Object.keys(Room.listRooms)[currentRoomIndex];
          const nextRoomIndex = currentRoomIndex + 1;
          const nextRoomSlug = Object.keys(Room.listRooms)[nextRoomIndex];
          if (nextRoomSlug !== undefined) {
            axios
              .put(`${BASE_URL}/api/sessions/${session.id}/modifications`, {
                room_id: Room.listRooms[`${nextRoomSlug}`].id,
              })
              .then(() => {
                let responsesSocketTwo = {
                  room,
                  roomSlug: currentRoomSlug,
                  isActive: false,
                };
                socket?.emit('send_room_active', responsesSocketTwo);

                dispatch({
                  type: 'ROOM_ACTIVE',
                  payload: {
                    room: currentRoomSlug,
                    active: false,
                  },
                });
                responsesSocketTwo = {
                  room,
                  roomSlug: nextRoomSlug,
                  isActive: true,
                };
                socket?.emit('send_room_active', responsesSocketTwo);

                dispatch({
                  type: 'ROOM_ACTIVE',
                  payload: {
                    room: nextRoomSlug,
                    active: true,
                  },
                });
              });
          } else {
            const responses = {
              room,
              roomSlug: currentRoomSlug,
              isActive: true,
            };
            socket?.emit('send_room_active', responses);
            dispatch({
              type: 'ROOM_ACTIVE',
              payload: {
                room: currentRoomSlug,
                active: true,
              },
            });
          }
        }
      });
  };

  const handleAnswer = (responseId, responseContent) => {
    if (userSurveyId) {
      linkAnswerBetweenQuestion(
        responseContent,
        currentQuestionId,
        userSurveyId,
        dispatch
      );
    }
  };

  const { isMainUser, type } =
    Object.keys(participants).length && participants[Object.keys(currentUser)[0]];

  const handleButtonQuestionnary = () => {
    if (
      game.type === 'solo' ||
      (game.type === 'multi' && isMainUser) ||
      (game.type === 'multi' && type === 'admin')
    ) {
      return (
        <div>
          {game.type === 'multi' && <p>{t('multi.visio.waitFriend')}</p>}
          <Button
            onClick={() => finishMessage()}
            buttonType="action"
            title="Suivant"
          />
        </div>
      );
    }
    return null;
  };

  const nextStep = () => {
    let newIndex = 0;
    if (index !== previousFirstIndex) {
      newIndex = index + 1;
      playSound(nextSound);
    }
    setIndex(newIndex);
  };

  const prevStep = () => {
    let newIndex;
    if (index === 0) {
      newIndex = previousFirstIndex;
    } else {
      newIndex = index - 1;
    }
    playSound(previousSound);
    setIndex(newIndex);
  };

  const variants = {
    initial: {
      x: 200,
      opacity: 0,
    },
    animate: {
      x: 0,
      opacity: 1,
    },
    exit: {
      x: -200,
      opacity: 0,
    },
  };

  return (
    <div
      className={`QuestionnaryContent ${game.game_type === 'multi' && '--multi'}`}
    >
      {questionnary?.survey?.name}
      <div className="QuestionnaryContent_questions">
        <motion.div
          variants={variants}
          className="slides QuestionnaryContent_question"
          animate="animate"
          initial="initial"
          exit="exit"
          key={questionnary?.list[index].id}
        >
          <p>{questionnary?.list[index].title}</p>
          <Note
            index={index}
            handleAnswer={handleAnswer}
            currentQuestionId={questionnary?.list[index].id}
          />
        </motion.div>
      </div>
      <div className="QuestionnaryContent_questions-btns">
        {index !== 0 && (
          <Button onClick={prevStep} buttonType="return" title="Retour" />
        )}
        {questionnary.list.length - 1 === index
          ? handleButtonQuestionnary()
          : answers[currentQuestionId] && (
              <Button onClick={nextStep} buttonType="action" title="Suivant" />
            )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  questionnary: state.questionnary,
  currentQuestionId: state.questionnary.currentQuestionId,
  userId: state.GameUsers.userDescription.id,
  userSurveyId: state.questionnary.userHasSurveyId,
  currentStep: state.Steps.currentStep,
  idSessionHasRoom: state.GameUsers.idSessionHasRoom,
  currentRoomId: state.Room.currentRoomId,
  answers: state.questionnary.answers,
  Room: state.Room,
  listSteps: state.Steps.list,
});

QuestionnaryContent.propTypes = {
  currentQuestionId: PropTypes.number,
  questionnary: PropTypes.shape({
    list: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
      })
    ),
    survey: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    questions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        response: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
      })
    ),
  }).isRequired,
  userId: PropTypes.number.isRequired,
  userSurveyId: PropTypes.number,
  currentStep: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  currentRoomId: PropTypes.number.isRequired,
  answers: PropTypes.shape({}).isRequired,
  dispatch: PropTypes.func.isRequired,
  idSessionHasRoom: PropTypes.number.isRequired,
  Room: PropTypes.shape({
    listRooms: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object])),
    currentRoomId: PropTypes.number,
  }).isRequired,
  listSteps: PropTypes.arrayOf(
    PropTypes.shape({
      isFound: PropTypes.number,
    })
  ).isRequired,
};

QuestionnaryContent.defaultProps = {
  userSurveyId: null,
  currentQuestionId: null,
};

export default connect(mapStateToProps)(QuestionnaryContent);
