const initialState = {
  dataGame: {
    background: null,
    points: 0,
    moves: 0,
    isStarted: 0,
    count: 0,
  },
};

const Tde = (state = initialState, action) => {
  switch (action.type) {
    // TODO see if we used it
    case 'INIT_GAME_ROOM': {
      return {
        ...state,
        dataGame: {
          ...action.payload,
          background: null,
          points: 0,
          moves: 0,
        },
      };
    }
    case 'INTRO_IS_DONE': {
      return {
        ...state,
        dataGame: {
          ...state.dataGame,
          isStarted: 1,
        },
      };
    }
    default:
      return state;
  }
};

export default Tde;
