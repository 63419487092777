import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// components
import SurveyDebrief from 'components/GameBoard/ReusableComponents/Multi/Shared/Evaluation/SurveyDebrief/SurveyDebrief';
import StoryLine from 'components/GameBoard/ReusableComponents/Multi/Shared/Evaluation/StoryLine/StoryLine';
import EvaluationQuestionnary from 'components/GameBoard/ReusableComponents/Multi/Shared/Evaluation/EvaluationQuestionnary';

// selectors
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';

// scss
import styles from 'components/GameBoard/ReusableComponents/Multi/Shared/Evaluation/Evaluation.module.scss';

const Evaluation = ({ content, isPedagogy }) => {
  const [status, setStatus] = useState('evaluation');
  const dispatch = useDispatch();
  const { socket } = useSelector(infoGameUser);
  useEffect(() => {
    socket.on('receive_stop_pedagogy', () => {
      dispatch({
        type: 'STOP_PEDAGOGY',
      });
    });
  }, [dispatch, socket]);

  return (
    <div className={styles.evaluation}>
      <div className={styles.blur} />
      <div className={styles.container}>
        {status === 'evaluation' && (
          <EvaluationQuestionnary
            setStatus={setStatus}
            content={content}
            isPedagogy
          />
        )}
        {!isPedagogy && status === 'debrief' && (
          <SurveyDebrief setStatus={setStatus} />
        )}
        {!isPedagogy && status === 'situation' && <StoryLine />}
      </div>
    </div>
  );
};

Evaluation.propTypes = {
  content: PropTypes.string.isRequired,
  isPedagogy: PropTypes.bool.isRequired,
};

export default Evaluation;
