/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable consistent-return */
/* eslint-disable func-names */
/* eslint-disable prefer-object-spread */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';

import '../../../../assets/css/components/GameBoard/TMS/Enigma/DocumentHolder.css';
import Button from '../../ReusableComponents/Actions/Button';

const BASE_URL = process.env.REACT_APP_BASE_URL;

function DocumentHolder({ allObjects, dispatch, idSessionHasRoom }) {
  const idDocumentHolder = 209;
  const idFolderPatientOne = 210;
  const idFolderPatientTwo = 211;

  const folderPatientOne = allObjects.find(
    (objectElement) => objectElement.id === idFolderPatientOne
  );
  const folderPatientTwo = allObjects.find(
    (objectElement) => objectElement.id === idFolderPatientTwo
  );
  const documentHolder = allObjects.find(
    (objectElement) => objectElement.id === idDocumentHolder
  );

  const documentHolderIndex = allObjects.findIndex(
    (objectElement) => objectElement.id === idDocumentHolder
  );

  const [fields, setFields] = useState([
    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Fvestiaires%2Fobjects%2Fcasier%2Fportedoc%2Ffiche-claude.svg?alt=media&token=af1bfbc8-d63d-4e4f-aeee-088f84992c00',
    null,
    null,
  ]);

  const listPatient = ['Jeanne', 'Bernard', 'Claude'];
  const [firstOrderUser, setFirstOrderUser] = useState([null, null, null]);
  const [secondOrderUser, setSecondOrderUser] = useState([null, null, null]);
  const [switchTablet, setSwitchTablet] = useState(false);
  const [checkInputs, setCheckInputs] = useState(false);

  useEffect(() => {
    const newFields = [...fields];

    if (folderPatientOne.isChecked) {
      const imageFolder = folderPatientOne.images.find(
        (imageFolderPatientOne) => imageFolderPatientOne.type === 'inventory'
      );
      newFields[1] = imageFolder.image;
    }

    if (folderPatientTwo.isChecked) {
      const imageFolder = folderPatientTwo.images.find(
        (imageFolderPatientTwo) => imageFolderPatientTwo.type === 'inventory'
      );
      newFields[2] = imageFolder.image;
    }

    setFields(newFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFirstOrder = (e, index) => {
    const newFirstOder = [...firstOrderUser];
    newFirstOder[index] = e.target.value;
    setFirstOrderUser(newFirstOder);
  };

  const handleSecondOrder = (e, index) => {
    const newSecondOder = [...secondOrderUser];
    newSecondOder[index] = e.target.value;
    setSecondOrderUser(newSecondOder);
  };

  const youWin = () => {
    return (
      <div className="DocumentHolder-bloc">
        <p className="DocumentHolder-title">
          Votre fatigue
          <br />
          musclo-squelettique
        </p>
        <p className="DocumentHolder-titl2">
          Portage &lt; Position de travail &lt; Répétitivité
        </p>
        <br />
        <br />
        <p>Super, vous avez trouvé un code secret</p>
        <p className="DocumentHolder-title">BJC</p>
      </div>
    );
  };

  const checkedObject = (collectObject, index, isMessage) => {
    axios
      .put(`${BASE_URL}/api/sessionsHasRoomHasObject/${idSessionHasRoom}`, {
        object_id: collectObject.id,
        isChecked: 1,
      })
      .then(() => {
        dispatch({
          type: 'CLICKED_OBJECT',
          payload: {
            index,
            isChecked: 1,
            type: 'checked',
          },
        });
        if (!isMessage) {
          dispatch({
            type: 'START_MESSAGE_PREVENTION',
          });
        }
      });
  };
  const handleContentTablet = () => {
    const goodFirstOrder = ['Claude', 'Jeanne', 'Bernard'];
    const goodSecondOrder = ['Jeanne', 'Claude', 'Bernard'];

    if (switchTablet) {
      if (!folderPatientOne.isChecked && !folderPatientTwo.isChecked) {
        return (
          <p>
            Il s&apos;emblerait qu&apos;il manque des dossiers à l’intérieur. Et si
            vous réunissiez les dossiers avant d’utiliser le porte-document ?
          </p>
        );
      }
      if (checkInputs) {
        if (goodFirstOrder.join('') === firstOrderUser.join('')) {
          dispatch({
            type: 'UPDATE_LEVEL',
            payload: 'DocumentHolder1',
          });
        }
        if (
          goodFirstOrder.join('') === firstOrderUser.join('') &&
          goodSecondOrder.join('') === secondOrderUser.join('')
        ) {
          checkedObject(documentHolder, documentHolderIndex, 'not-message');

          return youWin();
        }
        return (
          <div className="DocumentHolder-bloc">
            <p className="DocumentHolder-title">
              Votre fatigue
              <br />
              musclo-squelettique
            </p>
            <p className="DocumentHolder-titl2">
              Portage &lt; Position de travail &lt; Répétitivité
            </p>
            <br />
            <br />
            <p>
              Mauvaise combinaison
              <Button
                buttonType="action"
                title="Réésayer"
                onClick={() => setCheckInputs(false)}
              />
            </p>
          </div>
        );
      }

      if (documentHolder.isChecked) {
        return youWin();
      }

      return (
        <div>
          <p className="DocumentHolder-title">
            Votre fatigue
            <br />
            musclo-squelettique
          </p>
          <p className="DocumentHolder-titl2">
            Portage &lt; Position de travail &lt; Répétitivité
          </p>
          <div className="DocumentHolder-bloc">
            <label htmlFor="select-patient-day" id="patientDay">
              Fatigue accumulé dans une journée
            </label>
            {firstOrderUser.map((firstOrder, index) => (
              <select
                name="patientDay"
                onChange={(e) => handleFirstOrder(e, index)}
              >
                <option value="">Choisir le patient</option>
                {listPatient.map((patient) => (
                  <option value={patient}>{patient}</option>
                ))}
              </select>
            ))}
          </div>
          <div className="DocumentHolder-bloc">
            <label htmlFor="select-patient-life" id="patientLife">
              Fatigue accumulé dans une carrière
            </label>
            {secondOrderUser.map((secondOrder, index) => (
              <select
                name="patientLife"
                onChange={(e) => handleSecondOrder(e, index)}
              >
                <option value="">Choisir le patient</option>
                {listPatient.map((patient) => (
                  <option value={patient}>{patient}</option>
                ))}
              </select>
            ))}
          </div>
          <Button
            buttonType="action"
            title="Valider"
            onClick={() => setCheckInputs(true)}
          />
        </div>
      );
    }
    return '';
  };

  const handleField = (field) => {
    if (field !== null) {
      return <img src={field} alt="fiche" />;
    }
    return null;
  };

  return (
    <div className="DocumentHolder Modal_description">
      <div className="DocumentHolder_background">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Fvestiaires%2Fobjects%2Fcasier%2Fportedoc%2Fportedoc-ouvert.svg?alt=media&token=ad6f1d86-d323-45e1-87da-1212cf733531"
          className="DocumentHolder_background-img"
          alt="Porte document"
        />
        <div className="DocumentHolder_background-left">
          <div className="DocumentHolder_background-left-field">
            {fields.map((field) => handleField(field))}
          </div>
        </div>
        <div className="DocumentHolder_background-right">
          <div
            className="DocumentHolder_background-right-tablet"
            style={{ backgroundColor: switchTablet ? '#E5E5E5' : '#3e3e43' }}
          >
            <div className="DocumentHolder_background-right-tablet-content">
              {handleContentTablet()}
            </div>
            <button
              className="DocumentHolder_background-right-tablet-switch"
              type="button"
              onClick={() => setSwitchTablet(!switchTablet)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  idSessionHasRoom: state.GameUsers.idSessionHasRoom,
  allObjects: state.Objects.AllObjects,
});

DocumentHolder.propTypes = {
  allObjects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
  dispatch: PropTypes.func.isRequired,
  idSessionHasRoom: PropTypes.number.isRequired,
};

export default connect(mapStateToProps)(DocumentHolder);
