/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import '../../../../assets/css/components/GameBoard/TDE/Enigma/DrawerDuck.css';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const idDuckObject = 142;
const idBatteryObject = 143;
const idDrawerObject = 141;
const idLetterObject = 145;

function DrawerDuck({
  image,
  objectContent,
  idSessionHasRoom,
  allObjects,
  dispatch,
}) {
  const addToInventory = (object, index) => {
    axios
      .put(`${BASE_URL}/api/sessionsHasRoomHasObject/${idSessionHasRoom}`, {
        object_id: object.id,
        isInventory: true,
      })
      .then(() => {
        dispatch({
          type: 'CLICKED_OBJECT',
          payload: {
            index,
            isInventory: true,
            type: 'inventory',
          },
        });
        if (object.id === idBatteryObject) {
          const indexDuck = allObjects.findIndex(
            (objectElement) => objectElement.id === idDuckObject
          );
          axios
            .put(`${BASE_URL}/api/sessionsHasRoomHasObject/${idSessionHasRoom}`, {
              object_id: idDuckObject,
              isChecked: 1,
            })
            .then(() =>
              dispatch({
                type: 'CLICKED_OBJECT',
                payload: {
                  index: indexDuck,
                  isChecked: 1,
                  type: 'checked',
                },
              })
            );
        }
        dispatch({
          type: 'ADD_INVENTORY',
          payload: object,
        });
        dispatch({
          type: 'UPDATE_GAME_SCORE',
          payload: 100,
        });
        dispatch({
          type: 'CLOSE_MODAL',
        });
      });
  };

  const handleImage = (description) => {
    let imageDrawer;
    if (description && description.images) {
      const objectIndex = description.images.findIndex(
        (object) => object.type === 'clicked'
      );
      imageDrawer = description.images[objectIndex].image;
    }
    return imageDrawer;
  };

  const handleMessage = (description) => {
    let messageDrawer;
    if (description && description.images) {
      const objectIndex = description.images.findIndex(
        (object) => object.type === 'clicked'
      );
      messageDrawer = description.images[objectIndex].message;
    }
    return messageDrawer;
  };

  const changeStatusOfObject = (object, index) => {
    axios
      .put(`${BASE_URL}/api/sessionsHasRoomHasObject/${idSessionHasRoom}`, {
        object_id: object.id,
        isClicked: object.isClicked !== undefined ? object.isClicked + 1 : 1,
      })
      .then(() => {
        dispatch({
          type: 'CLICKED_OBJECT',
          payload: {
            index,
            isClicked: object.isClicked !== undefined ? object.isClicked + 1 : 1,
            type: 'clicked',
          },
        });
        const descriptionModal = {};
        descriptionModal.message = handleMessage(object);
        descriptionModal.image = handleImage(object);
        descriptionModal.type = 'inventory';

        if (!object.wrongobject) {
          const isDuckObject = object.id === idDuckObject;
          const isBatteryObject = object.id === idBatteryObject;
          const isLetterObject = object.id === idLetterObject;

          const openModalBattery = () => {
            const objectElement = allObjects.filter(
              (objectBattery) =>
                objectBattery.id === idBatteryObject && objectBattery
            );
            const indexBattery = allObjects.findIndex(
              (objectBattery) => objectBattery.id === idBatteryObject
            );
            changeStatusOfObject(objectElement[0], indexBattery);
          };

          if (isDuckObject) {
            descriptionModal.buttonDescription = {
              title: 'Récupérer les piles',
              onClick: () => openModalBattery(),
            };
          } else if (isBatteryObject) {
            descriptionModal.buttonDescription = {
              title: 'Inventaire',
              onClick: () => addToInventory(object, index),
            };
          } else if (isLetterObject) {
            descriptionModal.buttonDescription = {
              title: 'Inventaire',
              onClick: () => addToInventory(object, index),
            };
          }
        }

        dispatch({
          type: 'OPEN_MODAL',
          payload: descriptionModal,
        });
      });
  };

  const retrieveDuckOrLetter = () => {
    const objectDuck = allObjects.filter(
      (objectContentElement) =>
        objectContentElement.id === idDuckObject && objectContentElement
    );
    const indexDuck = allObjects.findIndex(
      (objectContentElement) => objectContentElement.id === idDuckObject
    );

    const objectLetter = allObjects.filter(
      (objectContentElement) =>
        objectContentElement.id === idLetterObject && objectContentElement
    );
    const indexLetter = allObjects.findIndex(
      (objectContentElement) => objectContentElement.id === idLetterObject
    );
    if (objectDuck[0].isChecked && objectLetter.isInventory) {
      return;
    }
    if (objectDuck[0].isChecked) {
      if (!objectLetter[0].isInventory) {
        changeStatusOfObject(objectLetter[0], indexLetter);
      }
    } else {
      changeStatusOfObject(objectDuck[0], indexDuck);
    }
  };

  const handleDrawerDuckStatus = () => {
    const objectDrawer = allObjects.filter(
      (objectContentElementTwo) =>
        objectContentElementTwo.id === idDrawerObject && objectContentElementTwo
    );
    const objectDuck = allObjects.filter(
      (objectContentElementTwo) =>
        objectContentElementTwo.id === idDuckObject && objectContentElementTwo
    );

    const objectLetter = allObjects.filter(
      (objectContentElementTwo) =>
        objectContentElementTwo.id === idLetterObject && objectContentElementTwo
    );
    let imageObjectDrawer;

    if (objectDuck[0].isChecked && !objectLetter[0].isInventory) {
      imageObjectDrawer = objectDrawer[0].images.filter(
        (imageContent) =>
          imageContent.type === 'checked' &&
          imageContent.slug === 'tiroir-without-duck' &&
          imageContent.image
      );

      return imageObjectDrawer[0].image;
    }
    if (objectLetter[0].isInventory && !objectDuck[0].isChecked) {
      imageObjectDrawer = objectDrawer[0].images.filter(
        (imageContent) =>
          imageContent.type === 'checked' &&
          imageContent.slug === 'tiroir-without-letter' &&
          imageContent.image
      );

      return imageObjectDrawer[0].image;
    }
    if (objectLetter[0].isInventory && objectDuck[0].isChecked) {
      imageObjectDrawer = objectDrawer[0].images.filter(
        (imageContent) => imageContent.type === 'empty' && imageContent.image
      );

      return imageObjectDrawer[0].image;
    }

    return image;
  };
  return (
    <div className="drawerDuck">
      <img
        className="drawerDuck-image"
        onClick={() => retrieveDuckOrLetter()}
        src={handleDrawerDuckStatus()}
        alt={objectContent.title}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  idSessionHasRoom: state.GameUsers.idSessionHasRoom,
  allObjects: state.Objects.AllObjects,
});

DrawerDuck.propTypes = {
  idSessionHasRoom: PropTypes.number.isRequired,
  allObjects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
  image: PropTypes.string.isRequired,
  objectContent: PropTypes.shape({
    title: PropTypes.string,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(DrawerDuck);
