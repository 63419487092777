import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CountUp from 'react-countup';
import Timer from 'components/GameBoard/ReusableComponents/HUD/Timer';
import { useTranslation } from 'react-i18next';

import 'assets/css/components/GameBoard/ReusableComponents/Hud/Inventory.css';

import * as images from '../../../../img/vaccination-solo/PuckLab/index';

function HudItem({ dispatch, title, text, image, gameData, Muted, Music, count }) {
  const { t } = useTranslation('common');

  switch (title) {
    case 'time':
      return (
        <div className="Hud-Item-Container">
          <div className={`Hud-Item-Text-${title}`}>
            {gameData.gameStarted === 1 ? <Timer /> : <p>00 : 00</p>}
          </div>
          <div className={`Hud-Item-Image-${title}`}>
            <div
              className={
                gameData.timerPause || gameData.gameStarted === 0
                  ? 'Hud-Item-time-stop'
                  : 'Hud-Item-time'
              }
            />
            <div className="Hud-Item-time-content" />
            <img
              src={images.clockEmpty}
              alt="clock-content"
              className="Hud-Item-Image-Content"
            />
          </div>
        </div>
      );
    case 'score':
      return (
        <div className="Hud-Item-Container">
          <div className={`Hud-Item-Text-${title}`}>
            <CountUp
              start={gameData.gameScore.past}
              end={gameData.gameScore.present}
              delay={0}
              duration={2}
              suffix={text}
            >
              {({ countUpRef }) => <p ref={countUpRef} />}
            </CountUp>
          </div>
          <img
            src={image}
            alt={text}
            className={
              gameData.scoreAnim
                ? `Hud-Item-Image-Anim-${title}`
                : `Hud-Item-Image-${title}`
            }
          />
        </div>
      );
    case 'sound':
      return (
        <div className={`Hud-Item-Container-${title}`}>
          <div
            className={`Hud-Item-Text-${title}`}
            onClick={() => dispatch({ type: 'MUTE_SOUNDTRACK', payload: !Muted })}
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
          >
            <img
              src={image}
              alt={text}
              title={Muted ? t('hud.soundOff') : t('hud.soundOn')}
              className={`Hud-Item-Image-${title}`}
            />
          </div>
        </div>
      );
    case 'music':
      return (
        <div className={`Hud-Item-Container-${title}`}>
          <div
            className={`Hud-Item-Text-${title}`}
            onClick={() => dispatch({ type: 'MUTE_MUSIC', payload: !Music })}
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
          >
            <img
              src={image}
              alt={text}
              title={Music ? t('hud.musicOff') : t('hud.musicOn')}
              className={`Hud-Item-Image-${title}`}
            />
          </div>
        </div>
      );

    default:
      return (
        <div className="Hud-Item-Container">
          <div className={`Hud-Item-Text-${title}`}>
            <p>
              {count} {text}
            </p>
          </div>
          <img src={image} alt={text} className={`Hud-Item-Image-${title}`} />
        </div>
      );
  }
}

HudItem.propTypes = {
  dispatch: PropTypes.func.isRequired,
  gameData: PropTypes.shape({
    gameScore: PropTypes.objectOf(PropTypes.number),
    scoreAnim: PropTypes.bool,
    timerPause: PropTypes.bool,
    gameStarted: PropTypes.number,
  }).isRequired,
  image: PropTypes.string.isRequired,
  text: PropTypes.string,
  title: PropTypes.string.isRequired,
  Muted: PropTypes.bool.isRequired,
  Music: PropTypes.bool.isRequired,
  count: PropTypes.number.isRequired,
};

HudItem.defaultProps = {
  text: undefined,
};

const mapStateToProps = (state) => ({
  gameData: state.GameUsers,
  count: state.GameUsers.count,
  Muted: state.GameUsers.soundtrack,
  Music: state.GameUsers.music,
});

export default connect(mapStateToProps)(HudItem);
