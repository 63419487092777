import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// components
import DrawerInputs from 'components/GameBoard/ReusableComponents/Enigma/Drawer/DrawerInputs';
import Books from 'components/GameBoard/Emotion/Multi/Enigma/DrawerTvStand/Books';

// styles
import styles from 'components/GameBoard/Emotion/Multi/Enigma/DrawerTvStand/DrawerTvStand.module.scss';

// constants
import { codeDrawerTvStand } from 'components/GameBoard/Emotion/Multi/constant';

// selectors
import { selectSession } from 'components/Reducers/session';
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';
import { selectModalEnigma } from 'components/Reducers/Modal/Modal';

// utils
import { animationConfetti, changeStatusOfObject } from 'utils/utilityFunctions';

const DrawerTvStand = () => {
  const { t } = useTranslation(['emotion-multi', 'common']);

  const [drawerStatus, setDrawerStatus] = useState('lock');

  const arrayCodeEmpty = Array(codeDrawerTvStand.length).fill('');
  const [code, setCode] = useState(arrayCodeEmpty);
  const [errorMessage, setErrorMessage] = useState(null);

  const session = useSelector(selectSession);
  const { socket, idSessionHasRoom } = useSelector(infoGameUser);
  const room = session.id;
  const { description } = useSelector(selectModalEnigma);
  const dispatch = useDispatch();

  useEffect(() => {
    if (description.content.status === 'open') {
      setDrawerStatus('open');
    }
    socket?.on('receive_error_messaging', (data) => {
      setErrorMessage(data.errorContent);
    });
    socket?.on('receive_emotion_multi_status_drawer', (data) => {
      setDrawerStatus(data.status);
    });
  }, [description.content.status, socket]);

  const submitCode = () => {
    if (code.join('').toLocaleLowerCase() === codeDrawerTvStand) {
      animationConfetti();

      const responsesSocket = {
        status: 'open',
        room,
      };
      socket?.emit('send_emotion_multi_status_drawer', responsesSocket);
      socket?.emit('send_confetti', responsesSocket);
      setDrawerStatus('open');
      changeStatusOfObject(
        description.content,
        'open',
        idSessionHasRoom,
        dispatch,
        socket,
        room
      );
    } else {
      const errorContent = t('tvStand.error');
      const responsesSocket = {
        errorContent,
        room,
      };
      socket?.emit('send_error_messaging', responsesSocket);
      setErrorMessage(errorContent);
    }
  };

  const handleLockDrawer = () => {
    return (
      <>
        <p>{t('tvStand.introduction')}</p>
        {errorMessage && <p>{errorMessage}</p>}
        <DrawerInputs
          handleGoodCode={codeDrawerTvStand}
          submitCode={submitCode}
          code={code}
          setCode={setCode}
          title={t('buttonFunction.open', { ns: 'common' })}
        />
      </>
    );
  };

  return (
    <div
      className={`${styles[`drawer-tv-stand`]} ${
        drawerStatus !== 'open' ? 'lock' : 'unlock'
      }`}
    >
      {drawerStatus !== 'open' ? handleLockDrawer() : <Books />}
    </div>
  );
};

export default DrawerTvStand;
