// scss
import styles from 'components/GameBoard/Emotion/Multi/Enigma/Crossword/CrosswordComponent.module.scss';
import CrosswordGame from './CrosswordGame';

const CrosswordComponent = () => {
  return (
    <div className={styles.crossword}>
      <div className={styles.content}>
        <CrosswordGame />
      </div>
    </div>
  );
};

export default CrosswordComponent;
