// rules scores
const scoreAddInventory = 100;

// PHONE
const phoneHour = '9:54';

// BUTTONS
const nextSound =
  'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FCommun%2Fsounds%2Fnext-message.mp3?alt=media&token=d69829ca-c241-4844-8b2a-96d4c6d0fdab';
const previousSound =
  'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FOffre%20Socle%2Fassets%2FRoom%202%2Fsounds%2Fswipe-132084.mp3?alt=media&token=806da4da-d335-4dec-a20c-f6378834d871';

const trophySound =
  'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FCommun%2Fsounds%2Fcard-win.mp3?alt=media&token=edc6bd56-55c9-4894-92e6-d7b3393f1de4';

export { scoreAddInventory, phoneHour, nextSound, previousSound, trophySound };
