import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// selectors
import { selectQuestionnary } from 'components/Reducers/questionnary';
import { selectSession } from 'components/Reducers/session';
import { selectUser } from 'components/Reducers/user';
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';

// actions
import {
  retrieveCurrentQuestion,
  addResponsesUserVisio,
  updateStatusQuestionnaryOfUser,
} from 'components/Action';

// utils
import {
  linkAnswerBetweenQuestion,
  linkUserSurveySession,
} from 'utils/utilityFunctions';

// components
import PlutchikWheelType from 'components/GameBoard/ReusableComponents/Multi/Shared/Questionnary/Type/PlutchikWheelType/PlutchikWheelType';
import Textarea from 'components/GameBoard/ReusableComponents/Multi/Shared/Questionnary/Type/Textarea/Textarea';
import Note from 'components/GameBoard/ReusableComponents/Multi/Shared/Questionnary/Type/Note/Note';

const Questionnary = ({ setIntroductionStatus, introductionStatus }) => {
  const dispatch = useDispatch();

  const { list, survey, userHasSurveyId } = useSelector(selectQuestionnary);
  const session = useSelector(selectSession);
  const { currentUser, id } = useSelector(selectUser);
  const { socket } = useSelector(infoGameUser);

  list.sort((firstQuestion, nextQuestion) => firstQuestion.id - nextQuestion.id);
  const [activeIndex, setActiveIndex] = useState(0);
  const room = session.id;
  const activeQuestionId = list[activeIndex].id;

  const { t } = useTranslation('common');

  useEffect(() => {
    if (activeQuestionId) {
      dispatch(retrieveCurrentQuestion(activeQuestionId));
    }
  }, [activeQuestionId, dispatch]);

  useEffect(() => {
    if (survey.id) {
      // init UserHasSurveyId (link between user and survey) The user need
      // this id to answer of the survey
      linkUserSurveySession(id, survey.id, session.id, dispatch);
    }
  }, [dispatch, id, session.id, survey.id]);

  const updateIndex = (index) => {
    let newIndex;
    if (index <= 0) {
      newIndex = 0;
    } else {
      newIndex = index;
    }
    setActiveIndex(newIndex);
  };

  const handleAnswer = (answer, question, isNew) => {
    const nextIndexQuestion = activeIndex + 1;

    // Next question
    if (nextIndexQuestion < list.length) {
      updateIndex(nextIndexQuestion);
    } else {
      setIntroductionStatus({ ...introductionStatus, status: 'debriefing' });
      // room element it's room of the socket to state it
      const responses = {
        currentUser,
        isFinish: true,
        room,
      };
      socket?.emit('send_finish_questionnary_user', responses);
      dispatch(updateStatusQuestionnaryOfUser(responses));
    }

    // link user, question, survey, response but if response don't exist we need to
    // create response and link between response and question
    linkAnswerBetweenQuestion(
      answer,
      question.id,
      userHasSurveyId,
      dispatch,
      isNew
    );

    // emit socket for the debriefing
    if (question.is_debriefing) {
      const responses = {
        userResponseOfQuestion: {
          answer,
        },
        currentUser,
        room,
      };
      socket?.emit('send_response_user_of_question', responses);
      dispatch(addResponsesUserVisio(responses));
    }
  };

  const handleReturn = () => {
    const lastIndexQuestion = activeIndex - 1;
    // Last question
    if (lastIndexQuestion >= 0) {
      updateIndex(lastIndexQuestion);
    }
  };

  const handleQuestions = () => {
    if (list) {
      switch (list[activeIndex].response_type) {
        case 'Plutchikwheel':
          return (
            <PlutchikWheelType
              question={list[activeIndex]}
              activeIndex={activeIndex}
              handleAnswer={handleAnswer}
              handleReturn={handleReturn}
            />
          );
        case 'Textarea':
          return (
            <Textarea
              question={list[activeIndex]}
              activeIndex={activeIndex}
              handleAnswer={handleAnswer}
              handleReturn={handleReturn}
            />
          );
        case 'Note':
          return (
            <Note
              question={list[activeIndex]}
              activeIndex={activeIndex}
              handleAnswer={handleAnswer}
              handleReturn={handleReturn}
            />
          );
        default:
          return <div>{t('error.empty')}</div>;
      }
    } else {
      return null;
    }
  };

  return handleQuestions();
};

Questionnary.propTypes = {
  setIntroductionStatus: PropTypes.func.isRequired,
  introductionStatus: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
};

export default Questionnary;
