import { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
// Components
import Button from 'components/GameBoard/ReusableComponents/Actions/Button';
// Selector functions
import {
  selectEmotion,
  selectComplexSituationEnigma,
} from 'components/Reducers/emotion';
// Constants
import { enigmas } from 'components/GameBoard/Emotion/Solo/constants';

// CSS in JS
const HeaderStyle = styled.div`
  position: absolute;
  top: 4px;
  left: 4px;
  width: 42%;
  height: 145px;
  padding: 0 0.5rem;
  border: 5px solid transparent;
  background: linear-gradient(
        80.62deg,
        ${({ bgGradient1 }) => bgGradient1} 3.36%,
        ${({ bgGradient2 }) => bgGradient2} 98.14%
      )
      padding-box,
    linear-gradient(180deg, #fff0bd 0%, #ebae9d 100%) border-box;

  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.4);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  img {
    max-width: 100%;
  }
`;
const TextStyle = styled.p`
  ref: ${({ ref }) => ref};
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  margin: 0.5rem;
  text-align: center;
  color: ${({ fontColor }) => fontColor};

  @media (min-width: 1800px) {
    font-size: 1.25rem;
    line-height: 1.5;
  }

  @media (min-width: 1940px) {
    font-size: 1.5rem;
    line-height: 1.25;
  }
`;

const { energyBarUrl, energyBarContainerStyle, energyBarStyle } =
  enigmas.ComplexSituation;

const EnergyBarContainerStyle = styled.div`
  position: absolute;
  top: ${({ panelHeight, panelTop }) =>
    energyBarContainerStyle.heightRatio * (panelHeight + panelTop)}px;
  width: ${energyBarContainerStyle.width}px;
  left: ${({ panelWidth, panelLeft }) =>
    (panelWidth + panelLeft - energyBarContainerStyle.width) / 2}px;

  div {
    position: relative;
    top: -39px;
    left: 48px;
    height: 20px;
    width: ${({ energyPercent }) =>
      (energyPercent / 100) *
      (energyBarStyle.widthRatio * energyBarContainerStyle.width)}px;
    color: white;
    background: linear-gradient(94.53deg, #417D82 21.36%, #85B19F 62.94%, #98C0A7 74.67%);
    display: flex;
    align-items: center;
    justify-content: center;
    // Design a left and right arrow shape
    clip-path: polygon(
      3px 0,
      9% 0%,
      100% 0%,
      calc(100% - 0.25vw) 0%,
      100% 49%,
      calc(100% - 0.25vw) 100%,
      100% 100%,
      4px 100%,
      3px 100%,
      0% 50%
    );
`;

/**
 * Header part with the narrator text
 * */
function NarratorTextHeader({
  message,
  isButton,
  buttonTitle,
  buttonType,
  handleClick,
  namespace,
  energyBar,
  narratorTextRef,
}) {
  const { headerMessageColor } = useSelector(selectEmotion);
  const { t } = useTranslation(namespace);
  const { energyPercentage } = useSelector(selectComplexSituationEnigma);

  const panelImgRef = useRef();

  return (
    <>
      <HeaderStyle
        bgGradient1={headerMessageColor.backgroundGradient1}
        bgGradient2={headerMessageColor.backgroundGradient2}
        ref={panelImgRef}
      >
        <TextStyle ref={narratorTextRef} fontColor={headerMessageColor.fontColor}>
          {message}
        </TextStyle>
        {isButton && (
          <Button
            onClick={handleClick}
            buttonType={buttonType}
            additionalStyle={{
              marginTop: '0.5rem',
              fontSize: '18px',
              fontWeight: '600',
              height: '35px',
              width: '113px',
            }}
            title={t(`buttonFunction.${buttonTitle}`)}
          />
        )}
      </HeaderStyle>
      {energyBar && (
        <EnergyBarContainerStyle
          panelHeight={panelImgRef?.current?.offsetHeight}
          panelWidth={panelImgRef?.current?.offsetWidth}
          panelTop={panelImgRef?.current?.offsetTop}
          panelLeft={panelImgRef?.current?.offsetLeft}
          energyPercent={energyPercentage >= 0 ? energyPercentage : 0}
        >
          <img
            alt={t('energyBar', { ns: 'emotion' })}
            src={energyBarUrl}
            width="100%"
            height="100%"
          />
          {energyPercentage > 0 && (
            // Hide the text % when the energy bar is too small
            <div>{energyPercentage > 10 && `${energyPercentage}%`}</div>
          )}
        </EnergyBarContainerStyle>
      )}
    </>
  );
}

NarratorTextHeader.propTypes = {
  message: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string,
  isButton: PropTypes.bool,
  buttonType: PropTypes.string,
  handleClick: PropTypes.func,
  namespace: PropTypes.arrayOf(PropTypes.string),
  energyBar: PropTypes.bool,
  narratorTextRef: PropTypes.shape({
    current: PropTypes.shape({
      innerText: PropTypes.string,
    }),
  }),
};

NarratorTextHeader.defaultProps = {
  buttonTitle: 'next',
  isButton: false,
  buttonType: 'emotion',
  handleClick: () => {},
  namespace: ['common', 'emotion'],
  energyBar: false,
  narratorTextRef: undefined,
};

export default NarratorTextHeader;
