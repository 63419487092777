import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// components
import DrawerInputs from 'components/GameBoard/ReusableComponents/Enigma/Drawer/DrawerInputs';
import Button from 'components/GameBoard/ReusableComponents/Actions/Button';

// selectors
import { selectSession } from 'components/Reducers/session';
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';
import { selectModalEnigma } from 'components/Reducers/Modal/Modal';
import {
  selectObject,
  selectObjectIndex,
} from 'components/Reducers/GameData/fetchObjects';
import { selectUser } from 'components/Reducers/user';

// constants
import { codeBox } from 'components/GameBoard/Emotion/Multi/constant';
import {
  pictureBedroomMultiEmotion,
  keyBedroomMultiEmotion,
  frameBedroomMultiEmotion,
} from 'components/GameBoard/ReusableComponents/Objects/constants';

// utils
import {
  addInventory,
  animationConfetti,
  changeStatusOfObject,
  checkedObject,
} from 'utils/utilityFunctions';

// scss
import styles from 'components/GameBoard/Emotion/Multi/Enigma/Table/Box/Box.module.scss';

const Box = () => {
  const { t } = useTranslation(['emotion-multi', 'common']);

  const [drawerStatus, setDrawerStatus] = useState('lock');

  const arrayCodeEmpty = Array(codeBox.length).fill('');
  const [code, setCode] = useState(arrayCodeEmpty);
  const [errorMessage, setErrorMessage] = useState(null);

  const session = useSelector(selectSession);
  const { socket, idSessionHasRoom } = useSelector(infoGameUser);
  const room = session.id;
  const { description } = useSelector(selectModalEnigma);
  const { participants } = useSelector(selectUser);
  const dispatch = useDispatch();

  const keyBedroomMultiEmotionObject = useSelector((state) =>
    selectObject(state, keyBedroomMultiEmotion)
  );
  const keyBedroomMultiEmotionIndex = useSelector((state) =>
    selectObjectIndex(state, keyBedroomMultiEmotion)
  );

  const pictureBedroomMultiEmotionObject = useSelector((state) =>
    selectObject(state, pictureBedroomMultiEmotion)
  );
  const pictureBedroomMultiEmotionIndex = useSelector((state) =>
    selectObjectIndex(state, pictureBedroomMultiEmotion)
  );

  const frameBedroomMultiEmotionObject = useSelector((state) =>
    selectObject(state, frameBedroomMultiEmotion)
  );
  const frameBedroomMultiEmotionIndex = useSelector((state) =>
    selectObjectIndex(state, frameBedroomMultiEmotion)
  );

  useEffect(() => {
    if (description.content.status === 'open') {
      setDrawerStatus('open');
    }
    socket?.on('receive_error_messaging', (data) => {
      setErrorMessage(data.errorContent);
    });
    socket?.on('receive_emotion_multi_status_drawer', (data) => {
      setDrawerStatus(data.status);
    });
  }, [description.content.status, socket]);

  const submitCode = () => {
    if (code.join('') === codeBox) {
      animationConfetti();

      const responsesSocket = {
        status: 'open',
        room,
      };
      socket?.emit('send_emotion_multi_status_drawer', responsesSocket);
      socket?.emit('send_confetti', responsesSocket);
      setDrawerStatus('open');
      changeStatusOfObject(
        description.content,
        'open',
        idSessionHasRoom,
        dispatch,
        socket,
        room
      );
    } else {
      const errorContent = t('box.error');
      const responsesSocket = {
        errorContent,
        room,
      };
      socket?.emit('send_error_messaging', responsesSocket);
      setErrorMessage(errorContent);
    }
  };

  const handleMessage = (descriptionContent) => {
    let message;
    if (descriptionContent?.images) {
      const objectIndex = descriptionContent.images.findIndex(
        (object) => object.type === 'clicked'
      );
      message = descriptionContent.images[objectIndex].message;
    }
    if (descriptionContent?.status === 'open') {
      const objectIndex = descriptionContent.images.findIndex(
        (object) => object.type === 'open'
      );
      message = descriptionContent.images[objectIndex].message;
    }

    return message;
  };

  const handleLockDrawer = () => {
    return (
      <>
        <p>{handleMessage(description.content)}</p>
        {errorMessage && <p>{errorMessage}</p>}
        <DrawerInputs
          handleGoodCode={codeBox}
          setCode={setCode}
          submitCode={submitCode}
          code={code}
          title={t('buttonFunction.open', { ns: 'common' })}
        />
      </>
    );
  };

  const handleImage = (descriptionContent) => {
    let imageDrawer;
    if (descriptionContent?.images) {
      const objectIndex = descriptionContent.images.findIndex(
        (object) => object.type === descriptionContent.type
      );
      imageDrawer = descriptionContent.images[objectIndex].image;
    }
    return imageDrawer;
  };

  const handleActionObject = (contentObject, index, typeAction, event) => {
    if (typeAction === 'used') {
      checkedObject(
        frameBedroomMultiEmotionObject,
        frameBedroomMultiEmotionIndex,
        idSessionHasRoom,
        dispatch,
        socket,
        room
      );
      const responsesSocket = { room };
      socket?.emit('send_start_message_prevention', responsesSocket);
      dispatch({
        type: 'START_MESSAGE_PREVENTION',
      });
      return checkedObject(
        contentObject,
        index,
        idSessionHasRoom,
        dispatch,
        socket,
        room
      );
    }
    return addInventory(
      dispatch,
      idSessionHasRoom,
      contentObject,
      index,
      event,
      socket,
      room,
      participants,
      session
    );
  };

  const handleObject = (content, index, typeAction) => {
    return (
      <div className={styles['object-content']}>
        <p>{handleMessage(content)}</p>
        <img src={handleImage(content)} alt={content.name} />
        <Button
          buttonType="action"
          onClick={(event) => handleActionObject(content, index, typeAction, event)}
          title={
            typeAction === 'used'
              ? t('box.action')
              : t('buttonFunction.addInventory', { ns: 'common' })
          }
        />
      </div>
    );
  };

  const handleObjects = () => {
    if (keyBedroomMultiEmotionObject.status !== 'open') {
      if (pictureBedroomMultiEmotionObject.isChecked) {
        return handleObject(
          keyBedroomMultiEmotionObject,
          keyBedroomMultiEmotionIndex
        );
      }
      return handleObject(
        pictureBedroomMultiEmotionObject,
        pictureBedroomMultiEmotionIndex,
        'used'
      );
    }
    return null;
  };

  return (
    <div className={styles.box}>
      {drawerStatus !== 'open' ? (
        handleLockDrawer()
      ) : (
        <div className={styles.contents}>{handleObjects()}</div>
      )}
    </div>
  );
};

export default Box;
