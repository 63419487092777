/* eslint-disable no-param-reassign */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
// components
import Audio from 'components/GameBoard/ReusableComponents/Actions/Audio';
import Inventory from 'components/GameBoard/ReusableComponents/Inventory';
import Modal from 'components/GameBoard/ReusableComponents/Modal/Modal';
import Steps from 'components/GameBoard/ReusableComponents/Steps';
import ListObjects from 'components/GameBoard/ReusableComponents/Objects/ListObjects';
import Zoom from 'components/GameBoard/ReusableComponents/Modal/Zoom';
import ModalZoom from 'components/GameBoard/ReusableComponents/Modal/ModalZoom';
import ModalRobot from 'components/GameBoard/ReusableComponents/Modal/ModalRobot';
import ModalEnigma from 'components/GameBoard/ReusableComponents/Modal/ModalEnigma';
import ObjectInInventory from 'components/GameBoard/ReusableComponents/Actions/ObjectInInventory';
import Hud from 'components/GameBoard/ReusableComponents/HUD/Hud';
import Scoreboard from 'components/GameBoard/ReusableComponents/Scoreboard/Scoreboard';
import ChatBot from 'components/GameBoard/ReusableComponents/ChatBot/ChatBot';
import ReactJoyride from 'react-joyride';
import Chevrons from 'components/GameBoard/ReusableComponents/Actions/Chevrons';
import BackgroundStyle from 'components/GameBoard/ReusableComponents/BackgroundStyle';
import Intro from 'components/GameBoard/ReusableComponents/Intro';

// selector functions
import { selectSession } from 'components/Reducers/session';

// css
import 'components/GameBoard/GameBoard.css';
import 'assets/css/components/GameBoard/Obesity/Rooms/KitchenLivingRoom.scss';

// sound
import click from 'sound/click-ongame.mp3';
import obesityTheme from 'sound/Obesity/john meyer for the lonely2.ogg';

// utils
import { useStartMessageOrQuestionary } from 'utils/utilityFunctions';
import useInitGame from 'utils/useInitGame';
import stepsObesity from 'components/Joyride/StepsObesity';
import genericJoyrideCallback from 'components/Joyride/constants/genericJoyrideCallback';
import backgroundMovingCallBackConditions from 'components/Joyride/constants/backgroundMovingCallBackConditions';

const BASE_URL = process.env.REACT_APP_BASE_URL;

function KitchenLivingRoom({
  game,
  GameUsers,
  modalIsOpen,
  currentRoom,
  MouseClicked,
  dispatch,
  startMessage,
  currentStep,
  startQuestionnary,
  idSessionHasRoom,
  zoomIsOpen,
  modalZoomIsOpen,
  modalRobotIsOpen,
  modalEnigmaIsOpen,
  isScoreBoard,
  isTutoFinish,
  listRooms,
  isStarted,
}) {
  const [backgroundPosition, setBackgroundPosition] = useState('center');
  const { t } = useTranslation(['common', 'obesity']);
  const [tutorialSteps, setTutorialSteps] = useState({
    run: GameUsers.gameTour,
    steps: stepsObesity(t),
    stepIndex: 0,
  });

  const session = useSelector(selectSession);

  useInitGame(currentRoom, session.id);

  // COUNTER
  useEffect(() => {
    if (idSessionHasRoom) {
      axios.put(
        `${BASE_URL}/api/sessionsHasRoom/${idSessionHasRoom}/modifications`,
        {
          count: GameUsers.count,
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GameUsers.count]);

  useStartMessageOrQuestionary(
    currentStep,
    idSessionHasRoom,
    startMessage,
    startQuestionnary
  );

  const clickOnGame = () => {
    dispatch({ type: 'INCREMENT_COUNTER' });
  };

  useEffect(() => {
    dispatch({
      type: 'CLICKED_MOUSE_DOWN',
    });
    setTimeout(() => {
      dispatch({
        type: 'CLICKED_MOUSE_UP',
      });
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GameUsers.count]);

  const updateMousePosition = (ev) => {
    dispatch({
      type: 'PLACE_CURSOR',
      payload: {
        x: ev.clientX,
        y: ev.clientY,
      },
    });
  };

  useEffect(() => {
    document
      .getElementById('KitchenLivingRoom-BKG')
      .addEventListener('mouseup', updateMousePosition);
    return () => window.removeEventListener('mouseup', updateMousePosition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const urlRoom = listRooms.KitchenLivingRoom.url;

  // Define variables used for the translation of the background
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;
  const imgElement = document.getElementById('img-background');
  /* Number of pixels needed to center a point of interest in the background */
  const imgCenter =
    (-8 / 3) * (50 * (screenHeight / 100)) + 50 * (screenWidth / 100);
  const imgCentered = `${imgCenter}px`;
  /* Number of pixels needed to reach the right end of the background */
  const imgRight = imgElement && `-${imgElement.width - screenWidth}px`;
  /* Number of pixels needed to reach the left end of the background */
  const imgLeft = '0px';

  // Array for background moving position in tutorial sent in Joyride callback
  const stepForBackground = [
    { id: 0, callfunction: () => setBackgroundPosition('left-from-center') },
    { id: 8, callfunction: () => setBackgroundPosition('right-from-center') },
    { id: 9, callfunction: () => setBackgroundPosition('left-from-center') },
  ];

  return (
    <div className="KitchenLivingRoom-img" id="KitchenLivingRoom-BKG">
      <Audio
        sound={obesityTheme}
        loop
        id="Obesity-Theme"
        muted={!GameUsers.music}
      />
      <Audio sound={click} condition={!MouseClicked && GameUsers.soundtrack} />
      <div
        className={MouseClicked ? 'cursor-clicked' : 'Hide'}
        style={{ top: GameUsers.cursor.y, left: GameUsers.cursor.x }}
      >
        <p>+1</p>
      </div>
      {modalIsOpen && <Modal game={game.id} />}
      {modalZoomIsOpen && <ModalZoom />}
      {modalRobotIsOpen && <ModalRobot />}
      {modalEnigmaIsOpen && <ModalEnigma />}
      {zoomIsOpen && <Zoom />}
      <Chevrons
        isTutoFinish={isTutoFinish}
        position={backgroundPosition}
        setPosition={setBackgroundPosition}
        modalEnigmaIsOpen={modalEnigmaIsOpen}
        hide={modalEnigmaIsOpen}
      />
      {!isTutoFinish && (
        <ReactJoyride
          callback={(data) =>
            genericJoyrideCallback(
              data,
              session,
              dispatch,
              tutorialSteps,
              setTutorialSteps,
              () => {
                backgroundMovingCallBackConditions(data, stepForBackground);
              }
            )
          }
          steps={tutorialSteps.steps}
          run={tutorialSteps.run}
          stepIndex={tutorialSteps.stepIndex}
          showSkipButton
          continuous
          disableCloseOnEsc
          disableOverlayClose
          hideBackButton
          hideCloseButton
          locale={{
            last: t('joyride.locale.last'),
            next: t('joyride.locale.next'),
            skip: t('joyride.locale.skip'),
          }}
          styles={{
            buttonNext: {
              backgroundColor: '#4ed199',
              border: '1px solid #48c48e',
              padding: '15px 25px',
              fontWeight: '700',
            },
            buttonSkip: {
              color: '#9fa2b4',
              fontWeight: '700',
            },
          }}
        />
      )}
      {!isStarted && isTutoFinish && (
        <Intro translationFileName="obesity" modalAfterIntro />
      )}
      {isScoreBoard && <Scoreboard />}
      <ObjectInInventory />
      {!isScoreBoard && (
        <header>
          <Inventory />
          <Steps />
          <Hud />
        </header>
      )}
      <BackgroundStyle
        position={backgroundPosition}
        start={imgCentered}
        right={imgRight}
        left={imgLeft}
        duration="1s"
        timing="ease"
        fillMode="forwards"
        className="KitchenLivingRoom-img-div"
        onClick={() => clickOnGame()}
        type="button"
      >
        <img
          src={urlRoom}
          height="100%"
          alt="Salle d'arcade"
          draggable="false"
          id="img-background"
        />
        <ListObjects />
      </BackgroundStyle>

      <ChatBot />
    </div>
  );
}

const mapStateToProps = (state) => ({
  game: state.game,
  modalIsOpen: state.Modal.modal.isOpen,
  isStarted: state.GameUsers.gameStarted,
  currentRoom: state.Room.currentRoomId,
  idSessionHasRoom: state.GameUsers.idSessionHasRoom,
  startMessage: state.Steps.startMessage,
  currentStep: state.Steps.currentStep,
  startQuestionnary: state.Steps.startQuestionnary,
  zoomIsOpen: state.Modal.zoom.isOpen,
  GameUsers: state.GameUsers,
  modalZoomIsOpen: state.Modal.modalZoom.isOpen,
  isAdvice: state.Steps.isAdvice,
  modalRobotIsOpen: state.Modal.modalRobot.isOpen,
  modalEnigmaIsOpen: state.Modal.modalEnigma.isOpen,
  MouseClicked: state.GameUsers.clicked,
  isScoreBoard: state.Steps.isScoreBoard,
  isTutoFinish: state.Steps.isTutoFinish,
  listRooms: state.Room.listRooms,
  questionnary: state.questionnary,
  steps: state.Steps.list,
  allObjects: state.Objects.AllObjects,
});

KitchenLivingRoom.propTypes = {
  MouseClicked: PropTypes.bool.isRequired,
  game: PropTypes.shape({
    photo: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
  GameUsers: PropTypes.shape({
    soundtrack: PropTypes.bool,
    music: PropTypes.bool,
    count: PropTypes.number,
    cursor: PropTypes.shape({
      x: PropTypes.string,
      y: PropTypes.string,
    }),
    gameTour: PropTypes.bool,
  }).isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  currentRoom: PropTypes.number.isRequired,
  idSessionHasRoom: PropTypes.number,
  startMessage: PropTypes.number.isRequired,
  currentStep: PropTypes.shape({
    id: PropTypes.number,
  }),
  startQuestionnary: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  zoomIsOpen: PropTypes.bool.isRequired,
  modalZoomIsOpen: PropTypes.bool.isRequired,
  modalRobotIsOpen: PropTypes.bool.isRequired,
  modalEnigmaIsOpen: PropTypes.bool.isRequired,
  isScoreBoard: PropTypes.bool.isRequired,
  isTutoFinish: PropTypes.number.isRequired,
  listRooms: PropTypes.shape({
    KitchenLivingRoom: PropTypes.shape({
      url: PropTypes.string,
    }),
  }).isRequired,
  isStarted: PropTypes.number.isRequired,
  questionnary: PropTypes.shape({
    list: PropTypes.shape({
      questions: PropTypes.arrayOf({
        id: PropTypes.number,
      }),
    }),
  }),
};

KitchenLivingRoom.defaultProps = {
  idSessionHasRoom: null,
  currentStep: null,
  questionnary: null,
};

export default connect(mapStateToProps)(KitchenLivingRoom);
