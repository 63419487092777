import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import confetti from 'canvas-confetti';

// API
import { sessionUpdate, getSessionDescription } from 'API/session';
import getGameDescription from 'API/games';
import { getUserDescription, verifyTokenUserSession } from 'API/user';
import { putSessionsHasRoomHasMessage } from 'API/sessionsHasRoomHasMessage';
import {
  getQuestions,
  getResponses,
  createResponse,
  createLinkBetweenUserSurveySession,
  createLinkResponseBetweenUserHasSurvey,
  getQuestionsDebrief,
  getSurvey,
} from 'API/questionnary';
import { putObjectInInventory } from 'API/objects';
import { putSessionsHasRoomHasObject } from 'API/sessionsHasRoomHasObject';
import { getMessagesOfGame } from 'API/messages';
import { putSessionsHasRoom } from 'API/sessionsHasRoom';

// actions
import {
  fetchMessagesOfGame,
  fetchRooms,
  initMainUser,
  retrieveCurrentQuestion,
} from 'components/Action';

// constants
import { robotObesity } from 'components/GameBoard/ReusableComponents/Objects/constants';
import { scoreAddInventory } from 'components/GameBoard/ReusableComponents/constants';
import { numberCounterMin } from 'components/GameBoard/ReusableComponents/Multi/constants';
import { startedMessage } from 'components/constants';

/**
 * Generate a random number between 2 limits
 * @param {number} min
 * @param {number} max
 * @returns number
 */
const getRandomNumber = (min, max) => {
  const minCeil = Math.ceil(min);
  const maxFloor = Math.floor(max);
  return Math.floor(Math.random() * (maxFloor - minCeil + 1)) + minCeil;
};

/**
 * Increment or reset an index
 * @param {number} index
 * @param {function} setIndex
 * @param {number} max - maximum value the index can take
 */
const incrementIndex = (index, max) => {
  let newIndex = index;
  if (index < max) {
    newIndex += 1;
  } else {
    newIndex = 0;
  }
  return newIndex;
};

/**
 * Calculate the points earned
 * @param {number} basePoints
 * @param {number} numberOfTries
 * @param {number} decreasePoints
 * @returns
 */
const calculatePoints = (basePoints, numberOfTries, decreasePoints) => {
  const calculatedPoints = basePoints - numberOfTries * decreasePoints;

  return calculatedPoints >= 0 ? calculatedPoints : 0;
};

/**
 * Shuffle an array. Not the best method because it is biased.
 * The sorting function is not meant to be used this way, so not all permutations have the same probability.
 * But it is good enough for now.
 * @param {[]} array
 */
const shuffleArray = (array) => {
  return array.sort(() => Math.random() - 0.5);
};

/**
 * Modify the background classname depending on the current position and which chevron was clicked
 * @param {string} chevron - clicked chevron
 * @param {string} position - current background position
 * @param {function} setPosition
 * @param {function} setButtonRight
 * @param {function} setButtonLeft
 */
const changeBackgroundClassName = (chevron, position, setPosition) => {
  switch (chevron) {
    case 'left-chevron':
      if (
        position === 'center' ||
        position === 'center-from-left' ||
        position === 'center-from-right'
      ) {
        setPosition('left-from-center');
      }
      if (position === 'right-from-center') {
        setPosition('center-from-right');
      }
      break;
    case 'right-chevron':
      if (
        position === 'center' ||
        position === 'center-from-left' ||
        position === 'center-from-right'
      ) {
        setPosition('right-from-center');
      }
      if (position === 'left-from-center') {
        setPosition('center-from-left');
      }
      break;
    default:
      break;
  }
};

/**
 * Construct the css value of `background-image`
 * @param {string[]|string } images
 * @returns {string}
 */
const handleBackgroundImages = (images) => {
  if (Array.isArray(images)) {
    let backgroundImages = '';
    images.forEach((image, index) =>
      index
        ? (backgroundImages += `,url(${image})`)
        : (backgroundImages += `url(${image})`)
    );
    return backgroundImages;
  }
  return `url(${images})`;
};

/**
 * Calculate the translation needed to center a point of interest in an image
 * @param {number} pointLocation - location of a point expressed as a % of the image width
 * @param {number} imageWidth - image width
 * @param {number} screenWidth - viewport width
 * @returns {number}
 */
const centerPoint = (pointLocation, imageWidth, screenWidth) => {
  return (pointLocation / 100) * imageWidth - screenWidth / 2;
};

/**
 *  Calculate the number of pixels needed to translate to the right end of an image
 * @param {number} imgWidth
 * @param {number} screenWidth
 * @returns {string}
 * */
const getRightCorner = (imgWidth, screenWidth) => {
  return `-${imgWidth - screenWidth}px`;
};

/**
 * Set the dimensions of an image on loading
 * @param {function} setState
 * @param {string} imageUrl
 */
const setOnLoadImageDimensions = (setState, imageUrl) => {
  const img = new Image();
  img.src = imageUrl;

  img.onload = () => {
    setState({
      width: img.width,
      height: img.height,
    });
  };
};

/**
 *
 * @param {Object} obj
 * @param {function} filter - condition of the filter
 * @returns {Object}
 */
const filterObject = (obj, filter) => {
  const objCopy = { ...obj };
  // Iterate the object
  Object.keys(objCopy).forEach((key) => {
    const val = objCopy[key];
    // Current val fails filter condition
    if (filter(val) === false) {
      delete objCopy[key];
    }
  });
  return objCopy;
};

// REDUX STORE + DATABASE

/**
 * Load content of one game when the user click on the button to start the game
 * @param {number} gameId
 * @param {number} userId
 * @param {number} sessionId
 * @param {function} dispatch
 */
const loadUserGameSession = async (gameId, userId, sessionId, token, dispatch) => {
  // Init user
  const dataUser = await getUserDescription(userId);
  dispatch({
    type: 'INIT_USER',
    payload: dataUser,
  });
  // REFACTO TO DELETE
  dispatch({
    type: 'SET_USER',
    payload: dataUser,
  });

  // Init game
  const dataGame = await getGameDescription(gameId);
  dispatch({ type: 'SET_INFOGAME', payload: dataGame });
  // REFACTO VACCINATION
  dispatch(fetchRooms(dataGame.rooms));
  // TO REFACTO
  // retrieve all messages pf one game it's hotfix for scoreboard
  const listMessagesOfGame = await getMessagesOfGame(gameId);
  dispatch(fetchMessagesOfGame(listMessagesOfGame));

  // Init session
  const dataSession = await getSessionDescription(sessionId);
  dispatch({
    type: 'START_GAME',
    payload: dataSession.isStarted,
  });
  dispatch({
    type: 'INIT_SESSION',
    payload: dataSession,
  });
  // REFACTO TO DELETE
  dispatch({
    type: 'INIT_GAME_ROOM',
    payload: dataSession,
  });
  const verifyToken = await verifyTokenUserSession(token);
  dispatch({
    type: 'USER_HAS_TOKEN',
    payload: true,
  });

  if (dataSession && dataGame && dataUser && verifyToken)
    dispatch({
      type: 'ALLOW_USER',
      payload: true,
    });
};

/**
 * Signal that the game started
 * @param {boolean} sessionId
 */
const beginGame = async (sessionId, dispatch, socket, room) => {
  const isStartedGame = 1;
  const isPauseTimer = false;
  sessionUpdate(sessionId, {
    isStarted: isStartedGame,
  });
  const responsesSocket = {
    isStartedGame,
    isPauseTimer,
    room,
  };
  socket?.emit('send_start_game', responsesSocket);
  socket?.emit('send_pause_timer', responsesSocket);
  dispatch({ type: 'START_GAME', payload: isStartedGame });
  dispatch({ type: 'PAUSE_TIMER', payload: isPauseTimer });
};

/**
 * Function that starts the emotion card step and end the prevention message step
 * @param {function} dispatch - redux store's dispatch function
 * @param {function} t - i18next translation function
 */
const handleNextStep = (dispatch, t, socket, room) => {
  let responsesSocket = { room };
  const descriptionModal = {};
  descriptionModal.buttonDescription = {
    title: t('buttonFunction.next'),
    onClick: () => {
      socket?.emit('send_start_questionnary', responsesSocket);
      socket?.emit('send_open_modal', responsesSocket);

      dispatch({
        type: 'START_QUESTIONNARY',
      });
    },
  };

  descriptionModal.type = 'emotionCard';
  responsesSocket = {
    ...responsesSocket,
    ...descriptionModal,
  };

  dispatch({
    type: 'OPEN_MODAL',
    payload: descriptionModal,
  });
  dispatch({
    type: 'END_MESSAGE_PREVENTION',
  });
  socket?.emit('send_end_message_prevention', responsesSocket);
};

/**
 * Launch the modal with the survey or the questionary
 * @param {function} dispatch - redux store's dispatch function
 * @param {function} t - i18next translation function
 * @param {string} type - type of modal
 * @param {number} room - session id
 * @param {Object} socket
 */
const openModal = (dispatch, t, type, room, socket) => {
  const descriptionModal = {};
  if (type === 'prevention')
    descriptionModal.buttonDescription = {
      onClick: () => {
        handleNextStep(dispatch, t, socket, room);
      },
    };
  descriptionModal.type = type;
  dispatch({
    type: 'OPEN_MODAL',
    payload: descriptionModal,
  });
};

/**
 * Personalized hook to save the prevention message/questionary step in database
 * and store and launch it
 * @param {Object} currentStep
 * @param {number} idSessionHasRoom
 * @param {number} startMessage
 * @param {number} startQuestionnary
 * @param {Object} session
 * @param {Object} socket
 */
const useStartMessageOrQuestionary = (
  currentStep,
  idSessionHasRoom,
  startMessage,
  startQuestionnary,
  session,
  socket
) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const room = session?.id;
  useEffect(() => {
    const saveAndLaunch = async () => {
      const currentDate = new Date().toISOString().slice(0, 19).replace('T', ' ');
      const currentStepId = currentStep.id;

      await putSessionsHasRoom(idSessionHasRoom, {
        current_step: currentStepId,
        start_message: 1,
      });
      // Prevention message
      if (startMessage === 1 && !startQuestionnary) {
        await putSessionsHasRoomHasMessage(idSessionHasRoom, {
          date: currentDate,
          startMessage: 1,
          message_id: currentStepId,
        });
        const responsesSocket = { dispatch, t, type: 'prevention', room };
        socket?.emit('send_open_modal_prevention', responsesSocket);
        openModal(dispatch, t, 'prevention', room, socket);
      }
      // Questionary
      if (startQuestionnary === 1) {
        await putSessionsHasRoomHasMessage(idSessionHasRoom, {
          date: currentDate,
          startQuestionnary: 1,
          message_id: currentStepId,
        });
        openModal(dispatch, t, 'questionnary', room, socket);
      }
    };

    if (currentStep) {
      saveAndLaunch();
    }
  }, [
    currentStep,
    dispatch,
    idSessionHasRoom,
    room,
    socket,
    startMessage,
    startQuestionnary,
    t,
  ]);
};

/**
 * Retrieve Questionnary with questions and responses of the message (alias survey)
 * @param {function} dispatch - redux store's dispatch function
 * @param {boolean} isStarted - when then questionnary begin after finish an enigma
 * @param {number} messageId
 */
const retrieveQuestionnary = async (messageId, dispatch, isStarted) => {
  const { questions, survey } = await getQuestions(messageId);
  questions
    .sort((firstQuestion, nextQuestion) => firstQuestion.level - nextQuestion.level)
    .map(async (question) => {
      const responses = await getResponses(question.id);
      const newQuestion = question;
      newQuestion.responses = responses;

      return question;
    });

  await dispatch({
    type: 'ADD_QUESTIONNARY',
    payload: survey,
  });

  await dispatch({
    type: 'ADD_QUESTION',
    payload: questions,
  });

  if (isStarted) {
    await dispatch(retrieveCurrentQuestion(questions[0].id));
  }
};

/**
 * Retrieve Questionnary Debrief with questions and responses
 * @param {boolean} isStarted - when then questionnary begin after finish an enigma
 * @param {number} questionId
 */
const retrieveQuestionnaryDebrief = async (questionId, dispatch) => {
  const data = await getQuestionsDebrief(questionId);
  data
    .sort((firstQuestion, nextQuestion) => firstQuestion.level - nextQuestion.level)
    .map(async (question) => {
      const responses = await getResponses(question.id);
      const newQuestion = question;
      newQuestion.responses = responses;

      return question;
    });

  await dispatch({
    type: 'CURRENT_QUESTION',
    payload: data[0].id,
  });

  await dispatch({
    type: 'ADD_QUESTION_DEBRIEF',
    payload: data,
  });
};

/**
 * Retrieve Questionnary with questions and responses of the message (alias survey)
 * @param {function} dispatch - redux store's dispatch function
 * @param {number} questionId
 * @param {number} userHasSurveyId - id of link between user and is survey
 * @param {boolean} isNew - if the question is type of textarea exemple we need
 * @param {function} socket
 * @param {number} room - id of session socket

 * to create a response in db responses
 */
const linkAnswerBetweenQuestion = async (
  answer,
  questionId,
  userHasSurveyId,
  dispatch,
  isNew
) => {
  let responseUser = answer;
  if (isNew) {
    const response = await createResponse(answer);
    responseUser = response;
  }

  await createLinkResponseBetweenUserHasSurvey(
    userHasSurveyId,
    responseUser,
    questionId,
    isNew
  );

  dispatch({
    type: 'ADD_ANSWER',
    payload: {
      question_id: questionId,
      response_id: Number(isNew ? responseUser.response_id : responseUser.id),
      weighting: responseUser.weighting,
      text: responseUser.text,
    },
  });
};

/**
 * Link an User, a survey and a session
 * @param {function} dispatch - redux store's dispatch function
 * @param {number} userId
 * @param {number} surveyId
 * @param {number} sessionId
 */
const linkUserSurveySession = async (userId, surveyId, sessionId, dispatch) => {
  const data = await createLinkBetweenUserSurveySession(
    userId,
    surveyId,
    sessionId
  );

  await dispatch({
    type: 'USER_HAS_SURVEY',
    payload: data.id,
  });
};

/**
 * Actions when tutorial ends
 * @param {Object} session
 * @param {number} session.id
 * @param {func} dispatch
 */
const endTutorial = async (session, dispatch) => {
  await sessionUpdate(session.id, { isTutoFinish: 1 });
  dispatch({ type: 'RESET_TUTO', payload: 1 });
};

/**
 * Retrieve Time complete hour / minute / second with seconds
 * @param {number} seconds
 * @returns {string} - time complete (H:M:S)
 */
const secondsToTime = (seconds) => {
  const hour = Math.floor(seconds / (60 * 60));

  const divisorForMinutes = seconds % (60 * 60);
  const minute = Math.floor(divisorForMinutes / 60);

  const divisorForSeconds = divisorForMinutes % 60;
  const second = Math.floor(divisorForSeconds);

  return `${hour ? `${hour}:` : ''}${
    minute ? `${minute}:${second}` : `${second}s`
  }`;
};

/**
 * pass to the next user if it's it turn
 * @param {Object} participants
 * @param {Object} responses
 * @param {function} socket
 * @param {function} dispatch
 * @param {number} mainUserId
 */
const passToTheNextUser = (
  participants,
  responses,
  socket,
  dispatch,
  mainUserId
) => {
  let newResponses = responses;
  const mainUserIndex = Object.keys(participants).findIndex(
    (participant) => participants[participant].isMainUser
  );

  let nextIndex = 0;
  if (mainUserIndex < Object.keys(participants).length - 1) {
    nextIndex = mainUserIndex + 1;
  }
  // the participant need to be a user and not a mediator
  const isIndexMediator =
    participants[`${Object.keys(participants)[nextIndex]}`].type === 'admin';

  if (isIndexMediator) {
    if (mainUserIndex < Object.keys(participants).length - 2) {
      nextIndex = mainUserIndex + 2;
    } else {
      const isIndexMediatorFirst =
        participants[`${Object.keys(participants)[0]}`].type === 'admin';
      if (isIndexMediatorFirst) {
        nextIndex = 1;
      } else {
        nextIndex = 0;
      }
    }
  }
  const nextMainUserId = Object.keys(participants)[nextIndex];
  setTimeout(() => {
    newResponses = {
      ...newResponses,
      nextMainUserId,
    };
    socket?.emit('send_change_main_user', newResponses);

    dispatch({
      type: 'CHANGE_MAIN_USER',
      payload: {
        mainUserId,
        nextMainUserId,
      },
    });
  }, [100]);
};

/**
 * Display the position of mouse
 * @param {function} dispatch
 * @param {Object} event
 */
const clickCount = (dispatch, event, socket, responsesSocket) => {
  dispatch({ type: 'INCREMENT_COUNTER' });
  socket?.emit('send_increment_counter', responsesSocket);

  const { clientX, clientY } = event;
  dispatch({
    type: 'PLACE_CURSOR',
    payload: {
      x: clientX,
      y: clientY,
    },
  });
  if (event?.target?.id === 'img-background') {
    dispatch({
      type: 'CLICKED_MOUSE_DOWN',
    });
    setTimeout(() => {
      dispatch({
        type: 'CLICKED_MOUSE_UP',
      });
    }, 500);
  }
};

/**
 * for multi game we need to attribute the click fot one main user
 * and deploy the click between player
 * @param {Object} participants
 * @param {function} socket
 * @param {function} dispatch
 * @param {Object} session
 */
const clickCountUser = (participants, socket, dispatch, session) => {
  if (socket) {
    const mainUserId = Object.keys(participants)?.find(
      (participant) => participants[participant].isMainUser
    );
    const nextCounter = participants[mainUserId].counter - 1;
    const room = session.id;
    const responses = {
      mainUserId,
      nextCounter,
      room,
    };
    const responsesSocket = { room, mainUserId, nextCounter };
    socket.emit('send_increment_counter_user', responsesSocket);
    dispatch({
      type: 'UPDATE_COUNT_USER',
      payload: {
        mainUserId,
        nextCounter,
      },
    });

    const isNextMainUser = participants[mainUserId].counter === numberCounterMin;
    if (isNextMainUser) {
      passToTheNextUser(participants, responses, socket, dispatch, mainUserId);
    }
  }
};

/** * When a message finish we need to put in db of the message is found
 * @param {number} idSessionHasRoom
 * @param {Object} objectElement - data of the current object
 * @param {Array}   allObjects
 * @param {func} dispatch
 * @param {function} socket
 * @param {Object} room
 */
const addInventory = async (
  dispatch,
  idSessionHasRoom,
  objectElement,
  index,
  event,
  socket,
  room,
  participants,
  session
) => {
  await putObjectInInventory(idSessionHasRoom, objectElement.id);
  const responses = {
    index,
    isInventory: true,
    type: 'inventory',
    objectElement,
    scoreAddInventory,
    room,
  };
  socket?.emit('send_close_modal', responses);
  socket?.emit('send_close_modal_enigma', responses);
  socket?.emit('send_clicked_object', responses);
  socket?.emit('send_add_inventory', responses);
  socket?.emit('send_update_game_score', responses);
  dispatch({
    type: 'CLOSE_MODAL',
  });
  dispatch({
    type: 'CLOSE_MODAL_ENIGMA',
  });
  dispatch({
    type: 'CLICKED_OBJECT',
    payload: {
      index,
      isInventory: true,
      type: 'inventory',
    },
  });
  dispatch({
    type: 'ADD_INVENTORY',
    payload: objectElement,
  });
  dispatch({ type: 'INCREMENT_COUNTER' });
  socket?.emit('send_increment_counter', responses);
  clickCountUser(participants, socket, dispatch, session);
  dispatch({
    type: 'UPDATE_GAME_SCORE',
    payload: scoreAddInventory,
  });
};

/**
 * When a message finish we need to put in db of the message is found
 * @param {number} idSessionHasRoom
 * @param {number} currentRoomId
 * @param {Object} currentStep - data of the current message
 * @param {Array} listSteps
 * @param {Array} rooms
 * @param {0bject} session - data of the session
 * @param {func} dispatch
 */
const finishMessage = async (
  idSessionHasRoom,
  currentRoomId,
  currentStep,
  listSteps,
  rooms,
  session,
  dispatch
) => {
  await putSessionsHasRoomHasMessage(idSessionHasRoom, {
    date: new Date().toISOString().slice(0, 19).replace('T', ' '),
    room_id: currentRoomId,
    message_id: currentStep.id,
    isFound: 1,
  });

  dispatch({
    type: 'CLOSE_MODAL',
  });

  dispatch({
    type: 'NEXT_MESSAGE',
    payload: currentStep,
  });
  dispatch({
    type: 'UPDATE_GAME_SCORE',
    payload: 100,
  });
  dispatch({
    type: 'STOP_QUESTIONNARY',
  });
  dispatch({
    type: 'RESET_ANSWER',
  });
  dispatch({
    type: 'PAUSE_TIMER',
    payload: false,
  });
  dispatch({
    type: 'CURRENT_STEP',
  });

  const allStepsFound = listSteps.find((steps) => !steps.isFound) === undefined;
  const lastRoomSlug = Object.keys(rooms).length - 1;
  const lastRoomFinish = rooms[Object.keys(rooms)[lastRoomSlug]].isActive;

  if (allStepsFound && !lastRoomFinish) {
    const currentRoomIndex = Object.keys(rooms).findIndex(
      (room) => rooms[room].id === rooms.currentRoomId
    );
    const currentRoomSlug = Object.keys(rooms)[currentRoomIndex];
    const nextRoomIndex = currentRoomIndex + 1;
    const nextRoomSlug = Object.keys(rooms)[nextRoomIndex];
    if (nextRoomSlug !== undefined) {
      await sessionUpdate(session.id, {
        room_id: rooms[`${nextRoomSlug}`].id,
      });

      dispatch({
        type: 'ROOM_ACTIVE',
        payload: {
          room: currentRoomSlug,
          active: false,
        },
      });
      dispatch({
        type: 'ROOM_ACTIVE',
        payload: {
          room: nextRoomSlug,
          active: true,
        },
      });
    } else {
      dispatch({
        type: 'ROOM_ACTIVE',
        payload: {
          room: currentRoomSlug,
          active: true,
        },
      });
    }
  }
};

/**
 * Change the status of an object like ('open', 'close', 'light')
 * @param {Object} object
 * @param {string} status
 * @param {number} idSessionHasRoom
 * @param {function} dispatch
 * @param {function} socket
 * @param {Object} room
 */
const changeStatusOfObject = async (
  object,
  status,
  idSessionHasRoom,
  dispatch,
  socket,
  room
) => {
  const responseUpdateObject = {
    id: object.id,
    status,
  };
  const responsesSocket = {
    ...responseUpdateObject,
    room,
  };
  socket?.emit('send_update_object', responsesSocket);
  dispatch({
    type: 'UPDATE_OBJECT',
    payload: {
      ...responseUpdateObject,
    },
  });
  await putSessionsHasRoomHasObject(idSessionHasRoom, {
    object_id: object.id,
    status,
  });
};

/**
 * Remove one object of the inventory
 * @param {Object} idObject
 * @param {number} idSessionHasRoom
 * @param {Array} allObjects
 * @param {function} dispatch
 */
const removeOfInventory = async (
  idSessionHasRoom,
  idObject,
  allObjects,
  dispatch
) => {
  await putSessionsHasRoomHasObject(idSessionHasRoom, {
    object_id: idObject,
    isChecked: true,
  });
  const objectIndex = allObjects.findIndex(
    (objectElement) => objectElement.id === idObject
  );
  dispatch({
    type: 'CLICKED_OBJECT',
    payload: {
      index: objectIndex,
      isChecked: true,
      type: 'checked',
    },
  });
  dispatch({
    type: 'REMOVE_OF_INVENTORY',
    payload: idObject,
  });
};

/**
 * Check the object when it was used, remove it of the inventory if it is there
 * and begin a message if we need a message prevent
 * @param {Object} objectElement
 * @param {number} idSessionHasRoom
 * @param {string} isMessage
 * @param {function} dispatch
 * @param {number} index
 * @param {function} socket
 * @param {Object} room
 * @param {string} isClosed
 */
const checkedObject = async (
  objectElement,
  index,
  idSessionHasRoom,
  dispatch,
  socket,
  room,
  isMessage,
  isClosed
) => {
  await putSessionsHasRoomHasObject(idSessionHasRoom, {
    object_id: objectElement.id,
    isChecked: 1,
  });

  let responsesSocket = {
    room,
  };
  const responsesClickedObject = {
    index,
    isChecked: 1,
    type: 'checked',
  };

  const idObjectToRemove = objectElement.id;

  responsesSocket = {
    ...responsesSocket,
    ...responsesClickedObject,
    idObjectToRemove,
  };
  socket?.emit('send_clicked_object', responsesSocket);

  if (!isClosed) {
    socket?.emit('send_close_modal_enigma', responsesSocket);
    dispatch({
      type: 'CLOSE_MODAL_ENIGMA',
    });
  }

  dispatch({
    type: 'CLICKED_OBJECT',
    payload: responsesClickedObject,
  });

  if (objectElement.id !== robotObesity) {
    socket?.emit('send_remove_of_inventory', responsesSocket);
    dispatch({
      type: 'REMOVE_OF_INVENTORY',
      payload: idObjectToRemove,
    });
  }
  if (!isMessage) {
    // Save in DB to retrieve the prevention message in case of reload
    await putSessionsHasRoom(idSessionHasRoom, {
      current_step: objectElement.id_message,
      start_message: startedMessage,
    });
    socket?.emit('send_start_message_prevention', responsesSocket);
    dispatch({
      type: 'START_MESSAGE_PREVENTION',
    });
  }
};

/**
 * init main user to begin round by round
 * @param {Object} participants
 * @param {Object} session
 * @param {function} socket
 * @param {function} dispatch
 */
const initRoundByRound = (participants, session, socket, dispatch) => {
  // init index first player
  if (participants) {
    const retrieveMainUserInitiate = Object.keys(participants).find(
      (participantId) => participants[participantId].isMainUser
    );

    if (retrieveMainUserInitiate === undefined) {
      let firstPlayerId = Object.keys(participants)[0];
      const isIndexMediator =
        participants[`${firstPlayerId}`] &&
        participants[`${firstPlayerId}`].type === 'admin';
      const nextIndex = 1;
      if (isIndexMediator) {
        firstPlayerId = Object.keys(participants)[nextIndex];
      }
      // Init round by round
      const room = session.id;
      const responses = {
        mainUserId: firstPlayerId,
        room,
      };
      socket?.emit('send_init_main_user', responses);
      dispatch(initMainUser(firstPlayerId));
    }
  }
};

/**
 * start animation confetti when the user win
 * TO REFACTO
 */
const animationConfetti = () => {
  const duration = 1.5 * 1000;
  const animationEnd = Date.now() + duration;
  const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 5 };

  function randomInRange(min, max) {
    return Math.random() * (max - min) + min;
  }
  const interval = setInterval(() => {
    const timeLeft = animationEnd - Date.now();

    if (timeLeft <= 0) {
      return clearInterval(interval);
    }

    const particleCount = 500 * (timeLeft / duration);
    // since particles fall down, start a bit higher than random
    confetti({
      ...defaults,
      particleCount,
      origin: { x: randomInRange(0.1, 0.4), y: Math.random() - 0.2 },
    });
    return confetti({
      ...defaults,
      particleCount,
      origin: { x: randomInRange(0.6, 0.9), y: Math.random() - 0.2 },
    });
  }, 250);
};

/**
 * for multi game we need to attribute the click fot one main user
 * and deploy the click between player
 * @param {Object} collectObject
 * @param {number} index
 * @param {number} idSessionHasRoom
 * @param {function} socket
 * @param {function} dispatch
 * @param {number} room
 */
const usedObject = async (
  collectObject,
  index,
  idSessionHasRoom,
  dispatch,
  socket,
  room
) => {
  const modification = {
    object_id: collectObject.id,
    status: 'open',
  };
  await putSessionsHasRoomHasObject(idSessionHasRoom, modification);
  const clickedContent = {
    index,
    status: 'open',
    type: 'used',
  };
  const responsesSocket = {
    ...clickedContent,
    room,
  };
  socket?.emit('send_clicked_object', responsesSocket);
  dispatch({
    type: 'CLICKED_OBJECT',
    payload: clickedContent,
  });
};

/**
 * Retrieve content of survey
 * @param {function} dispatch - redux store's dispatch function
 * @param {number} surveyId
 */
const retrieveSurvey = async (surveyId, dispatch) => {
  const data = await getSurvey(surveyId);
  dispatch({
    type: 'ADD_SURVEY',
    payload: data,
  });
};

/**
 * play sound
 * @param {number} urlSound
 */
const playSound = async (urlSound, volume) => {
  const sound = new Audio(urlSound);
  sound.volume = volume || 0.1;
  sound.play();
};

export {
  getRandomNumber,
  incrementIndex,
  calculatePoints,
  shuffleArray,
  getRightCorner,
  setOnLoadImageDimensions,
  filterObject,
  beginGame,
  handleBackgroundImages,
  changeBackgroundClassName,
  centerPoint,
  useStartMessageOrQuestionary,
  loadUserGameSession,
  retrieveQuestionnary,
  linkUserSurveySession,
  linkAnswerBetweenQuestion,
  endTutorial,
  secondsToTime,
  addInventory,
  finishMessage,
  changeStatusOfObject,
  removeOfInventory,
  checkedObject,
  clickCount,
  initRoundByRound,
  passToTheNextUser,
  clickCountUser,
  animationConfetti,
  usedObject,
  handleNextStep,
  retrieveQuestionnaryDebrief,
  retrieveSurvey,
  playSound,
};
