import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// api
import { putSessionsHasRoom } from 'API/sessionsHasRoom';

// selector functions
import { selectSession } from 'components/Reducers/session';
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';
import { selectRoom } from 'components/Reducers/Room';
import { selectInfoGame } from 'components/Reducers/game';

// components
import GameSolo from 'components/GameSolo';
import GameMulti from 'components/GameMulti';
import styles from 'components/Introduction/StartPage.module.scss';
import ErrorPage from 'components/errorPages/ErrorPage';

function Game() {
  const session = useSelector(selectSession);
  const gameUser = useSelector(infoGameUser);
  const rooms = useSelector(selectRoom);
  const game = useSelector(selectInfoGame);

  const startDate = new Date(session.date).toDateString();
  const endDate = new Date(session.end_date).toDateString();
  const startHour = session.debut_hour;
  const endHour = session.end_hour;
  const currentDate = new Date().toDateString();
  const currentHour = new Date().toLocaleTimeString();

  const { t } = useTranslation('common', {
    startDate,
    endDate,
    startHour,
    endHour,
  });
  const errorSessionExpired = t('error.sessionExpired');
  const errorSessionTime = t('error.sessionTime', {
    startDate,
    endDate,
    startHour,
    endHour,
  });
  const handleTextMessage = () => {
    if (currentDate > endDate && currentHour > endHour) {
      return errorSessionExpired;
    }
    if (currentDate < startDate && currentHour < startHour) {
      return errorSessionTime;
    }
    return null;
  };
  const error = handleTextMessage();

  useEffect(() => {
    if (gameUser.gameStarted) {
      putSessionsHasRoom(session.id, {
        count: gameUser.count,
      });
    }
    // case if we recharge the page we need to retrieve the last count of the game
  }, [gameUser.count, gameUser.gameStarted, session.id]);

  // REFACTO when we upgrade to redux toolkit we would have a variable with the state of the API call. Then if and if successful we would redirect to or .
  if (rooms.listRooms !== null) {
    if (game.type === 'solo') {
      return <GameSolo />;
    }
    if (game.type === 'multi') {
      if (error) {
        return (
          <div className={styles.background}>
            <ErrorPage errorMessage={errorSessionTime} />
          </div>
        );
      }
      return <GameMulti />;
    }
  }
}

export default Game;
