import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import confetti from 'canvas-confetti';

import Audio from '../../ReusableComponents/Actions/Audio';
import Button from '../../ReusableComponents/Actions/Button';
import successSound from '../../../../sound/success-card.mp3';
import '../../../../assets/css/components/GameBoard/TMS/Enigma/Board.css';

const BASE_URL = process.env.REACT_APP_BASE_URL;

function Board({
  objectContent,
  allObjects,
  dispatch,
  idSessionHasRoom,
  soundtrack,
}) {
  const [success, setSuccess] = useState(false);
  useEffect(() => {
    const duration = 1.5 * 1000;
    const animationEnd = Date.now() + duration;
    const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

    function randomInRange(min, max) {
      return Math.random() * (max - min) + min;
    }
    if (success) {
      const interval = setInterval(() => {
        const timeLeft = animationEnd - Date.now();

        if (timeLeft <= 0) {
          return clearInterval(interval);
        }

        const particleCount = 500 * (timeLeft / duration);
        // since particles fall down, start a bit higher than random
        confetti({
          ...defaults,
          particleCount,
          origin: { x: randomInRange(0.1, 0.4), y: Math.random() - 0.2 },
        });

        return confetti({
          ...defaults,
          particleCount,
          origin: { x: randomInRange(0.6, 0.9), y: Math.random() - 0.2 },
        });
      }, 250);
    }
  }, [success]);

  const [weight, setWeight] = useState([
    { name: 'nothing', listPatient: [], goodPatient: [], color: '#cecece' },
    {
      name: 'ponderal',
      listPatient: [],
      goodPatient: ['Emma', 'Doris'],
      color: '#FF866B',
    },
    {
      name: 'sub-ponderal',
      listPatient: [],
      goodPatient: ['Carles'],
      color: '#F9C660',
    },
    {
      name: 'no-ponderal',
      listPatient: [],
      goodPatient: ['Albert', 'Barbara'],
      color: '#2A9D8F',
    },
  ]);
  const [patients, setPatients] = useState([
    {
      name: 'Albert',
      description:
        'Je me déplace tout seul sans difficulté ! Enfin je cours pas aussi bien que quand j’avais 20 ans …',
      type: 'no-ponderal',
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Fvestiaires%2Fobjects%2Fboard%2Ftv-perso-albert.svg?alt=media&token=b7edf782-ec89-4d40-8142-0ddc9a2c8886',
      color: 0,
      showDetails: false,
    },
    {
      name: 'Barbara',
      description:
        'Je marche très bien, c’est juste que pour me lever je n’y arrive pas sans mes accoudoirs. J’en ai besoin pour prendre appuis.',
      type: 'no-ponderal',
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Fvestiaires%2Fobjects%2Fboard%2Ftv-perso-barbara.svg?alt=media&token=c4057dba-bfa6-483c-847f-fd2e9eea025a',
      color: 0,
      showDetails: false,
    },
    {
      name: 'Carles',
      type: 'sub-ponderal',
      description:
        "Vous savez moi, je peux aider un peu avec mes bras et mes épaules d'ancien gymnaste.. mais pour le reste faut pas y compter.",
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Fvestiaires%2Fobjects%2Fboard%2Ftv-perso-carles.svg?alt=media&token=3a94b851-266a-433a-bc9d-24b624fbfbf7',
      color: 0,
      showDetails: false,
    },
    {
      name: 'Doris',
      description:
        'Oulà… je m’en remets à vos bras, mon corps ne répond plus de rien, heureusement j’ai encore ma tête !',
      type: 'ponderal',
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Fvestiaires%2Fobjects%2Fboard%2Ftv-perso-doris.svg?alt=media&token=a87dbceb-e3d0-4d2b-9341-726e5be358dd',
      color: 0,
      showDetails: false,
    },
    {
      name: 'Emma',
      description: '…',
      type: 'ponderal',
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Fvestiaires%2Fobjects%2Fboard%2Ftv-perso-emma.svg?alt=media&token=2aece55a-743d-427e-b476-5aa6ab4815de',
      color: 0,
      showDetails: false,
    },
  ]);

  const [enigmaFinish, setEnigmaFinish] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleColor = (index) => {
    setErrorMessage('');

    const newPatients = [...patients];
    if (newPatients[index].color === 3) {
      newPatients[index].color = 1;
    } else {
      newPatients[index].color += 1;
    }
    setPatients(newPatients);
  };

  const handleSubmit = () => {
    patients.map((patient) => {
      const newWeight = [...weight];
      const newListPatient = [...newWeight[patient.color].listPatient];
      newListPatient.push(patient.name);
      newWeight[patient.color].listPatient = newListPatient;
      return setWeight(newWeight);
    });
    const errorPatient = [];

    weight
      .filter((_, index) => index !== 0)
      .map((weightContent) => {
        weightContent.listPatient.sort();
        weightContent.goodPatient.sort();
        if (
          weightContent.listPatient.join('') !== weightContent.goodPatient.join('')
        ) {
          return errorPatient.push(false);
        }
        return null;
      });

    const isError = errorPatient.includes(false);

    if (!isError) {
      setEnigmaFinish(true);
    } else {
      // eslint-disable-next-line array-callback-return
      weight.map((weightContent, index) => {
        const newWeight = [...weight];
        newWeight[index].listPatient = [];

        setWeight(newWeight);
      });
      setErrorMessage(
        "Vous n'avez pas la bonne combinaison, retentez votre chance !"
      );
      setEnigmaFinish(false);
    }
  };

  const checkedObject = (collectObject, index, isMessage) => {
    axios
      .put(`${BASE_URL}/api/sessionsHasRoomHasObject/${idSessionHasRoom}`, {
        object_id: collectObject.id,
        isChecked: 1,
      })
      .then(() => {
        dispatch({
          type: 'CLICKED_OBJECT',
          payload: {
            index,
            isChecked: 1,
            type: 'checked',
          },
        });
        dispatch({
          type: 'REMOVE_OF_INVENTORY',
          payload: collectObject.id,
        });
        if (!isMessage) {
          axios
            .put(
              `${process.env.REACT_APP_BASE_URL}/api/sessionsHasRoom/${idSessionHasRoom}/modifications`,
              {
                current_step: collectObject.content.id_message,
              }
            )
            .then(() => {
              dispatch({
                type: 'CURRENT_STEP',
                payload: collectObject.content.id_message,
              });
              dispatch({
                type: 'START_MESSAGE_PREVENTION',
              });
            });
        }
      });
  };

  useEffect(() => {
    if (enigmaFinish) {
      setSuccess(true);
      setTimeout(() => {
        const objectContentIndex = allObjects.findIndex(
          (allObjectContent) => allObjectContent.id === objectContent.content.id
        );
        checkedObject(objectContent, objectContentIndex);
        dispatch({ type: 'CLOSE_MODAL_ENIGMA' });
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enigmaFinish]);

  const handleShowDetails = (index, type) => {
    if (type === 'open') {
      const newPatients = [...patients];
      newPatients[index].showDetails = true;
      setPatients(newPatients);
    } else {
      const newPatients = [...patients];
      newPatients[index].showDetails = false;
      setPatients(newPatients);
    }
  };

  return (
    <div className="Board Modal_description">
      <Audio condition={success && soundtrack} sound={successSound} />
      <div className="Board_content">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Fvestiaires%2Fobjects%2Fboard%2Ftv-zoom2.svg?alt=media&token=cce58b27-522a-42e2-9d98-1560fd9fdc1d"
          className="BoardTMS_background-img"
          alt="Télévision"
        />
        <div className="Board_content-enigma">
          <div className="Board_content-Explanation">
            <h3>Tableau des résidents</h3>
            <p>
              Cliquez sur les photos des résidents pour leur attribuer un niveau
              d&apos;autonomie
            </p>
            <p className="Board_content-Error">{errorMessage}</p>
            <Button
              buttonType="action"
              title="Valider"
              onClick={() => handleSubmit()}
            />
          </div>
          <div className="Board_content-patients">
            {patients.map((patientContent, index) => (
              <div className={`Board_content-Patient${index}`}>
                <button
                  type="button"
                  className="Board_content-button"
                  style={{
                    backgroundColor: weight[patientContent.color].color,
                  }}
                  onClick={() => handleColor(index)}
                >
                  <img
                    src={patientContent.imageUrl}
                    alt={patientContent.name}
                    title={patientContent.name}
                  />
                  {/* {patientContent.name} */}
                </button>
                {patientContent.showDetails && (
                  <p className="Board_content-Patient-Description">
                    {patientContent.description}
                  </p>
                )}
                <button
                  type="button"
                  className={
                    patientContent.showDetails
                      ? 'Board_content-MoreInfo-Close'
                      : 'Board_content-MoreInfo'
                  }
                  onClick={() =>
                    handleShowDetails(
                      index,
                      patientContent.showDetails ? 'closed' : 'open'
                    )
                  }
                >
                  {patientContent.showDetails ? 'Fermer' : 'i'}
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  idSessionHasRoom: state.GameUsers.idSessionHasRoom,
  sessionId: state.GameUsers.idSession,
  soundtrack: state.GameUsers.soundtrack,
  allObjects: state.Objects.AllObjects,
});

Board.propTypes = {
  allObjects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
  objectContent: PropTypes.shape({
    content: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      status: PropTypes.bool,
    }),
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  idSessionHasRoom: PropTypes.number.isRequired,
  soundtrack: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(Board);
