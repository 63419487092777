import { useEffect } from 'react';

/**
 * Resize the background image when the window is resized
 * @param {Object} backgroundImageRef ref containing the background image DOM element
 */
const useWindowResize = (backgroundImageRef, handleResize) => {
  useEffect(() => {
    // Save to the store the background image dimensions when component is mounted
    if (backgroundImageRef.current) handleResize();

    window.addEventListener('resize', handleResize);

    // Cleanup function
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [backgroundImageRef, handleResize]);
};
export default useWindowResize;
