import chatbotStep from './constants/chatbotStep';
import commonSteps from './constants/commonSteps';
import inventoryStep from './constants/inventoryStep';

/**
 * Tutorial steps for TDE game
 * @param {func} t -translation function from i18next
 * @returns {Object[]}
 */
// Define a function to use the translation function as parameter
const stepsTDE = (t) => {
  // get constants steps from constants file
  const commonImportedSteps = commonSteps(
    t,
    `dans la ${t('room1', { ns: 'tde' })}`,
    `du ${t('narrator', { ns: 'tde' })}`,
    null
  );
  const inventoryImportedStep = inventoryStep(t);
  const chatbotImportedStep = chatbotStep(t);
  // Insert inventory step into commons steps
  commonImportedSteps.splice(1, 0, inventoryImportedStep);

  // Insert chatbot step into commons steps
  commonImportedSteps.splice(
    commonImportedSteps.length - 1,
    0,
    chatbotImportedStep
  );

  return commonImportedSteps;
};

export default stepsTDE;
