import { connect, useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// css
import 'assets/css/components/GameBoard/ReusableComponents/Inventory.css';

// components
import { startedMessage } from 'components/constants';

// utils
import {
  addInventory,
  changeStatusOfObject,
  retrieveQuestionnary,
} from 'utils/utilityFunctions';

// constants
import {
  chargerOfficeMultiEmotion,
  paper2OfficeMultiEmotion,
  sheetVisioOfficeMultiEmotion,
  paper1OfficeMultiEmotion,
  rebusOfficeMultiEmotion,
  emailOfficeMultiEmotion,
  paperFusionOfficeMultiEmotion,
  plugInOfficeMultiEmotion,
  computerLowBatRoomEmotionMultiEnigma,
  todoListLivingRoomMultiEmotion,
  remoteTvLivingRoomMultiEmotion,
  pictureBedroomMultiEmotion,
  keyBedroomMultiEmotion,
  noticeBedroomMultiEmotion,
  posterBedroomMultiEmotion,
  puzzleBedroomMultiEmotion,
  fortuneCookieLivingRoomEmotionMulti,
  crosswordLivingRoomMultiEmotion,
} from 'components/GameBoard/ReusableComponents/Objects/constants';
import { scoreAddInventory } from 'components/GameBoard/ReusableComponents/constants';

// selectors
import {
  selectObject,
  selectObjectIndex,
} from 'components/Reducers/GameData/fetchObjects';
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';
import { selectSession } from 'components/Reducers/session';
import { selectUser } from 'components/Reducers/user';

const BASE_URL = process.env.REACT_APP_BASE_URL;

function Inventory({ collectedObjects, idSessionHasRoom, objects }) {
  const { t } = useTranslation('common');
  const session = useSelector(selectSession);
  const { socket } = useSelector(infoGameUser);
  const { participants } = useSelector(selectUser);
  const room = session.id;

  const idBatteryObject = 143;
  const idLampObject = 147;
  const idLightObject = 148;
  const idCodeLightObject = 149;
  const idCodeMorseObject = 160;
  const idShovel = 178;
  const idGrass = 180;
  const idKey = 181;
  const idDiary = 182;
  const idPageOne = 183;
  const idPageTwo = 184;
  const idPageThree = 229;
  const idPageQuestionnary = 185;
  const idBalance = 186;
  const idCardAnniversary = 187;
  const idMark = 188;
  const idList = 191;
  const idApportColoric = 192;
  const idCaloric = 193;
  const idRemote = 195;
  const idTimeOne = 196;
  const idTimeTwo = 197;
  const idTimeThree = 198;
  const idField = 207;
  const idFolderPatientOne = 210;
  const idFolderPatientTwo = 211;
  const idRecap = 215;
  const idTimeBot = 200;
  const idTimeBotTwo = 201;
  const idTimeBotThree = 202;
  const idBookRPS = 244;
  const idAfficheYoga = 245;
  const idCarteRemerciement = 248;
  const idTableauConges = 249;
  const idCagnotteRaclette = 250;
  const idReservationRestaurant = 251;
  const idRdvMedical = 253;
  const idRdvManager = 254;

  const dispatch = useDispatch();

  const paper1EmotionIndex = useSelector((state) =>
    selectObjectIndex(state, paper1OfficeMultiEmotion)
  );
  const paper2EmotionMultiIndex = useSelector((state) =>
    selectObjectIndex(state, paper2OfficeMultiEmotion)
  );
  const paperFusionEmotionMultiIndex = useSelector((state) =>
    selectObjectIndex(state, paperFusionOfficeMultiEmotion)
  );

  const paper1EmotionObject = useSelector((state) =>
    selectObject(state, paper1OfficeMultiEmotion)
  );

  const paper2EmotionMultiObject = useSelector((state) =>
    selectObject(state, paper2OfficeMultiEmotion)
  );

  const paperFusionEmotionMultiObject = useSelector((state) =>
    selectObject(state, paperFusionOfficeMultiEmotion)
  );

  // charger emotion multi
  const chargerEmotionMultiIndex = useSelector((state) =>
    selectObjectIndex(state, chargerOfficeMultiEmotion)
  );
  const chargerEmotionMultiObject = useSelector((state) =>
    selectObject(state, chargerOfficeMultiEmotion)
  );

  // plug in emotion multi
  const pluginEmotionMultiObject = useSelector((state) =>
    selectObject(state, plugInOfficeMultiEmotion)
  );

  // desktop low bat emotion multi
  const computerLowBatRoomEmotionMultiObject = useSelector((state) =>
    selectObject(state, computerLowBatRoomEmotionMultiEnigma)
  );
  const computerLowBatRoomEmotionMultiIndex = useSelector((state) =>
    selectObjectIndex(state, computerLowBatRoomEmotionMultiEnigma)
  );

  // remote tv emotion multi
  const remoteTvLivingRoomMultiEmotionIndex = useSelector((state) =>
    selectObjectIndex(state, remoteTvLivingRoomMultiEmotion)
  );

  // puzzle tv emotion multi
  const puzzleBedroomMultiEmotionIndex = useSelector((state) =>
    selectObjectIndex(state, puzzleBedroomMultiEmotion)
  );

  // key living room emotion multi
  const keyBedroomMultiEmotionIndex = useSelector((state) =>
    selectObjectIndex(state, keyBedroomMultiEmotion)
  );

  // fortune cookie living room emotion multi
  const fortuneCookieLivingRoomEmotionMultiIndex = useSelector((state) =>
    selectObjectIndex(state, fortuneCookieLivingRoomEmotionMulti)
  );

  const fortuneCookieLivingRoomEmotionMultiObject = useSelector((state) =>
    selectObject(state, fortuneCookieLivingRoomEmotionMulti)
  );

  // crossword living room emotion multi
  const crosswordLivingRoomMultiEmotionIndex = useSelector((state) =>
    selectObjectIndex(state, crosswordLivingRoomMultiEmotion)
  );

  const crosswordLivingRoomMultiEmotionObject = useSelector((state) =>
    selectObject(state, crosswordLivingRoomMultiEmotion)
  );

  const dragStartRPS = (event, id) => {
    event.dataTransfer.setData('id', id);
  };

  const handleImage = (collectObject) => {
    if (collectObject && collectObject.images) {
      const objectIndex = collectObject.images.findIndex(
        (object) => object.type === 'inventory'
      );
      if (collectObject.id === idGrass && collectObject.status === 'open') {
        // CASE PARTICULAR BETWEEN GRASS
        const objectIndexOpen = collectObject.images.findIndex(
          (object) => object.type === 'open'
        );
        return collectObject.images[objectIndexOpen].image;
      }
      if (collectObject.id === idDiary && collectObject.status === 'open') {
        // CASE PARTICULAR BETWEEN DIARY
        const objectIndexOpen = collectObject.images.findIndex(
          (object) => object.type === 'open'
        );
        return collectObject.images[objectIndexOpen].image;
      }

      if (collectObject.id === idLampObject && collectObject.status === 'open') {
        // CASE PARTICULAR BETWEEN LAMP UV
        const objectIndexOpen = collectObject.images.findIndex(
          (object) => object.type === 'open'
        );
        return collectObject.images[objectIndexOpen].image;
      }

      if (
        collectObject.id === fortuneCookieLivingRoomEmotionMulti &&
        collectObject.status === 'open'
      ) {
        const objectIndexOpen = collectObject.images.findIndex(
          (objectContent) => objectContent.type === 'open'
        );
        return collectObject.images[objectIndexOpen].image;
      }

      return collectObject.images[objectIndex].image;
    }
    return null;
  };

  const handleMessage = (collectObject) => {
    const batteryObject = objects.filter(
      (objectContent) => objectContent.id === idBatteryObject
    );

    const shovelObject = objects.filter(
      (objectContent) => objectContent.id === idShovel
    );

    const keyObject = objects.filter((objectContent) => objectContent.id === idKey);

    const grassObject = objects.filter(
      (objectContent) => objectContent.id === idGrass
    );

    const diaryObject = objects.filter(
      (objectContent) => objectContent.id === idGrass
    );

    let message;

    if (collectObject.images) {
      if (collectObject.id === idLampObject) {
        // CASE PARTICULAR BETWEEN BATTERY AND LAMP UV AND LIGHT BUTTON
        const objectIndexWithoutPiles = collectObject.images.findIndex(
          (object) =>
            object.type === 'inventory' && object.slug === 'lampe-uv-inventory'
        );
        const objectIndexWithPiles = collectObject.images.findIndex(
          (object) =>
            object.type === 'inventory' &&
            object.slug === 'lampe-uv-inventory-with-piles'
        );

        const objectIndexReady = collectObject.images.findIndex(
          (object) =>
            object.type === 'inventory' && object.slug === 'lampe-uv-ready'
        );

        if (batteryObject[0].isChecked) {
          const lightObject = objects.filter(
            (objectContent) => objectContent.id === idLightObject
          );

          if (lightObject[0].status === 'open') {
            message = collectObject.images[objectIndexReady].message;
          } else {
            message = collectObject.images[objectIndexWithPiles].message;
          }
        } else {
          message = collectObject.images[objectIndexWithoutPiles].message;
        }
      } else if (collectObject.id === idGrass) {
        // CASE PARTICULAR BETWEEN GRASS AND SHOVEL
        const objectWithoutShovel = collectObject.images.findIndex(
          (object) => object.type === 'closed'
        );
        message = collectObject.images[objectWithoutShovel].message;

        if (shovelObject[0].isInventory) {
          if (collectObject.status !== 'open') {
            const objectWithShovel = collectObject.images.findIndex(
              (object) => object.type === 'inventory'
            );

            message = collectObject.images[objectWithShovel].message;
          } else {
            const objectGrassOpen = collectObject.images.findIndex(
              (object) => object.type === 'open'
            );
            message = collectObject.images[objectGrassOpen].message;
          }
        }
      } else if (collectObject.id === idDiary) {
        // CASE PARTICULAR BETWEEN Diary AND key
        const objectIndexWitoutKey = collectObject.images.findIndex(
          (object) => object.type === 'inventory'
        );

        message = collectObject.images[objectIndexWitoutKey].message;

        if (keyObject[0].isInventory) {
          if (collectObject.status !== 'open') {
            const objectIndexWithKey = collectObject.images.findIndex(
              (object) => object.type === 'closed'
            );
            message = collectObject.images[objectIndexWithKey].message;
          } else {
            const objectIndexOpen = collectObject.images.findIndex(
              (object) => object.type === 'open'
            );
            message = collectObject.images[objectIndexOpen].message;
          }
        }
      } else if (collectObject.id === idShovel) {
        const objectIndex = collectObject.images.findIndex(
          (object) => object.type === 'inventory'
        );

        message = collectObject.images[objectIndex].message;

        if (grassObject[0].isInventory) {
          const objectIndexWithGrass = collectObject.images.findIndex(
            (object) => object.type === 'open'
          );
          message = collectObject.images[objectIndexWithGrass].message;
        }
      } else if (collectObject.id === idKey) {
        const objectIndex = collectObject.images.findIndex(
          (object) => object.type === 'inventory'
        );

        message = collectObject.images[objectIndex].message;

        if (diaryObject[0].isInventory) {
          const objectIndexWithDiary = collectObject.images.findIndex(
            (object) => object.type === 'open'
          );

          message = collectObject.images[objectIndexWithDiary].message;
        }
      } else if (collectObject.id === idPageOne || collectObject.id === idPageTwo) {
        const objectIndex = collectObject.images.findIndex(
          (object) => object.type === 'inventory'
        );

        message = collectObject.images[objectIndex].message;
        const pageOneObject = objects.filter(
          (objectContent) => objectContent.id === idPageOne
        )[0];

        const pageTwoObject = objects.filter(
          (objectContent) => objectContent.id === idPageTwo
        )[0];

        if (pageOneObject.isInventory && pageTwoObject.isInventory) {
          const objectIndexPageOne = collectObject.images.findIndex(
            (object) => object.type === 'closed'
          );

          message = collectObject.images[objectIndexPageOne].message;
        }
      } else {
        const objectIndex = collectObject.images.findIndex(
          (object) => object.type === 'inventory'
        );

        message = collectObject.images[objectIndex].message;
      }

      return message;
    }
    return null;
  };

  const checkedObject = (collectObject, index, isMessage) => {
    axios
      .put(`${BASE_URL}/api/sessionsHasRoomHasObject/${idSessionHasRoom}`, {
        object_id: collectObject.id,
        isChecked: 1,
      })
      .then(() => {
        const responsesClickedObject = {
          index,
          isChecked: 1,
          type: 'checked',
        };
        const idObjectToRemove = collectObject.id;
        const responsesSocket = {
          ...responsesClickedObject,
          idObjectToRemove,
          room,
        };
        socket?.emit('send_clicked_object', responsesSocket);
        socket?.emit('send_remove_of_inventory', responsesSocket);

        dispatch({
          type: 'CLICKED_OBJECT',
          payload: {
            ...responsesClickedObject,
          },
        });
        dispatch({
          type: 'REMOVE_OF_INVENTORY',
          payload: idObjectToRemove,
        });

        if (!isMessage) {
          socket?.emit('send_start_message_prevention', responsesSocket);
          dispatch({
            type: 'START_MESSAGE_PREVENTION',
          });
        }
      });
  };

  const usedObject = (collectObject, index) => {
    axios
      .put(`${BASE_URL}/api/sessionsHasRoomHasObject/${idSessionHasRoom}`, {
        object_id: collectObject.id,
        status: 'open',
      })
      .then(() => {
        const clickedContent = {
          index,
          status: 'open',
          type: 'used',
        };
        const responsesSocket = {
          ...clickedContent,
          scoreAddInventory,
          room,
        };
        socket?.emit('send_clicked_object', responsesSocket);
        socket?.emit('send_update_game_score', responsesSocket);
        dispatch({
          type: 'CLICKED_OBJECT',
          payload: clickedContent,
        });
        dispatch({
          type: 'UPDATE_GAME_SCORE',
          payload: 100,
        });
      });
  };

  const openModal = (collectObject, index) => {
    const descriptionModal = {};
    descriptionModal.message = handleMessage(collectObject);
    descriptionModal.image = handleImage(collectObject);
    // Will be in the DB in the future
    descriptionModal.type = 'inventory';

    if (
      collectObject.id !== idBatteryObject &&
      collectObject.id !== idLampObject &&
      collectObject.id !== idCodeMorseObject &&
      collectObject.id !== idCodeLightObject &&
      collectObject.id !== idShovel &&
      collectObject.id !== idGrass &&
      collectObject.id !== idKey &&
      collectObject.id !== idDiary &&
      collectObject.id !== idPageOne &&
      collectObject.id !== idPageTwo &&
      collectObject.id !== idPageThree &&
      collectObject.id !== idBalance &&
      collectObject.id !== idCardAnniversary &&
      collectObject.id !== idMark &&
      collectObject.id !== idList &&
      collectObject.id !== idApportColoric &&
      collectObject.id !== idRemote &&
      collectObject.id !== idTimeOne &&
      collectObject.id !== idTimeTwo &&
      collectObject.id !== idTimeThree &&
      collectObject.id !== idCaloric &&
      collectObject.id !== idField &&
      collectObject.id !== idFolderPatientOne &&
      collectObject.id !== idFolderPatientTwo &&
      collectObject.id !== idRecap &&
      collectObject.id !== idTimeBot &&
      collectObject.id !== idTimeBotTwo &&
      collectObject.id !== idTimeBotThree &&
      collectObject.id !== idBookRPS &&
      collectObject.id !== idCarteRemerciement &&
      collectObject.id !== idRdvManager &&
      collectObject.id !== idRdvMedical &&
      collectObject.id !== idTableauConges &&
      collectObject.id !== idAfficheYoga &&
      collectObject.id !== idCagnotteRaclette &&
      collectObject.id !== idReservationRestaurant &&
      collectObject.id !== rebusOfficeMultiEmotion &&
      collectObject.id !== paper2OfficeMultiEmotion &&
      collectObject.id !== paper1OfficeMultiEmotion &&
      collectObject.id !== chargerOfficeMultiEmotion &&
      collectObject.id !== sheetVisioOfficeMultiEmotion &&
      collectObject.id !== emailOfficeMultiEmotion &&
      collectObject.id !== paperFusionOfficeMultiEmotion &&
      collectObject.id !== todoListLivingRoomMultiEmotion &&
      collectObject.id !== remoteTvLivingRoomMultiEmotion &&
      collectObject.id !== pictureBedroomMultiEmotion &&
      collectObject.id !== keyBedroomMultiEmotion &&
      collectObject.id !== noticeBedroomMultiEmotion &&
      collectObject.id !== posterBedroomMultiEmotion &&
      collectObject.id !== puzzleBedroomMultiEmotion &&
      collectObject.id !== fortuneCookieLivingRoomEmotionMulti &&
      collectObject.id !== crosswordLivingRoomMultiEmotion
    ) {
      descriptionModal.buttonDescription = {
        title: 'Transmettre',
        onClick: () => {
          axios
            .put(
              `${process.env.REACT_APP_BASE_URL}/api/sessionsHasRoom/${idSessionHasRoom}/modifications`,
              {
                current_step: collectObject.id_message,
                start_message: startedMessage,
              }
            )
            .then(() => {
              const idMessage = collectObject.id_message;
              const isPauseTimer = true;
              const responsesSocket = {
                id: idMessage,
                isPauseTimer,
                room,
              };
              socket?.emit('send_current_step', responsesSocket);
              socket?.emit('send_pause_timer', responsesSocket);
              dispatch({
                type: 'PAUSE_TIMER',
                payload: isPauseTimer,
              });
              dispatch({
                type: 'CURRENT_STEP',
                payload: idMessage,
              });

              checkedObject(collectObject, index);
            });
        },
      };
    } else if (collectObject.id === idLampObject) {
      // CASE PARTICULAR BETWEEN BATTERY AND LAMP UV AND LIGHT BUTTON

      const batteryObject = objects.filter(
        (objectContent) => objectContent.id === idBatteryObject
      );
      const batteryObjectIndex = objects.findIndex(
        (objectContent) => objectContent.id === idBatteryObject
      );

      const lampObject = objects.filter(
        (objectContent) => objectContent.id === idLampObject
      );

      const lampObjectIndex = objects.findIndex(
        (objectContent) => objectContent.id === idLampObject
      );

      if (batteryObject[0].isInventory) {
        if (batteryObject[0].isChecked) {
          descriptionModal.buttonDescription = {
            title: 'Utiliser',
            onClick: () => {
              usedObject(lampObject[0], lampObjectIndex);
              dispatch({
                type: 'CLOSE_MODAL',
              });
              dispatch({
                type: 'INCREMENT_COUNTER',
              });
            },
          };
        } else {
          descriptionModal.buttonDescription = {
            title: 'Associer',
            onClick: () => {
              checkedObject(batteryObject[0], batteryObjectIndex, 'not-message');
              dispatch({
                type: 'INCREMENT_COUNTER',
              });
              dispatch({
                type: 'CLOSE_MODAL',
              });
            },
          };
        }
      }
    } else if (collectObject.id === idGrass) {
      const shovelObject = objects.filter(
        (objectContent) => objectContent.id === idShovel
      );

      const shovelIndex = objects.findIndex(
        (objectContent) => objectContent.id === idShovel
      );

      const grassIndex = objects.findIndex(
        (objectContent) => objectContent.id === idGrass
      );

      const keyObject = objects.filter(
        (objectContent) => objectContent.id === idKey
      );
      const keyIndex = objects.findIndex(
        (objectContent) => objectContent.id === idKey
      );

      if (shovelObject[0].isInventory && collectObject.isInventory) {
        if (collectObject.status === 'open') {
          descriptionModal.buttonDescription = {
            title: "Ajouter à l'inventaire",
            onClick: (event) => {
              checkedObject(collectObject, grassIndex, 'not-message');
              addInventory(
                dispatch,
                idSessionHasRoom,
                keyObject[0],
                keyIndex,
                event
              );
            },
          };
        } else {
          descriptionModal.buttonDescription = {
            title: 'Utiliser la pelle',
            onClick: () => {
              checkedObject(shovelObject[0], shovelIndex, 'not-message');
              usedObject(collectObject, grassIndex);
              dispatch({
                type: 'INCREMENT_COUNTER',
              });
              dispatch({
                type: 'CLOSE_MODAL',
              });
            },
          };
        }
      }
    } else if (collectObject.id === idDiary) {
      const keyObject = objects.filter(
        (objectContent) => objectContent.id === idKey
      );
      const keyIndex = objects.findIndex(
        (objectContent) => objectContent.id === idKey
      );

      const diaryIndex = objects.findIndex(
        (objectContent) => objectContent.id === idDiary
      );

      if (keyObject[0].isInventory && collectObject.isInventory) {
        if (collectObject.status === 'open') {
          descriptionModal.buttonDescription = {
            title: 'Transmettre',
            onClick: () => {
              axios
                .put(
                  `${process.env.REACT_APP_BASE_URL}/api/sessionsHasRoom/${idSessionHasRoom}/modifications`,
                  {
                    current_step: collectObject.id_message,
                    start_message: startedMessage,
                  }
                )
                .then(() => {
                  dispatch({
                    type: 'PAUSE_TIMER',
                    payload: true,
                  });
                  dispatch({
                    type: 'CURRENT_STEP',
                    payload: collectObject.id_message,
                  });

                  retrieveQuestionnary(collectObject.id_message, dispatch);
                });
              checkedObject(collectObject, index);
              dispatch({
                type: 'CLOSE_MODAL',
              });
            },
          };
        } else {
          descriptionModal.buttonDescription = {
            title: 'Ouvrir',
            onClick: () => {
              checkedObject(keyObject[0], keyIndex, 'not-message');
              usedObject(collectObject, diaryIndex);
              dispatch({
                type: 'CLOSE_MODAL',
              });
            },
          };
        }
      }
    } else if (
      collectObject.id === idPageOne ||
      collectObject.id === idPageTwo ||
      collectObject.id === idPageThree
    ) {
      const pageOneObject = objects.filter(
        (objectContent) => objectContent.id === idPageOne
      )[0];
      const pageOneIndex = objects.findIndex(
        (objectContent) => objectContent.id === idPageOne
      );

      const pageTwoObject = objects.filter(
        (objectContent) => objectContent.id === idPageTwo
      )[0];
      const pageTwoIndex = objects.findIndex(
        (objectContent) => objectContent.id === idPageTwo
      );

      const pageThreeObject = objects.filter(
        (objectContent) => objectContent.id === idPageThree
      )[0];
      const pageThreeIndex = objects.findIndex(
        (objectContent) => objectContent.id === idPageThree
      );

      const pageQuestionnaryObject = objects.filter(
        (objectContent) => objectContent.id === idPageQuestionnary
      )[0];
      const pageQuestionnaryIndex = objects.findIndex(
        (objectContent) => objectContent.id === idPageQuestionnary
      );

      if (
        pageOneObject.isInventory &&
        pageTwoObject.isInventory &&
        pageThreeObject.isInventory
      ) {
        descriptionModal.buttonDescription = {
          title: 'Réunir',
          onClick: (event) => {
            checkedObject(pageOneObject, pageOneIndex, 'not-message');
            checkedObject(pageTwoObject, pageTwoIndex, 'not-message');
            checkedObject(pageThreeObject, pageThreeIndex, 'not-message');
            addInventory(
              dispatch,
              idSessionHasRoom,
              pageQuestionnaryObject,
              pageQuestionnaryIndex,
              event
            );
            dispatch({
              type: 'CLOSE_MODAL',
            });
          },
        };
      }
    } else if (collectObject.id === idRemote) {
      const listIndex = objects.findIndex(
        (objectContent) => objectContent.id === idRemote
      );
      descriptionModal.buttonDescription = {
        title: 'Utiliser',
        onClick: () => {
          usedObject(collectObject, listIndex);
          dispatch({
            type: 'CLOSE_MODAL',
          });
        },
      };
    } else if (collectObject.id === idFolderPatientOne) {
      const folderPatientOneContent = objects.find(
        (objectContent) => objectContent.id === idFolderPatientOne
      );
      const folderPatientOneIndex = objects.findIndex(
        (objectContent) => objectContent.id === idFolderPatientOne
      );
      if (objects[objects.findIndex((object) => object.id === 209)].isClicked > 0) {
        descriptionModal.buttonDescription = {
          title: 'Intégrer dans le dossier',
          onClick: () => {
            checkedObject(
              folderPatientOneContent,
              folderPatientOneIndex,
              'not-message'
            );
            dispatch({
              type: 'INCREMENT_COUNTER',
            });
            dispatch({
              type: 'CLOSE_MODAL',
            });
          },
        };
      }
    } else if (collectObject.id === idFolderPatientTwo) {
      const folderPatientTwoContent = objects.find(
        (objectContent) => objectContent.id === idFolderPatientTwo
      );
      const folderPatientTwoIndex = objects.findIndex(
        (objectContent) => objectContent.id === idFolderPatientTwo
      );

      if (objects[objects.findIndex((object) => object.id === 209)].isClicked > 0) {
        descriptionModal.buttonDescription = {
          title: 'Intégrer dans le dossier',
          onClick: () => {
            checkedObject(
              folderPatientTwoContent,
              folderPatientTwoIndex,
              'not-message'
            );
            dispatch({
              type: 'INCREMENT_COUNTER',
            });
            dispatch({
              type: 'CLOSE_MODAL',
            });
          },
        };
      }
    } else if (
      collectObject.id === paper1OfficeMultiEmotion ||
      collectObject.id === paper2OfficeMultiEmotion
    ) {
      // When paper 1 and 2 are in the inventory we need to fusion them
      if (paper1EmotionObject.isInventory && paper2EmotionMultiObject.isInventory) {
        descriptionModal.buttonDescription = {
          title: t('buttonFunction.reunite'),
          onClick: (event) => {
            checkedObject(paper1EmotionObject, paper1EmotionIndex, 'not-message');
            checkedObject(
              paper2EmotionMultiObject,
              paper2EmotionMultiIndex,
              'not-message'
            );
            addInventory(
              dispatch,
              idSessionHasRoom,
              paperFusionEmotionMultiObject,
              paperFusionEmotionMultiIndex,
              event,
              socket,
              room,
              participants,
              session
            );
            const responsesSocket = { room };
            socket?.emit('send_close_modal', responsesSocket);
            dispatch({
              type: 'CLOSE_MODAL',
            });
          },
        };
      }
    } else if (collectObject.id === chargerOfficeMultiEmotion) {
      // When the user finds the charger of the computer he needs to plug it in
      if (chargerEmotionMultiObject.isInventory) {
        descriptionModal.buttonDescription = {
          title: t('buttonFunction.connect'),
          onClick: (event) => {
            // finish object desktop Low Bat
            // update object plug in
            changeStatusOfObject(
              pluginEmotionMultiObject,
              'open',
              idSessionHasRoom,
              dispatch,
              socket,
              room
            );
            addInventory(
              dispatch,
              idSessionHasRoom,
              computerLowBatRoomEmotionMultiObject,
              computerLowBatRoomEmotionMultiIndex,
              event,
              socket,
              room,
              participants,
              session
            );
            const responsesSocket = { room };
            socket?.emit('send_close_modal', responsesSocket);
            dispatch({
              type: 'CLOSE_MODAL',
            });
            // finish object charger
            checkedObject(chargerEmotionMultiObject, chargerEmotionMultiIndex);
            // MULTI ADD INCREMENT
            setTimeout(() => {
              checkedObject(
                computerLowBatRoomEmotionMultiObject,
                computerLowBatRoomEmotionMultiIndex,
                'not-message'
              );
            }, [300]);
          },
        };
      }
    } else if (collectObject.id === remoteTvLivingRoomMultiEmotion) {
      descriptionModal.buttonDescription = {
        title: t('buttonFunction.used'),
        onClick: () => {
          usedObject(collectObject, remoteTvLivingRoomMultiEmotionIndex);
          const responsesSocket = { room };
          socket?.emit('send_close_modal', responsesSocket);
          dispatch({
            type: 'CLOSE_MODAL',
          });
        },
      };
    } else if (collectObject.id === keyBedroomMultiEmotion) {
      descriptionModal.buttonDescription = {
        title: t('buttonFunction.used'),
        onClick: () => {
          usedObject(collectObject, keyBedroomMultiEmotionIndex);
          const responsesSocket = { room };
          socket?.emit('send_close_modal', responsesSocket);
          dispatch({
            type: 'CLOSE_MODAL',
          });
        },
      };
    } else if (collectObject.id === puzzleBedroomMultiEmotion) {
      descriptionModal.buttonDescription = {
        title: t('buttonFunction.used'),
        onClick: () => {
          usedObject(collectObject, puzzleBedroomMultiEmotionIndex);
          checkedObject(
            collectObject,
            puzzleBedroomMultiEmotionIndex,
            'not-message'
          );
          const responsesSocket = { room };
          socket?.emit('send_close_modal', responsesSocket);
          dispatch({
            type: 'CLOSE_MODAL',
          });
        },
      };
    } else if (
      collectObject.id === fortuneCookieLivingRoomEmotionMulti &&
      fortuneCookieLivingRoomEmotionMultiObject.status !== 'open'
    ) {
      descriptionModal.buttonDescription = {
        title: t('buttonFunction.eatMe'),
        onClick: () => {
          usedObject(collectObject, fortuneCookieLivingRoomEmotionMultiIndex);
          const responsesSocket = { room };
          socket?.emit('send_close_modal', responsesSocket);
          dispatch({
            type: 'CLOSE_MODAL',
          });
          if (collectObject.status !== 'open') {
            socket?.emit('send_start_message_prevention', responsesSocket);
            dispatch({
              type: 'START_MESSAGE_PREVENTION',
            });
          }
        },
      };
    } else if (
      collectObject.id === crosswordLivingRoomMultiEmotion &&
      crosswordLivingRoomMultiEmotionObject.status !== 'open'
    ) {
      descriptionModal.buttonDescription = {
        title: t('buttonFunction.used'),
        onClick: () => {
          usedObject(collectObject, crosswordLivingRoomMultiEmotionIndex);
          const responsesSocket = { room };
          socket?.emit('send_close_modal', responsesSocket);
          dispatch({
            type: 'CLOSE_MODAL',
          });
          if (collectObject.status !== 'open') {
            socket?.emit('send_start_message_prevention', responsesSocket);
            dispatch({
              type: 'START_MESSAGE_PREVENTION',
            });
          }
        },
      };
    }
    const responsesSocket = { descriptionModal, room };
    socket?.emit('send_open_modal', responsesSocket);
    dispatch({
      type: 'OPEN_MODAL',
      payload: descriptionModal,
    });
  };

  return (
    <div className="Inventory">
      <p>Inventaire</p>
      {collectedObjects.map(
        (collectObject, index) =>
          collectObject.rpsDisplay && (
            <div
              className="Inventory_object"
              onClick={() => openModal(collectObject, index)}
              draggable="true"
              onDragStart={(event) => dragStartRPS(event, collectObject.id)}
              role="presentation"
              key={collectObject.id}
            >
              <img src={handleImage(collectObject)} alt={collectObject.title} />
            </div>
          )
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  collectedObjects: state.Inventory.list,
  idSessionHasRoom: state.GameUsers.idSessionHasRoom,
  objects: state.Objects.AllObjects,
});

Inventory.propTypes = {
  collectedObjects: PropTypes.arrayOf(
    PropTypes.shape({
      rpsDisplay: PropTypes.bool,
      title: PropTypes.string,
      id: PropTypes.number,
    })
  ).isRequired,
  idSessionHasRoom: PropTypes.number,
  objects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      isClicked: PropTypes.number,
    })
  ).isRequired,
};

Inventory.defaultProps = {
  idSessionHasRoom: null,
};

export default connect(mapStateToProps)(Inventory);
