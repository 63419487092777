/* eslint-disable no-shadow */
/* eslint-disable no-case-declarations */

const initialState = {
  Modal: {
    ModalIntro: false,
    ModalTutorial: true,
    ModalPause: false,
  },
  AllObjects: [],
  object: [],
};

const fetchObject = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_OBJECT':
      return {
        ...state,
        AllObjects: action.objects,
      };
    case 'RESET_OBJECTS':
      return {
        ...state,
        AllObjects: [],
      };
    case 'ADD_OBJECT':
      return {
        ...state,
        object: [...state.object, action.payload],
      };
    // REFACTO page 404
    case 'REMOVE_OBJECT': {
      return {
        ...state,
        object: state.object.filter(
          (item) => item.name !== action.payload.nameObject
        ),
      };
    }
    case 'CLICKED_OBJECT':
      const newAllObjects = [...state.AllObjects];

      if (action.payload.type === 'inventory') {
        newAllObjects[action.payload.index].isInventory =
          action.payload.isInventory;
      }

      if (action.payload.type === 'used') {
        newAllObjects[action.payload.index].status = action.payload.isUsed;
        if (action.payload.status) {
          newAllObjects[action.payload.index].status = action.payload.status;
        }
      }
      if (action.payload.type === 'checked') {
        newAllObjects[action.payload.index].isChecked = action.payload.isChecked;
      }
      if (action.payload.type === 'clicked') {
        newAllObjects[action.payload.index].isClicked = action.payload.isClicked;
        if (action.payload.status) {
          newAllObjects[action.payload.index].status = action.payload.status;
        }
      }

      return {
        ...state,
        AllObjects: newAllObjects,
      };

    case 'UPDATE_OBJECT':
      let newObjects = [];

      newObjects = [...state.AllObjects];
      const objectIndexState = newObjects.findIndex(
        (object) => object.id === action.payload.id
      );

      let objectIndexPayload;

      if (action.payload.listObjects) {
        objectIndexPayload = action.payload.listObjects.findIndex(
          (object) => object.id === action.payload.id
        );
      }

      if (newObjects[objectIndexState] !== undefined && action.payload.listImages) {
        newObjects[objectIndexState].images = action.payload.listImages;
        newObjects[objectIndexState].isChecked =
          action.payload.listObjects[objectIndexPayload].isChecked;
        newObjects[objectIndexState].isClicked =
          action.payload.listObjects[objectIndexPayload].isClicked;
        newObjects[objectIndexState].isInventory =
          action.payload.listObjects[objectIndexPayload].isInventory;
        newObjects[objectIndexState].status =
          action.payload.listObjects[objectIndexPayload].status;
      }
      // REFACTO API when we use the API Django we don't need this for the futur
      // because this dispatch retrieve the status of the object if the user refresh
      if (action.payload.status) {
        newObjects[objectIndexState].status = action.payload.status;
      }

      return {
        ...state,
        AllObjects: newObjects,
      };
    default:
      return state;
  }
};

// Define the selector functions
export const selectObjects = (state) => state.Objects;
export const selectAllObjects = (state) => state.Objects.AllObjects;

export const selectObjectIndex = (state, objectId) => {
  return state.Objects.AllObjects.findIndex((object) => object.id === objectId);
};

export const selectObject = (state, objectId) => {
  return state.Objects.AllObjects.find((object) => object.id === objectId);
};

// Selector functions specific to the Emotion game
export const selectEmoticonObjects = (state) =>
  state.Objects.AllObjects.filter((object) => object.name?.includes('face')).sort(
    (firstObject, secondObject) => firstObject.id - secondObject.id
  );

export const selectValidatedEmoticons = (state) => {
  const emoticonObjects = selectEmoticonObjects(state);
  return emoticonObjects.filter((emoticon) => emoticon.isChecked);
};
export const selectSelectedEmoticon = (state) => {
  const emoticonObjects = selectEmoticonObjects(state);
  return emoticonObjects.find((emoticon) => emoticon.status === 'selected');
};

export const selectStickObjects = (state) =>
  state.Objects.AllObjects.filter((object) => object.name?.includes('stick'));

export default fetchObject;
