import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { infoGameUser } from 'components/Reducers/GameData/GameUsers';
import { selectSession } from 'components/Reducers/session';
import ContentQuestion from 'components/GameBoard/ReusableComponents/Multi/Shared/Evaluation/StoryLine/ContentQuestion';
import { selectUser } from 'components/Reducers/user';

const StoryLine = () => {
  const { t } = useTranslation(['emotion-multi', 'common']);
  const allStories = t('storyline.content', { returnObjects: true });

  const dispatch = useDispatch();
  const { socket } = useSelector(infoGameUser);
  const session = useSelector(selectSession);
  const room = session.id;
  const { currentUser, participants } = useSelector(selectUser);

  const [indexStories, setIndexStories] = useState(0);

  const { isMainUser, type } = participants[Object.keys(currentUser)[0]];

  useEffect(() => {
    const descriptionModal = t('storyline', { returnObjects: true });
    const responsesSocket = { descriptionModal, room };
    socket?.emit('send_open_modal', responsesSocket);
    dispatch({
      type: 'OPEN_MODAL',
      payload: descriptionModal,
    });
  }, [dispatch, room, socket, t]);

  useEffect(() => {
    socket.on('receive_index_stories', (data) => {
      setIndexStories(data.index);
    });
  }, [dispatch, socket]);

  const handleNextStories = () => {
    const lastIndexStories = allStories.length - 1;
    if (isMainUser || type === 'admin') {
      let responsesSocket = {
        room,
      };
      if (indexStories !== lastIndexStories) {
        const nextIndex = indexStories + 1;
        responsesSocket = {
          ...responsesSocket,
          index: nextIndex,
        };
        socket?.emit('send_index_stories', responsesSocket);
        setIndexStories(nextIndex);
      } else {
        dispatch({
          type: 'START_SCOREBOARD',
        });
        socket?.emit('send_start_scoreboard', responsesSocket);
        const descriptionModal = t('conclusion', { returnObjects: true });
        responsesSocket = { ...responsesSocket, descriptionModal };
        socket?.emit('send_open_modal', responsesSocket);
        dispatch({
          type: 'OPEN_MODAL',
          payload: descriptionModal,
        });
      }
    }
  };

  return (
    <>
      <p>{allStories[indexStories].description}</p>
      <ContentQuestion
        idSurvey={allStories[indexStories].idSurvey}
        handleNextStories={handleNextStories}
        errors={allStories[indexStories].errors}
      />
    </>
  );
};

export default StoryLine;
