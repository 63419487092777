import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { putSessionsHasRoomHasObject } from 'API/sessionsHasRoomHasObject';
import { putSessionsHasRoom } from 'API/sessionsHasRoom';
// Components
import Button from 'components/GameBoard/ReusableComponents/Actions/Button';
import EmotionsSelection from 'components/GameBoard/Emotion/Solo/Enigma/EmotionsSelection';
// Selector functions
import {
  selectEmoticonObjects,
  selectSelectedEmoticon,
  selectValidatedEmoticons,
  selectObjectIndex,
  selectAllObjects,
} from 'components/Reducers/GameData/fetchObjects';
import { selectSteps } from 'components/Reducers/Steps/Steps';
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';
import {
  selectAnswersState,
  selectInterpretationMachineCalibrationEnigma,
} from 'components/Reducers/emotion';
// Style
import styles from 'components/GameBoard/Emotion/Solo/Enigma/EmotionsPanel.module.scss';
// Constant
import {
  enigmas,
  emotionIntensities,
  narratorTextHeaderHeight,
} from 'components/GameBoard/Emotion/Solo/constants';
import { startedMessage } from 'components/constants';
// Assets
import successSound from 'sound/Success.mp3';
import failSound from 'sound/fail.mp3';
import { calculatePoints, clickCount } from 'utils/utilityFunctions';
import styled from 'styled-components';

// Constants
const {
  emotionsPanelHeight,
  initialUserAnswers,
  closeImageUrl,
  initialWrongCount,
  basePoints,
  decreasePoints,
} = enigmas.InterpretationMachineCalibration;

let isMounted = false;

// CSS in JS
const EmotionPanelStyle = styled.div`
  position: absolute;
  // center the panel in the remaining space below NarratorTextHeader
  top: ${({ viewportHeight, bubbleHeight }) =>
    (viewportHeight - emotionsPanelHeight) / 2 + bubbleHeight / 2}px;
`;

function EmotionsPanel() {
  // `t` is a function coming from the i18next library used to translate text
  /* Load the `common` namespace = the one containing translations that are common between games
  and the `emotion` one (see public-> locales -> fr-FR) */
  const { t } = useTranslation('emotion', 'common');

  // Access i18next arrays and objects
  const { next, validate, retry } = t('buttonFunction', {
    returnObjects: true,
    ns: 'common',
  });

  const [selectedEmotions, setSelectedEmotions] = useState(initialUserAnswers);

  const isIntensityNotSelected =
    Object.values(selectedEmotions).includes(undefined);

  const dispatch = useDispatch();

  // Select from the store
  const preventionMessage = useSelector(selectSteps).list[0];
  const { idSessionHasRoom, soundtrack } = useSelector(infoGameUser);

  // Sorted emoticons objects
  const emoticons = useSelector(selectEmoticonObjects);

  // Validated emoticons
  const validatedEmoticons = useSelector(selectValidatedEmoticons);

  // Selected emoticon
  const selectedEmoticon = useSelector(selectSelectedEmoticon);
  const selectedEmoticonIndex = useSelector((state) =>
    selectObjectIndex(state, selectedEmoticon.id)
  );

  // select all objects
  const allObjects = useSelector(selectAllObjects);
  const lastEmoticonIndex = allObjects.length - 1;

  const enigma = useSelector(selectInterpretationMachineCalibrationEnigma);
  const { rightAnswers, userAnswers } = enigma[selectedEmoticon?.id];

  const answersState = useSelector((state) =>
    selectAnswersState(state, selectedEmoticon?.id)
  );
  // Define states
  const [wrongEmoticonCount, setWrongEmoticonCount] = useState(initialWrongCount);
  const [buttonTitle, setButtonTitle] = useState(validate);

  const isButtonDisabled =
    isIntensityNotSelected ||
    (!!selectedEmoticon.isChecked && buttonTitle !== next);

  // Change the button title
  useEffect(() => {
    const defineButtonTitle = () => {
      if (answersState === 'wrong') return setButtonTitle(retry);

      if (emoticons.length === validatedEmoticons.length) {
        return setButtonTitle(next);
      }
      return setButtonTitle(validate);
    };

    defineButtonTitle();
  }, [
    answersState,
    buttonTitle,
    emoticons.length,
    next,
    retry,
    validate,
    validatedEmoticons.length,
  ]);

  // Effects if the answers are correct
  useEffect(() => {
    // get first emoticon of list allObjects (exclude levier and selectedEmoticon)
    const findIndexFirstEmoticonNotchecked = () => {
      let filterEmoticons = allObjects.filter((objectContent) =>
        objectContent.name.includes('face')
      );
      filterEmoticons = filterEmoticons.filter(
        (objectContent) => objectContent.id !== selectedEmoticon.id
      );
      const findFirstEmoticonNotChecked = filterEmoticons.find(
        (face) => face.isChecked === 0
      );
      const indexFirstEmoticonNotchecked = allObjects.findIndex(
        (objectContent) => objectContent.id === findFirstEmoticonNotChecked?.id
      );
      if (indexFirstEmoticonNotchecked < 0) {
        return null;
      }
      return indexFirstEmoticonNotchecked;
    };
    const resultIndexNotChecked = findIndexFirstEmoticonNotchecked();
    // if last emoticon index 10, modal goes to first emoticon not checked
    const descriptionModalAfterLastEmoticon = {
      content: allObjects[resultIndexNotChecked],
    };
    // fo all emoticons except lastone index 10, modal goes to next emoticon
    const descriptionModalNextEmoticon = {
      content: allObjects[selectedEmoticonIndex + 1],
    };

    // for all commons emoticons if answer is right :
    //   - isChecked : 1 in DB and store
    //   - status : background
    //   - score modified
    const handleCommonValidationEmoji = () => {
      if (soundtrack) new Audio(successSound).play();
      dispatch({
        type: 'CLICKED_OBJECT',
        payload: {
          type: 'checked',
          index: selectedEmoticonIndex,
          isChecked: 1,
        },
      });
      putSessionsHasRoomHasObject(idSessionHasRoom, {
        object_id: selectedEmoticon.id,
        isChecked: 1,
      });
      dispatch({
        type: 'CLICKED_OBJECT',
        payload: {
          type: 'clicked',
          index: selectedEmoticonIndex,
          status: 'background',
        },
      });
      dispatch({
        type: 'UPDATE_GAME_SCORE',
        payload: calculatePoints(basePoints, wrongEmoticonCount, decreasePoints),
      });
      setWrongEmoticonCount(0);
    };

    // Function to Open next Modal Enigma and select next emoji
    const handleNextEmoji = (nextEmoticonIndex, descriptionModal) => {
      dispatch({
        type: 'CLICKED_OBJECT',
        payload: {
          type: 'clicked',
          index: nextEmoticonIndex,
          status: 'selected',
        },
      });
      dispatch({
        type: 'OPEN_MODAL_ENIGMA_EMOTION',
        payload: descriptionModal,
      });
    };

    if (!selectedEmoticon?.isChecked && answersState === 'right') {
      // for last emoji
      if (selectedEmoticonIndex === lastEmoticonIndex) {
        // if all emoticons are checked nothing happens next.
        if (!resultIndexNotChecked) {
          handleCommonValidationEmoji();
          handleNextEmoji(selectedEmoticonIndex, allObjects[selectedEmoticonIndex]);
        }
        // if at least one emoticon is not checked , goes to first one of the list
        else {
          handleCommonValidationEmoji();
          handleNextEmoji(resultIndexNotChecked, descriptionModalAfterLastEmoticon);
        }
      } else {
        // for all emoticons except index 10, we go to next emoticon : index of emoticon + 1.
        handleCommonValidationEmoji();
        handleNextEmoji(selectedEmoticonIndex + 1, descriptionModalNextEmoticon);
      }
    }
  }, [
    answersState,
    dispatch,
    idSessionHasRoom,
    selectedEmoticon.id,
    selectedEmoticon?.isChecked,
    selectedEmoticonIndex,
    soundtrack,
    wrongEmoticonCount,
    allObjects,
    lastEmoticonIndex,
  ]);

  // Effects if some of the answers are incorrect
  useEffect(() => {
    if (answersState === 'wrong' && !selectedEmoticon?.isChecked) {
      if (soundtrack) new Audio(failSound).play();
      setWrongEmoticonCount((prevState) => prevState + 1);
    }
  }, [answersState, selectedEmoticon?.isChecked, soundtrack]);

  // Reset selected emotions when we switch from one emoticone to another
  useEffect(() => {
    let retrieveAnswer;
    if (selectedEmoticon?.isChecked) {
      retrieveAnswer = rightAnswers;
    } else if (answersState === 'unanswered') {
      retrieveAnswer = initialUserAnswers;
    } else {
      retrieveAnswer = userAnswers;
    }
    setSelectedEmotions(retrieveAnswer);
  }, [dispatch, selectedEmoticon, rightAnswers, userAnswers, answersState]);

  // Retrieve the right answers for each validated emoticon when we refresh the page
  useEffect(() => {
    // Execute the effect only once at mouting
    if (isMounted) return;

    isMounted = true;
    if (validatedEmoticons.length) {
      validatedEmoticons.forEach((validatedEmoticon) => {
        dispatch({
          type: 'SET_USER_ANSWERS',
          payload: {
            emoticonId: validatedEmoticon.id,
            userAnswers: enigma[validatedEmoticon.id].rightAnswers,
          },
        });
      });
    }
  }, [dispatch, enigma, selectedEmoticon, validatedEmoticons]);

  /**
   * Handle the behaviour when the button Validate/Try again/Next
   * is clicked
   */
  const handleValidateClick = async (event) => {
    switch (buttonTitle) {
      case validate:
        dispatch({
          type: 'SET_USER_ANSWERS',
          payload: {
            emoticonId: selectedEmoticon.id,
            userAnswers: selectedEmotions,
          },
        });
        clickCount(dispatch, event);
        break;
      case next:
        dispatch({
          type: 'CLOSE_MODAL_ENIGMA_EMOTION',
        });
        // Save in DB to retrieve the prevention message in case of reload
        await putSessionsHasRoom(idSessionHasRoom, {
          current_step: preventionMessage.id,
          start_message: startedMessage,
        });
        // Launch the prevention message
        dispatch({
          type: 'CURRENT_STEP',
          payload: preventionMessage.id,
        });
        dispatch({
          type: 'START_MESSAGE_PREVENTION',
        });
        break;
      default:
        dispatch({
          type: 'RESET_USER_ANSWERS',
          payload: {
            emoticonId: selectedEmoticon.id,
          },
        });
        clickCount(dispatch, event);
        break;
    }
  };
  const handleCloseClick = () => {
    // Same behaviour than when the user deselects an emoticon
    dispatch({
      type: 'CLICKED_OBJECT',
      payload: {
        index: selectedEmoticonIndex,
        isClicked: selectedEmoticon.isClicked + 1,
        type: 'clicked',
        status: 'background',
      },
    });
  };

  return (
    <EmotionPanelStyle
      className={styles['emotion-panel']}
      viewportHeight={window.innerHeight}
      bubbleHeight={narratorTextHeaderHeight}
    >
      <button
        type="button"
        className={styles['close-button']}
        onClick={handleCloseClick}
      >
        <img
          src={closeImageUrl}
          alt={t('buttonFunction.close', { ns: 'common' })}
        />
      </button>

      {emotionIntensities.map((intensity, intensityIndex) => (
        <EmotionsSelection
          intensityIndex={intensityIndex}
          setSelectedEmotions={setSelectedEmotions}
          selectedEmotions={selectedEmotions}
          key={intensity}
          intensityName={intensity}
        />
      ))}
      <div className={styles['button-container']}>
        <Button
          onClick={(e) => handleValidateClick(e)}
          title={buttonTitle}
          disabled={isButtonDisabled}
          additionalStyle={{
            fontSize: '1rem',
            background: 'linear-gradient(80.62deg, #C4E1D2 3.36%, #85CCC9 98.14%)',
            boxShadow: '3px 3px 15px rgba(0, 0, 0, 0.2)',
            borderRadius: '10px',
            border: '2px solid #427E89',
            height: '31px',
            width: '114px',
            color: '#094747',
            padding: '0',
            opacity: isButtonDisabled ? '0.3' : '1',
            ':hover': {
              cursor: isButtonDisabled ? 'not-allowed' : 'pointer',
              background: !isButtonDisabled && 'transparent',
            },
          }}
        />
      </div>
    </EmotionPanelStyle>
  );
}

export default EmotionsPanel;
