import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// components
import IntroMessage from 'components/GameBoard/ReusableComponents/Actions/IntroMessage';

// css
import styles from 'assets/css/components/GameBoard/ReusableComponents/Intro.module.scss';
// utils
import { beginGame } from 'utils/utilityFunctions';

// functions
import { selectSession } from 'components/Reducers/session';
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';

/**
 * Generic component to display the introduction modal and
 * the different messages
 */
function Intro({ translationFileName, modalAfterIntro }) {
  const dispatch = useDispatch();

  const session = useSelector(selectSession);
  const { socket } = useSelector(infoGameUser);
  const room = session.id;

  // `t` is a function coming from the i18next library used to translate text
  // Load the right namespace = the one corresponding to the game
  const { t } = useTranslation(translationFileName, 'common');

  // Access the i18next arrays
  const messages = t('introMessage.messages', {
    returnObjects: true,
  });
  const buttonMessage = t('introMessage.buttonMessage', {
    returnObjects: true,
  });
  const titles = t('introMessage.titles', {
    returnObjects: true,
  });

  // State to store the titles and messages to display inside IntroMessage
  const [index, setIndex] = useState(0);

  useEffect(() => {
    socket?.on('receive_start_game', (data) => {
      dispatch({ type: 'START_GAME', payload: data.isStartedGame });
    });
    socket?.on('receive_pause_timer', (data) => {
      dispatch({ type: 'PAUSE_TIMER', payload: data.isPauseTimer });
    });
  }, [dispatch, socket]);

  /**
   * Update the message and the title at each click until we reach the last ones.
   * It is the number of messages that drive the update. The particular case of having more titles
   * than messages is not handled.
   * For the particular case where we want to display 1 title and 1 message,
   * then update the message (and not the title), then update the message and the title, the workaround is to duplicate the tile text:
   *    "titles": ["title1","title1", "title3"],
        "messages": ["message1", "message2","message3"],
   */
  const handleClick = async () => {
    if (index + 1 < messages.length) {
      setIndex(index + 1);
    } else {
      // We reach the last message so we start the game
      await beginGame(session.id, dispatch, socket, room);
      if (modalAfterIntro) {
        const descriptionModal = t('modalAfterIntro', { returnObjects: true });
        const responsesSocket = { descriptionModal, room };
        socket?.emit('send_open_modal', responsesSocket);
        dispatch({
          type: 'OPEN_MODAL',
          payload: descriptionModal,
        });
      }
    }
  };

  return (
    <div className={styles['intro-container']}>
      <div>
        <IntroMessage
          title={titles[index]}
          message={messages[index]}
          buttonTitle={t(buttonMessage, { ns: 'common' })}
          clickFunction={handleClick}
        />
      </div>
    </div>
  );
}

Intro.propTypes = {
  translationFileName: PropTypes.string.isRequired,
  modalAfterIntro: PropTypes.bool,
};

Intro.defaultProps = {
  modalAfterIntro: false,
};

export default Intro;
