import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

// components
import DialogueRow from 'components/GameBoard/OffreSocle/Enigmas/DialogueRow';

// SCSS
import styles from 'components/GameBoard/OffreSocle/Enigmas/Dialogue.module.scss';

const Dialogue = ({
  dialogueText,
  smallView,
  character1,
  character2,
  dialogueButtons,
  directionStart,
}) => {
  const [indexDialogue, setIndexDialogue] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (indexDialogue < dialogueText.length) {
        setIndexDialogue(indexDialogue + 1);
      } else {
        clearInterval(interval);
      }
    }, dialogueText[indexDialogue]?.duration);
    return () => clearInterval(interval);
  }, [dialogueText, dialogueText.length, indexDialogue]);

  // Define the order of display between the picture and the text in DialogueRow
  const handleRowDirection = (rowIndex) => {
    if (!rowIndex) {
      return directionStart;
    }
    if (rowIndex % 2 && directionStart === 'row') {
      return 'row-reverse';
    }
    return 'row';
  };

  const handleButtons = () => {
    if (indexDialogue === dialogueText.length && dialogueButtons) {
      return dialogueButtons;
    }
    return null;
  };

  return (
    <div className={`${styles.discussion} ${smallView && styles.smallView}`}>
      <motion.div className={styles['dialogue-container']}>
        {dialogueText
          .filter((row, rowIndex) => rowIndex <= indexDialogue)
          .map((row, rowIndex) => {
            return (
              <DialogueRow
                characterImage={rowIndex % 2 ? character1 : character2}
                key={dialogueText[rowIndex].id}
                direction={handleRowDirection(rowIndex)}
                dialogueText={dialogueText[rowIndex]}
                smallView={smallView}
              />
            );
          })}
        {handleButtons()}
      </motion.div>
    </div>
  );
};

Dialogue.propTypes = {
  dialogueText: PropTypes.arrayOf(PropTypes.string).isRequired,
  smallView: PropTypes.bool,
  character1: PropTypes.string.isRequired,
  character2: PropTypes.string.isRequired,
  dialogueButtons: PropTypes.shape({}),
  directionStart: PropTypes.string,
};

Dialogue.defaultProps = {
  smallView: false,
  dialogueButtons: undefined,
  directionStart: 'row',
};

export default Dialogue;
