import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Components
import Button from 'components/GameBoard/ReusableComponents/Actions/Button';
import CompanyView from 'components/GameBoard/OffreSocle/Enigmas/CompanyView';
import Dialogue from 'components/GameBoard/OffreSocle/Enigmas/Dialogue';
// Style & constants
import styles from 'components/GameBoard/OffreSocle/Enigmas/VideoConference.module.scss';
import { referringDoctorOffice } from 'components/GameBoard/OffreSocle/constants';
import { visioConfObjectId } from 'components/GameBoard/ReusableComponents/Objects/constants';
// Selector functions
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';
import {
  selectObject,
  selectObjectIndex,
} from 'components/Reducers/GameData/fetchObjects';

import { putSessionsHasRoomHasObject } from 'API/sessionsHasRoomHasObject';
import { selectOffreSocle } from 'components/Reducers/offreSocle';

const {
  computerScreen,
  videoConfBackground,
  doctorFace,
  companyView,
  directorFace,
  videoConfStep,
  companyViewStep,
} = referringDoctorOffice;

const VideoConference = () => {
  const { t } = useTranslation('offreSocle', 'common');

  const dispatch = useDispatch();
  const visioConfObject = useSelector((state) =>
    selectObject(state, visioConfObjectId)
  );

  const visioConfIndex = useSelector((state) =>
    selectObjectIndex(state, visioConfObject.id)
  );
  const { idSessionHasRoom } = useSelector(infoGameUser);
  const { enigmaStep } = useSelector(selectOffreSocle);

  const dialogueText = t('doctorDialogue1', {
    returnObjects: true,
  });

  // When we refresh the game
  useEffect(() => {
    if (visioConfObject.isChecked) {
      dispatch({ type: 'SET_ENIGMA_STEP', payload: { step: companyViewStep } });
      dispatch({ type: 'MUTE_MUSIC', payload: true });
    }
    return () => {
      dispatch({ type: 'MUTE_MUSIC', payload: false });
    };
  }, [visioConfObject.isChecked, dispatch]);

  const VideoConferenceView = () => {
    return (
      <div className={styles['video-conf-container']}>
        <div className={styles['img-container']}>
          <img
            src={computerScreen}
            alt={t('computer')}
            className={styles.screen}
            width="100%"
            height="100%"
          />
          <img
            src={videoConfBackground}
            alt={t('videoConference')}
            className={styles['visio-background']}
          />
          <img src={companyView} alt="company view" className={styles.company} />
          <div className={styles['dialogue-container']}>
            <Dialogue
              dialogueText={dialogueText}
              smallView
              character1={directorFace}
              character2={doctorFace}
              dialogueButtons={
                <Button
                  title={t('buttonFunction.close', { ns: 'common' })}
                  buttonType="offreSocle"
                  additionalStyle={{ width: '95%' }}
                  onClick={async () => {
                    const visioConfComputerImages = visioConfObject?.images.find(
                      (object) => object.type === 'background'
                    );
                    const closedDesktop = new Audio(visioConfComputerImages.sound);
                    closedDesktop.volume = 0.1;
                    closedDesktop.play();
                    dispatch({
                      type: 'CLICKED_OBJECT',
                      payload: {
                        type: 'checked',
                        index: visioConfIndex,
                        isChecked: 1,
                      },
                    });
                    await putSessionsHasRoomHasObject(idSessionHasRoom, {
                      object_id: visioConfObject.id,
                      isChecked: 1,
                    });
                    dispatch({
                      type: 'SET_ENIGMA_STEP',
                      payload: { step: companyViewStep },
                    });
                  }}
                />
              }
            />
          </div>
        </div>
      </div>
    );
  };

  const handleEnigmaDisplay = () => {
    switch (enigmaStep) {
      case videoConfStep:
        return VideoConferenceView();
      case companyViewStep:
        return <CompanyView />;
      default:
        return undefined;
    }
  };

  return handleEnigmaDisplay();
};

export default VideoConference;
