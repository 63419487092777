import { useState } from 'react';

// TO REFACTO SCSS AND TRANSLATION
import '../../../../../assets/css/components/GameBoard/Obesity/Enigma/Phone/Calculator.css';

function Calculator() {
  const [currentNumber, setCurrentNumber] = useState('0');
  const [operator, setOperator] = useState(undefined);
  const [negative, setNegative] = useState(undefined);

  const handleClick = (button) => {
    const { innerText } = button.target;

    if (currentNumber === '0') {
      setCurrentNumber(innerText);
    } else if (innerText !== 'DEL') {
      setCurrentNumber(currentNumber + innerText);
    }

    switch (innerText) {
      case 'AC': {
        setCurrentNumber('0');
        setOperator(undefined);
        setNegative(undefined);
        break;
      }

      case '=': {
        // eslint-disable-next-line no-eval
        const evaluated = eval(currentNumber);
        setCurrentNumber(evaluated);
        setOperator(undefined);
        setNegative(undefined);
        break;
      }

      case 'DEL': {
        setCurrentNumber(currentNumber.slice(0, -1));
        setOperator(undefined);
        setNegative(undefined);

        if (currentNumber.length === 1) {
          setCurrentNumber('0');
          setOperator(undefined);
        }
        break;
      }

      case '+':
      case '*':
      case '/': {
        setCurrentNumber(currentNumber + innerText);
        setOperator(true);
        if (operator === true) {
          setCurrentNumber(currentNumber);
        }
        break;
      }

      case '-': {
        setNegative(true);

        if (negative === true) {
          setCurrentNumber(currentNumber);

          break;
        }
      }
      // eslint-disable-next-line no-fallthrough
      default:
        break;
    }
  };

  return (
    <div className="Calculator">
      <div id="display" className="display">
        {currentNumber}
      </div>
      <button
        type="button"
        onClick={(e) => handleClick(e)}
        className="dark-gray larger"
      >
        AC
      </button>
      <button type="button" onClick={(e) => handleClick(e)} className="dark-gray">
        DEL
      </button>
      <button type="button" onClick={(e) => handleClick(e)} className="orange">
        /
      </button>
      <button type="button" onClick={(e) => handleClick(e)} className="darker">
        7
      </button>
      <button type="button" onClick={(e) => handleClick(e)} className="darker">
        8
      </button>
      <button type="button" onClick={(e) => handleClick(e)} className="darker">
        9
      </button>
      <button type="button" onClick={(e) => handleClick(e)} className="orange">
        *
      </button>
      <button type="button" onClick={(e) => handleClick(e)} className="darker">
        4
      </button>
      <button type="button" onClick={(e) => handleClick(e)} className="darker">
        5
      </button>
      <button type="button" onClick={(e) => handleClick(e)} className="darker">
        6
      </button>
      <button type="button" onClick={(e) => handleClick(e)} className="orange">
        -
      </button>
      <button type="button" onClick={(e) => handleClick(e)} className="darker">
        1
      </button>
      <button type="button" onClick={(e) => handleClick(e)} className="darker">
        2
      </button>
      <button type="button" onClick={(e) => handleClick(e)} className="darker">
        3
      </button>
      <button type="button" onClick={(e) => handleClick(e)} className="orange">
        +
      </button>
      <button
        type="button"
        onClick={(e) => handleClick(e)}
        className="darker larger"
      >
        0
      </button>
      <button type="button" onClick={(e) => handleClick(e)} className="darker">
        .
      </button>
      <button type="button" onClick={(e) => handleClick(e)} className="orange">
        =
      </button>
    </div>
  );
}

export default Calculator;
