import styles from 'components/Joyride/Steps.module.scss';
import * as images from '../../../img/vaccination-solo/index';

const inventoryStep = (t) => {
  const step = {
    target: '.Inventory',
    content: (
      <div className={styles['main-content']}>
        <h2>{t('joyride.inventory.title')}</h2>
        <img src={images.trickyCoin} alt="Tricky" />
        <p>{t('joyride.inventory.description')}</p>
      </div>
    ),
    placement: 'bottom',
  };
  return step;
};

export default inventoryStep;
