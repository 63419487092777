import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { selectQuestionnary } from 'components/Reducers/questionnary';
import PlutchikWheelType from 'components/GameBoard/ReusableComponents/Multi/Shared/Questionnary/Type/PlutchikWheelType/PlutchikWheelType';
import {
  linkAnswerBetweenQuestion,
  linkUserSurveySession,
  passToTheNextUser,
  retrieveQuestionnaryDebrief,
  retrieveSurvey,
} from 'utils/utilityFunctions';
import { selectSession } from 'components/Reducers/session';
import { selectUser } from 'components/Reducers/user';
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';
import { questionDebriefId } from 'components/GameBoard/ReusableComponents/Multi/constants';

const SurveyDebrief = ({ setStatus }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('emotion-multi');

  const { list, survey, userHasSurveyId } = useSelector(selectQuestionnary);
  const session = useSelector(selectSession);
  const { id, currentUser, participants } = useSelector(selectUser);

  const [activeIndex, setActiveIndex] = useState(0);
  const { socket } = useSelector(infoGameUser);
  const room = session.id;

  const { isMainUser, type } = participants[Object.keys(currentUser)[0]];

  useEffect(() => {
    const descriptionModal = t('surveyDebrief', { returnObjects: true });
    const responsesSocket = { descriptionModal, room };
    socket?.emit('send_open_modal', responsesSocket);
    dispatch({
      type: 'OPEN_MODAL',
      payload: descriptionModal,
    });
    retrieveQuestionnaryDebrief(questionDebriefId, dispatch);
    retrieveSurvey(questionDebriefId, dispatch);
  }, [dispatch, room, socket, t]);

  useEffect(() => {
    if (survey.id) {
      // init UserHasSurveyId (link between user and survey) The user need
      // this id to answer of the survey
      linkUserSurveySession(id, survey.id, session.id, dispatch);
    }
  }, [dispatch, id, session.id, survey.id]);

  useEffect(() => {
    socket.on('receive_status_debrief', (data) => {
      setStatus(data.status);
    });
    socket.on('receive_active_index', (data) => {
      setActiveIndex(data.index);
    });
  }, [setStatus, socket]);

  const handleAnswer = (
    answer,
    question,
    isNew,
    answerTwo,
    setFirstClick,
    setSecondClick
  ) => {
    const nextIndexQuestion = activeIndex + 1;
    let responsesSocket = {
      room,
    };
    socket?.emit('send_reset_clicked_emotion_plutchickwheel', responsesSocket);
    setFirstClick(null);
    setSecondClick(null);

    // Next question
    if (isMainUser || type === 'admin') {
      if (nextIndexQuestion < list.length) {
        responsesSocket = {
          ...responsesSocket,
          index: nextIndexQuestion,
          isWait: false,
        };
        socket?.emit('send_active_index', responsesSocket);
        socket?.emit('send_is_waiting', responsesSocket);

        const mainUserId = Object.keys(participants).find(
          (participant) => participants[participant].isMainUser
        );
        const nextCounter = participants[mainUserId].counter - 1;
        const responses = {
          mainUserId,
          nextCounter,
          room,
        };
        passToTheNextUser(participants, responses, socket, dispatch, mainUserId);
        setActiveIndex(nextIndexQuestion);
      } else {
        responsesSocket = {
          ...responsesSocket,
          status: 'situation',
        };
        socket?.emit('send_status_debrief', responsesSocket);
        setStatus('situation');
      }
    }

    // link user, question, survey, response but if response don't exist we need to
    // create response and link between response and question
    linkAnswerBetweenQuestion(
      answer,
      question.id,
      userHasSurveyId,
      dispatch,
      isNew
    );

    if (answerTwo) {
      linkAnswerBetweenQuestion(
        answerTwo,
        question.id,
        userHasSurveyId,
        dispatch,
        isNew
      );
    }
  };
  const handleReturn = () => {
    const pastIndexQuestion = activeIndex - 1;
    setActiveIndex(pastIndexQuestion);
  };

  return (
    <div>
      <PlutchikWheelType
        question={list[activeIndex]}
        activeIndex={activeIndex}
        handleAnswer={handleAnswer}
        handleReturn={handleReturn}
        isStoryline
        doubleResponses
      />
    </div>
  );
};

SurveyDebrief.propTypes = {
  setStatus: PropTypes.func.isRequired,
};

export default SurveyDebrief;
