import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import io from 'socket.io-client';
import { gameIdMultiEmotion } from 'components/constants';
// selector
import { selectSession } from 'components/Reducers/session';
import { selectInfoGame } from 'components/Reducers/game';

// components
import GameEmotionMulti from 'components/GameBoard/Emotion/Multi/GameEmotionMulti';
import CurrentParticipant from 'components/GameBoard/ReusableComponents/Multi/CheckRoom/CurrentParticipant';

const baseURL = process.env.REACT_APP_BASE_URL;

function GameMulti() {
  const dispatch = useDispatch();

  const [statusCheckRoom, setStatusCheckRoom] = useState('current-participant');

  const session = useSelector(selectSession);
  const game = useSelector(selectInfoGame);

  useEffect(() => {
    if (game.type === 'multi') {
      const socket = io.connect(baseURL);
      socket.on('connect', () => {
        dispatch({
          type: 'INIT_SOCKET',
          payload: socket,
        });
      });
      const room = session.id;
      if (room) {
        socket?.emit('join_room', room);
      }
    }
  }, [dispatch, game.type, session.id]);

  // Launch the checkRoom with current partcipant
  if (statusCheckRoom === 'current-participant') {
    return <CurrentParticipant setStatusCheckRoom={setStatusCheckRoom} />;
  }

  // Launch the correct game
  switch (game.id) {
    case gameIdMultiEmotion:
      return (
        <GameEmotionMulti
          setStatusCheckRoom={setStatusCheckRoom}
          statusCheckRoom={statusCheckRoom}
        />
      );
    default:
      return <p>The game id doesn&apos;t refer to any component game</p>;
  }
}

export default GameMulti;
