import PropTypes from 'prop-types';

// components
import Messaging from 'components/GameBoard/Emotion/Multi/Enigma/Desktop/PageScreen/Messaging';
import VideoApplication from 'components/GameBoard/Emotion/Multi/Enigma/Desktop/PageScreen/VideoApplication';
import NoteApplication from 'components/GameBoard/Emotion/Multi/Enigma/Desktop/PageScreen/NoteApplication';

const PageScreen = ({ typeApplication }) => {
  switch (typeApplication) {
    case 'message':
      return <Messaging />;
    case 'video':
      return <VideoApplication />;
    case 'note':
      return <NoteApplication />;
    default:
      return null;
  }
};

PageScreen.propTypes = {
  typeApplication: PropTypes.string,
};

PageScreen.defaultProps = {
  typeApplication: null,
};

export default PageScreen;
