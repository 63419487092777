import { lazy, Suspense, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  gameIdSoloVaccination,
  gameIdSoloTDE,
  gameIdSoloObesity,
  gameIdSoloTMS,
  gameIdSoloRPS,
  gameIdSoloTMSCongres,
  gameIdSoloRPSCongres,
  gameIdSoloEmotion,
  gameIdSoloOffreSocle,
} from 'components/constants';

import GameTDESolo from './GameBoard/TDE/GameTDESolo';
import GameObesitySolo from './GameBoard/Obesity/GameObesitySolo';
import LoaderVaccination from './GameBoard/Vaccination/LoaderVaccination';
import GameTMSSolo from './GameBoard/TMS/GameTMSSolo';
import GameRPSSolo from './GameBoard/RPS/GameRPSSolo';
import GameTMSCongresSolo from './GameBoard/TMSCongres/GameTMSCongresSolo';
import GameRPSCongresSolo from './GameBoard/RPSCongres/GameRPSCongresSolo';
import GameOffreSocleSolo from './GameBoard/OffreSocle/GameOffreSocleSolo';
import GameEmotionSolo from './GameBoard/Emotion/Solo/GameEmotionSolo';

const GameVaccinationSolo = lazy(() => {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise((resolve) => setTimeout(resolve, 4 * 1000)).then(() =>
    import('./GameBoard/Vaccination/GameVaccinationSolo')
  );
});

function GameSolo({ dispatch, game }) {
  useEffect(() => {
    return () => {
      dispatch({ type: 'PAUSE_TIMER', payload: true });
    };
  }, [dispatch]);

  // Launch the correct game
  switch (game.id) {
    case gameIdSoloTDE:
      return <GameTDESolo />;
    case gameIdSoloObesity:
      return <GameObesitySolo />;
    case gameIdSoloTMS:
      return <GameTMSSolo />;
    case gameIdSoloRPS:
      return <GameRPSSolo />;
    case gameIdSoloTMSCongres:
      return <GameTMSCongresSolo />;
    case gameIdSoloRPSCongres:
      return <GameRPSCongresSolo />;
    case gameIdSoloVaccination:
      return (
        <Suspense maxDuration={400} fallback={<LoaderVaccination />}>
          <GameVaccinationSolo />
        </Suspense>
      );
    case gameIdSoloEmotion:
      return <GameEmotionSolo />;
    case gameIdSoloOffreSocle:
      return <GameOffreSocleSolo />;
    default:
      return <div>GameSolo1</div>;
  }
}

GameSolo.propTypes = {
  dispatch: PropTypes.func.isRequired,
  game: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  game: state.game,
});

export default connect(mapStateToProps)(GameSolo);
