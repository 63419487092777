import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DatePicker from 'react-date-picker';
import axios from 'axios';
import Button from '../../../ReusableComponents/Actions/Button';

import '../../../../../assets/css/components/GameBoard/Obesity/Enigma/Television/Timer.css';

const BASE_URL = process.env.REACT_APP_BASE_URL;

function Timer({ value, onChange, allObjects, idSessionHasRoom, dispatch }) {
  const dateString = new Date(value);

  const month = dateString.getMonth() + 1;
  const day = dateString.getDate();
  const year = dateString.getFullYear();
  const idTimeOne = 196;
  const idTimeTwo = 197;
  const idTimeThree = 198;

  const goodDates = {
    '1/1/2017': {
      start: '17H53',
      end: '22H45',
      passed: '04H52',
      id: idTimeOne,
    },
    '1/6/2017': {
      start: '14H27',
      end: '23H30',
      passed: '09H03',
      id: idTimeTwo,
    },
    '2/12/2017': {
      start: '19H36',
      end: '00H45',
      passed: '05H09',
      id: idTimeThree,
    },
  };

  const stringDate = `${day}/${month}/${year}`;

  const addInventory = (objectElement, index) => {
    axios
      .put(`${BASE_URL}/api/sessionsHasRoomHasObject/${idSessionHasRoom}`, {
        object_id: objectElement.id,
        isInventory: true,
      })
      .then(() => {
        dispatch({
          type: 'CLICKED_OBJECT',
          payload: {
            index,
            isInventory: true,
            type: 'inventory',
          },
        });
        dispatch({
          type: 'ADD_INVENTORY',
          payload: objectElement,
        });
        dispatch({
          type: 'INCREMENT_COUNTTER',
        });
      });
  };

  const handleObjectTimeInventory = (date) => {
    const timeObject = allObjects.find(
      (objectContent) => objectContent.id === date.id
    );
    const timeIndex = allObjects.findIndex(
      (objectContent) => objectContent.id === date.id
    );
    addInventory(timeObject, timeIndex);
    dispatch({ type: 'CLOSE_MODAL_ENIGMA' });
  };

  const handleTimePassed = () => {
    if (value !== null) {
      if (goodDates[stringDate] !== undefined) {
        return (
          <>
            <p>Voici le temps total que vous avez passé devant votre écran</p>
            <div className="timer-tv-result-number">
              {goodDates[stringDate].passed}
            </div>
            <p>Heure d&apos;allumage</p>
            <div className="timer-tv-result-number">
              {goodDates[stringDate].start}
            </div>
            <p className="timer-tv-text-turn-off">Heure d&apos;extinction</p>
            <div className="timer-tv-result-number">
              {goodDates[stringDate].end}
            </div>
            <Button
              buttonType="action"
              title="Enregistrer"
              onClick={() => handleObjectTimeInventory(goodDates[stringDate])}
            />
          </>
        );
      }
      return <p>Cette donnée n&apos;est pas disponible</p>;
    }
    return <p>Entrer vos données</p>;
  };

  const allTimerInInventory = [];
  allObjects
    .filter(
      (objectContent) =>
        objectContent.id === idTimeOne ||
        objectContent.id === idTimeTwo ||
        objectContent.id === idTimeThree
    )
    .map(
      (objectContent) =>
        objectContent.isInventory && allTimerInInventory.push(objectContent)
    );

  if (allTimerInInventory.length === 3) {
    return (
      <p>Vous avez toutes les données qu&apos;il vous faut dans votre inventaire</p>
    );
  }

  return (
    <>
      <div className="timer-tv">
        <DatePicker
          calendarClassName="Date"
          onChange={onChange}
          value={null || value}
          disableCalendar
        />
      </div>
      <div className="timer-tv-text">{handleTimePassed()}</div>
    </>
  );
}

const mapStateToProps = (state) => ({
  allObjects: state.Objects.AllObjects,
  idSessionHasRoom: state.GameUsers.idSessionHasRoom,
});

Timer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  allObjects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
  idSessionHasRoom: PropTypes.number.isRequired,
  value: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(Timer);
