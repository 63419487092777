import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';

// components
import Button from 'components/GameBoard/ReusableComponents/Actions/Button';

// utils
import {
  addInventory,
  checkedObject,
  clickCount,
  clickCountUser,
} from 'utils/utilityFunctions';

// constant
import {
  holeMessage,
  inputsMessaging,
  dangerIcon,
  offsetMessageHint,
} from 'components/GameBoard/Emotion/Multi/constant';
import {
  computerRoomEmotionEnigma,
  rebusOfficeMultiEmotion,
} from 'components/GameBoard/ReusableComponents/Objects/constants';

// scss
import styles from 'components/GameBoard/Emotion/Multi/Enigma/Desktop/PageScreen/Messaging.module.scss';

// selectors
import { selectModalEnigma } from 'components/Reducers/Modal/Modal';
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';
import {
  selectObject,
  selectObjectIndex,
} from 'components/Reducers/GameData/fetchObjects';
import { selectSession } from 'components/Reducers/session';
import { selectUser } from 'components/Reducers/user';

const Messaging = () => {
  const { t } = useTranslation(['emotion-multi', 'common']);

  const session = useSelector(selectSession);
  const { socket } = useSelector(infoGameUser);
  const room = session.id;

  const [statusMessaging, setStatusMessaging] = useState(null);
  const [inputs, setInputs] = useState(inputsMessaging);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const { description } = useSelector(selectModalEnigma);
  const { idSessionHasRoom } = useSelector(infoGameUser);
  const { participants } = useSelector(selectUser);

  const computerRoomEmotionMultiObject = useSelector((state) =>
    selectObject(state, computerRoomEmotionEnigma)
  );

  const rebusObject = useSelector((state) =>
    selectObject(state, rebusOfficeMultiEmotion)
  );

  const messagingIndex = useSelector((state) =>
    selectObjectIndex(state, computerRoomEmotionMultiObject.object_id)
  );

  const indexRebus = useSelector((state) =>
    selectObjectIndex(state, rebusOfficeMultiEmotion)
  );

  useEffect(() => {
    if (
      computerRoomEmotionMultiObject.isChecked ||
      computerRoomEmotionMultiObject.isInventory
    ) {
      const statusMessage = 'empty';
      const responsesSocket = {
        status: statusMessage,
        room,
      };
      socket?.emit('send_emotion_multi_status_messaging_computer', responsesSocket);
      setStatusMessaging(statusMessage);
    }
  }, [computerRoomEmotionMultiObject, room, socket]);

  useEffect(() => {
    socket.on('receive_emotion_multi_inputs_messaging_computer', (data) => {
      setInputs({
        ...inputs,
        [`${data.type}`]: {
          ...inputs[data.type],
          text: data.value,
        },
      });
    });
    socket.on(
      'receive_emotion_multi_clean_wrong_inputs_messaging_computer',
      (data) => {
        setInputs(data);
      }
    );
    socket.on('receive_error_messaging', (data) => {
      setError(data.errorContent);
    });
    socket.on('receive_emotion_multi_status_messaging_computer', (data) => {
      setStatusMessaging(data.status);
    });
  }, [inputs, socket, error]);

  const handleInput = (type) => {
    return (
      <>
        <input
          name={type}
          type="text"
          style={{
            width: `${inputs[type].answer.text.length * 12.5}px`,
          }}
          onChange={(e) => {
            const inputsContent = {
              type,
              value: e.target.value.toLowerCase(),
            };

            setInputs({
              ...inputs,
              [`${inputsContent.type}`]: {
                ...inputs[inputsContent.type],
                text: inputsContent.value,
              },
            });
            const responsesSocket = {
              ...inputsContent,
              room,
            };
            socket?.emit(
              'send_emotion_multi_inputs_messaging_computer',
              responsesSocket
            );
          }}
          value={inputs[type].text}
        />

        <div
          className={styles.hint}
          data-tooltip-id={type}
          data-tooltip-content={inputs[type].answer.hint}
          data-tooltip-place="top"
          offset={offsetMessageHint}
        >
          ?
        </div>
        <Tooltip id={type} events={['click']} variant="dark" />
      </>
    );
  };

  const cleanWrongInputs = () => {
    const listTypeInput = Object.keys(inputs);

    const newInputArray = inputs;

    listTypeInput.map((type) => {
      if (newInputArray[type].text !== newInputArray[type].answer.text) {
        newInputArray[type].text = '';
        return newInputArray;
      }
      return null;
    });
    const responsesSocket = {
      ...newInputArray,
      room,
    };
    socket?.emit(
      'send_emotion_multi_clean_wrong_inputs_messaging_computer',
      responsesSocket
    );
    setInputs(newInputArray);
  };

  const handleAnswers = (event) => {
    let errorMessage = null;
    let responsesSocket;

    responsesSocket = {
      errorContent: errorMessage,
      room,
    };

    const allAnswersNotRetrieve = Object.keys(inputs).find(
      (inputType) => inputs[inputType].text !== inputs[inputType].answer.text
    );

    clickCount(dispatch, event, socket, responsesSocket);
    if (socket) {
      clickCountUser(participants, socket, dispatch, session);
    }

    if (allAnswersNotRetrieve === undefined) {
      const statusMessage = 'finish';
      responsesSocket = {
        status: statusMessage,
        room,
      };
      socket?.emit('send_emotion_multi_status_messaging_computer', responsesSocket);
      return setStatusMessaging(statusMessage);
    }
    cleanWrongInputs();

    errorMessage = t('desktop.email.errorHole');
    responsesSocket = {
      errorContent: errorMessage,
      room,
    };
    socket?.emit('send_error_messaging', responsesSocket);
    setTimeout(() => {
      errorMessage = null;
      responsesSocket = {
        errorContent: errorMessage,
        room,
      };
      setError(errorMessage);
      socket?.emit('send_error_messaging', responsesSocket);
    }, 3000);

    return setError(errorMessage);
  };

  const handleMessage = (text) => {
    return (
      <div key={text} className={styles.message}>
        <p>{text}</p>
      </div>
    );
  };

  const handleMessages = (messages) => {
    return messages.map((message) => handleMessage(message));
  };

  const handleMessageHole = (message) => {
    return message.map((text) =>
      typeof text === 'string' ? `${text} ` : handleInput(text.type)
    );
  };

  const handleMessaging = () => {
    if (statusMessaging === 'empty') {
      return null;
    }
    if (statusMessaging === 'responding') {
      return (
        <div className={styles.email}>
          <div className={styles.messages}>
            <div>
              {handleMessages(
                t('desktop.email.secondMessages', {
                  returnObjects: true,
                })
              )}
              <p>{handleMessageHole(holeMessage)}</p>
              {handleMessages(
                t('desktop.email.fourthMessages', {
                  returnObjects: true,
                })
              )}
            </div>
            {error && (
              <div className={styles.error}>
                <img src={dangerIcon} alt="alert" />
                <p>{error}</p>
              </div>
            )}
            <Button
              onClick={(event) => handleAnswers(event)}
              buttonType="action"
              title={t('desktop.email.sendButtonText')}
            />
          </div>
        </div>
      );
    }
    if (statusMessaging === 'finish') {
      return (
        <div className={styles.email}>
          <div className={styles.messages}>
            {handleMessages(
              t('desktop.email.lastMessages', {
                returnObjects: true,
              })
            )}
          </div>
          <Button
            onClick={(event) => {
              addInventory(
                dispatch,
                idSessionHasRoom,
                description.content,
                messagingIndex,
                event,
                socket,
                room,
                participants,
                session
              );
              checkedObject(
                rebusObject,
                indexRebus,
                idSessionHasRoom,
                dispatch,
                socket,
                room
              );
            }}
            buttonType="action"
            title={t('buttonFunction.addInventory', { ns: 'common' })}
          />
        </div>
      );
    }
    return (
      <div className={styles.email}>
        <div className={styles.messages}>
          {handleMessages(
            t('desktop.email.firstMessages', {
              returnObjects: true,
            })
          )}
        </div>
        <Button
          onClick={() => {
            const statusMessage = 'responding';
            const responsesSocket = {
              status: statusMessage,
              room,
            };
            socket?.emit(
              'send_emotion_multi_status_messaging_computer',
              responsesSocket
            );
            setStatusMessaging(statusMessage);
          }}
          buttonType="action"
          title={t('buttonFunction.respond', { ns: 'common' })}
        />
      </div>
    );
  };

  return handleMessaging();
};

export default Messaging;
