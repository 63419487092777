import { referringDoctorOffice } from 'components/GameBoard/OffreSocle/constants';

const { videoConfStep } = referringDoctorOffice;

const initialState = {
  isVideoConfAnswered: false,
  enigmaStep: videoConfStep,
  locker: {
    360: {
      status: 'unanswered',
    },
    361: {
      status: 'unanswered',
    },
    362: {
      status: 'unanswered',
    },
  },
  phoneNumber: '',
  modalMessage: {
    isOpen: false,
    text: '',
    subText: '',
  },
};

const offreSocle = (state = initialState, action) => {
  switch (action.type) {
    case 'ANSWER_VIDEO_CONF':
      return {
        ...state,
        isVideoConfAnswered: true,
      };
    case 'SET_ENIGMA_STEP':
      return {
        ...state,
        enigmaStep: action.payload.step,
      };
    case 'SET_LOCKER_STATUS':
      return {
        ...state,
        locker: {
          ...state.locker,
          [action.payload.lockerId]: {
            ...state.locker[action.payload.lockerId],
            status: action.payload.status,
          },
        },
      };
    case 'SET_PHONE_NUMBER':
      return {
        ...state,
        phoneNumber: action.payload,
      };
    case 'OPEN_MODAL_MESSAGE_RESPONSE':
      return {
        ...state,
        modalMessage: {
          isOpen: true,
          text: action.payload.text,
          subText: action.payload.subText,
        },
      };
    case 'CLOSE_MODAL_MESSAGE_RESPONSE':
      return {
        ...state,
        modalMessage: {
          isOpen: false,
          text: '',
          subText: '',
        },
      };
    default:
      return state;
  }
};
// Define the selector functions
export const selectOffreSocle = (state) => state.offreSocle;

export default offreSocle;
