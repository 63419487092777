import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';

// components
import ObjectContent from 'components/GameBoard/ReusableComponents/Objects/ObjectContent';

import 'assets/css/components/GameBoard/ReusableComponents/Objects/ListObjects.css';

const BASE_URL = process.env.REACT_APP_BASE_URL;

function ListObjects({ dispatch, objects, idSessionHasRoom, currentRoom }) {
  const useOneTimeEffect = useRef(false);

  const idLamp = 147;
  const idLight = 148;
  const idCodeLight = 149;
  const lampObject = objects.filter(
    (objectContent) =>
      objectContent.id === idLamp && objectContent.status === 'open'
  );
  const lightObject = objects.filter(
    (objectContent) =>
      objectContent.id === idLight && objectContent.status === 'open'
  );

  const codeLightObject = objects.filter(
    (objectContent) => objectContent.id === idCodeLight
  );

  let isLampOpen;
  let isLightOpen;
  let isCodeInInventory;
  if (lampObject && objects && lampObject[0] && lightObject[0]) {
    isLampOpen = lampObject[0].status === 'open';
    isLightOpen = lightObject[0].status === 'open';
    isCodeInInventory = codeLightObject[0].isInventory;
  }
  useEffect(() => {
    if (idSessionHasRoom && !useOneTimeEffect.current) {
      const url = `${BASE_URL}/api/sessionsHasRoomHasObject/${idSessionHasRoom}/rooms/${currentRoom}/objects`;
      axios.get(url).then((response) => {
        const listObjects = response.data;
        // When we change of room (with currentRoom) we need to reset all objects in inventory,
        // all the steps and all objects of the room
        if (listObjects.length === 0) {
          dispatch({
            type: 'RESET_INVENTORY',
          });
          dispatch({
            type: 'RESET_STEPS',
          });
          dispatch({
            type: 'RESET_OBJECTS',
          });
        }

        // When the user refresh the page, we need to retrieve all objects already adding in the inventory
        // and add all object in the inventory
        const listObjectsInInventory = listObjects.filter(
          (objectContent) =>
            !objectContent.wrongobject &&
            objectContent.isInventory &&
            !objectContent.isChecked &&
            objectContent
        );
        listObjectsInInventory.map(async (objectContent) => {
          const { data } = await axios.get(
            `${BASE_URL}/api/objects/${objectContent.id}/images`
          );

          const newObjectContent = { ...objectContent };
          newObjectContent.images = data;

          return dispatch({
            type: 'ADD_INVENTORY',
            payload: newObjectContent,
          });
        });
      });
    }
    return () => {
      useOneTimeEffect.current = true;
    };
  }, [idSessionHasRoom, dispatch, currentRoom]);

  return (
    <div
      className={`ListObjects${
        isLightOpen && isLampOpen && !isCodeInInventory
          ? '--light-switch-on'
          : '--light-switch-off'
      }`}
    >
      {objects
        .filter(
          (objectContent) =>
            !objectContent.isInventory &&
            objectContent.type !== 'background' &&
            objectContent.type !== 'backgroundEnigma'
        )
        .map((objectContent, index) => {
          return (
            <ObjectContent
              object={objectContent}
              index={index}
              key={objectContent.id}
            />
          );
        })}
    </div>
  );
}

const mapStateToProps = (state) => ({
  currentRoom: state.Room.currentRoomId,
  objects: state.Objects.AllObjects,
  idSessionHasRoom: state.GameUsers.idSessionHasRoom,
});

ListObjects.propTypes = {
  objects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      isInventory: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
      type: PropTypes.string,
      wrongobject: PropTypes.number,
      isChecked: PropTypes.number,
    })
  ).isRequired,
  currentRoom: PropTypes.number.isRequired,
  idSessionHasRoom: PropTypes.number,
  dispatch: PropTypes.func.isRequired,
};
ListObjects.defaultProps = {
  idSessionHasRoom: null,
};

export default connect(mapStateToProps)(ListObjects);
