import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

// css
import 'assets/css/components/GameBoard/ReusableComponents/Modal/ModalEnigma.css';

// constant emotion multi + offre socle
import {
  phoneEmotionMultiEnigma,
  computerRoomEmotionEnigma,
  computerWithNoteRoomEmotionMultiEnigma,
  computerLowBatRoomEmotionMultiEnigma,
  televisionLivingRoomMultiEmotion,
  crosswordLivingRoomMultiEmotion,
  tvStandLivingRoomMultiEmotion,
  tableBedroomMultiEmotion,
  frameBedroomMultiEmotion,
  boxBedroomMultiEmotion,
  wardrobeBedroomMultiEmotion,
  posterBedroomMultiEmotion,
  receptionistPhone,
  doctorComputer,
  doctorPhone,
  greenNotebook,
  directorId,
  employeId,
} from 'components/GameBoard/ReusableComponents/Objects/constants';

// selectors
import { selectSession } from 'components/Reducers/session';
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';
import { selectInfoGame } from 'components/Reducers/game';
// components
import Phone from 'components/GameBoard/ReusableComponents/Enigma/Phone/Phone';
// solo obesity
import Television from 'components/GameBoard/Obesity/Enigma/Television/Television';
import BoardSun from 'components/GameBoard/Obesity/Enigma/BoardSun/BoardSun';
import Fridge from 'components/GameBoard/Obesity/Enigma/Fridge';
// solo rps
import Computer from 'components/GameBoard/RPS/Enigma/Computer';
import HelpDoc from 'components/GameBoard/RPS/Enigma/HelpDoc';
// solo tms
import Board from 'components/GameBoard/TMS/Enigma/Board';
import LockerOne from 'components/GameBoard/TMS/Enigma/LockerOne';
import DocumentHolder from 'components/GameBoard/TMS/Enigma/DocumentHolder';
import LockerTwo from 'components/GameBoard/TMS/Enigma/LockerTwo';
import Door from 'components/GameBoard/TMS/Enigma/Door';
import Tablet from 'components/GameBoard/TMS/Enigma/Tablet';
// solo offer socle
import AnswerVideoConference from 'components/GameBoard/OffreSocle/Enigmas/AnswerVideoConference';
import Notebook from 'components/GameBoard/OffreSocle/Enigmas/Notebook';
import CallErgonomist from 'components/GameBoard/OffreSocle/Enigmas/CallErgonomist';
import AnswerPhone from 'components/GameBoard/OffreSocle/Enigmas/AnswerPhone';
import RecommandationErgonomist from 'components/GameBoard/OffreSocle/Enigmas/RecommandationErgonomist';
// multi emotion
import Box from 'components/GameBoard/Emotion/Multi/Enigma/Table/Box/Box';
import Wardrobe from 'components/GameBoard/Emotion/Multi/Enigma/Wardrobe/Wardrobe';
import Puzzle from 'components/GameBoard/Emotion/Multi/Enigma/Puzzle/Puzzle';
import CrosswordComponent from 'components/GameBoard/Emotion/Multi/Enigma/Crossword/CrosswordComponent';
import DrawerTvStand from 'components/GameBoard/Emotion/Multi/Enigma/DrawerTvStand/DrawerTvStand';
import TableBedroom from 'components/GameBoard/Emotion/Multi/Enigma/Table/TableBedroom';
import FramePicture from 'components/GameBoard/Emotion/Multi/Enigma/Table/FramePicture/FramePicture';
import DesktopEmotion from 'components/GameBoard/Emotion/Multi/Enigma/Desktop/DesktopEmotion';
import QuestionnaryErgonomist from 'components/GameBoard/OffreSocle/Enigmas/QuestionnaryErgonomist';

function ModalEnigma({
  position,
  gameName,
  modalEnigmaIsOpen,
  dispatch,
  enigma,
  residents,
  setInventory,
  setResidents,
  inventory,
  placeObject,
  HandleDrop,
  setPlaceObject,
  isButtonHidden,
}) {
  const { t } = useTranslation('common');

  const session = useSelector(selectSession);
  const { socket } = useSelector(infoGameUser);
  const game = useSelector(selectInfoGame);

  const room = session.id;

  const fridgeEnigma = 190;
  const tvEnigma = 194;
  const boardSunEnigma = 203;
  const phoneEnigma = 204;
  const lockerOneEnigma = 206;
  const documentHolderEnigma = 209;
  const lockerTwoEnigma = 212;
  const boardEnigma = 216;
  const doorOne = 222;
  const doorTwo = 225;
  const doorThree = 226;
  const doorFour = 227;
  const doorFive = 228;
  const TabletTMS = 230;
  const ComputerRPS = 252;
  const DocOne = 255;
  const DocTwo = 256;
  const DocThree = 257;
  const DocFour = 258;

  const handleEnigma = () => {
    switch (enigma.content.id) {
      case fridgeEnigma:
        return <Fridge />;
      case tvEnigma:
        return <Television />;
      case boardSunEnigma:
        return <BoardSun />;
      case phoneEnigma:
        return <Phone />;
      case lockerOneEnigma:
        return <LockerOne objectContent={enigma} />;
      case documentHolderEnigma:
        return <DocumentHolder objectContent={enigma} />;
      case lockerTwoEnigma:
        return <LockerTwo objectContent={enigma} />;
      case boardEnigma:
        return <Board objectContent={enigma} />;
      case doorOne:
        return (
          <Door
            enigma={enigma.content}
            inventory={inventory}
            setInventory={setInventory}
            index={0}
            residents={residents}
            setResidents={setResidents}
          />
        );
      case doorTwo:
        return (
          <Door
            enigma={enigma.content}
            inventory={inventory}
            setInventory={setInventory}
            index={1}
            residents={residents}
            setResidents={setResidents}
          />
        );
      case doorThree:
        return (
          <Door
            enigma={enigma.content}
            inventory={inventory}
            setInventory={setInventory}
            index={2}
            residents={residents}
            setResidents={setResidents}
          />
        );
      case doorFour:
        return (
          <Door
            enigma={enigma.content}
            inventory={inventory}
            setInventory={setInventory}
            index={3}
            residents={residents}
            setResidents={setResidents}
          />
        );
      case doorFive:
        return (
          <Door
            enigma={enigma.content}
            inventory={inventory}
            setInventory={setInventory}
            index={4}
            residents={residents}
            setResidents={setResidents}
          />
        );
      case TabletTMS:
        return <Tablet objectContent={enigma.content} />;
      case ComputerRPS:
        return <Computer objectContent={enigma.content} dispatch={dispatch} />;
      case DocOne:
      case DocTwo:
      case DocThree:
      case DocFour:
        return (
          <HelpDoc
            objectContent={enigma.content}
            dispatch={dispatch}
            placeObject={placeObject}
            HandleDrop={HandleDrop}
            DocNumber={enigma.content.id}
            setPlaceObject={setPlaceObject}
          />
        );
      case receptionistPhone:
        return <AnswerPhone />;
      case doctorComputer:
        return <AnswerVideoConference />;
      case greenNotebook:
        return <Notebook objectContent={enigma.content} />;
      case doctorPhone:
        return <CallErgonomist objectContent={enigma.content} />;
      case phoneEmotionMultiEnigma:
        return <Phone />;
      case computerRoomEmotionEnigma:
        return <DesktopEmotion statusType="lock" />;
      case computerWithNoteRoomEmotionMultiEnigma:
        return <DesktopEmotion statusType="unlock" />;
      case computerLowBatRoomEmotionMultiEnigma:
        return <DesktopEmotion statusType="lowbat" />;
      case televisionLivingRoomMultiEmotion:
        return <Television />;
      case crosswordLivingRoomMultiEmotion:
        return <CrosswordComponent />;
      case tvStandLivingRoomMultiEmotion:
        return <DrawerTvStand />;
      case tableBedroomMultiEmotion:
        return <TableBedroom />;
      case frameBedroomMultiEmotion:
        return <FramePicture />;
      case boxBedroomMultiEmotion:
        return <Box />;
      case wardrobeBedroomMultiEmotion:
        return <Wardrobe />;
      case posterBedroomMultiEmotion:
        return <Puzzle />;
      case directorId:
        return <RecommandationErgonomist />;
      case employeId:
        return <QuestionnaryErgonomist />;
      default:
        return null;
    }
  };
  if (enigma.content.id >= DocOne && enigma.content.id <= DocFour) {
    if (
      (position === 'center' ||
        position === 'center-from-right' ||
        position === 'center-from-left') &&
      gameName === 'Bien dans son job'
    ) {
      return (
        <div className="ModalEnigma_RPS">
          <div className="ModalEnigma_blur_RPS" />

          <div className="ModalEnigma_description">
            <div className="ModalEnigma_description-contents">{handleEnigma()}</div>
            <button
              className="ButtonActionDoc ReturnDoc"
              type="button"
              onClick={() => {
                dispatch({
                  type: 'CLOSE_MODAL_ENIGMA',
                });
              }}
            >
              Retour
            </button>
          </div>
        </div>
      );
    }
    if (gameName === 'Bien dans son job') {
      if (modalEnigmaIsOpen) {
        dispatch({
          type: 'CLOSE_MODAL_ENIGMA',
        });
      }
      if (!modalEnigmaIsOpen) return null;
    }
  }
  return (
    <div className="ModalEnigma">
      <div className="ModalEnigma_blur" />

      <div
        className={`ModalEnigma_description ${
          game.game_type === 'multi' && '--multi'
        }`}
      >
        <div className="ModalEnigma_description-contents">{handleEnigma()}</div>
        {!isButtonHidden && (
          <button
            className="ButtonAction Return"
            type="button"
            onClick={() => {
              const responsesSocket = { room };
              socket?.emit('send_close_modal_enigma', responsesSocket);
              dispatch({
                type: 'CLOSE_MODAL_ENIGMA',
              });
            }}
          >
            {t('buttonFunction.return')}
          </button>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  enigma: state.Modal.modalEnigma.description,
  position: state.RPS.dataGame.position,
  gameName: state.game.title,
  modalEnigmaIsOpen: state.Modal.modalEnigma.isOpen,
});

ModalEnigma.propTypes = {
  HandleDrop: PropTypes.func,
  dispatch: PropTypes.func.isRequired,
  enigma: PropTypes.shape({
    content: PropTypes.shape({
      id: PropTypes.number,
    }),
  }),
  inventory: PropTypes.arrayOf(
    PropTypes.shape({
      list: PropTypes.shape({
        useBy: PropTypes.arrayOf(PropTypes.number),
      }),
      isOpen: PropTypes.bool,
      id: PropTypes.number,
    })
  ),
  placeObject: PropTypes.arrayOf(
    PropTypes.shape({
      object: PropTypes.shape(PropTypes.objectOf(PropTypes.string)),
      key: PropTypes.number,
    })
  ),
  residents: PropTypes.arrayOf({
    id: PropTypes.number,
    name: PropTypes.string,
    url: PropTypes.string,
    urlInfo: PropTypes.string,
    description: PropTypes.arrayOf(PropTypes.string),
    isOpen: PropTypes.bool,
    residentGoodObject: PropTypes.arrayOf(PropTypes.number),
    inventoryResident: PropTypes.arrayOf(PropTypes.number),
    isChecked: PropTypes.bool,
  }),
  setInventory: PropTypes.func,
  setResidents: PropTypes.func,
  position: PropTypes.string,
  gameName: PropTypes.string,
  modalEnigmaIsOpen: PropTypes.bool,
  setPlaceObject: PropTypes.func,
  isButtonHidden: PropTypes.bool,
};

ModalEnigma.defaultProps = {
  HandleDrop: null,
  enigma: null,
  placeObject: null,
  setResidents: null,
  residents: null,
  setInventory: null,
  position: null,
  gameName: null,
  modalEnigmaIsOpen: null,
  inventory: null,
  setPlaceObject: () => {},
  isButtonHidden: false,
};

export default connect(mapStateToProps)(ModalEnigma);
