// BEDROOM
const goodPasswordOfComputerEmotionMulti = 'euphoria';

const listApplication = [
  {
    id: 1,
    icon: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fbedroom%2Fobjects%2Flogo%2FLOGO5.svg?alt=media&token=7db6aac5-a6a3-465a-8b5d-7423be5fec02',
    type: 'message',
  },
  {
    id: 2,
    icon: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fbedroom%2Fobjects%2Flogo%2FLOGO6.svg?alt=media&token=625163a1-c492-40f2-b108-20c72c7706a7',
    type: 'video',
  },
  {
    id: 3,
    icon: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fbedroom%2Fobjects%2Flogo%2FLOGO1.svg?alt=media&token=edae30af-f932-4ec7-9166-24ef5dfe0589',
    type: 'unknown',
  },
  {
    id: 4,
    icon: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fbedroom%2Fobjects%2Flogo%2FLOGO2.svg?alt=media&token=9ace877b-3910-464f-aee1-5cff80cb6fd4',
    type: 'slack',
  },
  {
    id: 5,
    icon: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fbedroom%2Fobjects%2Flogo%2FLOGO3.svg?alt=media&token=91b45a64-ebf6-4f53-b541-ddc28f820ac0',
    type: 'discord',
  },
  {
    id: 6,
    icon: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fbedroom%2Fobjects%2Flogo%2FLOGO4.svg?alt=media&token=b508ffe6-9b9a-4b49-b36f-16873d16dd91',
    type: 'et',
  },
  {
    id: 7,
    icon: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fbedroom%2Fobjects%2Flogo%2FLOGO7.svg?alt=media&token=bae43aa7-c72d-4085-8a10-ea4e7661a676',
    type: 'netflix',
  },
  {
    id: 8,
    icon: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fbedroom%2Fobjects%2Flogo%2FLOGO8.svg?alt=media&token=bedb33bc-8922-4ba3-ae3e-f42b4de8fd58',
    type: 'chrome',
  },
];

const motivation = {
  text: 'motivé',
  hint: 'Se dit de quelqu’un qui éprouve des conditions qui le poussent à agir.',
};
const anxious = {
  text: 'anxieux',
  hint: 'Se dit de quelqu’un qui se sent stressé par un événement attendu.',
};
const focus = {
  text: 'concentrer',
  hint: 'Fixer son attention sur quelque chose.',
};
const feel = {
  text: 'ressentir',
  hint: 'Éprouver une sensation, agréable ou non.',
};

const holeMessage = [
  'Je ne suis plus ',
  { type: 'motivation' },
  ', je suis un peu perdu. Je suis très ',
  { type: 'anxious' },
  'entre le confinement, les infos, les exams… Je ne sais plus où donner de la tête. Je n’arrive même plus à me ',
  { type: 'focus' },
  'pour réviser. J’ai l’impression d’être le seul à ',
  { type: 'feel' },
  'ça… des conseils ?',
];

const codeVisio = 'N2Kc4v';
const hourOfDesktop = '22:49';
const codeDrawerOfficeEmotion = '160420';
const codeIphone = '2358';
const inputsPhone = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];
const pinkColor = '#e4a6ea';
const redColor = '#ef6a6a';
const blueColor = '#85cded';
const greenColor = '#8fef99';
const yellowColor = '#e5bd49';
const listPersona = [
  {
    id: 1,
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fbedroom%2Fobjects%2Fdesktop%2Fvisio%2FPERSO1.svg?alt=media&token=8682e8d4-e26f-497f-be1e-41d4cfba05d1',
    color: 'transparent',
    goodColor: blueColor,
  },
  {
    id: 2,
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fbedroom%2Fobjects%2Fdesktop%2Fvisio%2FPERSO2.svg?alt=media&token=1e16b605-6ecd-4d73-ac93-101e4d9903d6',
    color: 'transparent',
    goodColor: redColor,
  },
  {
    id: 3,
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fbedroom%2Fobjects%2Fdesktop%2Fvisio%2FPERSO3.svg?alt=media&token=52e2bb25-7678-4b4b-bf16-2841313e3e03',
    color: 'transparent',
    goodColor: pinkColor,
  },
  {
    id: 4,
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fbedroom%2Fobjects%2Fdesktop%2Fvisio%2FPERSO4.svg?alt=media&token=6c71d658-10a8-4d5c-812a-6b09973deb29',
    color: 'transparent',
    goodColor: pinkColor,
  },
  {
    id: 5,
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fbedroom%2Fobjects%2Fdesktop%2Fvisio%2FPERSO5.svg?alt=media&token=f34c03eb-55c7-4143-955e-54be799eb8f1',
    color: 'transparent',
    goodColor: redColor,
  },
  {
    id: 6,
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fbedroom%2Fobjects%2Fdesktop%2Fvisio%2FPERSO6.svg?alt=media&token=865ca6c6-1951-4c9a-ac81-266f6808eb0e',
    color: 'transparent',
    goodColor: greenColor,
  },
  {
    id: 7,
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fbedroom%2Fobjects%2Fdesktop%2Fvisio%2FPERSO7.svg?alt=media&token=be54b49a-0ecf-4815-8542-a3b2addee991',
    color: 'transparent',
    goodColor: yellowColor,
  },
  {
    id: 8,
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fbedroom%2Fobjects%2Fdesktop%2Fvisio%2FPERSO8.svg?alt=media&token=c29b0111-391c-489d-a787-f2d6e5e4493a',
    color: 'transparent',
    goodColor: blueColor,
  },
  {
    id: 9,
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fbedroom%2Fobjects%2Fdesktop%2Fvisio%2FPERSO9.svg?alt=media&token=a1dc689e-7d11-4bd7-b2c1-3ac01ea0d84a',
    color: 'transparent',
    goodColor: redColor,
  },
];

const colorsPersona = [pinkColor, redColor, blueColor, greenColor, yellowColor];
const dangerIcon =
  'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fbedroom%2Fobjects%2Fdesktop%2FAlert.svg?alt=media&token=4fdadd05-7873-45ef-9557-c0bd18a1b0be';

const inputsMessaging = {
  motivation: {
    text: '',
    answer: motivation,
  },
  anxious: {
    text: '',
    answer: anxious,
  },
  focus: {
    text: '',
    answer: focus,
  },
  feel: {
    text: '',
    answer: feel,
  },
};

const notePaperImage =
  'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fbedroom%2Fobjects%2Fdesktop%2FGroup-2.svg?alt=media&token=20b1ee8c-4ee0-46f7-9560-aab0d8a2be51';

const iconsActionVisio =
  'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fbedroom%2Fobjects%2Fdesktop%2FICONS.svg?alt=media&token=84e68681-ea1c-42c2-b6ec-1149d35cf350';

const iconVisio =
  'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fbedroom%2Fobjects%2Fdesktop%2FICONS-2.svg?alt=media&token=70bef24a-fde5-4b97-a168-ce039a321f92';

const videoRoundRed =
  'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fbedroom%2Fobjects%2Fdesktop%2FEllipse%204.png?alt=media&token=a3f68275-4fb1-4f34-b9cf-fe71fd498d25';

const videoRoundYellow =
  'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fbedroom%2Fobjects%2Fdesktop%2FEllipse%203.png?alt=media&token=adbd4eda-8b28-484d-a23c-7844155f5b76';

const videoRoundGreen =
  'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fbedroom%2Fobjects%2Fdesktop%2FEllipse%202.svg?alt=media&token=cd14c3d8-a6df-440e-bf08-db069cbebb08';

const offsetMessageHint = 30;

// TV
const codeTelevision = '1104';
const arrayButtonRemote = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];
const crosswordImage =
  'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fliving-room%2Fobjects%2Fcrossword%2F_1.png?alt=media&token=0e0f9218-7f50-48fa-b008-e5d15d725a49';
const codeDrawerTvStand = 'plaisir';

const listBooks = {
  black: [
    {
      id: 1,
      url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fliving-room%2Fobjects%2Fbooks%2FLIVRES%201.png?alt=media&token=a0b5591c-b2a8-47b4-b57c-d6d7416ba6a7',
      isChecked: false,
      isGood: false,
    },
    {
      id: 2,
      url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fliving-room%2Fobjects%2Fbooks%2FLIVRES%20(2)%201.png?alt=media&token=be2e7894-2526-4b41-9d98-2b168a31ac8e',
      isChecked: false,
      isGood: true,
    },
  ],
  green: [
    {
      id: 3,
      url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fliving-room%2Fobjects%2Fbooks%2FLIVRES%20(3)%201.png?alt=media&token=dc3877e1-1dd7-43fa-92de-1f4a359558fa',
      isChecked: false,
      isGood: false,
    },
    {
      id: 4,
      url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fliving-room%2Fobjects%2Fbooks%2FLIVRES%20(4)%201.png?alt=media&token=74c12b26-cfff-45e5-a2c3-d43223877649',
      isChecked: false,
      isGood: true,
    },
  ],
  blue: [
    {
      id: 5,
      url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fliving-room%2Fobjects%2Fbooks%2FLIVRES%20(5)%201.png?alt=media&token=490abc1d-ee33-42a2-8a05-408f54320e3d',
      isChecked: false,
      isGood: false,
    },
    {
      id: 6,
      url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fliving-room%2Fobjects%2Fbooks%2FLIVRES%20(6)%201.png?alt=media&token=ecd49f8a-35f7-49af-9062-a42180a4d4f3',
      isChecked: false,
      isGood: true,
    },
  ],
  red: [
    {
      id: 7,
      url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fliving-room%2Fobjects%2Fbooks%2FLIVRES%20(7)%201.png?alt=media&token=31aeb0a6-bd90-4f0b-9f47-420499ca7948',
      isChecked: false,
      isGood: false,
    },
    {
      id: 8,
      url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fliving-room%2Fobjects%2Fbooks%2FLIVRES%20(8)%201.png?alt=media&token=ffdb51bb-d519-41cd-872d-e51341800297',
      isChecked: false,
      isGood: true,
    },
  ],
  blueDark: [
    {
      id: 9,
      url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fliving-room%2Fobjects%2Fbooks%2FLIVRES%20(9)%201.png?alt=media&token=2512be45-ef88-413b-abb0-690d45a19e53',
      isChecked: false,
      isGood: false,
    },
    {
      id: 10,
      url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fliving-room%2Fobjects%2Fbooks%2FLIVRES%20(10)%201.png?alt=media&token=b7fef62c-33ca-4f2f-90fe-73ed474d1a8d',
      isChecked: false,
      isGood: true,
    },
  ],
};

const emptyInventoy = [];
const resetIndex = 0;
const resetTypeBook = 'black';
const gridContent = [
  {
    id: 'A1',
    letter: null,
    value: '',
  },
  {
    id: 'A2',
    letter: null,
    value: '',
  },
  {
    id: 'A3',
    letter: null,
    value: '',
  },
  {
    id: 'A4',
    letter: 'd',
    across: false,
    clueAcross: null,
    down: true,
    clueDown: 4,
    number: 2,
    value: '',
  },
  {
    id: 'A5',
    letter: null,
    value: '',
  },
  {
    id: 'A6',
    letter: null,
    value: '',
  },
  {
    id: 'A7',
    letter: null,
    value: '',
  },
  {
    id: 'A8',
    letter: null,
    value: '',
  },
  {
    id: 'A9',
    letter: null,
    value: '',
  },
  {
    id: 'A10',
    letter: null,
    value: '',
  },
  {
    id: 'A11',
    letter: null,
    value: '',
  },
  {
    id: 'A12',
    letter: null,
    value: '',
  },
  {
    id: 'A13',
    letter: null,
    value: '',
  },
  {
    id: 'B1',
    letter: null,
    value: '',
  },
  {
    id: 'B2',
    letter: null,
    value: '',
  },
  {
    id: 'B3',
    letter: null,
    value: '',
  },
  {
    id: 'B4',
    letter: 'e',
    across: false,
    clueAcross: null,
    down: true,
    clueDown: 4,
    value: '',
  },
  {
    id: 'B5',
    letter: null,
    value: '',
  },
  {
    id: 'B6',
    letter: null,
    value: '',
  },
  {
    id: 'B7',
    letter: null,
    value: '',
  },
  {
    id: 'B8',
    letter: null,
    value: '',
  },
  {
    id: 'B9',
    letter: null,
    value: '',
  },
  {
    id: 'B10',
    letter: null,
    value: '',
  },
  {
    id: 'B11',
    letter: null,
    value: '',
  },
  {
    id: 'B12',
    letter: null,
    value: '',
  },
  {
    id: 'B13',
    letter: null,
    value: '',
  },
  {
    id: 'C1',
    letter: null,
    value: '',
  },
  {
    id: 'C2',
    letter: 'b',
    across: true,
    clueAcross: 1,
    down: false,
    clueDown: null,
    number: 1,
    value: '',
  },
  {
    id: 'C3',
    letter: 'i',
    across: true,
    clueAcross: 1,
    down: false,
    clueDown: null,
    value: '',
  },
  {
    id: 'C4',
    letter: 'p',
    across: true,
    clueAcross: 1,
    down: true,
    clueDown: 4,
    value: '',
    color: 'yellow',
  },
  {
    id: 'C5',
    letter: 'o',
    across: true,
    clueAcross: 1,
    down: false,
    clueDown: null,
    value: '',
  },
  {
    id: 'C6',
    letter: 'l',
    across: true,
    clueAcross: 1,
    down: false,
    clueDown: null,
    value: '',
    color: 'yellow',
  },
  {
    id: 'C7',
    letter: 'a',
    across: true,
    clueAcross: 1,
    down: false,
    clueDown: null,
    value: '',
  },
  {
    id: 'C8',
    letter: 'r',
    across: true,
    clueAcross: 1,
    down: false,
    clueDown: null,
    value: '',
  },
  {
    id: 'C9',
    letter: 'i',
    across: true,
    clueAcross: 1,
    down: false,
    clueDown: null,
    value: '',
    color: 'yellow',
  },
  {
    id: 'C10',
    letter: 't',
    across: true,
    clueAcross: 1,
    down: true,
    clueDown: 3,
    number: 3,
    value: '',
  },
  {
    id: 'C11',
    letter: 'e',
    across: true,
    clueAcross: 1,
    down: false,
    clueDown: null,
    value: '',
  },
  {
    id: 'C12',
    letter: null,
    value: '',
  },
  {
    id: 'C13',
    letter: null,
    value: '',
  },
  {
    id: 'D1',
    letter: null,
    value: '',
  },
  {
    id: 'D2',
    letter: null,
    value: '',
  },
  {
    id: 'D3',
    letter: null,
    value: '',
  },
  {
    id: 'D4',
    letter: 'r',
    across: false,
    clueAcross: null,
    down: true,
    clueDown: 4,
    value: '',
  },
  {
    id: 'D5',
    letter: null,
    value: '',
  },
  {
    id: 'D6',
    letter: null,
    value: '',
  },
  {
    id: 'D7',
    letter: null,
    value: '',
  },
  {
    id: 'D8',
    letter: null,
    value: '',
  },
  {
    id: 'D9',
    letter: null,
    value: '',
  },
  {
    id: 'D10',
    letter: 'c',
    across: false,
    clueAcross: null,
    down: true,
    clueDown: 3,
    value: '',
  },
  {
    id: 'D11',
    letter: null,
    value: '',
  },
  {
    id: 'D12',
    letter: null,
    value: '',
  },
  {
    id: 'D13',
    letter: null,
    value: '',
  },
  {
    id: 'E1',
    letter: null,
    value: '',
  },
  {
    id: 'E2',
    letter: null,
    value: '',
  },
  {
    id: 'E3',
    letter: null,
    value: '',
  },
  {
    id: 'E4',
    letter: 'e',
    across: false,
    clueAcross: null,
    down: true,
    clueDown: 4,
    value: '',
  },
  {
    id: 'E5',
    letter: null,
    value: '',
  },
  {
    id: 'E6',
    letter: null,
    value: '',
  },
  {
    id: 'E7',
    letter: null,
    value: '',
  },
  {
    id: 'E8',
    letter: null,
    value: '',
  },
  {
    id: 'E9',
    letter: null,
    value: '',
  },
  {
    id: 'E10',
    letter: 'a',
    across: false,
    clueAcross: null,
    down: true,
    clueDown: 3,
    value: '',
    color: 'yellow',
  },
  {
    id: 'E11',
    letter: null,
    value: '',
  },
  {
    id: 'E12',
    letter: null,
    value: '',
  },
  {
    id: 'E13',
    letter: null,
    value: '',
  },
  {
    id: 'F1',
    letter: null,
    value: '',
  },
  {
    id: 'F2',
    letter: null,
    value: '',
  },
  {
    id: 'F3',
    letter: null,
    value: '',
  },
  {
    id: 'F4',
    letter: 's',
    across: false,
    clueAcross: null,
    down: true,
    clueDown: 4,
    value: '',
  },
  {
    id: 'F5',
    letter: null,
    value: '',
  },
  {
    id: 'F6',
    letter: null,
    value: '',
  },
  {
    id: 'F7',
    letter: null,
    value: '',
  },
  {
    id: 'F8',
    letter: null,
    value: '',
  },
  {
    id: 'F9',
    letter: null,
    value: '',
  },
  {
    id: 'F10',
    letter: null,
    value: '',
  },
  {
    id: 'F11',
    letter: null,
    value: '',
  },
  {
    id: 'F12',
    letter: null,
    value: '',
  },
  {
    id: 'F13',
    letter: null,
    value: '',
  },
  {
    id: 'G1',
    letter: null,
    value: '',
  },
  {
    id: 'G2',
    letter: null,
    value: '',
  },
  {
    id: 'G3',
    letter: null,
    value: '',
  },
  {
    id: 'G4',
    letter: 's',
    across: false,
    clueAcross: null,
    down: true,
    clueDown: 4,
    value: '',
  },
  {
    id: 'G5',
    letter: null,
    value: '',
  },
  {
    id: 'G6',
    letter: null,
    value: '',
  },
  {
    id: 'G7',
    letter: null,
    value: '',
  },
  {
    id: 'G8',
    letter: null,
    value: '',
  },
  {
    id: 'G9',
    letter: null,
    value: '',
  },
  {
    id: 'G10',
    letter: null,
    value: '',
  },
  {
    id: 'G11',
    letter: null,
    value: '',
  },
  {
    id: 'G12',
    letter: null,
    value: '',
  },
  {
    id: 'G13',
    letter: null,
    value: '',
  },
  {
    id: 'H1',
    letter: 's',
    across: true,
    clueAcross: 2,
    down: false,
    clueDown: null,
    number: 4,
    value: '',
    color: 'yellow',
  },
  {
    id: 'H2',
    letter: 'c',
    across: true,
    clueAcross: 2,
    down: false,
    clueDown: null,
    value: '',
  },
  {
    id: 'H3',
    letter: 'h',
    across: true,
    clueAcross: 2,
    down: false,
    clueDown: null,
    value: '',
  },
  {
    id: 'H4',
    letter: 'i',
    across: true,
    clueAcross: 2,
    down: true,
    clueDown: 4,
    value: '',
    color: 'yellow',
  },
  {
    id: 'H5',
    letter: 'z',
    across: true,
    clueAcross: 2,
    down: false,
    clueDown: null,
    value: '',
  },
  {
    id: 'H6',
    letter: 'o',
    across: true,
    clueAcross: 2,
    down: false,
    clueDown: null,
    value: '',
  },
  {
    id: 'H7',
    letter: 'p',
    across: true,
    clueAcross: 2,
    down: false,
    clueDown: null,
    value: '',
  },
  {
    id: 'H8',
    letter: 'h',
    across: true,
    clueAcross: 2,
    down: false,
    clueDown: null,
    value: '',
  },
  {
    id: 'H9',
    letter: 'r',
    across: true,
    clueAcross: 2,
    down: false,
    clueDown: null,
    value: '',
    color: 'yellow',
  },
  {
    id: 'H10',
    letter: 'e',
    across: true,
    clueAcross: 2,
    down: false,
    clueDown: null,
    value: '',
  },
  {
    id: 'H11',
    letter: 'n',
    across: true,
    clueAcross: 2,
    down: false,
    clueDown: null,
    value: '',
  },
  {
    id: 'H12',
    letter: 'i',
    across: true,
    clueAcross: 2,
    down: false,
    clueDown: null,
    value: '',
  },
  {
    id: 'H13',
    letter: 'e',
    across: true,
    clueAcross: 2,
    down: false,
    clueDown: null,
    value: '',
  },
  {
    id: 'I1',
    letter: null,
    value: '',
  },
  {
    id: 'I2',
    letter: null,
    value: '',
  },
  {
    id: 'I3',
    letter: null,
    value: '',
  },
  {
    id: 'I4',
    letter: 'o',
    across: false,
    clueAcross: null,
    down: true,
    clueDown: 4,
    value: '',
  },
  {
    id: 'I5',
    letter: null,
    value: '',
  },
  {
    id: 'I6',
    letter: null,
    value: '',
  },
  {
    id: 'I7',
    letter: null,
    value: '',
  },
  {
    id: 'I8',
    letter: null,
    value: '',
  },
  {
    id: 'I9',
    letter: null,
    value: '',
  },
  {
    id: 'I10',
    letter: null,
    value: '',
  },
  {
    id: 'I11',
    letter: null,
    value: '',
  },
  {
    id: 'I12',
    letter: null,
    value: '',
  },
  {
    id: 'I13',
    letter: null,
    value: '',
  },
  {
    id: 'J1',
    letter: null,
    value: '',
  },
  {
    id: 'J2',
    letter: null,
    value: '',
  },
  {
    id: 'J3',
    letter: null,
    value: '',
  },
  {
    id: 'J4',
    letter: 'n',
    across: false,
    clueAcross: null,
    down: true,
    clueDown: 4,
    value: '',
  },
  {
    id: 'J5',
    letter: null,
    value: '',
  },
  {
    id: 'J6',
    letter: null,
    value: '',
  },
  {
    id: 'J7',
    letter: null,
    value: '',
  },
  {
    id: 'J8',
    letter: null,
    value: '',
  },
  {
    id: 'J9',
    letter: null,
    value: '',
  },
  {
    id: 'J10',
    letter: null,
    value: '',
  },
  {
    id: 'J11',
    letter: null,
    value: '',
  },
  {
    id: 'J12',
    letter: null,
    value: '',
  },
  {
    id: 'J13',
    letter: null,
    value: '',
  },
];

const clues = {
  across: [
    {
      number: 1,
      clue: 'Chaîne TV 1',
      answer: 'bipolarité',
    },
    {
      number: 2,
      clue: 'Chaîne TV 4',
      answer: 'schizophrénie',
    },
  ],
  down: [
    {
      number: 0,
      clue: '',
      answer: '',
    },
    {
      number: 0,
      clue: '',
      answer: '',
    },
    {
      number: 3,
      clue: 'Chaîne TV 3',
      answer: 'tca',
    },
    {
      number: 4,
      clue: 'Chaîne TV 2',
      answer: 'dépression',
    },
  ],
};
const codeTableBedroom = '39016';
const codeBox = '7531';

const listPostIt = [
  {
    id: 1,
    title: 'Je suis nul',
    isGood: false,
  },
  {
    id: 2,
    title: 'Personne ne m’aime',
    isGood: false,
  },
  {
    id: 3,
    title: 'Je ne vaux rien',
    isGood: false,
  },
  {
    id: 4,
    title: 'Les autres feront toujours mieux',
    isGood: false,
  },
  {
    id: 5,
    title: 'Je n’y arriverai jamais',
    isGood: false,
  },
  {
    id: 6,
    title: 'J’en suis capable',
    isGood: true,
  },
  {
    id: 7,
    title: 'Je peux le faire',
    isGood: true,
  },
  {
    id: 8,
    title: 'Je suis une belle personne',
    isGood: true,
  },
  {
    id: 9,
    title: 'On tient à moi',
    isGood: true,
  },
  {
    id: 10,
    title: 'Mon avis compte',
    isGood: true,
  },
];

const listSpotlight = [
  {
    id: 1,
    slug: 'yellow',
    color: '#a58f39',
    isGood: false,
  },
  {
    id: 2,
    slug: 'red',
    isGood: true,
  },
  {
    id: 3,
    slug: 'blue',
    isGood: false,
  },
  {
    id: 4,
    slug: 'green',
    isGood: false,
  },
];

const spotlightBlue =
  'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fbedroom2%2Fobjects%2Flumiere%2FLIGHT-BLUE.svg?alt=media&token=55e02295-9855-4972-beeb-5d3641dfd866';
const spotlightRed =
  'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fbedroom2%2Fobjects%2Flumiere%2FLIGHT-RED-2%201-3.svg?alt=media&token=cfce5986-416a-494f-bb8c-f94d71d74b6d&_gl=1*2txq8k*_ga*NTE4NzU3NzkyLjE2Nzc0NDk2ODg.*_ga_CW55HF8NVT*MTY5NzUzMDc5My42My4xLjE2OTc1MzA3OTUuNTguMC4w';
const spotlightYellow =
  'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fbedroom2%2Fobjects%2Flumiere%2FLIGHT-YELLOW.svg?alt=media&token=209dfafb-770d-4d57-a4ce-07d92027b934';
const spotlightGreen =
  'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fbedroom2%2Fobjects%2Flumiere%2FLIGHT-GREEN.svg?alt=media&token=5dc52c1f-f5ee-45ed-9c02-bc69e020edbd&_gl=1*1hfxlnf*_ga*NTE4NzU3NzkyLjE2Nzc0NDk2ODg.*_ga_CW55HF8NVT*MTY5NzUyODY4OS42Mi4xLjE2OTc1Mjg3MDIuNDcuMC4w';
const spotlight =
  'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fbedroom2%2Fobjects%2Flumiere%2FLIGHT-SOLO-1.svg?alt=media&token=e3b8f60f-6194-4e37-a3f0-07aaf6deb15f';
const puzzlePieces = [
  {
    id: 1,
    degres: 0,
    src: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fbedroom2%2Fobjects%2Fpuzzle%2FsquarePuzzle%2FP1.svg?alt=media&token=c74c64ed-83fc-4d65-89a0-32fa3263c969',
  },
  {
    id: 2,
    degres: 180,
    src: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fbedroom2%2Fobjects%2Fpuzzle%2FsquarePuzzle%2FP2.svg?alt=media&token=e017bd59-fc8d-403a-bb5d-528570596bd4',
  },
  {
    id: 3,
    degres: 90,
    src: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fbedroom2%2Fobjects%2Fpuzzle%2FsquarePuzzle%2FP3.svg?alt=media&token=e8345d7d-9771-45cb-adf3-1a9a50df4f94',
  },
  {
    id: 4,
    degres: 270,
    src: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fbedroom2%2Fobjects%2Fpuzzle%2FsquarePuzzle%2FP4.svg?alt=media&token=4d41e1ca-9ce1-419b-ba54-682f3c052629',
  },
  {
    id: 5,
    degres: 0,
    src: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fbedroom2%2Fobjects%2Fpuzzle%2FsquarePuzzle%2FP5.svg?alt=media&token=d4bc4ced-864b-4afa-8481-e80707e919d9',
  },
  {
    id: 6,
    degres: 90,
    src: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fbedroom2%2Fobjects%2Fpuzzle%2FsquarePuzzle%2FP6.svg?alt=media&token=b164e780-8b79-46fd-9d62-7e696fd70e05',
  },
  {
    id: 7,
    degres: 0,
    src: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fbedroom2%2Fobjects%2Fpuzzle%2FsquarePuzzle%2FP7.svg?alt=media&token=61bacb7f-b2ab-4989-afe4-09b15f7a1c08',
  },
  {
    id: 8,
    degres: 270,
    src: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fbedroom2%2Fobjects%2Fpuzzle%2FsquarePuzzle%2FP8.svg?alt=media&token=367fac9c-949f-42bc-b5eb-7f1e6d47ebe2',
  },
  {
    id: 9,
    degres: 90,
    src: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fbedroom2%2Fobjects%2Fpuzzle%2FsquarePuzzle%2FP9.svg?alt=media&token=edfc0b16-6d2f-437e-9a39-6968374cdad7',
  },
];
const minDegres = 0;
const maxDegres = 270;
const numberAddDegres = 90;
const volumeMulti = 0.06;
const volumeMultiDesktopTheme = 0.04;

const modalBackgroundColor =
  'linear-gradient(108.15deg, rgba(219, 219, 219, 0.5) 8.96%, #353757 91.54%);';

export {
  goodPasswordOfComputerEmotionMulti,
  listApplication,
  motivation,
  anxious,
  focus,
  feel,
  holeMessage,
  codeVisio,
  codeDrawerOfficeEmotion,
  inputsPhone,
  codeIphone,
  listPersona,
  colorsPersona,
  hourOfDesktop,
  dangerIcon,
  inputsMessaging,
  notePaperImage,
  iconsActionVisio,
  iconVisio,
  videoRoundRed,
  videoRoundYellow,
  videoRoundGreen,
  offsetMessageHint,
  codeTelevision,
  arrayButtonRemote,
  crosswordImage,
  codeDrawerTvStand,
  listBooks,
  emptyInventoy,
  resetIndex,
  resetTypeBook,
  gridContent,
  clues,
  codeTableBedroom,
  codeBox,
  listPostIt,
  listSpotlight,
  spotlightBlue,
  spotlightRed,
  spotlightYellow,
  spotlightGreen,
  spotlight,
  puzzlePieces,
  minDegres,
  maxDegres,
  numberAddDegres,
  volumeMulti,
  volumeMultiDesktopTheme,
  modalBackgroundColor,
};
