import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
// scss
import styles from 'components/GameBoard/Emotion/Multi/Enigma/Puzzle/Puzzle.module.scss';
// utils
import { checkedObject, animationConfetti } from 'utils/utilityFunctions';
// components
import Button from 'components/GameBoard/ReusableComponents/Actions/Button';
import Audio from 'components/GameBoard/ReusableComponents/Actions/Audio';
// selectors
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';
import { selectModalEnigma } from 'components/Reducers/Modal/Modal';
import { selectSession } from 'components/Reducers/session';
import {
  selectObject,
  selectObjectIndex,
} from 'components/Reducers/GameData/fetchObjects';
// constants
import {
  puzzlePieces,
  minDegres,
  maxDegres,
  numberAddDegres,
} from 'components/GameBoard/Emotion/Multi/constant';
import soundEffects from 'assets/sound/soundEffects/soundEffects';
import {
  puzzleBedroomMultiEmotion,
  posterBedroomMultiEmotion,
} from 'components/GameBoard/ReusableComponents/Objects/constants';

const Puzzle = () => {
  const { t } = useTranslation(['common', 'emotion-multi']);
  const dispatch = useDispatch();
  const [puzzle, setPuzzle] = useState(puzzlePieces);
  const [errorPuzzleMessage, setErrorPuzzleMessage] = useState(null);
  const [soundPuzzleOn, setSoundPuzzleOn] = useState(false);
  const session = useSelector(selectSession);
  const { socket, idSessionHasRoom, soundtrack } = useSelector(infoGameUser);
  const room = session.id;
  const { description } = useSelector(selectModalEnigma);

  const puzzleBedroomMultiEmotionObject = useSelector((state) =>
    selectObject(state, puzzleBedroomMultiEmotion)
  );
  const posterBedroomMultiEmotionObject = useSelector((state) =>
    selectObject(state, posterBedroomMultiEmotion)
  );
  const posterBedroomMultiEmotionIndex = useSelector((state) =>
    selectObjectIndex(state, posterBedroomMultiEmotion)
  );
  const handleImage = (descriptionContent) => {
    let imageDrawer;
    if (descriptionContent?.images) {
      const objectIndex = descriptionContent.images.findIndex(
        (object) => object.type === 'clicked'
      );
      imageDrawer = descriptionContent.images[objectIndex].image;
    }

    if (puzzleBedroomMultiEmotionObject?.status === 'open') {
      const objectIndex = descriptionContent.images.findIndex(
        (object) => object.type === 'open'
      );
      imageDrawer = descriptionContent.images[objectIndex].image;
    }
    return imageDrawer;
  };

  const handleMessage = (descriptionContent) => {
    let message;
    if (descriptionContent?.images) {
      const objectIndex = descriptionContent.images.findIndex(
        (object) => object.type === 'clicked'
      );
      message = descriptionContent.images[objectIndex].message;
    }
    if (
      descriptionContent.id === posterBedroomMultiEmotionObject?.id &&
      puzzleBedroomMultiEmotionObject?.isChecked
    ) {
      const objectIndex = descriptionContent.images.findIndex(
        (object) => object.type === 'unordered'
      );
      message = descriptionContent.images[objectIndex].message;
    }
    if (posterBedroomMultiEmotionObject?.isChecked) {
      const objectIndex = descriptionContent.images.findIndex(
        (object) => object.type === 'open'
      );
      message = descriptionContent.images[objectIndex].message;
    }

    return message;
  };

  useEffect(() => {
    socket?.on('receive_emotion_multi_set_rotate_puzzle', (data) => {
      setPuzzle(data.newState);
    });
  }, [socket, setPuzzle, puzzle]);

  const handleRotate = (data) => {
    setPuzzle((prevState) => {
      const newState = prevState.map((obj) => {
        let newDegres = obj.degres;
        if (obj.id === data.id) {
          if (obj.degres !== maxDegres) {
            newDegres += numberAddDegres;
          } else {
            newDegres = minDegres;
          }
        }
        return { ...obj, degres: newDegres };
      });
      setErrorPuzzleMessage(null);
      const responsesSocket = {
        newState,
        room,
      };
      socket?.emit('send_emotion_multi_set_rotate_puzzle', responsesSocket);
      return newState;
    });
  };

  const handleValidatePuzzle = () => {
    const allPiecesHaveGoodPlace = puzzle.map(
      (piece) => piece.degres === minDegres
    );
    const puzzleVerify = allPiecesHaveGoodPlace.includes(false);
    if (puzzleVerify) {
      setErrorPuzzleMessage(t('puzzle.error', { ns: 'emotion-multi' }));
    } else {
      setErrorPuzzleMessage(null);
      animationConfetti();
      setTimeout(() => {
        checkedObject(
          posterBedroomMultiEmotionObject,
          posterBedroomMultiEmotionIndex,
          idSessionHasRoom,
          dispatch,
          socket,
          room
        );
      }, 2000);
    }
    return null;
  };
  return (
    <div className={styles.container}>
      {posterBedroomMultiEmotionObject.isChecked ||
      !puzzleBedroomMultiEmotionObject.isChecked ? (
        <div className={styles.container}>
          <p>{handleMessage(description.content)}</p>
          <img src={handleImage(description.content)} alt="piece" />
        </div>
      ) : (
        <div className={styles.container}>
          <Audio
            sound={soundEffects.puzzleRotate}
            condition={soundtrack && soundPuzzleOn}
          />
          <p>{handleMessage(description.content)}</p>
          <p>{errorPuzzleMessage && errorPuzzleMessage}</p>
          <div className={styles.puzzle}>
            {puzzle.map((piece) => (
              <img
                key={piece.id}
                onClick={() => handleRotate(piece)}
                onMouseUp={() => setSoundPuzzleOn(false)}
                onMouseDown={() => setSoundPuzzleOn(true)}
                aria-hidden="true"
                className={`piece${piece.id}`}
                src={piece.src}
                alt="pièce du puzzle"
                style={{ transform: `rotate(${piece.degres}deg)` }}
              />
            ))}
          </div>

          <Button
            buttonType="action"
            title={t('buttonFunction.validate')}
            onClick={() => handleValidatePuzzle()}
          />
        </div>
      )}
    </div>
  );
};

export default Puzzle;
