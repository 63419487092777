import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';

// selectors
import { selectModalEnigma } from 'components/Reducers/Modal/Modal';
import {
  selectObject,
  selectObjectIndex,
} from 'components/Reducers/GameData/fetchObjects';
import { selectSession } from 'components/Reducers/session';
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';
// components
import Button from 'components/GameBoard/ReusableComponents/Actions/Button';

// scss
import styles from 'components/GameBoard/Emotion/Multi/Enigma/Wardrobe/Wardrobe.module.scss';

// constant
import { keyBedroomMultiEmotion } from 'components/GameBoard/ReusableComponents/Objects/constants';
import { listPostIt } from 'components/GameBoard/Emotion/Multi/constant';

// utils
import {
  animationConfetti,
  changeStatusOfObject,
  checkedObject,
  shuffleArray,
} from 'utils/utilityFunctions';

const Wardrobe = () => {
  const { t } = useTranslation(['common', 'emotion-multi']);
  const dispatch = useDispatch();

  const { description } = useSelector(selectModalEnigma);
  const session = useSelector(selectSession);
  const { socket, idSessionHasRoom } = useSelector(infoGameUser);
  const room = session.id;

  const resetListPost = shuffleArray(listPostIt);
  const [listPostItMirror, setListPostItMirror] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (description.content.status === 'open' || description.content.isChecked) {
      setIsOpen(true);
    }
  }, [description.content]);
  useEffect(() => {
    socket?.on('receive_emotion_multi_set_post_it_wradrobe', (data) => {
      setListPostItMirror(data.newArray);
    });
    socket?.on('receive_error_messaging', (data) => {
      setErrorMessage(data.errorContent);
    });
    socket?.on('receive_is_open', (data) => {
      setIsOpen(data.isOpen);
    });
  }, [socket]);

  const handleResetListPost = useCallback(() => {
    setListPostItMirror(resetListPost);
    const responsesSocket = {
      newArray: resetListPost,
      errorContent: null,
      room,
    };
    socket?.emit('send_emotion_multi_set_post_it_wradrobe', responsesSocket);
    socket?.emit('send_error_messaging', responsesSocket);
    setErrorMessage(null);
  }, [resetListPost, room, socket]);

  useEffect(() => {
    if (socket && !listPostItMirror.length) {
      handleResetListPost();
    }
  }, [handleResetListPost, listPostItMirror, resetListPost, room, socket]);

  // content object key
  const keyBedroomMultiEmotionObject = useSelector((state) =>
    selectObject(state, keyBedroomMultiEmotion)
  );
  const keyBedroomMultiEmotionIndex = useSelector((state) =>
    selectObjectIndex(state, keyBedroomMultiEmotion)
  );

  const wardrobeBedroomEmotionMultiIndex = useSelector((state) =>
    selectObjectIndex(state, description.content.id)
  );

  const handleImage = (descriptionContent) => {
    let imageDrawer;
    if (descriptionContent?.images) {
      const objectIndex = descriptionContent.images.findIndex(
        (object) => object.type === 'clicked'
      );
      imageDrawer = descriptionContent.images[objectIndex].image;
    }
    if (isOpen) {
      const objectIndex = descriptionContent.images.findIndex(
        (object) => object.type === 'open'
      );
      imageDrawer = descriptionContent.images[objectIndex].image;
    }
    return imageDrawer;
  };

  const handleMessage = (descriptionContent) => {
    let message;
    if (descriptionContent?.images) {
      const objectIndex = descriptionContent.images.findIndex(
        (object) => object.type === 'clicked'
      );
      message = descriptionContent?.images[objectIndex].message;
    }
    if (isOpen) {
      const objectIndex = descriptionContent.images.findIndex(
        (object) => object.type === 'open'
      );
      message = descriptionContent?.images[objectIndex].message;
    }

    return message;
  };

  const usedKey = (objectElement, index) => {
    const responsesSocket = { isOpen: true, room };
    socket?.emit('send_is_open', responsesSocket);
    setIsOpen(true);
    changeStatusOfObject(
      description.content,
      'open',
      idSessionHasRoom,
      dispatch,
      socket,
      room
    );
    checkedObject(
      objectElement,
      index,
      idSessionHasRoom,
      dispatch,
      socket,
      room,
      'not-message',
      'not-closed'
    );
  };

  const deletePostIt = (index) => {
    const newListPostItMirror = [...listPostItMirror];
    newListPostItMirror.splice(index, 1);
    const responsesSocket = {
      newArray: newListPostItMirror,
      room,
    };
    socket?.emit('send_emotion_multi_set_post_it_wradrobe', responsesSocket);

    setListPostItMirror(newListPostItMirror);
  };

  const submitPostIt = () => {
    const goodCombinaison = listPostItMirror.find((postIt) => !postIt.isGood);
    if (goodCombinaison === undefined) {
      animationConfetti();
      const responsesSocket = {
        room,
      };
      socket?.emit('send_confetti', responsesSocket);
      checkedObject(
        description.content,
        wardrobeBedroomEmotionMultiIndex,
        idSessionHasRoom,
        dispatch,
        socket,
        room
      );
    } else {
      const errorContent = t('wradrobe.error', { ns: 'emotion-multi' });
      const responsesSocket = {
        errorContent,
        room,
      };
      socket?.emit('send_error_messaging', responsesSocket);
      setErrorMessage(errorContent);
    }
  };

  return (
    <div className={styles.wardrobe}>
      {!description.content.isChecked && (
        <p className={styles.message}>
          {errorMessage || handleMessage(description.content)}
        </p>
      )}
      {keyBedroomMultiEmotionObject.status === 'open' && !isOpen && (
        <div className={styles.key}>
          <img src={handleImage(keyBedroomMultiEmotionObject)} alt="frame" />
          <Button
            buttonType="action"
            title={t('buttonFunction.open')}
            onClick={() =>
              usedKey(keyBedroomMultiEmotionObject, keyBedroomMultiEmotionIndex)
            }
          />
        </div>
      )}
      {isOpen && (
        <div className={styles.mirror}>
          {listPostItMirror.map((postIt, index) => {
            return (
              <button
                key={postIt.id}
                type="button"
                className={`${styles['post-it']} ${styles[`post-it-${postIt.id}`]}`}
                onClick={() => deletePostIt(index)}
              >
                <p>{postIt.title}</p>
              </button>
            );
          })}
        </div>
      )}
      {isOpen && (
        <div className={styles.actions}>
          <Button
            buttonType="action"
            title={
              errorMessage
                ? t('buttonFunction.retry')
                : t('buttonFunction.validate')
            }
            onClick={() => (errorMessage ? handleResetListPost() : submitPostIt())}
          />
        </div>
      )}
      <img
        className={styles.background}
        src={handleImage(description.content)}
        alt="frame"
      />
    </div>
  );
};

export default Wardrobe;
