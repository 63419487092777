import PropTypes from 'prop-types';
import Button from 'components/GameBoard/ReusableComponents/Actions/Button';
import ModalHead from 'components/GameBoard/OffreSocle/Enigmas/ModalHead';
import styles from 'components/GameBoard/OffreSocle/Enigmas/OffreSocleModal2.module.scss';

const OffreSocleModal2 = ({
  text,
  subText,
  buttonTitle,
  onClick,
  button2Title,
  onClick2,
}) => {
  return (
    <div className={styles['modal-position']}>
      <div className={styles['modal-container']}>
        <ModalHead />
        <div className={styles['modal-content']}>
          {text && <h1>{text}</h1>}
          {subText && <p className={styles.text}>{subText}</p>}
        </div>
        <div>
          <Button
            onClick={() => onClick()}
            title={buttonTitle}
            buttonType="offreSocle"
          />
          {button2Title && (
            <Button
              onClick={() => onClick2()}
              title={button2Title}
              buttonType="offreSocle"
            />
          )}
        </div>
      </div>
    </div>
  );
};

OffreSocleModal2.propTypes = {
  buttonTitle: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  subText: PropTypes.string,
  text: PropTypes.string,
  button2Title: PropTypes.string,
  onClick2: PropTypes.func,
};

OffreSocleModal2.defaultProps = {
  subText: undefined,
  text: undefined,
  button2Title: undefined,
  onClick2: undefined,
};

export default OffreSocleModal2;
