const initialState = {
  idSessionHasRoom: null,
  goToFocusSurvey: false,
  count: 0,
  clicked: false,
  gameStarted: 0,
  gameTour: true,
  gameOver: false,
  gameFinished: false,
  displayScores: false,
  idSession: 0,
  timerPause: false,
  time: 0,
  gameScore: {
    past: 0,
    present: 0,
  },
  scoreAnim: false,
  soundtrack: true,
  music: true,
  cursor: {
    x: null,
    y: null,
  },
  userDescription: null,
};

const GameUsers = (state = initialState, action) => {
  switch (action.type) {
    case 'INIT_SOCKET': {
      return {
        ...state,
        socket: action.payload,
      };
    }
    case 'SET_USER':
      return {
        ...state,
        userDescription: action.payload,
      };
    // REFACTO vaccination
    case 'SET_COUNT_BACK':
      return {
        ...state,
        count: action.payload,
      };
    case 'SET_SESSION_HAS_ROOM_ID':
      return {
        ...state,
        idSessionHasRoom: action.payload,
      };
    // REFACTO vaccination
    case 'GAME_ACTION': {
      const whichAction = action.payload.typeOfAction;
      return {
        ...state,
        [whichAction]: action.payload.action,
      };
    }
    case 'SET_COUNT': {
      return {
        ...state,
        count: action.payload,
      };
    }
    case 'SET_SCORE': {
      return {
        ...state,
        gameScore: {
          ...state.gameScore,
          present: action.payload,
          past: action.payload,
        },
      };
    }
    case 'UPDATE_GAME_SCORE':
      return {
        ...state,
        gameScore: {
          ...state.gameScore,
          past: state.gameScore.present,
          present: state.gameScore.present + action.payload,
        },
      };
    // REFACTO vaccination
    case 'TRIGGER_SCORE_ANIM':
      return {
        ...state,
        scoreAnim: action.payload,
      };
    case 'PLACE_CURSOR':
      return {
        ...state,
        cursor: action.payload,
      };
    // REFACTO scoreboard of vaccination
    case 'SET_SESSION_NAME':
      return {
        ...state,
        sessionName: action.roomName,
        idSession: action.idSession,
      };
    case 'CLICKED_MOUSE_UP':
      return {
        ...state,
        clicked: false,
      };
    case 'CLICKED_MOUSE_DOWN':
      return {
        ...state,
        clicked: true,
      };
    case 'START_GAME':
      return {
        ...state,
        gameStarted: action.payload,
      };
    case 'SET_GAME_TIME':
      return {
        ...state,
        time: action.payload,
      };
    // REFACTO vaccination
    case 'START_GAME_TOUR':
      return {
        ...state,
        gameTour: action.payload,
      };
    // REFACTO vaccination
    case 'GAME_OVER':
      return {
        ...state,
        gameOver: action.payload,
      };
    case 'PAUSE_TIMER':
      return {
        ...state,
        timerPause: action.payload,
      };
    case 'MUTE_SOUNDTRACK':
      return {
        ...state,
        soundtrack: action.payload,
      };
    case 'MUTE_MUSIC':
      return {
        ...state,
        music: action.payload,
      };
    case 'INCREMENT_COUNTER':
      return {
        ...state,
        count: state.count + 1,
      };
    // REFACTO scoreboard vaccination
    case 'GAME_MASTER_GO_TO_SURVEY':
      return {
        ...state,
        goToFocusSurvey: action.payload,
      };
    case 'INCREMENT_COUNT_USER': {
      return {
        ...state,
        count: action.payload.count ? action.payload.count + 1 : state.count,
      };
    }
    default:
      return state;
  }
};

// Define the selector functions
export const infoGameUser = (state) => state.GameUsers;

export default GameUsers;
