/* eslint-disable consistent-return */
/* eslint-disable func-names */
/* eslint-disable prefer-object-spread */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import confetti from 'canvas-confetti';

import Audio from '../../ReusableComponents/Actions/Audio';
import successSound from '../../../../sound/success-card.mp3';
import '../../../../assets/css/components/GameBoard/TMS/Enigma/LockerOne.css';

const BASE_URL = process.env.REACT_APP_BASE_URL;

function LockerOne({
  soundtrack,
  objectContent,
  allObjects,
  dispatch,
  idSessionHasRoom,
}) {
  const [success, setSuccess] = useState(false);
  useEffect(() => {
    const duration = 1.5 * 1000;
    const animationEnd = Date.now() + duration;
    const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

    function randomInRange(min, max) {
      return Math.random() * (max - min) + min;
    }
    if (success) {
      const interval = setInterval(function () {
        const timeLeft = animationEnd - Date.now();

        if (timeLeft <= 0) {
          return clearInterval(interval);
        }

        const particleCount = 500 * (timeLeft / duration);
        // since particles fall down, start a bit higher than random
        confetti(
          Object.assign({}, defaults, {
            particleCount,
            origin: { x: randomInRange(0.1, 0.4), y: Math.random() - 0.2 },
          })
        );
        confetti(
          Object.assign({}, defaults, {
            particleCount,
            origin: { x: randomInRange(0.6, 0.9), y: Math.random() - 0.2 },
          })
        );
      }, 250);
    }
  }, [success]);

  let clickedContent = objectContent.content.images.find(
    (contentImage) => contentImage.type === 'clicked'
  );

  const [inputsLock, setInputsLock] = useState([0, 0, 0, 0]);

  const listLock = [
    {
      name: 'nothing',
      url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Fvestiaires%2Fobjects%2Fcasier%2FverrouL-vide.svg?alt=media&token=f14d3bfc-04c2-49c3-8173-5350db355f70',
    },
    {
      name: 'arm',
      url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Fvestiaires%2Fobjects%2Fcasier%2FverouI-jambes.svg?alt=media&token=73e50183-1dca-4788-8ccb-ed9339c09a4c',
    },
    {
      name: 'head',
      url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Fvestiaires%2Fobjects%2Fcasier%2FverouI-tete.svg?alt=media&token=168fce84-fd74-444c-b8c5-ebd9dfc92b3a',
    },
    {
      name: 'leg',
      url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Fvestiaires%2Fobjects%2Fcasier%2FverrouI-bras.svg?alt=media&token=2d983695-ba21-45e7-8c85-cdf38877ac28',
    },
    {
      name: 'bust',
      url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Fvestiaires%2Fobjects%2Fcasier%2FverrouI-buste.svg?alt=media&token=6b4514bc-23b9-4467-acea-77f4221eb163',
    },
  ];

  const usedObject = (collectObject, index) => {
    axios
      .put(`${BASE_URL}/api/sessionsHasRoomHasObject/${idSessionHasRoom}`, {
        object_id: collectObject.id,
        status: 'open',
      })
      .then(() => {
        dispatch({
          type: 'CLICKED_OBJECT',
          payload: {
            index,
            status: 'open',
            type: 'used',
          },
        });
        axios
          .put(
            `${process.env.REACT_APP_BASE_URL}/api/sessionsHasRoom/${idSessionHasRoom}/modifications`,
            {
              current_step: objectContent.content.id_message,
            }
          )
          .then(() => {
            dispatch({
              type: 'CURRENT_STEP',
              payload: objectContent.content.id_message,
            });
            dispatch({
              type: 'START_MESSAGE_PREVENTION',
            });
          });
      });
  };

  useEffect(() => {
    const goodCombinaison = [3, 4, 1, 2];
    if (goodCombinaison.join('') === inputsLock.join('')) {
      dispatch({
        type: 'UPDATE_LEVEL',
        payload: 1,
      });
      setSuccess(true);
      const collectObjectLocker = allObjects.find(
        (collectContent) => collectContent.id === objectContent.content.id
      );
      const collectObjectLockerIndex = allObjects.findIndex(
        (collectContent) => collectContent.id === objectContent.content.id
      );
      setTimeout(() => {
        usedObject(collectObjectLocker, collectObjectLockerIndex);
        dispatch({ type: 'CLOSE_MODAL_ENIGMA' });
      }, 200);
    }
    // TO REFACTO
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputsLock]);

  const handleInputLock = (elementLock, index) => {
    const newInputsLock = [...inputsLock];

    if (elementLock === 4) {
      newInputsLock[index] = 1;
    } else {
      newInputsLock[index] = elementLock + 1;
    }

    setInputsLock(newInputsLock);
  };

  if (objectContent.content.status === 'open') {
    clickedContent = objectContent.content.images.find(
      (contentImage) => contentImage.type === 'open'
    );
    return (
      <div className="LockerOne Modal_description">
        <div className="Modal_description-text">
          <p>{clickedContent.message}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="LockerOne Modal_description">
      <Audio condition={success && soundtrack} sound={successSound} />
      <div className="Modal_description-text">
        <p>{clickedContent.message}</p>
      </div>
      <img
        className="LockerOne_image"
        src={clickedContent.image}
        alt={clickedContent.name}
      />
      <div className="LockerOne_buttons">
        {inputsLock.map((inputLock, index) => (
          <div
            onClick={() => handleInputLock(inputLock, index)}
            className="LockerOne_button"
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
          >
            <img src={listLock[inputLock].url} alt={listLock[inputLock].name} />
          </div>
        ))}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  idSessionHasRoom: state.GameUsers.idSessionHasRoom,
  soundtrack: state.GameUsers.soundtrack,
  allObjects: state.Objects.AllObjects,
});

LockerOne.propTypes = {
  allObjects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
  objectContent: PropTypes.shape({
    content: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      images: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string,
        })
      ),
      status: PropTypes.bool,
      id_message: PropTypes.number,
    }),
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  idSessionHasRoom: PropTypes.number.isRequired,
  soundtrack: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(LockerOne);
