import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

const RoomContainer = styled(motion.div)`
  overflow: hidden;

  ${({ isBackgroundFixed, maxWidth, imgDimensions }) =>
    isBackgroundFixed
      ? css`
          max-width: ${maxWidth};
          position: relative;
          top: ${window.innerHeight - imgDimensions.height >= 0
            ? (window.innerHeight - imgDimensions.height) / 2
            : 0}px;
          margin: auto;
        `
      : css`
          width: ${maxWidth};
        `}

  header {
    display: flex;
    position: absolute;
    width: 100%;
  }
`;

export default RoomContainer;
