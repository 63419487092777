import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { infoGameUser } from 'components/Reducers/GameData/GameUsers';
import { selectSession } from 'components/Reducers/session';
import ContentQuestion from 'components/GameBoard/ReusableComponents/Multi/Shared/Evaluation/StoryLine/ContentQuestion';
import { selectUser } from 'components/Reducers/user';
import { selectQuestionnary } from 'components/Reducers/questionnary';
import { linkUserSurveySession } from 'utils/utilityFunctions';

const EvalutionQuestionnary = ({ setStatus, content, isPedagogy }) => {
  const { t } = useTranslation(['emotion-multi', 'common']);
  const allQuestions = t(`${content}.content`, { returnObjects: true });

  const dispatch = useDispatch();
  const { socket } = useSelector(infoGameUser);
  const session = useSelector(selectSession);
  const room = session.id;
  const { id, currentUser, participants } = useSelector(selectUser);
  const { survey } = useSelector(selectQuestionnary);

  const [indexQuestion, setIndexQuestion] = useState(0);

  const { isMainUser, type } = participants[Object.keys(currentUser)[0]];

  useEffect(() => {
    const descriptionModal = t(content, { returnObjects: true });
    const responsesSocket = { descriptionModal, room };
    socket?.emit('send_open_modal', responsesSocket);
    dispatch({
      type: 'OPEN_MODAL',
      payload: descriptionModal,
    });
  }, [content, dispatch, room, socket, t]);

  useEffect(() => {
    if (survey.id) {
      // init UserHasSurveyId (link between user and survey) The user need
      // this id to answer of the survey
      linkUserSurveySession(id, survey.id, session.id, dispatch);
    }
  }, [dispatch, id, session.id, survey.id]);

  useEffect(() => {
    socket.on('receive_status_debrief', (data) => {
      setStatus(data.status);
    });
    socket.on('receive_index_stories', (data) => {
      setIndexQuestion(data.index);
    });
  }, [dispatch, setStatus, socket]);

  const handleNext = () => {
    const responsesSocket = {
      room,
      status: 'debrief',
    };
    if (!isPedagogy) {
      socket?.emit('send_status_debrief', responsesSocket);
      setStatus('debrief');
    } else {
      socket?.emit('send_stop_pedagogy', responsesSocket);
      dispatch({
        type: 'STOP_PEDAGOGY',
      });
    }
  };

  const handleNextQuestionnary = () => {
    const lastIndexStories = allQuestions.length - 1;
    if (isMainUser || type === 'admin') {
      let responsesSocket = {
        room,
      };
      if (indexQuestion !== lastIndexStories) {
        const nextIndex = indexQuestion + 1;
        responsesSocket = {
          ...responsesSocket,
          index: nextIndex,
        };
        socket?.emit('send_index_stories', responsesSocket);
        setIndexQuestion(nextIndex);
      } else {
        handleNext();
      }
    }
  };

  const handleControlButton = () => {
    if (isMainUser || type === 'admin') {
      return false;
    }
    return true;
  };

  return (
    <ContentQuestion
      idSurvey={allQuestions[indexQuestion].idSurvey}
      handleNextStories={handleNextQuestionnary}
      isControlButton={handleControlButton()}
    />
  );
};

EvalutionQuestionnary.propTypes = {
  setStatus: PropTypes.func.isRequired,
  content: PropTypes.string.isRequired,
  isPedagogy: PropTypes.bool.isRequired,
};

export default EvalutionQuestionnary;
