import { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';

import '../../../../assets/css/components/GameBoard/RPS/Enigma/Computer.css';

const BASE_URL = process.env.REACT_APP_BASE_URL;

function Computer({ dispatch, allObjects, idSessionHasRoom }) {
  const [isComputerOn, setIsComputerOn] = useState(false);
  const [menu] = useState([
    {
      id: 253,
      name: 'RDV Manager',
      icon: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FRPS%2Fassets%2Frooms%2FBreakRoom%2Fobjects%2Frest%2Ficon-manager.svg?alt=media&token=d5fe8f3f-e50b-4d2d-89e3-c6136045b0a6',
    },
    {
      id: 254,
      name: 'RDV Médical',
      icon: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FRPS%2Fassets%2Frooms%2FBreakRoom%2Fobjects%2Frest%2Ficon-medical.svg?alt=media&token=6df4f813-2f10-446a-9973-7260a475af69',
    },
    {
      id: 3,
      name: 'Photos',
      icon: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FRPS%2Fassets%2Frooms%2FBreakRoom%2Fobjects%2Frest%2Ficon-photos.svg?alt=media&token=6001508c-88c6-4bd6-8663-312a0a54e550',
    },
    {
      id: 4,
      name: 'Emails',
      icon: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FRPS%2Fassets%2Frooms%2FBreakRoom%2Fobjects%2Frest%2Ficon-mails.svg?alt=media&token=b84ae6ea-05da-4d55-a1a3-4a137a44351c',
    },
  ]);
  const [idMenu, setIdMenu] = useState(0);

  const addInventory = (objectElement) => {
    axios
      .put(`${BASE_URL}/api/sessionsHasRoomHasObject/${idSessionHasRoom}`, {
        object_id: objectElement.id,
        isInventory: true,
      })
      .then(() => {
        dispatch({
          type: 'ADD_INVENTORY',
          payload: objectElement,
        });
        dispatch({
          type: 'INCREMENT_COUNTER',
        });
        setTimeout(() => {
          dispatch({
            type: 'CLOSE_MODAL',
          });
        }, 100);
      });
  };

  const handleModal = (id) => {
    const idRdvManager = 253;
    const idRdvMedical = 254;

    const objectRdvManager = allObjects.find(
      (objectElement) => objectElement.id === idRdvManager
    );
    const objectRdvMedical = allObjects.find(
      (objectElement) => objectElement.id === idRdvMedical
    );
    let descriptionModal = {};

    switch (id) {
      case idRdvManager:
        descriptionModal = {
          message: [
            'Félicitations !',
            ' Vous venez d’obtenir un coupon pour une prise de rendez - vous avec un manager.',
            " Voulez-vous l’ajouter  à l'inventaire ?",
          ],
          type: 'inventory',
          buttonDescription: {
            title: 'Ajouter',
            onClick: () => {
              addInventory(objectRdvManager, idRdvManager);
            },
          },
          image:
            'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FRPS%2Fassets%2Frooms%2FBreakRoom%2Fobjects%2Frest%2Frdv-manager%20.svg?alt=media&token=b95e879c-580e-4776-b9e0-b6bb4f1891ea',
        };
        break;
      case idRdvMedical:
        descriptionModal = {
          message: [
            'Félicitations !',
            ' Vous venez d’obtenir un coupon pour une prise de rendez-vous avec un professionel de santé.',
            " Voulez-vous l’ajouter à l'inventaire ?",
          ],
          type: 'inventory',
          buttonDescription: {
            title: 'Ajouter',
            onClick: () => {
              addInventory(objectRdvMedical, idRdvMedical);
            },
          },
          image:
            'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FRPS%2Fassets%2Frooms%2FBreakRoom%2Fobjects%2Frest%2Frdv-medical.svg?alt=media&token=2ee9647b-bea8-43c5-8312-662d7a1472f8',
        };
        break;

      default:
        break;
    }

    dispatch({
      type: 'CLOSE_MODAL_ENIGMA',
    });

    dispatch({
      type: 'OPEN_MODAL',
      payload: descriptionModal,
    });
  };

  const handleScreen = () => {
    const idRdvManager = 253;
    const idRdvMedical = 254;
    const idPhotos = 3;
    const idEmails = 4;

    switch (idMenu) {
      case idRdvManager:
        return (
          <div className="Icons_Computer">
            <div className="Computer_content-right-screen">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FRPS%2Fassets%2Frooms%2FBreakRoom%2Fobjects%2Frest%2Ffenetre-rdvmanager.svg?alt=media&token=c474cacf-19d1-4e05-abd4-99eeaa3acd1b"
                alt="rdvmanager"
              />
              {!allObjects.filter((obj) => obj.id === idRdvManager)[0]
                .isInventory && (
                <div className="ButtonConfirmRDV">
                  <button type="button" onClick={() => handleModal(253)}>
                    Confirmer
                  </button>
                </div>
              )}
            </div>
          </div>
        );
      case idRdvMedical:
        return (
          <div className="Icons_Computer">
            <div className="Computer_content-right-screen">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FRPS%2Fassets%2Frooms%2FBreakRoom%2Fobjects%2Frest%2Ffenetre-rdvmedical.svg?alt=media&token=26d83e4c-d9b3-4c01-946a-c1dd72d7fdc8"
                alt="rdvmedical"
              />
              {!allObjects.filter((obj) => obj.id === idRdvMedical)[0]
                .isInventory && (
                <div className="ButtonConfirmRDV">
                  <button type="button" onClick={() => handleModal(254)}>
                    Confirmer
                  </button>
                </div>
              )}
            </div>
          </div>
        );
      case idPhotos:
        return (
          <div className="Computer_content-right-screen">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FRPS%2Fassets%2Frooms%2FBreakRoom%2Fobjects%2Frest%2Ffenetre-photos.svg?alt=media&token=2c173a3b-2ee8-47b3-a608-379d32b453ef"
              alt="photos"
            />
          </div>
        );
      case idEmails:
        return (
          <div className="Computer_content-right-screen">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FRPS%2Fassets%2Frooms%2FBreakRoom%2Fobjects%2Frest%2Ffenetre-mails.svg?alt=media&token=80d69cab-cdbd-4daf-bf3e-bb29f85927ab"
              alt="photos"
            />
          </div>
        );
      default:
        return null;
    }
  };
  return (
    <button
      type="button"
      onClick={() => setIsComputerOn(true)}
      className={`Computer ${isComputerOn ? 'on' : 'off'}`}
    >
      {isComputerOn && (
        <div className="Computer_content">
          <div className="Computer_content-left">
            {menu.map((menuContent) => (
              <button
                className="Computer_content-left-button"
                type="button"
                onClick={() => setIdMenu(menuContent.id)}
              >
                <img src={menuContent.icon} alt={menuContent.name} />
              </button>
            ))}
          </div>
          <div className="Computer_content-right">{handleScreen()}</div>
        </div>
      )}
    </button>
  );
}
const mapStateToProps = (state) => ({
  contents: state.Modal.modalEnigma.description.content,
  allObjects: state.Objects.AllObjects,
  idSessionHasRoom: state.GameUsers.idSessionHasRoom,
  inventory: state.Inventory,
});

Computer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  allObjects: PropTypes.arrayOf({
    id: PropTypes.number,
  }).isRequired,
  idSessionHasRoom: PropTypes.number.isRequired,
};

export default connect(mapStateToProps)(Computer);
