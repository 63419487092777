import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { selectSteps } from 'components/Reducers/Steps/Steps';
import OffreSocleModal from 'components/GameBoard/OffreSocle/Enigmas/OffreSocleModal';
import Button from 'components/GameBoard/ReusableComponents/Actions/Button';
import styles from 'components/GameBoard/OffreSocle/Enigmas/CallErgonomist.module.scss';
import { referringDoctorOffice } from 'components/GameBoard/OffreSocle/constants';
import { selectOffreSocle } from 'components/Reducers/offreSocle';
import { playSound } from 'utils/utilityFunctions';

const { hangUpUrl, pickUpUrl, lastPhoneRow } = referringDoctorOffice;

const CallErgonomist = ({ objectContent }) => {
  const { t } = useTranslation('offreSocle', 'common');
  const dispatch = useDispatch();

  const { list } = useSelector(selectSteps);
  const firstMessageFound = list?.[0]?.isFound;

  const phoneClicked = objectContent.images.find(
    (object) => object.type === 'clicked'
  );

  const phoneHangUp = objectContent.images.find(
    (object) => object.type === 'background'
  );

  const { phoneNumber } = useSelector(selectOffreSocle);
  const [localPhoneNumber, setLocalPhoneNumber] = useState(phoneNumber);

  const handleHangUp = () => {
    playSound(phoneHangUp.sound);
    dispatch({
      type: 'CLOSE_MODAL_ENIGMA',
    });
  };

  return !firstMessageFound ? (
    <OffreSocleModal
      objectImage={phoneClicked.image}
      objectMessage={t('phoneNotNeeded')}
      buttonTitle={t('buttonFunction.return', { ns: 'common' })}
      onClick={() =>
        dispatch({
          type: 'CLOSE_MODAL_ENIGMA',
        })
      }
    />
  ) : (
    <div className={styles['enigma-container']}>
      <div className={styles['phone-container']}>
        <div className={styles['top-part']}>
          <div>{localPhoneNumber}</div>
        </div>
        <div className={styles['bottom-part']}>
          <button
            onClick={() => handleHangUp()}
            type="button"
            className={styles.hang}
          >
            <img src={hangUpUrl} alt={t('hangUp')} />
          </button>
          <div />
          <button
            type="button"
            className={styles.pick}
            onClick={() => {
              if (localPhoneNumber) {
                dispatch({ type: 'CLOSE_MODAL_ENIGMA' });
                dispatch({ type: 'SET_PHONE_NUMBER', payload: localPhoneNumber });
              }
            }}
          >
            <img src={pickUpUrl} alt={t('pickUp')} />
          </button>
          {/* Create an array [1,...,9] and map on it */}
          {Array.from({ length: 9 }, (value, key) => key + 1).map((number) => (
            <button
              key={number}
              type="button"
              className={styles.number}
              onClick={() => {
                setLocalPhoneNumber(localPhoneNumber.concat(number));
                playSound(phoneClicked.sound);
              }}
            >
              {number}
            </button>
          ))}
          {lastPhoneRow.map((phoneKey, index) => (
            <button
              key={phoneKey}
              type="button"
              className={styles.number}
              onClick={(event) => {
                playSound(phoneClicked.sound);
                setLocalPhoneNumber(
                  localPhoneNumber.concat(event.target.innerText)
                );
              }}
            >
              {index === lastPhoneRow.length - 1 ? (
                <span>{phoneKey}</span>
              ) : (
                phoneKey
              )}
            </button>
          ))}
        </div>
      </div>
      <Button
        title={t('buttonFunction.return', { ns: 'common' })}
        buttonType="offreSocle"
        onClick={() => handleHangUp()}
      />
    </div>
  );
};

CallErgonomist.propTypes = {
  objectContent: PropTypes.shape({
    images: PropTypes.shape({
      find: PropTypes.func,
    }),
  }).isRequired,
};

export default CallErgonomist;
