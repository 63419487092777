import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import RoomsComponents from 'components/GameBoard/ReusableComponents/RoomsComponents';

import { selectRoom } from 'components/Reducers/Room';
import { selectInfoGame } from 'components/Reducers/game';
import { ThemeProvider } from 'styled-components';

function GameSoloGeneric({ folderName, setStatusCheckRoom, statusCheckRoom }) {
  const dispatch = useDispatch();

  // `t` is a function coming from the i18next library used to translate text
  /* Load the `common` namespace = the one containing translations that are common between games
   (see public-> locales -> fr-FR) */
  const { t } = useTranslation('common');

  // Call data from the store
  const Room = useSelector(selectRoom);
  const game = useSelector(selectInfoGame);

  // Dispatch the right active room
  useEffect(() => {
    // Load the right room when we refresh a game.The data has already been in the store thanks to dispatch INIT_GAME_ROOM in Game.jsx
    if (Room.data.room_id !== null && !game.gameOver) {
      const slugRoom = Object.values(Room.listRooms).find(
        (room) => room.id === Room.data.room_id
      ).slug;

      dispatch({
        type: 'ROOM_ACTIVE',
        payload: {
          room: slugRoom,
          active: true,
        },
      });
    } else {
      // When no room has already been loaded
      const firstRoom = Object.keys(Room.listRooms).filter(
        (room) => Room.listRooms[room].niveau === 1 && Room.listRooms[room]
      );
      dispatch({
        type: 'ROOM_ACTIVE',
        payload: {
          room: firstRoom,
          active: true,
        },
      });
    }

    // start timer if we recharge and in the dbb the game already start
    if (game.gameStarted) {
      dispatch({ type: 'PAUSE_TIMER', payload: false });
    }

    // END SEARCH LIST OF ROOM //
    /* TODO this action has already been dispatched in Game.jsx.
     We need to keep it only in one of the 2 components */
    // When we recharge the page check if it's the moment to view the tuto or not

    dispatch({
      type: 'RESET_TUTO',
      payload: Room.data.isTutoFinish,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Find the active room
  const activeRoom = Object.keys(RoomsComponents[folderName].list).find(
    (roomName) => Room.listRooms[roomName].isActive
  );

  // Find if there is a style theme
  const { theme } = RoomsComponents[folderName];

  /**
   * Function that dynamically returns a component
   * @returns {component|string}
   */
  const handleDynamicCall = () => {
    if (activeRoom) {
      let RoomComponent;

      if (RoomsComponents[folderName].type !== 'demo') {
        RoomComponent = RoomsComponents[folderName].list[activeRoom];
      } else {
        // Special case where the game is a demo
        RoomComponent =
          RoomsComponents[folderName][activeRoom][
            Object.keys(RoomsComponents[folderName][activeRoom])
          ];
      }

      return (
        <RoomComponent
          setStatusCheckRoom={setStatusCheckRoom}
          statusCheckRoom={statusCheckRoom}
        />
      );
    }
    return t('activeRoom');
  };

  return (
    // CSS-in-JS theme for games provided with one
    theme ? (
      <ThemeProvider theme={theme}>{handleDynamicCall()}</ThemeProvider>
    ) : (
      <>{handleDynamicCall()}</>
    )
  );
}

GameSoloGeneric.propTypes = {
  folderName: PropTypes.string.isRequired,
  setStatusCheckRoom: PropTypes.func,
  statusCheckRoom: PropTypes.string,
};

GameSoloGeneric.defaultProps = {
  setStatusCheckRoom: () => {},
  statusCheckRoom: 'introduction',
};

export default GameSoloGeneric;
