/* eslint-disable no-param-reassign */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import axios from 'axios';
import ReactJoyride from 'react-joyride';
import { useTranslation } from 'react-i18next';

// Components
import Scoreboard from 'components/GameBoard/ReusableComponents/Scoreboard/Scoreboard';
import ChatBot from 'components/GameBoard/ReusableComponents/ChatBot/ChatBot';
import Audio from 'components/GameBoard/ReusableComponents/Actions/Audio';
import Inventory from 'components/GameBoard/ReusableComponents/Inventory';
import Modal from 'components/GameBoard/ReusableComponents/Modal/Modal';
import Steps from 'components/GameBoard/ReusableComponents/Steps';
import ListObjects from 'components/GameBoard/ReusableComponents/Objects/ListObjects';
import Zoom from 'components/GameBoard/ReusableComponents/Modal/Zoom';
import ModalZoom from 'components/GameBoard/ReusableComponents/Modal/ModalZoom';
import ObjectInInventory from 'components/GameBoard/ReusableComponents/Actions/ObjectInInventory';
import Hud from 'components/GameBoard/ReusableComponents/HUD/Hud';
import Intro from 'components/GameBoard/ReusableComponents/Intro';
import stepsTDE from 'components/Joyride/StepsTDE';
import Garland from 'components/GameBoard/TDE/Enigma/Garland';

// selector functions
import { selectSession } from 'components/Reducers/session';

// Utils
import useInitGame from 'utils/useInitGame';
import { useStartMessageOrQuestionary } from 'utils/utilityFunctions';
// Constants
import genericJoyrideCallback from 'components/Joyride/constants/genericJoyrideCallback';
// Style
import 'assets/css/components/GameBoard/TDE/Rooms/BedroomTDE.css';
import '../../GameBoard.css';
// Image
import haloDay1 from '../../../../img/tde/halo-day1.svg';
import haloDay2 from '../../../../img/tde/halo-day2.svg';
import haloNight1 from '../../../../img/tde/halo-night1.svg';
import haloNight2 from '../../../../img/tde/halo-night2.svg';
// Sound
import click from '../../../../sound/click-ongame.mp3';
import tdeTheme from '../../../../sound/TDE/In love with you.mp3';

const BASE_URL = process.env.REACT_APP_BASE_URL;

function BedroomTDE({
  GameUsers,
  modalIsOpen,
  isStarted,
  currentRoom,
  MouseClicked,
  dispatch,
  startMessage,
  currentStep,
  startQuestionnary,
  idSessionHasRoom,
  zoomIsOpen,
  modalZoomIsOpen,
  objects,
  isScoreBoard,
  isTutoFinish,
  listRooms,
}) {
  // `t` is a function coming from the i18next library used to translate text
  /* Load the `common` namespace = the one containing translations that are common between games
   (see public-> locales -> fr-FR) */
  const { t } = useTranslation(['common', 'tde']);

  const [tutorialSteps, setTutorialSteps] = useState({
    run: GameUsers.gameTour,
    steps: stepsTDE(t),
    stepIndex: 0,
  });

  const session = useSelector(selectSession);
  useInitGame(currentRoom, session.id);

  // COUNTER
  useEffect(() => {
    if (idSessionHasRoom) {
      axios.put(
        `${BASE_URL}/api/sessionsHasRoom/${idSessionHasRoom}/modifications`,
        {
          count: GameUsers.count,
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GameUsers.count]);

  useStartMessageOrQuestionary(
    currentStep,
    idSessionHasRoom,
    startMessage,
    startQuestionnary
  );

  const idLightObject = 148;
  const objectLight = objects.find(
    (objectContent) => objectContent.id === idLightObject
  );

  function parralax(e) {
    // eslint-disable-next-line react/no-this-in-sfc
    this.querySelectorAll('.layer-halo').forEach((layer) => {
      const speed = layer.getAttribute('data-speed');
      const x = (window.innerWidth - e.pageX * speed) / 1000;
      const y = (window.innerHeight - e.pageY * speed) / 1000;
      // eslint-disable-next-line no-param-reassign
      layer.style.transform = `translateX(${x}px) translateY(${y}px)`;
    });
  }
  document.addEventListener('mousemove', parralax);
  const clickOnGame = () => {
    dispatch({ type: 'INCREMENT_COUNTER' });
  };

  useEffect(() => {
    dispatch({
      type: 'CLICKED_MOUSE_DOWN',
    });
    setTimeout(() => {
      dispatch({
        type: 'CLICKED_MOUSE_UP',
      });
    }, 1000);
  }, [GameUsers.count, dispatch]);

  const updateMousePosition = (ev) => {
    const offset = document
      .querySelector('#BedroomTDE-BKG')
      .getBoundingClientRect();
    dispatch({
      type: 'PLACE_CURSOR',
      payload: {
        x: ev.pageX - offset.left,
        y: ev.pageY - offset.top,
      },
    });
  };

  useEffect(() => {
    document
      .getElementById('BedroomTDE-BKG')
      .addEventListener('mouseup', updateMousePosition);
    return () => window.removeEventListener('mouseup', updateMousePosition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const urlRoom = listRooms.BedroomTDE.url;
  return (
    <div className="bedroomTDE" id="BedroomTDE-BKG">
      <Audio sound={tdeTheme} loop id="TDE-Theme" muted={!GameUsers.music} />
      <Audio sound={click} condition={!MouseClicked && GameUsers.soundtrack} />
      <ChatBot />
      {!isScoreBoard && (
        <header>
          <Inventory />
          <Steps />
          <Hud />
        </header>
      )}
      <div
        className={MouseClicked ? 'cursor-clicked' : 'Hide'}
        style={{ top: GameUsers.cursor.y, left: GameUsers.cursor.x }}
      >
        <p>+1</p>
      </div>
      <div
        onClick={() => clickOnGame()}
        onKeyDown={() => {}}
        role="presentation"
        className="bedroomTDE-img-div"
      >
        <img
          className="bedroomTDE-background"
          role="presentation"
          src={
            objectLight && objectLight.status === 'open'
              ? 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTDE%2Fassets%2Fbackgrounds%2Fbackground-night-vide.svg?alt=media&token=54771048-0766-4080-905a-bc9c1ea84411'
              : urlRoom
          }
          alt="Fond TDE"
          draggable="false"
        />
        <Garland />
      </div>
      <ObjectInInventory />
      {modalIsOpen && <Modal />}
      {modalZoomIsOpen && <ModalZoom />}
      {zoomIsOpen && <Zoom />}
      {!isStarted && isTutoFinish && (
        <Intro translationFileName="tde" modalAfterIntro />
      )}
      {isScoreBoard && <Scoreboard />}
      <ListObjects />
      {!isTutoFinish && (
        <ReactJoyride
          callback={(data) =>
            genericJoyrideCallback(
              data,
              session,
              dispatch,
              tutorialSteps,
              setTutorialSteps
            )
          }
          steps={tutorialSteps.steps}
          run={tutorialSteps.run}
          stepIndex={tutorialSteps.stepIndex}
          showSkipButton
          continuous
          disableCloseOnEsc
          disableOverlayClose
          hideBackButton
          hideCloseButton
          locale={{
            last: t('joyride.locale.last'),
            next: t('joyride.locale.next'),
            skip: t('joyride.locale.skip'),
          }}
          styles={{
            buttonNext: {
              backgroundColor: '#4ed199',
              border: '1px solid #48c48e',
              padding: '15px 25px',
              fontWeight: '700',
            },
            buttonSkip: {
              color: '#9fa2b4',
              fontWeight: '700',
            },
          }}
        />
      )}
      {objects.filter((item) => item.name === 'Interrupteur') &&
        objects.filter((item) => item.name === 'Interrupteur')[0] &&
        objects.filter((item) => item.name === 'Interrupteur')[0].status && (
          <div className="bedroomTDE-halo">
            <img
              src={
                objects.filter((item) => item.name === 'Interrupteur')[0].status ===
                  'closed' ||
                objects.filter((item) => item.name === 'Interrupteur')[0].status ===
                  undefined
                  ? haloDay1
                  : haloNight1
              }
              data-speed="6"
              alt="Halo de limière"
              className="layer-halo"
              draggable="false"
            />
            <img
              src={
                objects.filter((item) => item.name === 'Interrupteur')[0].status ===
                  'closed' ||
                objects.filter((item) => item.name === 'Interrupteur')[0].status ===
                  undefined
                  ? haloDay2
                  : haloNight2
              }
              data-speed="-6"
              alt="Halo de limière"
              className="layer-halo"
              draggable="false"
            />
          </div>
        )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  game: state.game,
  modalIsOpen: state.Modal.modal.isOpen,
  isStarted: state.GameUsers.gameStarted,
  currentRoom: state.Room.currentRoomId,
  idSessionHasRoom: state.GameUsers.idSessionHasRoom,
  GameUsers: state.GameUsers,
  startMessage: state.Steps.startMessage,
  currentStep: state.Steps.currentStep,
  startQuestionnary: state.Steps.startQuestionnary,
  zoomIsOpen: state.Modal.zoom.isOpen,
  modalZoomIsOpen: state.Modal.modalZoom.isOpen,
  allObjects: state.Objects.AllObjects,
  isAdvice: state.Steps.isAdvice,
  objects: state.Objects.AllObjects,
  MouseClicked: state.GameUsers.clicked,
  isScoreBoard: state.Steps.isScoreBoard,
  isTutoFinish: state.Steps.isTutoFinish,
  listRooms: state.Room.listRooms,
});

BedroomTDE.propTypes = {
  MouseClicked: PropTypes.bool.isRequired,
  game: PropTypes.shape({
    photo: PropTypes.string,
  }).isRequired,
  GameUsers: PropTypes.shape({
    soundtrack: PropTypes.bool,
    music: PropTypes.bool,
    cursor: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number,
    }),
    count: PropTypes.number,
    time: PropTypes.string,
    gameTour: PropTypes.bool.isRequired,
  }).isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  isStarted: PropTypes.number.isRequired,
  currentRoom: PropTypes.number.isRequired,
  idSessionHasRoom: PropTypes.number,
  startMessage: PropTypes.number.isRequired,
  currentStep: PropTypes.shape({
    id: PropTypes.number,
  }),
  startQuestionnary: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  zoomIsOpen: PropTypes.bool.isRequired,
  modalZoomIsOpen: PropTypes.bool.isRequired,
  objects: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ).isRequired,
  isScoreBoard: PropTypes.bool.isRequired,
  listRooms: PropTypes.shape({
    BedroomTDE: PropTypes.shape({
      url: PropTypes.string,
    }),
  }).isRequired,
  isTutoFinish: PropTypes.number.isRequired,
};

BedroomTDE.defaultProps = {
  idSessionHasRoom: null,
  currentStep: null,
};

export default connect(mapStateToProps)(BedroomTDE);
