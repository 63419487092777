import {
  offreSocleLocker1Id,
  offreSocleLocker2Id,
  offreSocleLocker3Id,
} from 'components/GameBoard/ReusableComponents/Objects/constants';

const frontDeskOffice = {
  secondBackgroundUrl:
    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FOffre%20Socle%2Fassets%2FRoom%201%2Fbackground-2.svg?alt=media&token=4b846f8c-936a-4bec-b74d-1ad1f48dbfca',
  backgroundMaxWidth: '181vh',
  phoneStatus: {
    background: 'background',
    answered: 'answered',
  },
  receptionnistFace:
    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FOffre%20Socle%2Fassets%2FRoom%201%2Freceptionnist-face.png?alt=media&token=f2d4272a-f438-4c6a-8432-82a5cd3bdac9',
};

const referringDoctorOffice = {
  backgroundMaxWidth: '190vh',
  computerScreen:
    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FOffre%20Socle%2Fassets%2FRoom%202%2Fcomputer-screen.svg?alt=media&token=34d00dd8-6907-4f06-ae1a-2f1f9ea3c608',
  videoConfBackground:
    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FOffre%20Socle%2Fassets%2FRoom%202%2Fvideo-conf-background.svg?alt=media&token=2e0e722c-afb2-4a2d-a8da-824ca6f6af47',
  companyView:
    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FOffre%20Socle%2Fassets%2FRoom%202%2Fcompany-view.svg?alt=media&token=39143c5c-bb53-41a8-94ff-97aaa60e2b9d',
  directorFace:
    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FOffre%20Socle%2Fassets%2FRoom%202%2Fdirector-face.svg?alt=media&token=7f08b1a8-51ba-4c3d-bbc1-fb3826f94455',
  doctorFace:
    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FOffre%20Socle%2Fassets%2FRoom%202%2Fdoctor-face.svg?alt=media&token=a24a60a2-376e-4f2d-bc52-c16d7e87a859',
  employeeFace:
    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FOffre%20Socle%2Fassets%2FRoom%202%2Femployee-face.svg?alt=media&token=7863b466-eacb-4224-b47a-63a36e5a6a61',
  companyBackgroundUrl:
    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FOffre%20Socle%2Fassets%2FRoom%202%2Fcompany-background.svg?alt=media&token=880920fe-feef-4e9b-b75c-4fd2e864dbe4',
  companyBackgroundStyle: {
    width: '225vh',
    // Location of the point of interest (% of the svg width)
    pointOfInterest: 30,
    // Transition when we move in the background
    duration: '1s',
    timing: 'ease',
    fillMode: 'forwards',
    /* Number of pixels needed to reach the left end of the background */
    imgLeft: '0px',
  },
  padlock:
    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FOffre%20Socle%2Fassets%2FRoom%202%2Fpadlock.svg?alt=media&token=09e75b60-0f91-4311-809d-1280ca0ebfba',
  codeBlock:
    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FOffre%20Socle%2Fassets%2FRoom%202%2Fcode-block.svg?alt=media&token=94c369e2-b56e-4acf-a9f5-d5ff359eb43a',
  arrowUp:
    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FOffre%20Socle%2Fassets%2FRoom%202%2Farrow-up.svg?alt=media&token=2f3578dd-58e9-42d3-9417-c8316bfa7a4a',
  arrowDown:
    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FOffre%20Socle%2Fassets%2FRoom%202%2Farrow-down.svg?alt=media&token=3d063db8-d322-453c-95a1-40b6e0b4e415',
  locker: {
    [offreSocleLocker1Id]: {
      rightCode: 667,
      content:
        'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FOffre%20Socle%2Fassets%2FRoom%202%2Ftoolbox.svg?alt=media&token=3bfbf95f-fc5a-4dd5-b957-a6d2c863f469',
      message: 'Une caisse à outils.',
    },
    [offreSocleLocker2Id]: {
      rightCode: 130,
      content:
        'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FOffre%20Socle%2Fassets%2FRoom%202%2Fmedkit.svg?alt=media&token=5b8c5d88-2099-4aa5-93f3-15c4c16d2a64',
      message: 'Une trousse de soins.',
    },
    [offreSocleLocker3Id]: {
      rightCode: 479,
      content:
        'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FOffre%20Socle%2Fassets%2FRoom%202%2Fduerp-export-vecto.svg?alt=media&token=fa91f641-a3a5-4a64-bcf9-cbd8506b69bc',
      message: {
        text: 'Ah... Voilà le DUERP ! ',
        audio:
          'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FOffre%20Socle%2Fassets%2Faudios-perso%2Froom3%2Froom3-employeur-2bis.mp3?alt=media&token=1d42f6fd-0a24-410f-b849-49960b244343',
      },
    },
  },

  maxLockerNumber: 9,
  minLockerNumber: 0,
  initialCombinaison: {
    'number-1': 0,
    'number-2': 0,
    'number-3': 0,
  },
  initialCombinaisonStatus: 'unanswered',
  videoConfStep: 'videoConf',
  companyViewStep: 'companyView',
  ergonomistCallStep: 'ergonomistCall',
  hangUpUrl:
    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FOffre%20Socle%2Fassets%2FRoom%202%2Fhang-up.svg?alt=media&token=f6a4c91e-7c81-419a-9eb6-75151a39356e',
  pickUpUrl:
    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FOffre%20Socle%2Fassets%2FRoom%202%2Fpick-up.svg?alt=media&token=42cdbf5e-fe5f-4b63-82ad-e67b952e9c07',
  lastPhoneRow: ['#', 0, '*'],
  correctNumber1: '0604792643',
  correctNumber2: '0612034566',
};

const firstObjectImageContent = 0;
const firstPreventionMessage = 0;

const questionErgonomist = {
  id: 1,
  title:
    'Pour maintenir le salarié à son poste de travail, que faudrait-il faire ?',
  responses: [
    {
      id: 1,
      text: 'Prévoir une étude de poste pour un aménagement et éviter ainsi les contraintes',
      goodResponse: true,
      messageText: {
        type: 'good',
        text: `Une étude de poste consiste à recueillir des informations sur les tâches, les équipements, les conditions de travail, les contraintes physiques et mentales nécessaires pour réaliser une activité. Cette analyse permet d'identifier les facteurs de risque qui peuvent affecter la santé et la sécurité des salariés, tels que les mauvaises postures, les gestes répétitifs, les vibrations, le bruit, le stress, etc. En fonction des résultats de l'étude de poste, des mesures d'aménagement peuvent être proposées pour réduire les contraintes et les risques, améliorer l'ergonomie et faciliter le travail des salariés.`,
      },
      sound:
        'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FOffre%20Socle%2Fassets%2FRoom%202%2Fsounds%2Fsuccess-fanfare-trumpets-6185.mp3?alt=media&token=f350fc8e-60bb-4b1b-bdb7-2a05d81eac4b',
    },
    {
      id: 2,
      text: 'Lui payer des séances de massage du dos 2 fois par semaine',
      goodResponse: false,
      messageText: {
        type: 'wrong',
        text: 'Bien que le massage soit agréable et puisse soulager les douleurs musculaires, il  ne résout pas la cause profonde des TMS, qui est souvent liée à différents facteurs (organisationnels, environnementaux, biomécaniques...). Par conséquent, cette solution ne résout pas le problème à long terme et peut même aggraver les symptômes dans certains cas.',
      },
      sound:
        'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FOffre%20Socle%2Fassets%2FRoom%202%2Fsounds%2Ferror-126627.mp3?alt=media&token=69c311ff-554f-4456-9313-cd4c0fe0dfd1',
    },
    {
      id: 3,
      text: `Ne rien faire, si le salarié a repris, c'est qu'il est forme pour travailler`,
      goodResponse: false,
      messageText: {
        type: 'wrong',
        text: 'En ne prenant pas de mesures préventives pour améliorer les conditions de travail, cela peut entraîner une aggravation des troubles de santé et une augmentation des arrêts',
      },
      sound:
        'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FOffre%20Socle%2Fassets%2FRoom%202%2Fsounds%2Ferror-126627.mp3?alt=media&token=69c311ff-554f-4456-9313-cd4c0fe0dfd1',
    },
    {
      id: 4,
      text: 'Organiser une visite de reprise avec le SPST',
      goodResponse: true,
      messageText: {
        type: 'good',
        text: `La visite de reprise permet de s'assurer que le poste du salarié est compatible avec son état de santé. Il existe d'autres types de visites qui permettent de déceler encore plus tôt une problématique (visite de pré-reprise et visite à la demande.) Plus tôt les difficultés d'une personne à son poste de travail sont identifiées, plus on multiplie les chances d'un maintien du salarié dans l'emploi. N'hésitez pas à prendre contact avec votre médecin du travail.`,
      },
      sound:
        'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FOffre%20Socle%2Fassets%2FRoom%202%2Fsounds%2Fsuccess-fanfare-trumpets-6185.mp3?alt=media&token=f350fc8e-60bb-4b1b-bdb7-2a05d81eac4b',
    },
  ],
};
const maxAnswers = 2;
const volume = 0.1;
const volumePersonna = 0.5;

const ergonomistSoundRoom =
  'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FOffre%20Socle%2Fassets%2FRoom%204%2Fsounds%2FCUTE-LOFI-(LOOP)-CHILL-(CUTIE)_AdobeStock_613612388_preview.m4a?alt=media&token=4851d62b-7dda-4fe4-8e52-499c305c74d0';
const frontDeskSoundRoom =
  'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FOffre%20Socle%2Fassets%2FRoom%201%2Fsounds%2FSMOOTH-RNB-SAX-LOUNGE-(GIRLZ)_AdobeStock_616222511_preview.m4a?alt=media&token=b1b1c4f3-56b4-4bc1-b40d-7302c881afd2';
const doctorOfficeSoundRoom =
  'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FOffre%20Socle%2Fassets%2FRoom%202%2Fsounds%2FPiano-Serenity---Relaxing-Melodies-for-Peaceful-Moments_AdobeStock_615407140_preview.m4a?alt=media&token=faa08c54-3cd7-40a9-b2ba-2b34f4653b45';

const specialistFace =
  'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FOffre%20Socle%2Fassets%2FRoom%204%2Favatar%2Fspecialiste_1.svg?alt=media&token=e6f61ed8-5f3e-48ef-93f4-6712ba64dcd0';
const employeLeftFace =
  'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FOffre%20Socle%2Fassets%2FRoom%204%2Favatar%2Femploye-left-face.svg?alt=media&token=8ec3a054-0510-4eb2-87f2-a168b116c970';

export {
  frontDeskOffice,
  referringDoctorOffice,
  firstObjectImageContent,
  firstPreventionMessage,
  questionErgonomist,
  maxAnswers,
  volume,
  ergonomistSoundRoom,
  frontDeskSoundRoom,
  doctorOfficeSoundRoom,
  specialistFace,
  employeLeftFace,
  volumePersonna,
};
