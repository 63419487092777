import { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { endTutorial } from 'utils/utilityFunctions';

const genericJoyrideCallback = (
  data,
  session,
  dispatch,
  tutorialSteps,
  setTutorialSteps,
  specificConditions
) => {
  const { action, index, type, status } = data;

  if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
    if (specificConditions) specificConditions();
    // Update state to advance the tour
    setTutorialSteps({
      ...tutorialSteps,
      stepIndex: index + (action === ACTIONS.NEXT ? 1 : -1),
    });
  } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
    endTutorial(session, dispatch);
  }
};

export default genericJoyrideCallback;
