import styles from 'components/GameBoard/ReusableComponents/Enigma/Phone/App.module.scss';
// TO REFACTO TRANSLATION
const Facetime = () => {
  return (
    <div className={styles.app}>
      <p className={`${styles.title} ${styles.firstTitle}`}>Facetime</p>
      <div className={styles.top}>
        <p className={styles.secondTitle}>
          Vous ne pouvez pas utiliser Facetime pour le moment.
        </p>
      </div>
    </div>
  );
};

export default Facetime;
