import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// utils & constants
import { checkedObject } from 'utils/utilityFunctions';
import {
  referringDoctorOffice,
  frontDeskOffice,
} from 'components/GameBoard/OffreSocle/constants';
// selector functions
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';
import { selectSteps } from 'components/Reducers/Steps/Steps';
import { selectObjectIndex } from 'components/Reducers/GameData/fetchObjects';

// components
import Button from 'components/GameBoard/ReusableComponents/Actions/Button';
import Dialogue from './Dialogue';

const { receptionnistFace } = frontDeskOffice;
const { directorFace } = referringDoctorOffice;

const ReceptionnistDialogueButtons = ({ objectToBeChecked }) => {
  // translation
  const { t } = useTranslation('offreSocle');
  const receptionistChoices = t('receptionistChoices', {
    returnObjects: true,
  });
  // ref
  const discussionRef = useRef();
  // selectors
  const dispatch = useDispatch();
  const { idSessionHasRoom } = useSelector(infoGameUser);
  const preventionMessage = useSelector(selectSteps).list[0];
  // state
  const [textAfterChoice, setTextAfterChoice] = useState([]);

  // Adjust scroll when dialogue continues
  useEffect(() => {
    discussionRef.current?.scrollIntoView();
  }, [textAfterChoice]);

  const objectToBeCheckedIndex = useSelector((state) =>
    selectObjectIndex(state, objectToBeChecked.id)
  );

  const handleEnigmaEnd = async () => {
    checkedObject(
      objectToBeChecked,
      objectToBeCheckedIndex,
      idSessionHasRoom,
      dispatch
    );

    // Launch the prevention message
    dispatch({
      type: 'CURRENT_STEP',
      payload: preventionMessage.id,
    });
  };

  const handleChoices = (event) => {
    const copiedEvent = event;
    copiedEvent.target.disabled = true;
    const textButton = copiedEvent.target.innerText;
    const isVisioChoice = textButton === receptionistChoices.visio;

    const displayText = t(
      isVisioChoice
        ? 'receptionistDialogueVisio'
        : 'receptionistDialogueMonthMeeting',
      {
        returnObjects: true,
      }
    );

    const displayedElements = (
      <>
        <Dialogue
          dialogueText={displayText}
          character1={directorFace}
          character2={receptionnistFace}
        />
        <Button
          title={
            isVisioChoice
              ? t('buttonFunction.continue', { ns: 'common' })
              : receptionistChoices.visio
          }
          buttonType="offreSocle"
          additionalStyle={{ width: '100%' }}
          onClick={(e) => (!isVisioChoice ? handleChoices(e) : handleEnigmaEnd())}
        />
      </>
    );

    return setTextAfterChoice((prevState) => prevState.concat(displayedElements));
  };

  return (
    <>
      <Button
        title={receptionistChoices.visio}
        buttonType="offreSocle"
        additionalStyle={{
          width: '100%',
        }}
        onClick={(e) => {
          handleChoices(e);
        }}
        disabled={!!textAfterChoice.length}
      />
      <Button
        title={receptionistChoices['month-meeting']}
        buttonType="offreSocle"
        additionalStyle={{
          width: '100%',
        }}
        onClick={(e) => handleChoices(e)}
        disabled={!!textAfterChoice.length}
      />
      {textAfterChoice}
    </>
  );
};

ReceptionnistDialogueButtons.propTypes = {
  objectToBeChecked: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};

export default ReceptionnistDialogueButtons;
