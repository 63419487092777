/* eslint-disable no-shadow */
const TDE = (dispatch, inventory, objects, step) => {
  switch (step) {
    case 85:
      // eslint-disable-next-line no-shadow
      if (inventory.find((inventory) => inventory.id === 134) !== undefined) {
        dispatch({
          type: 'RETRIEVE_RESPONSE',
          payload: {
            response: {
              text: 'Essayez de transmettre la feuille du journal intime dans votre inventaire à la psychologue.',
            },
          },
        });
      } else {
        dispatch({
          type: 'RETRIEVE_RESPONSE',
          payload: {
            response: {
              text: "Regardez sur le sol en face de vous, il me semble que j'y ai vu une page du journal intime de Lea.",
            },
          },
        });
      }
      break;
    case 86:
      if (inventory.find((inventory) => inventory.id === 145) !== undefined) {
        dispatch({
          type: 'RETRIEVE_RESPONSE',
          payload: {
            response: {
              text: 'Essayez de transmettre la feuille du journal intime dans votre inventaire à la psychologue.',
            },
          },
        });
      } else {
        dispatch({
          type: 'RETRIEVE_RESPONSE',
          payload: {
            response: {
              text: 'Regardez dans le tiroir de la table de chevet, il me semble que j’y ai vu une page du journal intime de Lea.',
            },
          },
        });
      }
      break;
    case 87:
      if (inventory.find((inventory) => inventory.id === 161) !== undefined) {
        break;
      } else if (
        inventory.find((inventory) => inventory.id === 147) === undefined &&
        inventory.find((inventory) => inventory.id === 149) === undefined
      ) {
        dispatch({
          type: 'RETRIEVE_RESPONSE',
          payload: {
            response: {
              text: 'Regardez sur le bureau, il me semble que j’y ai vu une lampe UV qui va surement nous aider à découvir des indices.',
            },
          },
        });
      } else if (
        objects[objects.findIndex((object) => object.id === 148)].status !==
          'open' &&
        inventory.find((inventory) => inventory.id === 149) === undefined
      ) {
        dispatch({
          type: 'RETRIEVE_RESPONSE',
          payload: {
            response: {
              text: 'Essayez d’éteindre la lumiére avec l’interrupteur. Ca sera plus simple pour trouver un indice caché avec la lampe à UV.',
            },
          },
        });
      } else if (
        inventory.find((inventory) => inventory.id === 149) === undefined
      ) {
        dispatch({
          type: 'RETRIEVE_RESPONSE',
          payload: {
            response: {
              text: 'Regardez avec la lampe UV dans l’angle entre le mur de gauche et le plafond. Il me semble qu’un code y est cacher.',
            },
          },
        });
      } else {
        dispatch({
          type: 'RETRIEVE_RESPONSE',
          payload: {
            response: {
              text: 'Essayez de rentrer le code dans le tiroir sous le lit, il me semble que Léa y cache des affaires importantes.',
            },
          },
        });
      }
      break;
    case 88:
      if (inventory.find((inventory) => inventory.id === 160) === undefined) {
        dispatch({
          type: 'RETRIEVE_RESPONSE',
          payload: {
            response: {
              text: `Regardez dans la trappe au pied du lit, il me semble qu’une feuille du journal intime s’y trouve.`,
            },
          },
        });
      } else {
        dispatch({
          type: 'RETRIEVE_RESPONSE',
          payload: {
            response: {
              text: `Regardez sur le lit, il me semble qu'il faut écrire "Kilo" en morse à l'aide de la télécommande`,
            },
          },
        });
      }
      break;
    case 89:
      if (
        inventory.find((inventory) => inventory.id === 181) === undefined &&
        inventory.find((inventory) => inventory.id === 178) !== undefined
      ) {
        if (
          objects[objects.findIndex((object) => object.id === 180)].status !==
          'open'
        ) {
          dispatch({
            type: 'RETRIEVE_RESPONSE',
            payload: {
              response: {
                text: 'Essayez d’utiliser la pelle pour creuser la terre, elle me semble avoir été rémuée recemment.',
              },
            },
          });
        } else {
          dispatch({
            type: 'RETRIEVE_RESPONSE',
            payload: {
              response: {
                text: 'Regardez au pied du lit, il me semble que dans le pot avec la plante a été bougé recemment.',
              },
            },
          });
        }
      } else if (
        inventory.find((inventory) => inventory.id === 181) === undefined &&
        inventory.find((inventory) => inventory.id === 182) === undefined
      ) {
        dispatch({
          type: 'RETRIEVE_RESPONSE',
          payload: {
            response: {
              text: 'Regardez dans le bloc de terre que vous venez de récupérer, il me semble qu’il y ai une clé enfouie dedans.',
            },
          },
        });
      } else if (
        inventory.find((inventory) => inventory.id === 182) === undefined
      ) {
        dispatch({
          type: 'RETRIEVE_RESPONSE',
          payload: {
            response: {
              text: 'Regardez sur le bureau, il me semble que j’y ai vu le journal intime de Lea, fermé à clés malheureusement.',
            },
          },
        });
      } else if (
        inventory.find((inventory) => inventory.id === 181) !== undefined
      ) {
        dispatch({
          type: 'RETRIEVE_RESPONSE',
          payload: {
            response: {
              text: 'Essayez d’ouvrir le journal intime de Lea avec la clés trouver dans le pot de la plante, on devrait y trouver les dernieres informations manquantes.',
            },
          },
        });
      } else if (
        inventory.find((inventory) => inventory.id === 180) !== undefined
      ) {
        dispatch({
          type: 'RETRIEVE_RESPONSE',
          payload: {
            response: {
              text: 'Regardez dans le bloc de terre que vous venez de récupérer, il me semble qu’il y ai une clé enfouie dedans.',
            },
          },
        });
      } else {
        dispatch({
          type: 'RETRIEVE_RESPONSE',
          payload: {
            response: {
              text: 'Essayez de transmettre le journal intime dans votre inventaire à la psychologue.',
            },
          },
        });
      }
      break;
    default:
      break;
  }
};

const Obesity = (dispatch, inventory, objects, step, level) => {
  switch (step) {
    case 111:
      if (inventory.find((inventory) => inventory.id === 185) === undefined) {
        if (inventory.find((inventory) => inventory.id === 229) !== undefined) {
          if (inventory.find((inventory) => inventory.id === 183) !== undefined) {
            if (inventory.find((inventory) => inventory.id === 184) !== undefined) {
              dispatch({
                type: 'RETRIEVE_RESPONSE',
                payload: {
                  response: {
                    text: 'Essayez de réunir les différentes feuilles du questionnaire PSS dans votre inventaire.',
                  },
                },
              });
            } else {
              dispatch({
                type: 'RETRIEVE_RESPONSE',
                payload: {
                  response: {
                    text: 'Regardez au pied de la lampe, il me semble que j’y ai vu une page du questionnaire PSS.',
                  },
                },
              });
            }
          } else {
            dispatch({
              type: 'RETRIEVE_RESPONSE',
              payload: {
                response: {
                  text: 'Regardez au sol au niveau de la télé, il me semble que j’y ai vu une page du questionnaire PSS.',
                },
              },
            });
          }
        } else {
          dispatch({
            type: 'RETRIEVE_RESPONSE',
            payload: {
              response: {
                text: 'Regardez sur la table, il me semble que j’y ai vu une page du questionnaire PSS.',
              },
            },
          });
        }
      }
      break;

    case 112:
      if (level !== 'caloric') {
        if (inventory.find((inventory) => inventory.id === 186) !== undefined) {
          if (inventory.find((inventory) => inventory.id === 188) !== undefined) {
            if (inventory.find((inventory) => inventory.id === 187) !== undefined) {
              dispatch({
                type: 'RETRIEVE_RESPONSE',
                payload: {
                  response: {
                    text: 'Essayez de donner les différentes informations à Alexo le robot d’Arthur. ',
                  },
                },
              });
            } else {
              dispatch({
                type: 'RETRIEVE_RESPONSE',
                payload: {
                  response: {
                    text: 'Regardez sur le canapé, il me semble que j’y ai vu une carte d’anniversaire d’Arthur.',
                  },
                },
              });
            }
          } else {
            dispatch({
              type: 'RETRIEVE_RESPONSE',
              payload: {
                response: {
                  text: 'Regardez la cloison entre le frigo et les étagéres, il me semble que j’y ai vu Arthur se mesurer la derniere fois.',
                },
              },
            });
          }
        } else {
          dispatch({
            type: 'RETRIEVE_RESPONSE',
            payload: {
              response: {
                text: 'Regardez sous le canapé, il me semble que j’y ai vu une balance.',
              },
            },
          });
        }
      } else if (
        inventory.find((inventory) => inventory.id === 191) === undefined &&
        inventory.find((inventory) => inventory.id === 192) === undefined
      ) {
        dispatch({
          type: 'RETRIEVE_RESPONSE',
          payload: {
            response: {
              text: 'Regardez dans la cuisine, il me semble que j’y ai vu un papier sur lequel Arthur note ses menus.',
            },
          },
        });
      } else if (
        inventory.find((inventory) => inventory.id === 192) === undefined
      ) {
        dispatch({
          type: 'RETRIEVE_RESPONSE',
          payload: {
            response: {
              text: 'Essayez de rentrer dans l’interface du frigo les différents aliments qu’Arthur a consommé pour calculer son apport calorique.',
            },
          },
        });
      } else {
        dispatch({
          type: 'RETRIEVE_RESPONSE',
          payload: {
            response: {
              text: 'Essayez de donner les différentes informations à Alexo le robot d’Arthur. ',
            },
          },
        });
      }
      break;

    case 113:
      if (level !== 'rising') {
        if (
          inventory.find((inventory) => inventory.id === 196) === undefined &&
          inventory.find((inventory) => inventory.id === 197) === undefined &&
          inventory.find((inventory) => inventory.id === 198) === undefined
        ) {
          if (inventory.find((inventory) => inventory.id === 195) === undefined) {
            dispatch({
              type: 'RETRIEVE_RESPONSE',
              payload: {
                response: {
                  text: 'Alexo a parlé de la télévision. Regardez sur le canapé,  il me semble que j’y ai vu la télécommande.',
                },
              },
            });
          } else {
            dispatch({
              type: 'RETRIEVE_RESPONSE',
              payload: {
                response: {
                  text: 'Regardez dans l’application temps d’écran, il me semble que si vous rentrez la date vous obtiendrez l’heure de coucher d’Arthur.',
                },
              },
            });
          }
        } else if (
          inventory.find((inventory) => inventory.id === 196) !== undefined
        ) {
          if (inventory.find((inventory) => inventory.id === 197) !== undefined) {
            if (inventory.find((inventory) => inventory.id === 198) !== undefined) {
              dispatch({
                type: 'RETRIEVE_RESPONSE',
                payload: {
                  response: {
                    text: 'Essayez de donner les différentes informations à Alexo le robot d’Arthur. ',
                  },
                },
              });
            } else {
              dispatch({
                type: 'RETRIEVE_RESPONSE',
                payload: {
                  response: {
                    text: 'Essayez de rentrer la date du 02/12/2017 dans l’application temps d’écran sur la télévision.',
                  },
                },
              });
            }
          } else {
            dispatch({
              type: 'RETRIEVE_RESPONSE',
              payload: {
                response: {
                  text: 'Essayez de rentrer la date du 01/06/2017 dans l’application temps d’écran sur la télévision.',
                },
              },
            });
          }
        } else {
          dispatch({
            type: 'RETRIEVE_RESPONSE',
            payload: {
              response: {
                text: 'Essayez de rentrer la date du 01/01/2017 dans l’application temps d’écran sur la télévision.',
              },
            },
          });
        }
      } else if (
        inventory.find((inventory) => inventory.id === 200) === undefined &&
        inventory.find((inventory) => inventory.id === 201) === undefined &&
        inventory.find((inventory) => inventory.id === 202) === undefined
      ) {
        dispatch({
          type: 'RETRIEVE_RESPONSE',
          payload: {
            response: {
              text: 'Regardez dans l’historique des eclairages sur l’interface de gestion des lumières., vous trouverez l’heure de lever d’Arthur.',
            },
          },
        });
      } else if (
        inventory.find((inventory) => inventory.id === 200) !== undefined
      ) {
        if (inventory.find((inventory) => inventory.id === 201) !== undefined) {
          if (inventory.find((inventory) => inventory.id === 202) !== undefined) {
            dispatch({
              type: 'RETRIEVE_RESPONSE',
              payload: {
                response: {
                  text: 'Essayez de donner les différentes informations à Alexo le robot d’Arthur. ',
                },
              },
            });
          } else {
            dispatch({
              type: 'RETRIEVE_RESPONSE',
              payload: {
                response: {
                  text: 'Essayez de rentrer la date du 02/12/2017 dans l’historique de l’eclairage sur l’interface de gestion des lumières.',
                },
              },
            });
          }
        } else {
          dispatch({
            type: 'RETRIEVE_RESPONSE',
            payload: {
              response: {
                text: 'Essayez de rentrer la date du 02/06/2017 dans l’historique de l’eclairage sur l’interface de gestion des lumières.',
              },
            },
          });
        }
      } else {
        dispatch({
          type: 'RETRIEVE_RESPONSE',
          payload: {
            response: {
              text: 'Essayez de rentrer la date du 02/01/2017 dans l’historique de l’eclairage sur l’interface de gestion des lumières.',
            },
          },
        });
      }
      break;

    case 114:
      if (
        objects[objects.findIndex((object) => object.id === 204)].isClicked === 0
      ) {
        dispatch({
          type: 'RETRIEVE_RESPONSE',
          payload: {
            response: {
              text: 'Regardez sur une chaise dans la cuisine il y a le portable d’Arthur, il me semble qu’il a des applications qui trackent les données qu’Alexo recherche. ',
            },
          },
        });
      } else {
        dispatch({
          type: 'RETRIEVE_RESPONSE',
          payload: {
            response: {
              text: "Regardez dans les applications santé et temps d'écran sur le portable, il me semble que vous pourrez retrouver les infos necessaires sur Arthur.",
            },
          },
        });
      }
      break;
    default:
      break;
  }
};

const TMS = (dispatch, inventory, objects, step, level) => {
  switch (step) {
    case 121:
      if (inventory.find((inventory) => inventory.id === 207) === undefined) {
        dispatch({
          type: 'RETRIEVE_RESPONSE',
          payload: {
            response: {
              text: 'Regardez sur un des casiers au fond, il me semble avoir vu un poster avec les différentes parties du corps resposable de la mobilité.',
            },
          },
        });
      } else if (
        objects[objects.findIndex((object) => object.id === 206)].isClicked === 0
      ) {
        dispatch({
          type: 'RETRIEVE_RESPONSE',
          payload: {
            response: {
              text: 'Regardez sur le casier avec un code à 4 entrées, il me semble que vous avez les éléments necessaires pour trouver le code.',
            },
          },
        });
      } else {
        dispatch({
          type: 'RETRIEVE_RESPONSE',
          payload: {
            response: {
              text: 'Essayez de rentre le code jambe, tronc , bras et tête, il me semble que c’est celui la.',
            },
          },
        });
      }
      break;
    case 122:
      if (
        objects[objects.findIndex((object) => object.id === 209)].isClicked === 0
      ) {
        dispatch({
          type: 'RETRIEVE_RESPONSE',
          payload: {
            response: {
              text: 'Regardez dans le casier qui vient de s’ouvrir, il me semble avoir vu le porte document avec les informations sur les aides soignants.',
            },
          },
        });
      } else if (
        objects[objects.findIndex((object) => object.id === 210)].isClicked === 0
      ) {
        dispatch({
          type: 'RETRIEVE_RESPONSE',
          payload: {
            response: {
              text: 'Regardez sur le sol au pied du sac de sport , il me semble avoir vu une feuille avec des informations sur un aide soignant.',
            },
          },
        });
      } else if (
        objects[objects.findIndex((object) => object.id === 211)].isClicked === 0
      ) {
        dispatch({
          type: 'RETRIEVE_RESPONSE',
          payload: {
            response: {
              text: 'Regardez sur le banc à côté du short , il me semble avoir vu une feuille avec des informations sur un aide soignant.',
            },
          },
        });
      } else if (
        inventory.find(
          (inventory) => inventory.id === 210 || inventory.id === 211
        ) !== undefined
      ) {
        dispatch({
          type: 'RETRIEVE_RESPONSE',
          payload: {
            response: {
              text: 'Essayez d’associer les fiches récupérées avec le porte document dans votre inventaire.',
            },
          },
        });
      } else if (
        objects[objects.findIndex((object) => object.id === 209)].isChecked === 0
      ) {
        if (level === null) {
          dispatch({
            type: 'RETRIEVE_RESPONSE',
            payload: {
              response: {
                text: 'Essayez de rentrer les noms dans cet ordre : Claude, Jeanne et Bernard pour la première partie.',
              },
            },
          });
        } else if (level === 'DocumentHolder1') {
          dispatch({
            type: 'RETRIEVE_RESPONSE',
            payload: {
              response: {
                text: 'Essayez de rentrer les noms dans cet ordre : Jeanne, Claude et Bernard pour la deuxième partie.',
              },
            },
          });
        }
      } else if (
        objects[objects.findIndex((object) => object.id === 212)].isClicked === 0
      ) {
        dispatch({
          type: 'RETRIEVE_RESPONSE',
          payload: {
            response: {
              text: 'Regardez sur le casier avec un code à 3 entrées, il me semble que vous avez les éléments necessaires pour trouver le code.',
            },
          },
        });
      } else if (
        objects[objects.findIndex((object) => object.id === 212)].isChecked === 0
      ) {
        dispatch({
          type: 'RETRIEVE_RESPONSE',
          payload: {
            response: {
              text: 'Essayez de rentrer le code que vous avez obtenu sur la tablette, il me semble que c’etait “BJC”.',
            },
          },
        });
      }
      break;
    case 123:
      if (
        objects[objects.findIndex((object) => object.id === 215)].isInventory === 0
      ) {
        dispatch({
          type: 'RETRIEVE_RESPONSE',
          payload: {
            response: {
              text: 'Regardez dans le casier qui vient de s’ouvrir, il me semble avoir vu une feuille de rappel sur des notions de mobilité.',
            },
          },
        });
      } else if (
        objects[objects.findIndex((object) => object.id === 216)].isClicked === 0
      ) {
        dispatch({
          type: 'RETRIEVE_RESPONSE',
          payload: {
            response: {
              text: "Regardez l'écran, au fond de la salle, il me semble que vous avez les informatiosn nécessaires .",
            },
          },
        });
      } else {
        dispatch({
          type: 'RETRIEVE_RESPONSE',
          payload: {
            response: {
              text: `Essayez la combinaison suivante :
                    Carl en sub pondérale (jaune)
                    Albert et Barbara en non pondérale (rose)
                    Doris et Emma en pondérale (bleu)`,
            },
          },
        });
      }
      break;
    case 118:
      if (!objects[objects.findIndex((object) => object.id === 222)].isChecked) {
        dispatch({
          type: 'RETRIEVE_RESPONSE',
          payload: {
            response: {
              text: 'Pour la chambre du patient 1, je pense qu’il lui faudrait ...',
            },
          },
        });
      } else if (
        !objects[objects.findIndex((object) => object.id === 225)].isChecked
      ) {
        dispatch({
          type: 'RETRIEVE_RESPONSE',
          payload: {
            response: {
              text: 'Pour la chambre du patient 2, je pense qu’il lui faudrait ...',
            },
          },
        });
      } else if (
        !objects[objects.findIndex((object) => object.id === 226)].isChecked
      ) {
        dispatch({
          type: 'RETRIEVE_RESPONSE',
          payload: {
            response: {
              text: 'Pour la chambre du patient 3, je pense qu’il lui faudrait ...',
            },
          },
        });
      } else if (
        !objects[objects.findIndex((object) => object.id === 227)].isChecked
      ) {
        dispatch({
          type: 'RETRIEVE_RESPONSE',
          payload: {
            response: {
              text: 'Pour la chambre du patient 4, je pense qu’il lui faudrait ...',
            },
          },
        });
      } else if (
        !objects[objects.findIndex((object) => object.id === 228)].isChecked
      ) {
        dispatch({
          type: 'RETRIEVE_RESPONSE',
          payload: {
            response: {
              text: 'Pour la chambre du patient 5, je pense qu’il lui faudrait ...',
            },
          },
        });
      }
      break;
    default:
      break;
  }
};

const EmotionMulti = (dispatch, inventory, objects, step) => {
  switch (step) {
    case 133:
      if (inventory.find((inventory) => inventory.id === 296)) {
        dispatch({
          type: 'RETRIEVE_RESPONSE',
          payload: {
            response: {
              text: 'Mon premier est pondu par la poule… Mon second n’est pas faible… Mon troisième est la traduction du mot “oui” en allemand. Mon tout est une série américaine sortie en 2019 avec Zendaya comme actrice principale.',
            },
          },
        });
      } else {
        dispatch({
          type: 'RETRIEVE_RESPONSE',
          payload: {
            response: {
              text: 'Regardez de plus près la feuille qui est au-dessus du bureau…',
            },
          },
        });
      }
      break;
    case 134:
      if (inventory.find((inventory) => inventory.id === 303)) {
        dispatch({
          type: 'RETRIEVE_RESPONSE',
          payload: {
            response: {
              text: 'Lisez bien le mail reçu dans votre inventaire, il vous aidera à ouvrir le tiroir',
            },
          },
        });
      }
      if (inventory.find((inventory) => inventory.id === 311)) {
        dispatch({
          type: 'RETRIEVE_RESPONSE',
          payload: {
            response: {
              text: "Vous pouvez maintenant recharger l'ordinateur",
            },
          },
        });
      }
      break;
    case 135:
      if (
        inventory.find((inventory) => inventory.id === 302) &&
        inventory.find((inventory) => inventory.id === 312)
      ) {
        dispatch({
          type: 'RETRIEVE_RESPONSE',
          payload: {
            response: {
              text: 'Associez le papier trouvé dans la poubelle avec celui récupéré dans le tiroir',
            },
          },
        });
      } else if (inventory.find((inventory) => inventory.id === 314)) {
        dispatch({
          type: 'RETRIEVE_RESPONSE',
          payload: {
            response: {
              text: "Le questionnaire de l'inventaire vous aidera à débloquer le téléphone",
            },
          },
        });
      } else
        dispatch({
          type: 'RETRIEVE_RESPONSE',
          payload: {
            response: {
              text: 'Avez-vous bien fouillé partout... même dans la poubelle?',
            },
          },
        });
      break;
    case 136:
      if (objects[objects.findIndex((object) => object.id === 297)].isChecked) {
        dispatch({
          type: 'RETRIEVE_RESPONSE',
          payload: {
            response: {
              text: 'Cherchez un indice dans le téléphone',
            },
          },
        });
      }
      break;
    case 137:
      dispatch({
        type: 'RETRIEVE_RESPONSE',
        payload: {
          response: {
            text: 'Associez les bonnes couleurs de la visio, une fiche dans votre inventaire pourrait vous être utile',
          },
        },
      });
      break;
    default:
      break;
  }
};

const OffreSocle = (dispatch, inventory, objects, step) => {
  switch (step) {
    case 132:
      dispatch({
        type: 'RETRIEVE_RESPONSE',
        payload: {
          response: {
            text: 'La secrétaire médicale répond à de nombreux appels d’entreprises. Il doit donc y avoir quelque chose à faire en rapport avec ces appels…',
          },
        },
      });
      break;
    case 148:
      if (!objects[objects.findIndex((object) => object.id === 346)].isChecked) {
        dispatch({
          type: 'RETRIEVE_RESPONSE',
          payload: {
            response: {
              text: 'N’oubliez pas que le médecin du travail va donc recevoir un appel en visio. Regardez du côté de son bureau pour activer la visio…',
            },
          },
        });
      } else {
        dispatch({
          type: 'RETRIEVE_RESPONSE',
          payload: {
            response: {
              text: 'L’entrepôt est grand… Pensez à vous promener partout, y compris du côté du tableau d’affichage et à cliquer sur les différents éléments qui s’y trouvent.',
            },
          },
        });
      }
      break;
    case 149:
      dispatch({
        type: 'RETRIEVE_RESPONSE',
        payload: {
          response: {
            text: 'Pour résoudre ce mystère, commencez par regarder sur le tableau d’affichage pour en apprendre davantage sur les professionnels de l’équipe pluridisciplinaire du SPSTI… Et gardez en tête que les couleurs ne sont pas anodines…',
          },
        },
      });
      break;
    case 152:
      if (!objects[objects.findIndex((object) => object.id === 373)].isClicked) {
        dispatch({
          type: 'RETRIEVE_RESPONSE',
          payload: {
            response: {
              text: 'Avant de prendre toute décision, il serait peut-être sage de rencontrer le directeur. Il pourrait avoir des informations cruciales à partager.',
            },
          },
        });
      } else {
        dispatch({
          type: 'RETRIEVE_RESPONSE',
          payload: {
            response: {
              text: "Pour répondre à cette question, il est essentiel de se montrer prévoyant et organisé, tout comme dans la résolution de l'énigme.",
            },
          },
        });
      }
      break;
    default:
      break;
  }
};

const Clue = (dispatch, inventory, objects, step, id, level) => {
  switch (id) {
    case 146:
      TDE(dispatch, inventory, objects, step);
      break;
    case 160:
      Obesity(dispatch, inventory, objects, step, level);
      break;
    case 161:
      TMS(dispatch, inventory, objects, step, level);
      break;
    case 198:
      EmotionMulti(dispatch, inventory, objects, step, level);
      break;
    case 199:
      OffreSocle(dispatch, inventory, objects, step, level);
      break;
    default:
      break;
  }
};

export default Clue;
