import styles from 'components/Joyride/Steps.module.scss';

/**
 *
 * @param {func} t - translation function from i18next
 * @param {string} val - specific text to display in the tutorial
 * @returns
 */
const backgroundMovingSteps = (t, val) => {
  const content = (
    <div className={styles['main-content']}>
      <h2>{t('joyride.navigation.title')}</h2>
      <p>
        {t('joyride.navigation.description', {
          val,
        })}
      </p>
    </div>
  );

  const steps = [
    {
      target: '#right-chevron',
      content,
      placement: 'left',
    },
    {
      target: '#left-chevron',
      content,
      placement: 'right',
    },
  ];
  return steps;
};

export default backgroundMovingSteps;
