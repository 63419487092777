import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// selectors
import { selectModalEnigma } from 'components/Reducers/Modal/Modal';
import {
  selectObject,
  selectObjectIndex,
} from 'components/Reducers/GameData/fetchObjects';
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';
import { selectSession } from 'components/Reducers/session';
import { selectUser } from 'components/Reducers/user';

// components
import CrosswordBoxes from 'components/GameBoard/Emotion/Multi/Enigma/Crossword/CrosswordBoxes';

// utils
import { addInventory, animationConfetti } from 'utils/utilityFunctions';

// constants
import { clues, gridContent } from 'components/GameBoard/Emotion/Multi/constant';

import 'components/GameBoard/Emotion/Multi/Enigma/Crossword/CrosswordGame.css';

// TO REFATCO
const CrosswordGame = () => {
  const dispatch = useDispatch();

  const { socket, idSessionHasRoom } = useSelector(infoGameUser);
  const session = useSelector(selectSession);
  const { participants } = useSelector(selectUser);

  const { description } = useSelector(selectModalEnigma);
  const crosswordLivingRoomMultiEmotionObject = useSelector((state) =>
    selectObject(state, description.content.id)
  );
  const crosswordLivingRoomMultiEmotionIndex = useSelector((state) =>
    selectObjectIndex(state, description.content.id)
  );

  const room = session.id;

  // TO REFACTO
  const [grid, setGrid] = useState(gridContent);
  const handleFinishCrossword = (event) => {
    animationConfetti();
    setTimeout(() => {
      addInventory(
        dispatch,
        idSessionHasRoom,
        crosswordLivingRoomMultiEmotionObject,
        crosswordLivingRoomMultiEmotionIndex,
        event,
        socket,
        room,
        participants,
        session
      );
    }, 3000);
  };

  useEffect(() => {
    socket?.on('receive_emotion_multi_set_grid_crossword', (data) => {
      setGrid(data.newGrid);
    });
  }, [socket]);

  return (
    <div className="crossword">
      <CrosswordBoxes
        clues={clues}
        grid={grid}
        setGrid={setGrid}
        handleFinishCrossword={handleFinishCrossword}
      />
    </div>
  );
};

export default CrosswordGame;
