import styled, { keyframes } from 'styled-components';

import * as images from '../../img/vaccination-solo/index';
import * as imageNext from '../../img/vaccination-solo/PuckLab';

const rotateAnimation = keyframes`
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(180deg);
  }
`;

const ImageDefault = styled.img`
  margin: 20px;
  width: 50%;
`;

const Coin = styled.img`
  margin: 20px;
  width: 50%;
  animation-name: ${rotateAnimation};
  animation-duration: 1s;
  animation-iteration-count: infinite;
`;

const stepsTMSCongres = [
  {
    target: '.CorridorTMS',
    content: (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space evenly',
          alignItems: 'center',
        }}
      >
        <h2
          style={{
            fontWeight: 700,
            color: '#000',
          }}
        >
          Bienvenue dans le couloir de l&apos;établissement
        </h2>
      </div>
    ),
    placement: 'center',
  },

  {
    target: '.Inventory',
    content: (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space evenly',
          alignItems: 'center',
        }}
      >
        <h2
          style={{
            fontWeight: 700,
            color: '#000',
          }}
        >
          Inventaire
        </h2>
        <ImageDefault src={images.trickyCoin} alt="Tricky" />
        <p>Vous collecterez des objets durant le jeu, ils apparaîtront ici</p>
      </div>
    ),
    placement: 'bottom',
  },
  {
    target: '.Steps',
    content: (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space evenly',
          alignItems: 'center',
        }}
      >
        <h2
          style={{
            fontWeight: 700,
            color: '#000',
          }}
        >
          Etapes
        </h2>
        <p>Collectez les cartes d&apos;activitées</p>
      </div>
    ),
  },
  {
    target: '.Hud-Item-Text-undefined',
    content: (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space evenly',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <h2
          style={{
            fontWeight: 700,
            color: '#000',
          }}
        >
          Temps
        </h2>
        <p>Chaque minute compte</p>
        <ImageDefault src={imageNext.time} alt="Tricky" />
        <p>
          Obtenez un meilleur score en finissant l&apos;expérience le plus vite
          possible
        </p>
      </div>
    ),
    placement: 'left',
  },
  {
    target: '.Hud-Item-Text-clics',
    content: (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space evenly',
          alignItems: 'center',
        }}
      >
        <h2
          style={{
            fontWeight: 700,
            color: '#000',
          }}
        >
          Clics
        </h2>
        <p>Le nombre de clics est comptabilisé</p>
        <ImageDefault src={imageNext.cursor} alt="Tricky" />{' '}
        <p>
          Votre mission consistera à cliquer sur différents objets dans le jeu pour
          découvrir des indices et accomplir des actions. Obtenez un meilleur score
          en finissant l’expérience avec le moins de clics possible, car ils seront
          comptabilisés.
        </p>
      </div>
    ),
    placement: 'bottom',
  },
  {
    target: '.Hud-Item-Text-pts',
    content: (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space evenly',
          alignItems: 'center',
        }}
      >
        <h2
          style={{
            fontWeight: 700,
            color: '#000',
          }}
        >
          Points
        </h2>
        <p>Gagnez des points en finissant les jeux de chaque étape</p>
        <Coin src={imageNext.coinScore} alt="Tricky" />{' '}
      </div>
    ),
    placement: 'bottom',
  },
  {
    target: '.Hud-Item-Text-sound',
    content: (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space evenly',
          alignItems: 'center',
        }}
      >
        <h2
          style={{
            fontWeight: 700,
            color: '#000',
          }}
        >
          Musique
        </h2>
        <p>Activez ou désactivez la musique d&apos;ambiance ici.</p>
        <ImageDefault src={imageNext.notMuteIcon} alt="Tricky" />{' '}
      </div>
    ),
    placement: 'bottom',
  },
  {
    target: '.CorridorTMS',
    content: (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space evenly',
          alignItems: 'center',
        }}
      >
        <h2
          style={{
            fontWeight: 700,
            color: '#000',
          }}
        >
          Bonne chance
        </h2>
        <p>Je vous laisse équiper les 5 chambres</p>
      </div>
    ),
    placement: 'center',
  },
];

export default stepsTMSCongres;
