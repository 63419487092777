import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL;

/**
 * Get the description/content of a user
 * @param {number} userId
 * @returns {Promise<object[]>} Response of the API call if successful
 */
const getUserDescription = async (userId) => {
  // REFACTO API DJANGO
  const { data } = await axios.get(`${baseUrl}/api/users/${userId}/`);
  return data;
};

/**
 * Verify the token
 * @param {string} token
 * @returns {Promise<object[]>} Response of the API call if successful
 */

const verifyTokenUserSession = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { data } = await axios.post(`${baseUrl}/api/userSession`, null, config);
  return data;
};

export { getUserDescription, verifyTokenUserSession };
