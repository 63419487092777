import chatbotStep from 'components/Joyride//constants/chatbotStep';
import commonSteps from 'components/Joyride//constants/commonSteps';
import inventoryStep from 'components/Joyride//constants/inventoryStep';
import commonMultiSteps from 'components/Joyride//constants/commonMultiSteps';

/**
 * Tutorial steps for Emotion multi game
 * @param {func} t -translation function from i18next
 * @returns {Object[]}
 */
// Define a function to use the translation function as parameter
const stepsEmotionMulti = (t) => {
  // get constants steps from constants file
  const commonImportedSteps = commonSteps(
    t,
    null,
    null,
    t('joyride.welcome.description', { ns: 'common-multi' })
  );
  const inventoryImportedStep = inventoryStep(t);
  const chatbotImportedStep = chatbotStep(t);
  const commonMultiImportedStep = commonMultiSteps(t);

  // Insert inventory step in common steps
  commonImportedSteps.splice(1, 0, inventoryImportedStep);

  // Insert steps that exists only in Multi into common steps
  commonMultiImportedStep.forEach((step) => {
    commonImportedSteps.splice(commonImportedSteps.length - 1, 0, step);
  });
  // Insert chatbot step in commons steps
  commonImportedSteps.splice(
    commonImportedSteps.length - 2,
    0,
    chatbotImportedStep
  );
  // Take Off good luck message, replaced by message from common multi steps
  commonImportedSteps.pop();
  return commonImportedSteps;
};
export default stepsEmotionMulti;
