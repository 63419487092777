const startedMessage = 1;

// Games id
const gameIdSoloVaccination = 145;
const gameIdSoloTDE = 146;
const gameIdSoloObesity = 160;
const gameIdSoloTMS = 161;
const gameIdSoloRPS = 162;
const gameIdSoloTMSCongres = 183;
const gameIdSoloRPSCongres = 186;
const gameIdSoloEmotion = 197;
const gameIdMultiEmotion = 198;
const gameIdSoloOffreSocle = 199;

export {
  startedMessage,
  gameIdSoloVaccination,
  gameIdSoloTDE,
  gameIdSoloObesity,
  gameIdSoloTMS,
  gameIdSoloRPS,
  gameIdSoloTMSCongres,
  gameIdSoloRPSCongres,
  gameIdSoloEmotion,
  gameIdMultiEmotion,
  gameIdSoloOffreSocle,
};
