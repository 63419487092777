import { Suspense, StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import ReduxThunk from 'redux-thunk';
import 'react-tooltip/dist/react-tooltip.css';

// components
// import Page404 from 'components/GameBoard/404/Page404';
import ErrorPage from 'components/errorPages/ErrorPage';
import appReducer from 'components/Reducers';
import StartPage from 'components/Introduction/StartPage';

// css
import './index.css';

// use the translation and pluralisation of i18n
import './i18n';

const middleware = [ReduxThunk];

export const store = createStore(
  appReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

const container = document.getElementById('root');
const root = createRoot(container);

const isStrictMode = process.env.REACT_APP_STRICT_MODE;
const handleSwitch = () => {
  return (
    //  Async loading of i18next
    <Suspense fallback="i18next is loading">
      <Routes>
        <Route path="/" element={<StartPage />} />
        {/* Page404 Not Working to Refacto */}
        {/* <Route
          exact
          path="/youvelostyourwaymyfriendthisisa404pageerror-password=5689-dontwakeupthecat"
          element={<Page404 />}
        /> */}
        {/* <Route path="*" element={<ErrorPage />} /> */}
        <Route path="/404" element={<ErrorPage />} />
      </Routes>
    </Suspense>
  );
};

const handleRouter = () => {
  if (isStrictMode) {
    return <StrictMode>{handleSwitch()}</StrictMode>;
  }
  return handleSwitch();
};

root.render(
  <Provider store={store}>
    <BrowserRouter>{handleRouter()}</BrowserRouter>
  </Provider>
);

export default store;
