import styles from 'components/GameBoard/OffreSocle/Enigmas/ModalHead.module.scss';

const ModalHead = () => {
  return (
    <div className={styles['modal-head']}>
      <div className={styles['small-buttons']}>
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

export default ModalHead;
