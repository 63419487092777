import { EVENTS } from 'react-joyride';

/**
 * Handle the background moving during the tutorial step
 * @param {Object} data - Joyride library event
 * @param {Array} steps - contains id and a callfunction. Id is the index of the step wanted.
 * @returns
 */
const backgroundMovingCallBackConditions = (data, steps) => {
  const { lifecycle, type, index, action } = data;

  if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
    steps.map((step) => {
      if (index === step.id && lifecycle === 'complete' && action === 'next') {
        return step.callfunction();
      }
      return null;
    });
  }
  return null;
};

export default backgroundMovingCallBackConditions;
