import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

// components
import Button from 'components/GameBoard/ReusableComponents/Actions/Button';
import FooterApplication from 'components/GameBoard/Emotion/Multi/Enigma/Desktop/FooterApplication';
import PageScreen from 'components/GameBoard/Emotion/Multi/Enigma/Desktop/PageScreen';

// constant
import {
  dangerIcon,
  goodPasswordOfComputerEmotionMulti,
} from 'components/GameBoard/Emotion/Multi/constant';
import {
  computerRoomEmotionEnigma,
  plugInOfficeMultiEmotion,
} from 'components/GameBoard/ReusableComponents/Objects/constants';

// scss
import styles from 'components/GameBoard/Emotion/Multi/Enigma/Desktop/DesktopEmotion.module.scss';

// utils
import { clickCount, clickCountUser } from 'utils/utilityFunctions';

// selectors
import { selectObject } from 'components/Reducers/GameData/fetchObjects';
import { selectSession } from 'components/Reducers/session';
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';
import { selectUser } from 'components/Reducers/user';

function DesktopEmotion({ statusType }) {
  const { t } = useTranslation(['emotion-multi', 'common']);
  const dispatch = useDispatch();

  const session = useSelector(selectSession);
  const { participants } = useSelector(selectUser);
  const { socket } = useSelector(infoGameUser);
  const room = session.id;

  const [status, setStatus] = useState(statusType);
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState({
    type: '',
    text: '',
  });
  const [typeApplication, setTypeApplication] = useState(null);

  const computerRoomEmotionMultiObject = useSelector((state) =>
    selectObject(state, computerRoomEmotionEnigma)
  );

  const plugInOfficeMultiEmotionObject = useSelector((state) =>
    selectObject(state, plugInOfficeMultiEmotion)
  );

  useEffect(() => {
    let responsesSocket;
    if (computerRoomEmotionMultiObject.isInventory) {
      responsesSocket = {
        status: 'lowbat',
        room,
      };
      socket?.emit('send_emotion_multi_status_computer', responsesSocket);
      setStatus('lowbat');
    }
    if (plugInOfficeMultiEmotionObject.status === 'open') {
      responsesSocket = {
        status: 'unlock',
        typeApplication: 'note',
        room,
      };
      socket?.emit('send_emotion_multi_status_computer', responsesSocket);
      socket?.emit('send_emotion_multi_type_application_computer', responsesSocket);
      setStatus('unlock');
      setTypeApplication('note');
    }
  }, [
    computerRoomEmotionMultiObject,
    plugInOfficeMultiEmotionObject,
    room,
    socket,
  ]);

  const submitPassword = (event) => {
    const contentMessage = {
      type: 'error',
      text: t('desktop.incorrectPassword'),
    };
    let responsesSocket = { room };
    clickCount(dispatch, event, socket, responsesSocket);
    if (socket) {
      clickCountUser(participants, socket, dispatch, session);
    }
    if (goodPasswordOfComputerEmotionMulti === password.toLocaleLowerCase()) {
      responsesSocket = {
        status: 'unlock',
        room,
      };
      socket?.emit('send_emotion_multi_status_computer', responsesSocket);
      return setStatus('unlock');
    }
    responsesSocket = {
      ...contentMessage,
      room,
    };
    socket?.emit('send_emotion_multi_message_error_computer', responsesSocket);
    return setMessage(contentMessage);
  };

  const handleUnLock = () => {
    return (
      <>
        <div className={styles.page}>
          <PageScreen typeApplication={typeApplication} />
        </div>
        <div className={styles.footer}>
          <FooterApplication setTypeApplication={setTypeApplication} />
        </div>
      </>
    );
  };

  useEffect(() => {
    socket.on('receive_emotion_multi_password_computer', (data) => {
      setMessage(data.resetErrorMessage);
      setPassword(data.value);
    });
    socket.on('receive_emotion_multi_status_computer', (data) => {
      setStatus(data.status);
    });
    socket.on('receive_emotion_multi_message_error_computer', (data) => {
      setMessage({
        type: data.type,
        text: data.text,
      });
    });
    socket.on('receive_emotion_multi_type_application_computer', (data) => {
      setTypeApplication(data.typeApplication);
    });
  }, [socket]);

  const handleLock = () => {
    return (
      <>
        <div className={styles.forms}>
          {message?.text && (
            <div className={styles.error}>
              <img src={dangerIcon} alt="alert" />
              <p>{message.text}</p>
            </div>
          )}
          <div className={styles.inputs}>
            <input
              name="password"
              type="text"
              onChange={(e) => {
                const resetErrorMessage = {
                  type: '',
                  text: '',
                };
                setMessage(resetErrorMessage);
                setPassword(e.target.value);
                const responsesSocket = {
                  ...resetErrorMessage,
                  value: e.target.value,
                  room,
                };
                socket?.emit(
                  'send_emotion_multi_password_computer',
                  responsesSocket
                );
              }}
              value={password}
              placeholder={t('desktop.placeholderPassword')}
            />
            <Button
              buttonType="action"
              title={t('buttonFunction.validate', { ns: 'common' })}
              onClick={(event) => submitPassword(event)}
            />
          </div>
        </div>
        <div className={styles.forget}>
          <button
            type="button"
            className={styles.link}
            onClick={() => {
              const contentMessage = {
                type: 'indice',
                text: t('desktop.rebusHint'),
              };
              const responsesSocket = {
                ...contentMessage,
                room,
              };
              socket?.emit(
                'send_emotion_multi_message_error_computer',
                responsesSocket
              );
              setMessage(contentMessage);
            }}
          >
            {t('desktop.forgetPassword')}
          </button>
        </div>
      </>
    );
  };

  const handleContentScreenStatus = () => {
    if (status === 'unlock') {
      return handleUnLock();
    }
    if (status === 'lowbat') {
      return null;
    }

    return handleLock();
  };

  return (
    <div
      className={`${styles.desktop} ${
        status === 'unlock' ? styles.unlock : styles.lock
      } ${status === 'lowbat' && styles.lowbat}`}
    >
      <div className={styles.screen}>{handleContentScreenStatus()}</div>
    </div>
  );
}

DesktopEmotion.propTypes = {
  statusType: PropTypes.string,
};

DesktopEmotion.defaultProps = {
  statusType: 'unlock',
};

export default DesktopEmotion;
