import { motion } from 'framer-motion';

import 'assets/css/components/GameBoard/ReusableComponents/Hud/RoomName.css';
import { selectRoom } from 'components/Reducers/Room';
import { useSelector } from 'react-redux';
import { selectOffreSocle } from 'components/Reducers/offreSocle';

const RoomName = () => {
  const { currentRoomId, listRooms } = useSelector(selectRoom);

  const { enigmaStep } = useSelector(selectOffreSocle);
  const currentRoom = Object.values(listRooms).find(
    (roomObject) => roomObject.id === currentRoomId
  );

  const handleName = () => {
    let name = currentRoom.name.FR;
    if (enigmaStep === 'companyView') {
      name = 'Entreprise – DUERP';
    }
    return name;
  };

  return (
    <motion.div
      className="RoomName"
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 2 }}
      exit={{ opacity: 0 }}
    >
      <div className="RoomName-content">
        <p>{handleName()}</p>
      </div>
    </motion.div>
  );
};

export default RoomName;
