import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

// components
import {
  employeLeftFace,
  referringDoctorOffice,
  specialistFace,
} from 'components/GameBoard/OffreSocle/constants';
import Dialogue from 'components/GameBoard/OffreSocle/Enigmas/Dialogue';
import Button from 'components/GameBoard/ReusableComponents/Actions/Button';
// Constants & style
import styles from 'components/GameBoard/OffreSocle/Enigmas/RecommandationErgonomist.module.scss';
import { selectObject } from 'components/Reducers/GameData/fetchObjects';
import { directorId } from 'components/GameBoard/ReusableComponents/Objects/constants';
import DialogueRow from 'components/GameBoard/OffreSocle/Enigmas/DialogueRow';

// constants

const RecommandationErgonomist = () => {
  const dispatch = useDispatch();
  const { directorFace } = referringDoctorOffice;

  const directorObject = useSelector((state) => selectObject(state, directorId));

  const { t } = useTranslation('common', 'offreSocle');
  const dialogueText = t('recommandationErgonomistDialogue', {
    returnObjects: true,
    ns: 'offreSocle',
  });

  if (directorObject.isClicked !== 0) {
    return (
      <div className={styles.recommandationErgonomist}>
        <div className={styles['bubble-and-button-container']}>
          <motion.div className={styles['bubble-container']}>
            <DialogueRow
              characterImage={directorFace}
              direction="row"
              dialogueText={t('directorCheckEmployee', {
                ns: 'offreSocle',
                returnObjects: true,
              })}
            />
          </motion.div>
          <Button
            title={t('buttonFunction.continue', { ns: 'common' })}
            buttonType="offreSocle"
            additionalStyle={{
              width: 'fit-content',
              margin: '0',
              alignSelf: 'center',
              position: 'absolute',
              bottom: '0',
            }}
            onClick={() => {
              dispatch({
                type: 'CLOSE_MODAL_ENIGMA',
              });
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={styles['dialogue-container']}>
      <Dialogue
        dialogueText={dialogueText}
        character1={specialistFace}
        character2={employeLeftFace}
        dialogueButtons={
          <Button
            title={t('buttonFunction.next', { ns: 'common' })}
            buttonType="offreSocle"
            additionalStyle={{ width: '95%' }}
            onClick={async () => {
              dispatch({
                type: 'CLOSE_MODAL_ENIGMA',
              });
            }}
          />
        }
      />
    </div>
  );
};

export default RecommandationErgonomist;
