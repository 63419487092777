import commonSteps from 'components/Joyride/constants/commonSteps';
import backgroundMovingSteps from 'components/Joyride/constants/backgroundMovingSteps';

/**
 * Tutorial steps for Emotion game
 * @param {func} t -translation function from i18next
 * @returns {Object[]}
 */
const stepsEmotion = (t) => {
  const commonImportedSteps = commonSteps(
    t,
    `dans la ${t('room1', { ns: 'emotion' })}`,
    `du ${t('narrator', { ns: 'emotion' })}`
  );
  const backgroundMovingImportedSteps = backgroundMovingSteps(
    t,
    `dans la ${t('room1', { ns: 'emotion' })}`
  );

  // Insert the background moving steps in the common steps
  backgroundMovingImportedSteps.forEach((step) => {
    commonImportedSteps.splice(commonImportedSteps.length - 1, 0, step);
  });

  return commonImportedSteps;
};
export default stepsEmotion;
