import { infoGameUser } from 'components/Reducers/GameData/GameUsers';
import { selectSession } from 'components/Reducers/session';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from 'components/Reducers/user';
import PropTypes from 'prop-types';

import 'components/GameBoard/Emotion/Multi/Enigma/Crossword/CrosswordGame.css';

const CrosswordBoxe = ({ box, onCorrect, onIncorrect, setGrid, grid }) => {
  const { id, letter, across, clueAcross, down, clueDown, number, value, color } =
    box;
  const [isCorrect, setCorrect] = useState(false);

  const session = useSelector(selectSession);

  const { socket } = useSelector(infoGameUser);

  const { currentUser, participants } = useSelector(selectUser);

  const { isMainUser } = participants[Object.keys(currentUser)[0]];

  const room = session.id;

  const handleChange = (event) => {
    const newGrid = [...grid];
    const boxIndex = newGrid.findIndex((boxElement) => boxElement.id === id);
    newGrid[boxIndex].value = event.target.value.toLowerCase();
    setGrid(newGrid);

    const responsesSocket = {
      newGrid,
      room,
    };
    socket?.emit('send_emotion_multi_set_grid_crossword', responsesSocket);
    const targetEvent = event.target.value
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');

    const goodLetter = targetEvent === letter;

    if (goodLetter && !isCorrect) {
      const cluesToUpdate = [];
      if (across) {
        cluesToUpdate.push({
          direction: 'across',
          number: clueAcross,
        });
      }

      if (down) {
        cluesToUpdate.push({
          direction: 'down',
          number: clueDown,
        });
      }
      onCorrect(cluesToUpdate, event);
    } else if (isCorrect && value && event.target.value.toLowerCase() !== letter) {
      setCorrect(false);

      onIncorrect();
    }
  };

  if (!letter) {
    return <div className="box blank" />;
  }
  if (number) {
    return (
      <div className="box-wrapper">
        <label htmlFor={`box-${id}`} className="box-label box-label--number">
          {number}
        </label>
        <input
          id={`box-${id}`}
          name={`box-${id}`}
          type="text"
          disabled={!isMainUser}
          onChange={(event) => handleChange(event)}
          maxLength="1"
          className={`box ${
            (value === letter ? 'isValid' : 'isNotValid',
            !isMainUser && 'isNotMainUser')
          }`}
          value={value}
        />
      </div>
    );
  }
  return (
    <input
      type="text"
      onChange={(event) => handleChange(event)}
      maxLength="1"
      disabled={!isMainUser}
      className={`box ${color && 'boxYellow'} ${
        (value === letter ? 'isValid' : 'isNotValid',
        !isMainUser && 'isNotMainUser')
      }`}
      value={value}
    />
  );
};

CrosswordBoxe.propTypes = {
  box: PropTypes.shape({
    id: PropTypes.string,
    letter: PropTypes.string,
    across: PropTypes.bool,
    clueAcross: PropTypes.number,
    down: PropTypes.bool,
    clueDown: PropTypes.number,
    number: PropTypes.number,
    value: PropTypes.string,
    color: PropTypes.string,
  }).isRequired,
  onCorrect: PropTypes.func.isRequired,
  onIncorrect: PropTypes.func.isRequired,
  setGrid: PropTypes.func.isRequired,
  grid: PropTypes.arrayOf(PropTypes.objectOf).isRequired,
};

export default CrosswordBoxe;
