import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
// Components
import Button from 'components/GameBoard/ReusableComponents/Actions/Button';
import OffreSocleModal from 'components/GameBoard/OffreSocle/Enigmas/OffreSocleModal';
import DuerpFound from 'components/GameBoard/OffreSocle/Enigmas/DuerpFound';
// Selector functions & API
import { selectObjectIndex } from 'components/Reducers/GameData/fetchObjects';
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';
import { selectOffreSocle } from 'components/Reducers/offreSocle';
import { putSessionsHasRoomHasObject } from 'API/sessionsHasRoomHasObject';
// utils & constants
import { clickCount, playSound } from 'utils/utilityFunctions';
import { referringDoctorOffice } from 'components/GameBoard/OffreSocle/constants';
import { offreSocleLocker3Id } from 'components/GameBoard/ReusableComponents/Objects/constants';
// Style
import styles from 'components/GameBoard/OffreSocle/Enigmas/LockerCode.module.scss';
import OffreSocleModal2 from 'components/GameBoard/OffreSocle/Enigmas/OffreSocleModal2';
import { trophySound } from 'components/GameBoard/ReusableComponents/constants';

const {
  maxLockerNumber,
  minLockerNumber,
  arrowDown,
  arrowUp,
  codeBlock,
  initialCombinaison,
} = referringDoctorOffice;

const LockerCode = ({ objectClicked, setObjectClicked }) => {
  const { t } = useTranslation('common', 'offreSocle');

  const dispatch = useDispatch();

  const { rightCode, content, message } =
    referringDoctorOffice.locker[objectClicked.id];
  const [combinaison, setCombinaison] = useState(initialCombinaison);

  const { status } = useSelector(selectOffreSocle).locker[objectClicked.id];

  const objectClickedIndex = useSelector((state) =>
    selectObjectIndex(state, objectClicked.id)
  );
  const { idSessionHasRoom } = useSelector(infoGameUser);

  const validateCombinaison = (event, combinaisonType) => {
    dispatch({
      type: 'SET_LOCKER_STATUS',
      payload: {
        lockerId: objectClicked.id,
        status: combinaisonType,
      },
    });
    clickCount(dispatch, event);
  };

  const handleArrowClick = (event, index) => {
    let newNumber = combinaison[Object.keys(combinaison)[index]];
    const swipeImage = objectClicked?.images.find(
      (object) => object.type === 'clicked'
    );
    const swipeSound = new Audio(swipeImage.sound);
    swipeSound.volume = 0.1;
    swipeSound.play();
    if (event.currentTarget.name === 'arrow-up') {
      if (newNumber < maxLockerNumber) {
        newNumber += 1;
      } else {
        newNumber = 0;
      }
    } else if (event.currentTarget.name === 'arrow-down') {
      if (newNumber > minLockerNumber) {
        newNumber -= 1;
      } else {
        newNumber = maxLockerNumber;
      }
    }

    setCombinaison({
      ...combinaison,
      [Object.keys(combinaison)[index]]: newNumber,
    });
  };

  const handleDisplay = () => {
    switch (status) {
      case 'unanswered':
        return (
          <div className={styles['modal-container']}>
            <div className={styles['code-and-text']}>
              <div className={styles.modal}>
                {Object.values(combinaison).map((number, index) => {
                  return (
                    <motion.div
                      key={Object.keys(combinaison)[index]}
                      className={styles['block-code']}
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      transition={{
                        type: 'spring',
                        stiffness: 260,
                        damping: 20,
                      }}
                    >
                      <button
                        type="button"
                        onClick={(event) => handleArrowClick(event, index)}
                        name="arrow-up"
                      >
                        <img
                          src={arrowUp}
                          draggable="false"
                          alt="object"
                          width="50%"
                          height="50%"
                        />
                      </button>
                      <img
                        src={codeBlock}
                        draggable="false"
                        alt="object"
                        width="70%"
                        height="70%"
                      />
                      <p id={styles.number}>{number}</p>
                      <button
                        type="button"
                        onClick={(event) => handleArrowClick(event, index)}
                        name="arrow-down"
                      >
                        <img
                          src={arrowDown}
                          draggable="false"
                          alt="object"
                          width="50%"
                          height="50%"
                        />
                      </button>
                    </motion.div>
                  );
                })}
              </div>
              <p id={styles.text}>{t('codeText', { ns: 'offreSocle' })}</p>
            </div>
            <div>
              <Button
                onClick={() => {
                  setObjectClicked(null);
                }}
                title={t('buttonFunction.return')}
                buttonType="offreSocle"
              />
              <Button
                onClick={(event) => {
                  const playerCombinaison = Number(
                    Object.values(combinaison).join('')
                  );
                  const combinaisonType =
                    playerCombinaison === rightCode ? 'right' : 'wrong';
                  validateCombinaison(event, combinaisonType);
                }}
                title={t('buttonFunction.unlock')}
                buttonType="offreSocle"
              />
            </div>
          </div>
        );

      case 'right': {
        if (objectClicked.id === offreSocleLocker3Id) {
          playSound(trophySound);
          return <DuerpFound setObjectClicked={setObjectClicked} />;
        }

        const lockerImageWin = objectClicked?.images.find(
          (object) => object.type === 'checked'
        );
        const lockerWinSound = new Audio(lockerImageWin.sound);
        lockerWinSound.volume = 0.1;
        lockerWinSound.play();

        return (
          <OffreSocleModal
            objectImage={content}
            objectMessage={message}
            onClick={() => {
              setObjectClicked(false);
              dispatch({
                type: 'CLICKED_OBJECT',
                payload: {
                  index: objectClickedIndex,
                  type: 'checked',
                  isChecked: 1,
                },
              });
              putSessionsHasRoomHasObject(idSessionHasRoom, {
                object_id: objectClicked.id,
                isChecked: 1,
              });
              dispatch({
                type: 'UNLOCK_LOCKER',
                payload: { lockerId: objectClicked.id },
              });
            }}
            buttonTitle={t('buttonFunction.continue')}
          />
        );
      }
      case 'wrong': {
        const lockerImageError = objectClicked?.images.find(
          (object) => object.type === 'error'
        );
        const lockerErrorSound = new Audio(lockerImageError.sound);
        lockerErrorSound.volume = 0.3;
        lockerErrorSound.play();
        return (
          <OffreSocleModal2
            text={t('wrongCombinaisonLocker', { ns: 'offreSocle' })}
            subText={t('tryAgain', { ns: 'offreSocle' })}
            buttonTitle={t('buttonFunction.retry')}
            onClick={() =>
              dispatch({
                type: 'SET_LOCKER_STATUS',
                payload: { lockerId: objectClicked.id, status: 'unanswered' },
              })
            }
            button2Title={t('buttonFunction.return')}
            onClick2={() => {
              setObjectClicked(false);
              dispatch({
                type: 'SET_LOCKER_STATUS',
                payload: { lockerId: objectClicked.id, status: 'unanswered' },
              });
            }}
          />
        );
      }
      default:
        return undefined;
    }
  };

  return handleDisplay();
};

LockerCode.propTypes = {
  objectClicked: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};

export default LockerCode;
