import { useEffect, useState, Suspense, lazy } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

// utils
import useInitGame from 'utils/useInitGame';
import { loadUserGameSession } from 'utils/utilityFunctions';
import styles from 'components/Introduction/StartPage.module.scss';

// components
import Game from 'components/Game';
import Loading from 'components/Introduction/Loading';
import { selectInfoGame } from 'components/Reducers/game';
import RoomContainer from 'components/GameBoard/ReusableComponents/RoomContainer';
import { selectUser } from 'components/Reducers/user';

// constants
import startPageBackground from 'components/Introduction/constants';

let isMounted = false;
const StartButtonLazy = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(resolve, 5000);
  }).then(() => import('components/Introduction/StartButton'));
});

function StartPage() {
  const [startButtonClicked, setStartButtonClicked] = useState(false);

  const dispatch = useDispatch();

  // Retrieve data in url
  const [params] = useSearchParams();
  const gameId = params.get('game');
  const roomName = params.get('RoomName');
  const token = params.get('token');
  const userId = params.get('user') || params.get('admin');
  const sessionId = Number(roomName?.match(/(\d+)/g)?.[0]);

  const backgroundImage = useSelector(selectInfoGame).intro_image;
  const isAllowedUser = useSelector(selectUser).isAllowed;

  const [backgroundImageDimensions, setBackgroundImageDimensions] = useState({
    width: 0,
    height: 0,
  });
  const backgroundImageMaxWidth = startPageBackground[gameId]?.width;
  const backgroundImageFixed = startPageBackground[gameId]?.isBackgroundFixed;

  useInitGame(null, sessionId);

  useEffect(() => {
    if (!isMounted) {
      isMounted = true;
      // Retrieve all data for a game
      loadUserGameSession(gameId, userId, sessionId, token, dispatch);
    }
  }, [dispatch, gameId, userId, sessionId, token]);

  const handleClick = () => {
    setStartButtonClicked(true);
  };

  if (startButtonClicked) {
    return <Game />;
  }

  return (
    <>
      <RoomContainer
        isBackgroundFixed={backgroundImageFixed}
        maxWidth={backgroundImageMaxWidth}
        imgDimensions={backgroundImageDimensions}
      >
        <img
          src={backgroundImage}
          alt="background"
          width="100%"
          height="100%"
          className={styles['img-background']}
          onLoad={(event) => {
            setBackgroundImageDimensions({
              width: event.target.width,
              height: event.target.height,
            });
          }}
        />
      </RoomContainer>

      <Suspense fallback={<Loading />}>
        <StartButtonLazy handleClick={handleClick} isAllowedUser={isAllowedUser} />
      </Suspense>
    </>
  );
}

export default StartPage;
