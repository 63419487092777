import { useEffect, useState, useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// components
import Button from 'components/GameBoard/ReusableComponents/Actions/Button';
import Audio from 'components/GameBoard/ReusableComponents/Actions/Audio';
import QuestionnaryContent from 'components/GameBoard/ReusableComponents/Questionnary/QuestionnaryContent';

// css
import 'components/GameBoard/ReusableComponents/Modal/Modal.scss';
import styled from 'styled-components';
// selectors
import { selectSession } from 'components/Reducers/session';
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';
import { selectUser } from 'components/Reducers/user';
import { selectInfoGame } from 'components/Reducers/game';
import { selectRoom } from 'components/Reducers/Room';

const ModalTextStyle = styled.div`
  width: 100%;
  height: 90%;
  padding: ${({ textPaddingLeft }) =>
    `2rem 2rem 2rem ${textPaddingLeft || '4.5rem'}`};
  background: ${({ theme, modalBackgroundColor }) =>
    theme.characterBubbleColor || modalBackgroundColor || '#FFF'};
  border-radius: 35px;
  ref: ${({ ref }) => ref};

  div {
    overflow-y: auto;
    width: 100%;
    height: 100%;

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      width: 10px;
      background-color: rgb(245, 245, 245);
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #555;
    }
  }

  p {
    line-height: 1.5;
    color: ${({ textColor }) => textColor ?? 'black'};
    margin-right: 1rem;
    height: ${({ ModalTextStyleRef }) =>
      `calc(${ModalTextStyleRef?.current?.offsetHeight}px - 4rem)`};
  }
`;

function Modal({
  soundtrack,
  dispatch,
  title,
  message,
  image,
  buttonDescription,
  type,
  currentStep,
  messages,
  gameId,
  action,
  stepsList,
  modalBackgroundColor,
  textPaddingLeft,
}) {
  const { t } = useTranslation('common');

  const session = useSelector(selectSession);
  const { socket } = useSelector(infoGameUser);
  const { currentUser, participants } = useSelector(selectUser);
  const room = session.id;

  const [reverseCard, setReverseCard] = useState(false);
  const obesityGameId = 160;
  const [audioElement, setAudio] = useState(null);
  const [indexMessage, setIndexMessage] = useState(0);
  const [subIndex, setSubIndex] = useState(0);
  const [blink, setBlink] = useState(true);
  const [reverse, setReverse] = useState(false);
  const game = useSelector(selectInfoGame);
  const { currentRoomId, listRooms } = useSelector(selectRoom);

  const ModalTextStyleRef = useRef();
  // Grab the correct data to be displayed inside the modal
  let contentElement;
  if (type === 'intro') {
    // contentElement is an array of strings
    contentElement = messages;
  } else if (type === 'prevention') {
    contentElement = currentStep.messages;
  }

  useEffect(() => {
    socket?.on('receive_index_message', (data) => {
      setIndexMessage(data.indexMessage);
    });
    socket?.on('receive_sub_index', (data) => {
      setSubIndex(data.indexMessage);
    });
    socket?.on('receive_blink', (data) => {
      setBlink(data.blink);
    });
    socket?.on('receive_reverse', (data) => {
      setReverse(data.isReverse);
    });
  }, [indexMessage, reverse, socket]);
  // if an intro or prevention message appears, the background music stops
  useEffect(() => {
    if (type === 'intro' || type === 'prevention') {
      dispatch({ type: 'MUTE_MUSIC', payload: false });
      dispatch({ type: 'PAUSE_TIMER', payload: true });
    }

    // TODO
    /* At the emotion card step, type is not yet updated so the cleanup function
     is done which unmute the music no matter if the player muted it before entering the prevention message or not */
    return () => {
      if (type === 'intro' || type === 'prevention') {
        dispatch({ type: 'MUTE_MUSIC', payload: true });
        dispatch({ type: 'PAUSE_TIMER', payload: false });
      }
    };
  }, [dispatch, type]);

  // Handle the closing of the modal
  useEffect(() => {
    if (contentElement && indexMessage === contentElement.length) {
      if (type === 'intro') {
        const responsesSocket = { room };
        socket?.emit('send_close_modal', responsesSocket);
        dispatch({
          type: 'CLOSE_MODAL',
        });

        if (action) {
          action.onClick();
        }
      } else if (type === 'prevention') {
        buttonDescription.onClick();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexMessage]);

  // typeWriter
  // Handle the progressive writing of the text ?
  useEffect(() => {
    let responsesSocket = { room };
    // when reverse is true ?
    if (subIndex === 0 && reverse) {
      responsesSocket = { ...responsesSocket, isReverse: false };
      socket?.emit('send_reverse', responsesSocket);
      setReverse(false);
      setIndexMessage((prev) => {
        responsesSocket = { ...responsesSocket, indexMessage: prev + 1 };

        socket?.emit('send_index_message', responsesSocket);
        return prev + 1;
      });
      return;
    }

    const timeout = setTimeout(() => {
      setSubIndex((prev) => {
        if (reverse) {
          responsesSocket = { ...responsesSocket, indexMessage: 0 };
          socket?.emit('send_sub_index', responsesSocket);
          return 0;
        }
        responsesSocket = { ...responsesSocket, indexMessage: prev + 1 };
        socket?.emit('send_sub_index', responsesSocket);
        return prev + 1;
      });
    }, 50);

    if (contentElement && contentElement[indexMessage]) {
      if (contentElement[indexMessage].audio) {
        setAudio(contentElement[indexMessage].audio);
      }
    }
    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subIndex, indexMessage, reverse]);

  // blinker
  useEffect(() => {
    const timeout2 = setTimeout(() => {
      setBlink((prev) => {
        const responsesSocket = { room, blink: !prev };
        socket?.emit('send_blink', responsesSocket);
        return !prev;
      });
    }, 500);
    return () => clearTimeout(timeout2);
  }, [blink, room, socket]);

  // Handle the progressive display of the message
  const handleMessage = () => {
    let messageContent;

    if (!contentElement[indexMessage].description) {
      messageContent = contentElement[indexMessage].substring(0, subIndex);
    } else {
      messageContent = contentElement[indexMessage].description.substring(
        0,
        subIndex
      );
    }
    return `${messageContent}${blink ? '|' : ' '}`;
  };

  const isMainUser =
    currentUser &&
    participants[Object.keys(currentUser)[0]] &&
    participants[Object.keys(currentUser)[0]].isMainUser;

  const isMediator =
    currentUser &&
    participants[Object.keys(currentUser)[0]] &&
    participants[Object.keys(currentUser)[0]].type === 'admin';

  const handleButtonClick = () => {
    setReverse(true);
    const responsesSocket = { room, isReverse: true };
    socket?.emit('send_reverse', responsesSocket);
  };

  /** audioElement
   * Handle the content of the modal
   * @returns
   */
  const handleContent = () => {
    if (type === 'intro' || type === 'prevention') {
      return gameId === obesityGameId ? (
        <div className="ModalRobot_description">
          <div className="ModalRobot_description-contents">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FObesity%2Fassets%2Fobjects%2Frobot.svg?alt=media&token=30b58f7e-f4f1-4fd4-95bb-936360d3278e"
              alt="robot"
              className="bounceRobot ModalRobot_description-robot"
            />
            <div className="ModalRobot_description-bubble">
              <div className="ModalRobot_description-text">
                <Audio
                  sound={audioElement ?? ''}
                  condition={audioElement !== null && soundtrack}
                />

                {contentElement[indexMessage] && <p>{handleMessage()}</p>}
                <button
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    right: '41vw',
                  }}
                  className="ButtonAction Action"
                  type="button"
                  onClick={() => {
                    const responsesSocket = { room, isReverse: true };
                    socket?.emit('send_reverse', responsesSocket);
                    setReverse(true);
                  }}
                >
                  {t('buttonFunction.next')}
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="Modal_description-content-prevention">
          <div id="bubble-container">
            <img alt="message-intro" draggable="false" src={game.avatar} />

            <Audio
              sound={audioElement ?? ''}
              condition={audioElement !== null && soundtrack}
            />

            <ModalTextStyle
              modalBackgroundColor={modalBackgroundColor}
              textPaddingLeft={textPaddingLeft}
              ref={ModalTextStyleRef}
              ModalTextStyleRef={ModalTextStyleRef}
            >
              <div>{contentElement[indexMessage] && <p>{handleMessage()}</p>}</div>
            </ModalTextStyle>
          </div>
          <button
            style={{
              bottom: 0,
            }}
            className="ButtonAction Action"
            type="button"
            onClick={() => {
              handleButtonClick();
            }}
          >
            {t('buttonFunction.next')}
          </button>
        </div>
      );
    }

    if (type === 'emotionCard') {
      const handleStepText = (position) => {
        switch (position) {
          case 0:
            return ' première ';
          case 1:
            return ' deuxième ';
          case 2:
            return ' troisième ';
          case 3:
            return ' quatrième ';
          case 4:
            return ' dernière ';
          default:
            return ' ';
        }
      };

      const currentRoom = Object.values(listRooms).find(
        (roomObject) => roomObject.id === currentRoomId
      );
      return (
        <div className="Modal_description-content">
          <div
            className={`Modal_description-image${!reverseCard ? '' : '--reverse'}`}
          >
            <button
              className="Modal_description-button"
              type="button"
              onClick={() => setReverseCard(!reverseCard)}
            >
              <img
                draggable="false"
                src={
                  reverseCard ? currentStep.imageFoundZoom : currentStep.imageFound
                }
                alt={title}
              />
            </button>
          </div>
          {!reverseCard && (
            <div className="Modal_description-card">
              <p>Félicitations !</p>
              <p>
                {t('card.congratulation', {
                  val: `${
                    stepsList.length > 1 ? handleStepText(currentStep.level) : ''
                  }`,
                  val2: `${currentStep.card_title ?? 'récompense'}`,
                  val3: `${
                    stepsList.length === 1
                      ? `:
                  "${currentStep.title}"`
                      : `dans cette ${handleStepText(currentRoom.niveau - 1)} room`
                  }`,
                })}
              </p>
              <p>Répondez à quelques questions pour évaluer vos besoins.</p>
            </div>
          )}
          {buttonDescription &&
            (game.type === 'solo' ||
              (game.type === 'multi' && isMainUser) ||
              (game.type === 'multi' && isMediator)) && (
              <Button
                buttonType="action"
                title={buttonDescription.title}
                onClick={() => buttonDescription.onClick()}
              />
            )}
        </div>
      );
    }

    return (
      <div className="modal-object">
        {image && (
          <img
            src={image}
            draggable="false"
            alt={title}
            width="100%"
            height="100%"
          />
        )}
        <p>{message}</p>
      </div>
    );
  };

  if (type === 'questionnary') {
    return (
      <div className="Modal">
        <div className="Modal_blur" />
        <div className="Modal_description">
          <div className="Modal_description-content">
            <div className="Modal_description-text">
              <QuestionnaryContent />
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="Modal">
      <div className="Modal_blur" />
      <div
        className={`Modal_description ${
          type === 'prevention' && 'Modal_description-prevention'
        } ${gameId === obesityGameId && 'Modal_description-robot'}
        ${
          type === 'wrong-object' || type === 'inventory'
            ? 'modal-object-container'
            : ''
        }
        `}
      >
        {handleContent()}
        <div className="Modal_Card_Emotion_Buttons">
          {type !== 'intro' &&
            type !== 'prevention' &&
            type !== 'emotionCard' &&
            type !== 'questionnary' &&
            !buttonDescription && (
              <Button
                buttonType="return"
                title={t('buttonFunction.return')}
                onClick={() => {
                  const responsesSocket = { room };
                  socket?.emit('send_close_modal', responsesSocket);
                  dispatch({
                    type: 'CLOSE_MODAL',
                  });
                }}
              />
            )}
          {type !== 'prevention' && type !== 'emotionCard' && buttonDescription && (
            <Button
              buttonType="action"
              title={buttonDescription.title}
              onClick={() => buttonDescription.onClick()}
            />
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  // `title` is not used for an intro type
  title: state.Modal.modal.description.title,
  message: state.Modal.modal.description.message,
  // `image` is not used for an intro type
  image: state.Modal.modal.description.image,
  buttonDescription: state.Modal.modal.description.buttonDescription,
  action: state.Modal.modal.description.action,
  type: state.Modal.modal.description.type,
  currentStep: state.Steps.currentStep,
  stepsList: state.Steps.list,
  gameId: state.game.id,
  messages: state.Modal.modal.description.messages,
  backgroundMessage: state.game.tuto_background,
  soundtrack: state.GameUsers.soundtrack,
});

Modal.propTypes = {
  title: PropTypes.string,
  messages: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  message: PropTypes.string,
  image: PropTypes.string,
  gameId: PropTypes.number.isRequired,
  buttonDescription: PropTypes.shape({
    onClick: PropTypes.func,
    title: PropTypes.string,
  }),
  action: PropTypes.shape({
    onClick: PropTypes.func,
  }),
  type: PropTypes.string,
  currentStep: PropTypes.shape({
    message: PropTypes.string,
    level: PropTypes.number,
    imageFoundZoom: PropTypes.string,
    imageFound: PropTypes.string,
    messages: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
    card_title: PropTypes.string,
    title: PropTypes.string,
  }),
  stepsList: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array])
  ).isRequired,
  dispatch: PropTypes.func.isRequired,
  soundtrack: PropTypes.bool.isRequired,
  textPaddingLeft: PropTypes.string,
  modalBackgroundColor: PropTypes.string,
};

Modal.defaultProps = {
  title: '',
  messages: null,
  message: '',
  image: '',
  action: null,
  buttonDescription: null,
  type: '',
  currentStep: null,
  textPaddingLeft: null,
  modalBackgroundColor: null,
};

export default connect(mapStateToProps)(Modal);
