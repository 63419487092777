import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import Joyride from 'react-joyride';
import { useTranslation } from 'react-i18next';

// components
import Inventory from 'components/GameBoard/ReusableComponents/Inventory';
import Modal from 'components/GameBoard/ReusableComponents/Modal/Modal';
import Steps from 'components/GameBoard/ReusableComponents/Steps';
import ListObjects from 'components/GameBoard/ReusableComponents/Objects/ListObjects';
import Zoom from 'components/GameBoard/ReusableComponents/Modal/Zoom';
import ModalZoom from 'components/GameBoard/ReusableComponents/Modal/ModalZoom';
import ObjectInInventory from 'components/GameBoard/ReusableComponents/Actions/ObjectInInventory';
import Hud from 'components/GameBoard/ReusableComponents/HUD/Hud';
import ModalEnigma from 'components/GameBoard/ReusableComponents/Modal/ModalEnigma';
import stepsEmotionMulti from 'components/Joyride/StepsEmotionMulti';
import ChatBot from 'components/GameBoard/ReusableComponents/ChatBot/ChatBot';
import genericJoyrideCallback from 'components/Joyride/constants/genericJoyrideCallback';
import Audio from 'components/GameBoard/ReusableComponents/Actions/Audio';

// selector functions
import { selectSession } from 'components/Reducers/session';
import { selectSteps } from 'components/Reducers/Steps/Steps';
import { selectUser } from 'components/Reducers/user';
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';
import { selectRoom } from 'components/Reducers/Room';
import { selectModal } from 'components/Reducers/Modal/Modal';

// utils
import useInitGame from 'utils/useInitGame';
import {
  beginGame,
  clickCount,
  clickCountUser,
  useStartMessageOrQuestionary,
} from 'utils/utilityFunctions';

// css
import 'assets/css/components/GameBoard/TMS/Rooms/ChangingRoomTMS.css';
import 'components/GameBoard/Emotion/Multi/Rooms/DesktopV2.css';

// sound
import musicTheme from 'assets/sound/musicTheme/musicTheme';
// constants
import {
  volumeMultiDesktopTheme,
  modalBackgroundColor,
} from 'components/GameBoard/Emotion/Multi/constant';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const musicThemeDesktop = musicTheme['upbeat-inspiring'];

const DesktopV2 = ({ statusCheckRoom }) => {
  const { t } = useTranslation(['common', 'common-multi']);
  const [tutorialSteps, setTutorialSteps] = useState({
    run: true,
    steps: stepsEmotionMulti(t),
    stepIndex: 0,
  });

  const session = useSelector(selectSession);
  const {
    startMessage,
    startQuestionnary,
    currentStep,
    isTutoFinish,
    isScoreBoard,
  } = useSelector(selectSteps);
  const { participants, currentUser } = useSelector(selectUser);
  const { socket, idSessionHasRoom, count, cursor, clicked, music } =
    useSelector(infoGameUser);

  const dispatch = useDispatch();
  const { currentRoomId, listRooms } = useSelector(selectRoom);
  const { modal, modalZoom, zoom, modalEnigma } = useSelector(selectModal);

  useInitGame(currentRoomId, session.id);
  useStartMessageOrQuestionary(
    currentStep,
    idSessionHasRoom,
    startMessage,
    startQuestionnary,
    session,
    socket
  );

  // COUNTER
  useEffect(() => {
    if (idSessionHasRoom) {
      axios.put(
        `${BASE_URL}/api/sessionsHasRoom/${idSessionHasRoom}/modifications`,
        {
          count,
        }
      );
    }
  }, [count, idSessionHasRoom]);

  useEffect(() => {
    if (isTutoFinish) {
      const room = session.id;
      beginGame(session.id, dispatch, socket, room);
    }
  }, [dispatch, isTutoFinish, session.id, socket]);

  const clickOnGame = (event) => {
    const room = session.id;
    const responsesSocket = { room };
    clickCount(dispatch, event, socket, responsesSocket);
    if (socket) {
      clickCountUser(participants, socket, dispatch, session);
    }
  };
  const isNotYourTurn =
    currentUser &&
    statusCheckRoom === 'introduction-finish' &&
    participants[Object.keys(currentUser)[0]] &&
    !participants[Object.keys(currentUser)[0]].isMainUser;
  const urlRoom = listRooms.DesktopV2.url;
  return (
    <div className="Bedroom" id="BedroomRoom-BKG img-background">
      <Audio
        sound={musicThemeDesktop}
        loop
        id="MULTI-Theme"
        condition={isTutoFinish}
        muted={!music}
        volume={volumeMultiDesktopTheme}
      />
      <div
        className={clicked ? 'cursor-clicked' : 'Hide'}
        style={{ top: cursor.y - 30, left: cursor.x }}
      >
        <p>{isNotYourTurn ? "Ce n'est pas votre tour" : '+1'}</p>
      </div>
      {!isScoreBoard && statusCheckRoom === 'introduction-finish' && (
        <header>
          <Inventory />
          <Steps />
          <Hud />
        </header>
      )}
      {!isTutoFinish && statusCheckRoom === 'introduction-finish' && (
        <Joyride
          callback={(data) =>
            genericJoyrideCallback(
              data,
              session,
              dispatch,
              tutorialSteps,
              setTutorialSteps
            )
          }
          steps={tutorialSteps.steps}
          run={tutorialSteps.run}
          stepIndex={tutorialSteps.stepIndex}
          showSkipButton
          continuous
          disableCloseOnEsc
          disableOverlayClose
          hideBackButton
          hideCloseButton
          locale={{
            last: t('joyride.locale.last'),
            next: t('joyride.locale.next'),
            skip: t('joyride.locale.skip'),
          }}
          styles={{
            buttonNext: {
              backgroundColor: '#4ed199',
              border: '1px solid #48c48e',
              padding: '15px 25px',
              fontWeight: '700',
            },
            buttonSkip: {
              color: '#9fa2b4',
              fontWeight: '700',
            },
          }}
        />
      )}
      <button
        aria-label="background-desktopv2"
        type="button"
        className="Bedroom-img-div"
        style={{
          backgroundImage: `${
            statusCheckRoom === 'introduction-finish' && `url(${urlRoom})`
          }`,
        }}
        onClick={(event) =>
          statusCheckRoom === 'introduction-finish' && clickOnGame(event)
        }
      />
      {modal.isOpen && (
        <Modal
          modalBackgroundColor={modalBackgroundColor}
          textPaddingLeft="9.5rem"
        />
      )}
      {modalZoom.isOpen && <ModalZoom />}
      {zoom.isOpen && <Zoom />}
      {modalEnigma.isOpen && <ModalEnigma />}
      {statusCheckRoom === 'introduction-finish' && (
        <>
          <ObjectInInventory />
          <ListObjects />
          <ChatBot />
        </>
      )}
      {/* round by round the user cannot click on the game */}
      {isNotYourTurn && (
        <div
          className="ModalTricky-NoClick2"
          role="presentation"
          aria-label="Not Your Turn"
        />
      )}
    </div>
  );
};

DesktopV2.propTypes = {
  statusCheckRoom: PropTypes.string.isRequired,
};

export default DesktopV2;
