import PropTypes from 'prop-types';
import GameSoloGeneric from 'components/GameBoard/ReusableComponents/GameSoloGeneric';
import WaitingRoomParticipants from 'components/GameBoard/ReusableComponents/Multi/CheckRoom/WaitingRoomParticipants';

function GameEmotionMulti({ setStatusCheckRoom, statusCheckRoom }) {
  return (
    <>
      <GameSoloGeneric
        folderName="EmotionMulti"
        setStatusCheckRoom={setStatusCheckRoom}
        statusCheckRoom={statusCheckRoom}
      />
      <WaitingRoomParticipants
        setStatusCheckRoom={setStatusCheckRoom}
        statusCheckRoom={statusCheckRoom}
      />
    </>
  );
}

GameEmotionMulti.propTypes = {
  setStatusCheckRoom: PropTypes.func.isRequired,
  statusCheckRoom: PropTypes.string.isRequired,
};

export default GameEmotionMulti;
