import { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import Button from 'components/GameBoard/ReusableComponents/Actions/Button';
import Input from 'components/GameBoard/ReusableComponents/Actions/Input';
import 'assets/css/components/GameBoard/Obesity/Enigma/Fridge.css';
import Audio from 'components/GameBoard/ReusableComponents/Actions/Audio';
import initIngredients from 'components/GameBoard/Obesity/Rooms/constant';
import {
  idList,
  idApportCaloric,
  goodApport,
} from 'components/GameBoard/ReusableComponents/Modal/constant';

import fail from 'sound/Error.mp3';
import click from 'sound/iphone-click.mp3';
import success from 'sound/Success.mp3';
import cling from 'sound/cling.mp3';
import { useTranslation } from 'react-i18next';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const Fridge = ({ dispatch, allObjects, idSessionHasRoom, soundtrack }) => {
  const { t } = useTranslation('obesity');
  const listUsed = allObjects.find((objectContent) => objectContent.id === idList);
  const [ingredientClicked, setIngredientClicked] = useState(null);
  const [openModalQuantity, setOpenModalQuantity] = useState({
    status: false,
    gram: false,
  });
  const [soundFail, setSoundFail] = useState(false);
  const [soundClick, setSoundClick] = useState(false);
  const [soundSuccess, setSoundSuccess] = useState(false);
  const [soundTotalCaloricIngredients, setSoundTotalCaloricIngredients] =
    useState(false);

  const [totalCaloricIngredients, setTotalCaloricIngredients] = useState(0);
  const [ingredients, setIngredients] = useState(initIngredients);
  const resetIngredients = () => {
    const updateIngredients = ingredients.map((ingredient) => {
      const newIngredient = ingredient;
      newIngredient.isClicked = false;
      newIngredient.quantity = null;
      newIngredient.totalCaloric = null;
      return newIngredient;
    });
    setIngredients(updateIngredients);
  };

  const handleImage = () => {
    let imageSrc = '';
    const contentElement = listUsed.images.find(
      (image) => image.type === 'clicked'
    );
    imageSrc = contentElement.image;
    return imageSrc;
  };
  const handleClickedIngredient = (index, ingredient) => {
    const newIngredients = ingredients;

    if (ingredients.filter((item) => item.id === index + 1)[0].isClicked) {
      if (newIngredients[index].type !== 'gram') {
        setTotalCaloricIngredients(
          Number(
            totalCaloricIngredients -
              newIngredients[index].caloric * newIngredients[index].quantity
          )
        );
      } else {
        setTotalCaloricIngredients(
          Number(
            totalCaloricIngredients -
              (newIngredients[index].quantity * newIngredients[index].caloric) / 100
          )
        );
      }
      setOpenModalQuantity({
        status: false,
        gram: false,
      });
      newIngredients[index].isClicked = !newIngredients[index].isClicked;
      setIngredientClicked({
        id: index,
        type: ingredient.type ? ingredient.type : null,
      });

      newIngredients[index].quantity = 0;
      setIngredients(newIngredients);
    } else {
      setSoundClick(true);
      setTimeout(() => setSoundClick(false));

      setOpenModalQuantity({
        status: true,
        gram: ingredient.type === 'gram',
      });

      newIngredients[index].isClicked = !newIngredients[index].isClicked;
      setIngredients(newIngredients);
      setIngredientClicked({
        id: index,
        type: ingredient.type ? ingredient.type : null,
      });
    }
  };
  const calculateCaloric = (newIngredients) => {
    if (ingredientClicked.type === 'gram' && ingredientClicked.quantity !== 0) {
      return (
        (Number(ingredientClicked.quantity) *
          newIngredients[ingredientClicked.id].caloric) /
        100
      );
    }
    return (
      Number(ingredientClicked.quantity) *
      newIngredients[ingredientClicked.id].caloric
    );
  };
  const calculateCaloricOfIngredient = () => {
    const newIngredients = [...ingredients];
    if (!ingredientClicked.quantity) {
      ingredientClicked.quantity = 0;
      newIngredients[ingredientClicked.id].isClicked = false;
      newIngredients[ingredientClicked.id].quantity = 0;
    }
    newIngredients[ingredientClicked.id].quantity = ingredientClicked.quantity;

    newIngredients[ingredientClicked.id].totalCaloric =
      calculateCaloric(newIngredients);
    setIngredients(newIngredients);
    setOpenModalQuantity({
      status: false,
      gram: false,
    });
    setIngredientClicked(null);

    setSoundTotalCaloricIngredients(true);
    setTimeout(() => setSoundTotalCaloricIngredients(false), 1000);

    setTotalCaloricIngredients(
      totalCaloricIngredients + calculateCaloric(newIngredients)
    );
  };

  const addInventory = (objectElement, index) => {
    const imageInventoryApport = objectElement.images.find(
      (image) => image.type === 'inventory'
    );
    axios
      .put(`${BASE_URL}/api/sessionsHasRoomHasObject/${idSessionHasRoom}`, {
        object_id: objectElement.id,
        isInventory: true,
      })
      .then(() => {
        dispatch({
          type: 'CLICKED_OBJECT',
          payload: {
            index,
            isInventory: true,
            type: 'inventory',
          },
        });
        dispatch({
          type: 'ADD_INVENTORY',
          payload: objectElement,
        });
        dispatch({
          type: 'INCREMENT_COUNTER',
        });
        setTimeout(() => {
          dispatch({
            type: 'CLOSE_MODAL_ENIGMA',
          });
        }, 1000);
        setTimeout(() => {
          dispatch({
            type: 'OPEN_MODAL',
            payload: {
              image: imageInventoryApport.image,
            },
          });
        }, 1000);
      });
  };

  const checkedObject = (collectObject, index, isMessage) => {
    axios
      .put(`${BASE_URL}/api/sessionsHasRoomHasObject/${idSessionHasRoom}`, {
        object_id: collectObject.id,
        isChecked: 1,
      })
      .then(() => {
        dispatch({
          type: 'CLICKED_OBJECT',
          payload: {
            index,
            isChecked: 1,
            type: 'checked',
          },
        });
        dispatch({
          type: 'REMOVE_OF_INVENTORY',
          payload: collectObject.id,
        });
        if (!isMessage) {
          dispatch({
            type: 'START_MESSAGE_PREVENTION',
          });
        }
      });
  };

  const handleTotalIngredientsCaloric = () => {
    if (totalCaloricIngredients === goodApport) {
      const apportElement = allObjects.find(
        (apportContent) => apportContent.id === idApportCaloric
      );
      const apportElementIndex = allObjects.findIndex(
        (apportContent) => apportContent.id === idApportCaloric
      );
      const listElement = allObjects.find(
        (listContent) => listContent.id === idList
      );
      const listElementIndex = allObjects.findIndex(
        (listContent) => listContent.id === idList
      );

      setSoundSuccess(true);
      // Delay the addition to inventory sound to let success sound finish
      setTimeout(() => addInventory(apportElement, apportElementIndex), 1000);
      checkedObject(listElement, listElementIndex, 'not-message');
    } else {
      setTotalCaloricIngredients(0);
      setSoundFail(true);
      resetIngredients();
      setTimeout(() => {
        setSoundFail(false);
      }, 3000);
    }
  };
  const isApportValidate = allObjects.find(
    (objectContent) =>
      objectContent.id === idApportCaloric && objectContent.isInventory
  );

  const isCaloricInventory = allObjects.find(
    (objectContent) => objectContent.id === idList && objectContent.isInventory
  );
  const handleImageList = () => {
    if (isCaloricInventory && !isApportValidate) {
      return (
        <img className="Fridge_enigma-left-list" src={handleImage()} alt="tablet" />
      );
    }
    if (soundFail) {
      return <p className="Fridge_enigma-left-sentence">{t('fridgeMenuError')}</p>;
    }
    if (isApportValidate) {
      return (
        <p className="Fridge_enigma-left-sentence">{t('fridgeMenuSuccess')}</p>
      );
    }
    return null;
  };

  return (
    <div className="Fridge">
      <Audio condition={soundFail && soundtrack} sound={fail} />
      <Audio condition={soundClick && soundtrack} sound={click} />
      <Audio condition={soundSuccess && soundtrack} sound={success} />
      <Audio condition={soundTotalCaloricIngredients && soundtrack} sound={cling} />
      <div className="Fridge_enigma">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FObesity%2Fassets%2Fobjects%2Ffridge-zoom-vide1.svg?alt=media&token=142840e9-09b7-4554-8f1f-b64d4014a542"
          alt="Fridge background"
          className="Fridge_enigma-BKG"
        />
        <div className="Fridge_enigma-left">
          {handleImageList()}
          {openModalQuantity.status && (
            <div className="Fridge_enigma-Quantity">
              <p>Quelle quantité voulez-vous ajouter ?</p>
              <div className="Fridge_enigma-gramme">
                <Input
                  name="quantity"
                  type="number"
                  isRequired
                  onChange={(e) =>
                    setIngredientClicked({
                      ...ingredientClicked,
                      quantity: e.quantity,
                    })
                  }
                  inputs={ingredientClicked}
                />
                {openModalQuantity.gram && 'g'}
              </div>
              <Button
                buttonType="action"
                title="Valider"
                onClick={() => calculateCaloricOfIngredient()}
              />
            </div>
          )}
        </div>
        <div className="Fridge_enigma-right">
          <div className="Fridge_enigma-items">
            {ingredients.map((ingredient, index) => (
              <button
                className={`Fridge_enigma-item ${
                  ingredient.isClicked && 'Fridge_enigma-itemClicked'
                }`}
                type="button"
                onClick={
                  !isApportValidate &&
                  !openModalQuantity.status &&
                  (() => handleClickedIngredient(index, ingredient))
                }
                key={ingredient.id}
              >
                <img
                  src={ingredient.image}
                  alt={ingredient.name}
                  title={ingredient.name}
                />
              </button>
            ))}
          </div>
          {totalCaloricIngredients > 0 && (
            <div className="Fridge_enigma-kcal">
              <p>{isApportValidate ? goodApport : totalCaloricIngredients} kcal</p>
              {!isApportValidate && (
                <div className="Fridge_enigma-kcal-Buttons">
                  <button
                    type="button"
                    className="Fridge_enigma-kcal-Save"
                    onClick={() => handleTotalIngredientsCaloric()}
                  >
                    Enregistrer
                  </button>
                  <button
                    type="button"
                    className="Fridge_enigma-kcal-Reset"
                    onClick={() => {
                      resetIngredients();
                      setTotalCaloricIngredients(0);
                    }}
                  >
                    Effacer
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  contents: state.Modal.modalEnigma.description.content,
  allObjects: state.Objects.AllObjects,
  idSessionHasRoom: state.GameUsers.idSessionHasRoom,
  soundtrack: state.GameUsers.soundtrack,
});

Fridge.propTypes = {
  dispatch: PropTypes.func.isRequired,
  allObjects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
  idSessionHasRoom: PropTypes.number.isRequired,
  soundtrack: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(Fridge);
