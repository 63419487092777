import * as images from 'img/obesity/index';

const initIngredients = [
  {
    id: 1,
    name: 'Yaourt',
    caloric: 59,
    image: images.img1,
    isClicked: false,
    quantity: null,
    totalCaloric: null,
  },
  {
    id: 2,
    name: 'Steak',
    caloric: 157,
    image: images.img2,
    isClicked: false,
    quantity: null,
    totalCaloric: null,
  },
  {
    id: 3,
    name: 'Potiron',
    caloric: 26,
    image: images.img3,
    isClicked: false,
    quantity: null,
    totalCaloric: null,
    type: 'gram',
  },
  {
    id: 4,
    name: 'Fromage',
    caloric: 413,
    image: images.img4,
    isClicked: false,
    quantity: null,
    totalCaloric: null,
    type: 'gram',
  },
  {
    id: 5,
    name: 'Pain',
    caloric: 265,
    image: images.img5,
    isClicked: false,
    quantity: null,
    totalCaloric: null,
  },
  {
    id: 6,
    name: 'Maïs',
    caloric: 100,
    image: images.img6,
    isClicked: false,
    quantity: null,
    totalCaloric: null,
    type: 'gram',
  },
  {
    id: 7,
    name: 'Saucisson',
    caloric: 416,
    image: images.img7,
    isClicked: false,
    quantity: null,
    totalCaloric: null,
  },
  {
    id: 8,
    name: 'Pâte',
    caloric: 131,
    image: images.img8,
    isClicked: false,
    quantity: null,
    totalCaloric: null,
    type: 'gram',
  },
  {
    id: 9,
    name: 'Chocolat',
    caloric: 1092,
    image: images.img9,
    isClicked: false,
    quantity: null,
    totalCaloric: null,
  },
  {
    id: 10,
    name: 'Fraise',
    caloric: 33,
    image: images.img10,
    isClicked: false,
    quantity: null,
    totalCaloric: null,
    type: 'gram',
  },
  {
    id: 11,
    name: 'Gateau au chocolat',
    caloric: 371,
    image: images.img11,
    isClicked: false,
    quantity: null,
    totalCaloric: null,
  },
  {
    id: 12,
    name: 'Brioche',
    caloric: 375,
    image: images.img12,
    isClicked: false,
    quantity: null,
    totalCaloric: null,
  },
  {
    id: 13,
    name: 'Hamburger',
    caloric: 295,
    image: images.img13,
    isClicked: false,
    quantity: null,
    totalCaloric: null,
  },
  {
    id: 14,
    name: 'Brocolis',
    caloric: 28.7,
    image: images.img14,
    isClicked: false,
    quantity: null,
    totalCaloric: null,
    type: 'gram',
  },
  {
    id: 15,
    name: 'Pâte à tartiner',
    caloric: 235,
    image: images.img15,
    isClicked: false,
    quantity: null,
    totalCaloric: null,
    type: 'gram',
  },
  {
    id: 16,
    name: 'Bonbon',
    caloric: 535,
    image: images.img16,
    isClicked: false,
    quantity: null,
    totalCaloric: null,
  },
];

export default initIngredients;
