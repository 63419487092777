import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GiSandsOfTime, GiNetworkBars } from 'react-icons/gi';
import { IoIosBatteryCharging } from 'react-icons/io';
import { ImCamera } from 'react-icons/im';
import { FcCalculator } from 'react-icons/fc';
import { MdOutlineEventNote } from 'react-icons/md';
import { GoDeviceCameraVideo } from 'react-icons/go';
import { GrSettingsOption } from 'react-icons/gr';
import { AiFillHeart } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';

// selectors
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';
import { selectModalEnigma } from 'components/Reducers/Modal/Modal';
import {
  selectAllObjects,
  selectObject,
  selectObjectIndex,
} from 'components/Reducers/GameData/fetchObjects';
import { selectSession } from 'components/Reducers/session';
import { selectUser } from 'components/Reducers/user';

// components
import Audio from 'components/GameBoard/ReusableComponents/Actions/Audio';
import Timer from 'components/GameBoard/ReusableComponents/Enigma/Phone/Timer';
import Camera from 'components/GameBoard/ReusableComponents/Enigma/Phone/Camera';
import Settings from 'components/GameBoard/ReusableComponents/Enigma/Phone/Settings';
import Health from 'components/GameBoard/ReusableComponents/Enigma/Phone/Health';
import Facetime from 'components/GameBoard/ReusableComponents/Enigma/Phone/Facetime';
import Calculator from 'components/GameBoard/ReusableComponents/Enigma/Phone/Calculator';
import Note from 'components/GameBoard/ReusableComponents/Enigma/Phone/Note';

// utils
import { checkedObject, clickCountUser } from 'utils/utilityFunctions';

// css
import styles from 'components/GameBoard/ReusableComponents/Enigma/Phone/Phone.module.scss';

// sound
import click from 'sound/iphone-click.mp3';

// constant
import { phoneEmotionMultiEnigma } from 'components/GameBoard/ReusableComponents/Objects/constants';
import {
  codeIphone,
  hourOfDesktop,
  inputsPhone,
} from 'components/GameBoard/Emotion/Multi/constant';
import { phoneHour } from 'components/GameBoard/ReusableComponents/constants';

// TO REFACTO
function Phone() {
  const arrayCodeEmptyContent = Array(codeIphone.length).fill('');
  const phoneUnLockContent = false;
  const soundClickCloseContent = false;
  const soundClickOpenContent = true;

  const [currentApp, setCurrentApp] = useState(null);
  const [soundClick, setSoundClick] = useState(false);
  const [isLock, setIsLock] = useState(true);
  const [currentNumber, setCurrentNumber] = useState([]);
  const [arrayCode, setArrayCode] = useState(arrayCodeEmptyContent);

  const { description } = useSelector(selectModalEnigma);
  const { soundtrack, idSessionHasRoom, socket } = useSelector(infoGameUser);
  const allObjects = useSelector(selectAllObjects);
  const session = useSelector(selectSession);
  const { participants } = useSelector(selectUser);
  const room = session.id;

  const dispatch = useDispatch();

  const isEnigmaPhoneEmotionMulti =
    phoneEmotionMultiEnigma === description.content.id;

  const phoneObject = useSelector((state) =>
    selectObject(state, phoneEmotionMultiEnigma)
  );

  const phoneIndex = useSelector((state) =>
    selectObjectIndex(state, phoneEmotionMultiEnigma)
  );

  const handleLockCondition = useCallback(
    (currentNumberElement) => {
      let responsesSocket = {
        room,
      };

      if (codeIphone.length === currentNumberElement.length + 1) {
        // clickCount don't work because it's a callback and there is the "event" props to pass
        // in the clickCount function but with the socket, the event and the callback there is a loop.
        // We can't pass "event" parameter in socket props, there is some loop
        // TO REFACTO
        dispatch({ type: 'INCREMENT_COUNTER' });
        socket?.emit('send_increment_counter', responsesSocket);
        if (socket) {
          clickCountUser(participants, socket, dispatch, session);
        }
        if (codeIphone === arrayCode.join('')) {
          // user found the good code
          responsesSocket = {
            status: phoneUnLockContent,
            ...responsesSocket,
          };
          socket?.emit('send_emotion_multi_is_lock_phone', responsesSocket);
          setIsLock(phoneUnLockContent);
          checkedObject(
            phoneObject,
            phoneIndex,
            idSessionHasRoom,
            dispatch,
            socket,
            room
          );
        }
        setTimeout(() => {
          // clear the last code
          responsesSocket = {
            newArrayCode: arrayCodeEmptyContent,
            newCurrentNumber: [],
            ...responsesSocket,
          };
          socket?.emit('send_emotion_multi_array_code_phone', responsesSocket);
          socket?.emit('send_emotion_multi_current_number_phone', responsesSocket);
          setArrayCode(arrayCodeEmptyContent);
          setCurrentNumber([]);
        }, 100);
      }
    },
    [
      arrayCode,
      arrayCodeEmptyContent,
      dispatch,
      idSessionHasRoom,
      participants,
      phoneIndex,
      phoneObject,
      phoneUnLockContent,
      room,
      session,
      socket,
    ]
  );

  useEffect(() => {
    socket?.on('receive_emotion_multi_current_number_phone', (data) => {
      setCurrentNumber(data.newCurrentNumber);

      if (codeIphone.length === data.currentNumber.length + 1) {
        handleLockCondition(data.currentNumber);
      }
    });
    socket?.on('receive_emotion_multi_array_code_phone', (data) => {
      setArrayCode(data.newArrayCode);
    });
    socket?.on('receive_emotion_multi_is_lock_phone', (data) => {
      setIsLock(data.status);
    });
    socket?.on('receive_emotion_multi_current_app_phone', (data) => {
      setCurrentApp(data.current);
    });
    socket?.on('receive_emotion_multi_sound_click_phone', (data) => {
      setSoundClick(data.soundStatus);
    });
  }, [handleLockCondition, socket]);

  useEffect(() => {
    if (!isEnigmaPhoneEmotionMulti) {
      const responsesSocket = {
        status: phoneUnLockContent,
        room,
      };
      socket?.emit('send_emotion_multi_is_lock_phone', responsesSocket);
      setIsLock(phoneUnLockContent);
    }
  }, [isEnigmaPhoneEmotionMulti, phoneUnLockContent, room, socket]);

  useEffect(() => {
    const phoneObjectElement = allObjects.find(
      (objectContent) => objectContent.id === phoneEmotionMultiEnigma
    );
    if (phoneObjectElement && phoneObjectElement.isChecked) {
      const responsesSocket = {
        status: phoneUnLockContent,
        room,
      };
      socket?.emit('send_emotion_multi_is_lock_phone', responsesSocket);
      setIsLock(phoneUnLockContent);
    }
  }, [allObjects, phoneUnLockContent, room, socket]);

  const { t } = useTranslation(['objects', 'emotion-multi']);

  const handleScreenContent = () => {
    if (currentApp === 'timer') {
      return <Timer />;
    }
    if (currentApp === 'camera') {
      return <Camera />;
    }
    if (currentApp === 'settings') {
      return <Settings />;
    }
    if (currentApp === 'facetime') {
      return <Facetime />;
    }
    if (currentApp === 'health') {
      return <Health />;
    }
    if (currentApp === 'calculator') {
      return <Calculator />;
    }
    if (currentApp === 'note' && isEnigmaPhoneEmotionMulti) {
      return <Note />;
    }

    let responsesSocket = {
      room,
    };

    return (
      <div className={styles.buttons}>
        <div className={styles.button}>
          <button
            type="button"
            className={`${styles.app} ${styles.grey}`}
            onClick={() => {
              responsesSocket = {
                current: 'camera',
                soundStatus: soundClickOpenContent,
                ...responsesSocket,
              };
              socket?.emit('send_emotion_multi_current_app_phone', responsesSocket);
              socket?.emit('send_emotion_multi_sound_click_phone', responsesSocket);
              setCurrentApp('camera');
              setSoundClick(soundClickOpenContent);
              setTimeout(() => {
                responsesSocket = {
                  soundStatus: soundClickCloseContent,
                  ...responsesSocket,
                };
                socket?.emit(
                  'send_emotion_multi_sound_click_phone',
                  responsesSocket
                );
                setSoundClick(soundClickCloseContent);
              });
            }}
          >
            <ImCamera size={30} style={{ color: '#2F2F31' }} />
          </button>
          <p>Caméra</p>
        </div>
        <div className={styles.button}>
          <button
            type="button"
            className={`${styles.app} ${styles.greylight}`}
            onClick={() => {
              responsesSocket = {
                current: 'calculator',
                soundStatus: soundClickOpenContent,
                ...responsesSocket,
              };
              socket?.emit('send_emotion_multi_current_app_phone', responsesSocket);
              socket?.emit('send_emotion_multi_sound_click_phone', responsesSocket);
              setCurrentApp('calculator');
              setSoundClick(soundClickOpenContent);
              setTimeout(() => {
                responsesSocket = {
                  soundStatus: soundClickCloseContent,
                  ...responsesSocket,
                };
                socket?.emit(
                  'send_emotion_multi_sound_click_phone',
                  responsesSocket
                );
                setSoundClick(soundClickCloseContent);
              });
            }}
          >
            <FcCalculator size={30} style={{ color: 'white' }} />
          </button>
          <p>Calculatrice</p>
        </div>
        {isEnigmaPhoneEmotionMulti && (
          <div className={styles.button}>
            <button
              type="button"
              className={`${styles.app} ${styles.bluelight}`}
              onClick={() => {
                responsesSocket = {
                  current: 'note',
                  soundStatus: soundClickOpenContent,
                  ...responsesSocket,
                };
                socket?.emit(
                  'send_emotion_multi_current_app_phone',
                  responsesSocket
                );
                socket?.emit(
                  'send_emotion_multi_sound_click_phone',
                  responsesSocket
                );
                setCurrentApp('note');
                setSoundClick(soundClickOpenContent);
                setTimeout(() => {
                  responsesSocket = {
                    soundStatus: soundClickCloseContent,
                    ...responsesSocket,
                  };
                  socket?.emit(
                    'send_emotion_multi_sound_click_phone',
                    responsesSocket
                  );
                  setSoundClick(soundClickCloseContent);
                });
              }}
            >
              <MdOutlineEventNote size={30} style={{ color: 'white' }} />
            </button>
            <p>{t('phone.note.title')}</p>
          </div>
        )}
        <div className={styles.button}>
          <button
            type="button"
            className={`${styles.app} ${styles.greenlight}`}
            onClick={() => {
              responsesSocket = {
                current: 'facetime',
                soundStatus: soundClickOpenContent,
                ...responsesSocket,
              };
              socket?.emit('send_emotion_multi_current_app_phone', responsesSocket);
              socket?.emit('send_emotion_multi_sound_click_phone', responsesSocket);
              setCurrentApp('facetime');
              setSoundClick(soundClickOpenContent);
              setTimeout(() => {
                responsesSocket = {
                  soundStatus: soundClickCloseContent,
                  ...responsesSocket,
                };
                socket?.emit(
                  'send_emotion_multi_sound_click_phone',
                  responsesSocket
                );
                setSoundClick(soundClickCloseContent);
              });
            }}
          >
            <GoDeviceCameraVideo size={30} style={{ color: 'white' }} />
          </button>
          <p>Facetime</p>
        </div>
        <div className={styles.button}>
          <button
            type="button"
            className={`${styles.app} ${styles.grey}`}
            onClick={() => {
              responsesSocket = {
                current: 'settings',
                soundStatus: soundClickOpenContent,
                ...responsesSocket,
              };
              socket?.emit('send_emotion_multi_current_app_phone', responsesSocket);
              socket?.emit('send_emotion_multi_sound_click_phone', responsesSocket);
              setCurrentApp('settings');
              setSoundClick(soundClickOpenContent);
              setTimeout(() => {
                responsesSocket = {
                  soundStatus: soundClickCloseContent,
                  ...responsesSocket,
                };
                socket?.emit(
                  'send_emotion_multi_sound_click_phone',
                  responsesSocket
                );
                setSoundClick(soundClickCloseContent);
              });
            }}
          >
            <GrSettingsOption size={30} style={{ color: '#2F2F31' }} />
          </button>
          <p>Réglages</p>
        </div>
        <div className={styles.button}>
          <button
            type="button"
            className={`${styles.app} ${styles.violet}`}
            onClick={() => {
              responsesSocket = {
                current: 'timer',
                soundStatus: soundClickOpenContent,
                ...responsesSocket,
              };
              socket?.emit('send_emotion_multi_current_app_phone', responsesSocket);
              socket?.emit('send_emotion_multi_sound_click_phone', responsesSocket);
              setCurrentApp('timer');
              setSoundClick(soundClickOpenContent);
              setTimeout(() => {
                responsesSocket = {
                  soundStatus: soundClickCloseContent,
                  ...responsesSocket,
                };
                socket?.emit(
                  'send_emotion_multi_sound_click_phone',
                  responsesSocket
                );
                setSoundClick(soundClickCloseContent);
              });
            }}
          >
            <GiSandsOfTime size={30} style={{ color: 'white' }} />
          </button>
          <p>Temps d&apos;écran</p>
        </div>
      </div>
    );
  };

  const handleFooter = () => {
    return (
      <div className={styles.footer}>
        <div className={styles.button}>
          <button
            type="button"
            className={`${styles.app} ${styles.white}`}
            onClick={() => {
              let responsesSocket = {
                room,
              };
              responsesSocket = {
                current: 'health',
                soundStatus: soundClickOpenContent,
                ...responsesSocket,
              };
              socket?.emit('send_emotion_multi_current_app_phone', responsesSocket);
              socket?.emit('send_emotion_multi_sound_click_phone', responsesSocket);
              setCurrentApp('health');
              setSoundClick(soundClickOpenContent);
              setTimeout(() => {
                responsesSocket = {
                  soundStatus: soundClickCloseContent,
                  ...responsesSocket,
                };
                socket?.emit(
                  'send_emotion_multi_sound_click_phone',
                  responsesSocket
                );
                setSoundClick(soundClickCloseContent);
              });
            }}
          >
            <AiFillHeart size={30} style={{ color: '#EB5781' }} />
          </button>
          <p>Santé</p>
        </div>
      </div>
    );
  };

  const handleHour = () => {
    switch (description.content.id) {
      case phoneEmotionMultiEnigma:
        return hourOfDesktop;

      default:
        return phoneHour;
    }
  };

  const handleUnlock = () => {
    return (
      <>
        <div className={styles.screenContent}>
          <div className={styles.screenHeader}>
            <GiNetworkBars size={20} />
            <div>{handleHour()}</div>
            <IoIosBatteryCharging size={20} />
          </div>
          {handleScreenContent()}
          {currentApp === null && handleFooter()}
        </div>
        <button
          type="button"
          className={styles.pad}
          aria-label="home"
          onClick={() => {
            let responsesSocket = {
              room,
            };
            responsesSocket = {
              current: null,
              soundStatus: soundClickOpenContent,
              ...responsesSocket,
            };
            socket?.emit('send_emotion_multi_current_app_phone', responsesSocket);
            socket?.emit('send_emotion_multi_sound_click_phone', responsesSocket);
            setCurrentApp(null);
            setSoundClick(soundClickOpenContent);
            setTimeout(() => {
              responsesSocket = {
                soundStatus: soundClickCloseContent,
                ...responsesSocket,
              };
              socket?.emit('send_emotion_multi_sound_click_phone', responsesSocket);
              setSoundClick(soundClickCloseContent);
            });
          }}
        />
      </>
    );
  };

  const handleLock = () => {
    const handleClick = (button) => {
      let responsesSocket;

      responsesSocket = {
        room,
      };

      const { innerText } = button.target;
      if (codeIphone.length > currentNumber.length) {
        // enter the code
        const newCurrentNumber = currentNumber + innerText;
        responsesSocket = {
          newCurrentNumber,
          ...responsesSocket,
        };
        socket?.emit('send_emotion_multi_current_number_phone', responsesSocket);
        setCurrentNumber(newCurrentNumber);
        const newArrayCode = arrayCode;
        newArrayCode[currentNumber.length] = innerText;
        responsesSocket = {
          newArrayCode,
          ...responsesSocket,
        };
        socket?.emit('send_emotion_multi_array_code_phone', responsesSocket);
        setArrayCode(newArrayCode);
      }
      handleLockCondition(currentNumber);
    };

    return (
      <div className={styles.lock}>
        <img
          alt="lock"
          src="https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Frooms%2Fbedroom%2Fobjects%2Fphone%2FVector-4.svg?alt=media&token=8f9f4b05-2691-4b58-b1aa-1f861a338b62"
        />
        <div className={styles.codes}>
          {arrayCode.map((codeElement) => {
            return (
              <div
                className={`${styles.points} ${
                  codeElement !== '' && styles.background
                }`}
              />
            );
          })}
        </div>
        <div className={styles.touchs}>
          {inputsPhone.map((number) => {
            return (
              <button
                type="button"
                onClick={(e) => handleClick(e)}
                className="darker"
              >
                {number}
              </button>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div className={styles.phone}>
      <Audio condition={soundClick && soundtrack} sound={click} />
      <div
        className={`${styles.enigma} ${
          isLock ? styles.enigmaLock : styles.enigmaUnlock
        }`}
      >
        <div className={styles.bar} />
        {isLock ? handleLock() : handleUnlock()}
      </div>
    </div>
  );
}

export default Phone;
