import { useTranslation } from 'react-i18next';

import styles from 'components/GameBoard/ReusableComponents/Enigma/Phone/App.module.scss';
import { codeVisio } from 'components/GameBoard/Emotion/Multi/constant';

const Note = () => {
  const { t } = useTranslation('emotion-multi');

  return (
    <div className={styles.app}>
      <p className={`${styles.title} ${styles.firstTitle}`}>
        {t('phone.note.title')}
      </p>
      <div className={styles.top}>
        <p className={styles.secondTitle}>
          {t('phone.note.description')}
          <strong> {codeVisio}</strong>
        </p>
      </div>
    </div>
  );
};

export default Note;
