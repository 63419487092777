const musicTheme = {
  'pensees-your-lips':
    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/musicTheme%2Fpensees-your-lips.ogg?alt=media&token=e1e40a5d-9ff9-472d-893d-c87f58f71067',
  'subsets-destinations':
    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/musicTheme%2Fsubsets-destinations1.ogg?alt=media&token=102cfde7-e19b-4385-9013-8ed6f76f83dc',
  'only-ukulele':
    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/musicTheme%2Fonly-ukulele.mp3?alt=media&token=a364ec5b-c1a3-4b78-a373-dde57671954f',
  'light-at-the-end':
    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/musicTheme%2Flight-at-the-end.mp3?alt=media&token=e3a619ef-706d-4af0-a5d4-69fdeaa14629',
  'ukelele-trip':
    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/musicTheme%2Fukulele-trip.mp3?alt=media&token=95642336-2fce-4621-9a1c-7e18967a3097',
  'just-a-little-child':
    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/musicTheme%2Fjust-a-little-child.mp3?alt=media&token=1b9a36c4-4a87-4605-aea0-126e12b5dde4',
  'upbeat-inspiring':
    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/musicTheme%2Fupbeat-inspiring.mp3?alt=media&token=cc833076-537f-42ef-8979-f1fca935dfc8',
};
export default musicTheme;
