import PropTypes from 'prop-types';
import Button from 'components/GameBoard/ReusableComponents/Actions/Button';
import styles from 'assets/css/components/GameBoard/ReusableComponents/Intro.module.scss';
import Audio from 'components/GameBoard/ReusableComponents/Actions/Audio';
import { useSelector } from 'react-redux';
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';
import { volumePersonna } from 'components/GameBoard/OffreSocle/constants';

/**
 * Generic component to display the intro messages
 */
function IntroMessage({
  title,
  message,
  buttonType,
  buttonTitle,
  clickFunction,
  backgroundImageUrl,
}) {
  const { music } = useSelector(infoGameUser);
  return (
    <div>
      <div className={styles['intro-message']}>
        {title && <h1>{title}</h1>}
        {message.audio && (
          <Audio
            sound={message.audio}
            id="MULTI-Theme"
            muted={!music}
            volume={volumePersonna}
          />
        )}
        <p>{message.text}</p>
      </div>
      <Button
        backgroundImageUrl={backgroundImageUrl}
        title={buttonTitle}
        buttonType={buttonType}
        onClick={clickFunction}
        additionalStyle={{ margin: '1rem' }}
      />
    </div>
  );
}

IntroMessage.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
  buttonType: PropTypes.string,
  clickFunction: PropTypes.func.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  backgroundImageUrl: PropTypes.string,
};

IntroMessage.defaultProps = {
  title: undefined,
  buttonType: 'action',
  backgroundImageUrl: undefined,
};

export default IntroMessage;
