import { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';

// selector functions
import { selectSession } from 'components/Reducers/session';

// components
import ScoreboardItem from 'components/GameBoard/ReusableComponents/Scoreboard/ScoreboardItem';

const BASE_URL = process.env.REACT_APP_BASE_URL;

function MyScoreboard({ status, gameId }) {
  const [players, setPlayers] = useState([]);
  const session = useSelector(selectSession);

  useEffect(() => {
    setTimeout(() => {
      axios
        .get(`${BASE_URL}/api/scores/groups/${gameId}?by=Score`)
        .then((response) => response.data)
        .then((data) => setPlayers(data));
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rankPlayer = players
    .filter((item) => item.session_id === session.id)
    .map((item) => item.ranking)[0];
  let rankPlayerStart = 1;
  let rankPlayerEnd = 1;

  if (rankPlayer - 2 < 0) {
    rankPlayerStart = 0;
  } else if (rankPlayer - 2 > 0 && rankPlayer + 2 > players.length) {
    rankPlayerStart = rankPlayer - (5 - (players.length - rankPlayer));
  } else {
    rankPlayerStart = rankPlayer - 2;
  }

  if (rankPlayer + 2 > players.length) {
    rankPlayerEnd = players.length;
  } else if (rankPlayer - 2 < 0 && rankPlayer + 2 < players.length) {
    rankPlayerEnd = rankPlayer + (5 - rankPlayer);
  } else {
    rankPlayerEnd = rankPlayer + 2;
  }

  return (
    <div className={status ? 'Show' : 'Hide'}>
      {players.length !== 0 ? (
        players
          .slice(rankPlayerStart, rankPlayerEnd)
          .map((item, index) => (
            <ScoreboardItem
              key={item.id}
              id={index}
              style={item.session_id === session.id}
              rank={item.ranking}
              pseudo={item.pseudo}
              count={item.score}
            />
          ))
      ) : (
        <div className="Loader-ScoreBoard">
          <div className="preloader">
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  gameId: state.game.id,
});

MyScoreboard.propTypes = {
  gameId: PropTypes.number.isRequired,
  status: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(MyScoreboard);
