import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import axios from 'axios';
import confetti from 'canvas-confetti';
import { useTranslation } from 'react-i18next';

// selector functions
import { selectSession } from 'components/Reducers/session';

// components
import Chevrons from 'components/GameBoard/ReusableComponents/Actions/Chevrons';
import Audio from 'components/GameBoard/ReusableComponents/Actions/Audio';
import Inventory from 'components/GameBoard/ReusableComponents/Inventory';
import Modal from 'components/GameBoard/ReusableComponents/Modal/Modal';
import Steps from 'components/GameBoard/ReusableComponents/Steps';
import ListObjects from 'components/GameBoard/ReusableComponents/Objects/ListObjects';
import Zoom from 'components/GameBoard/ReusableComponents/Modal/Zoom';
import ModalZoom from 'components/GameBoard/ReusableComponents/Modal/ModalZoom';
import ModalRobot from 'components/GameBoard/ReusableComponents/Modal/ModalRobot';
import ModalEnigma from 'components/GameBoard/ReusableComponents/Modal/ModalEnigma';
import ObjectInInventory from 'components/GameBoard/ReusableComponents/Actions/ObjectInInventory';
import Hud from 'components/GameBoard/ReusableComponents/HUD/Hud';
import Progression from 'components/GameBoard/RPS/Enigma/Progression';

// utils & constants
import useInitGame from 'utils/useInitGame';
import { useStartMessageOrQuestionary } from 'utils/utilityFunctions';
import modalBackgroundColor from 'components/GameBoard/RPS/constants';

// css
import 'assets/css/components/GameBoard/RPS/Rooms/BreakRoomRPS.css';
import { startedMessage } from 'components/constants';

import obesityTheme from '../../../../sound/Obesity/john meyer for the lonely2.ogg';
import click from '../../../../sound/click-ongame.mp3';

const BASE_URL = process.env.REACT_APP_BASE_URL;

function BreakRoomRPS({
  game,
  GameUsers,
  modalIsOpen,
  currentRoom,
  MouseClicked,
  dispatch,
  startMessage,
  currentStep,
  startQuestionnary,
  idSessionHasRoom,
  zoomIsOpen,
  modalZoomIsOpen,
  modalRobotIsOpen,
  modalEnigmaIsOpen,
  listRooms,
  progression,
}) {
  const [backgroundPosition, setBackgroundPosition] = useState('center');
  const session = useSelector(selectSession);

  useEffect(() => {
    dispatch({
      type: 'BACKGROUND_POSITION',
      payload: backgroundPosition,
    });
  }, [backgroundPosition, dispatch]);

  const randomInRange = (min, max) => {
    return Math.random() * (max - min) + min;
  };

  const urlRoom = listRooms.BreakRoomRPS.url;
  const { t } = useTranslation('rps');

  useInitGame(currentRoom, session.id, listRooms);
  // END INITIATE ROOM , INITIATE ALL STEPS AND OBJECTS OF THE ROOM, RETRIEVE ALL STEPS AND OBJECTS OF THE BDD
  const [placeObject, setPlaceObject] = useState([
    { key: 0, object: null, goodPlacementObject: false },
    { key: 1, object: null, goodPlacementObject: false },
    { key: 2, object: null, goodPlacementObject: false },
    { key: 3, object: null, goodPlacementObject: false },
    { key: 4, object: null, goodPlacementObject: false },
    { key: 5, object: null, goodPlacementObject: false },
    { key: 6, object: null, goodPlacementObject: false },
    { key: 7, object: null, goodPlacementObject: false },
  ]);

  const displayBackInventory = (idItem) => {
    dispatch({
      type: 'SHOW_ITEM',
      payload: idItem,
    });
  };

  const ConfirmDocBreakRoom = (key, newPlaceObject) => {
    switch (key) {
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
        if (
          newPlaceObject[0].goodPlacementObject === true &&
          newPlaceObject[1].goodPlacementObject === true &&
          newPlaceObject[2].goodPlacementObject === true &&
          newPlaceObject[3].goodPlacementObject === true &&
          newPlaceObject[4].goodPlacementObject === true &&
          newPlaceObject[5].goodPlacementObject === true &&
          newPlaceObject[6].goodPlacementObject === true &&
          newPlaceObject[7].goodPlacementObject === true
        ) {
          dispatch({
            type: 'CLOSE_MODAL_ENIGMA',
          });
          const duration = 1.5 * 200;
          const animationEnd = Date.now() + duration;
          const defaults = {
            startVelocity: 30,
            spread: 360,
            ticks: 60,
            zIndex: 0,
          };

          // eslint-disable-next-line consistent-return
          const interval = setInterval(function () {
            const timeLeft = animationEnd - Date.now();

            if (timeLeft <= 0) {
              return clearInterval(interval);
            }

            const particleCount = 50 * (timeLeft / duration);
            // since particles fall down, start a bit higher than random
            confetti({
              ...defaults,
              particleCount,
              origin: { x: randomInRange(0.1, 0.4), y: Math.random() - 0.2 },
            });
            confetti({
              ...defaults,
              particleCount,
              origin: { x: randomInRange(0.6, 0.9), y: Math.random() - 0.2 },
            });

            axios
              .put(
                `${process.env.REACT_APP_BASE_URL}/api/sessionsHasRoom/${idSessionHasRoom}/modifications`,
                {
                  current_step: 125,
                  start_message: startedMessage,
                }
              )
              .then(() =>
                dispatch({
                  type: 'CURRENT_STEP',
                  payload: 125,
                })
              );
          });
        }
        break;
      default:
        break;
    }
  };

  const checkUpBreakRoomDoc = (id, key) => {
    const newPlaceObject = placeObject;
    switch (key) {
      case 0:
      case 1:
        if ((Number(id) === 250 || Number(id) === 249) && Number(key) === 0) {
          newPlaceObject[0].goodPlacementObject = true;
          dispatch({ type: 'ADD_PROGRESSION' });
          setPlaceObject(newPlaceObject);
          break;
        } else if (id !== 250 && id !== 249 && Number(key) === 0)
          newPlaceObject[0].goodPlacementObject = false;
        if ((Number(id) === 250 || Number(id) === 249) && Number(key) === 1) {
          newPlaceObject[1].goodPlacementObject = true;
          dispatch({ type: 'ADD_PROGRESSION' });
          setPlaceObject(newPlaceObject);
        } else if (id !== 250 && id !== 249 && Number(key) === 1)
          newPlaceObject[1].goodPlacementObject = false;
        break;
      case 2:
      case 3:
        if ((Number(id) === 254 || Number(id) === 245) && Number(key) === 2) {
          newPlaceObject[2].goodPlacementObject = true;
          dispatch({ type: 'ADD_PROGRESSION' });
          setPlaceObject(newPlaceObject);
          break;
        } else if (id !== 254 && id !== 245 && Number(key) === 2)
          newPlaceObject[2].goodPlacementObject = false;
        if ((Number(id) === 254 || Number(id) === 245) && Number(key) === 3) {
          newPlaceObject[3].goodPlacementObject = true;
          dispatch({ type: 'ADD_PROGRESSION' });
          setPlaceObject(newPlaceObject);
          break;
        } else if (id !== 254 && id !== 245 && Number(key) === 3)
          newPlaceObject[3].goodPlacementObject = false;
        break;
      case 4:
      case 5:
        if ((Number(id) === 253 || Number(id) === 244) && Number(key) === 4) {
          newPlaceObject[4].goodPlacementObject = true;
          dispatch({ type: 'ADD_PROGRESSION' });
          setPlaceObject(newPlaceObject);
          break;
        } else if (id !== 253 && id !== 244 && Number(key) === 4)
          newPlaceObject[4].goodPlacementObject = false;
        if ((Number(id) === 253 || Number(id) === 244) && Number(key) === 5) {
          newPlaceObject[5].goodPlacementObject = true;
          dispatch({ type: 'ADD_PROGRESSION' });
          setPlaceObject(newPlaceObject);
          break;
        } else if (id !== 253 && id !== 244 && Number(key) === 5)
          newPlaceObject[5].goodPlacementObject = false;
        break;
      case 6:
      case 7:
        if ((Number(id) === 251 || Number(id) === 248) && Number(key) === 6) {
          newPlaceObject[6].goodPlacementObject = true;
          dispatch({ type: 'ADD_PROGRESSION' });
          setPlaceObject(newPlaceObject);
          break;
        } else if (id !== 251 && id !== 248 && Number(key) === 6)
          newPlaceObject[6].goodPlacementObject = false;

        if ((Number(id) === 251 || Number(id) === 248) && Number(key) === 7) {
          newPlaceObject[7].goodPlacementObject = true;
          dispatch({ type: 'ADD_PROGRESSION' });
          setPlaceObject(newPlaceObject);
          break;
        } else if (id !== 251 && id !== 248 && Number(key) === 7)
          newPlaceObject[7].goodPlacementObject = false;

        ConfirmDocBreakRoom(key, newPlaceObject);
        break;
      default:
        break;
    }
  };

  // Trigger end of the room.

  const HandleDrop = (e, key) => {
    e.preventDefault();
    const id = e.dataTransfer.getData('id');
    if (id) {
      if (placeObject[key].object) displayBackInventory(placeObject[key].object);
      dispatch({
        type: 'HIDE_ITEM',
        payload: id,
      });

      checkUpBreakRoomDoc(id, key);
      let newPlaceObject = placeObject;
      newPlaceObject = newPlaceObject.map((obj) => {
        if (Number(obj.key) === Number(key)) {
          // eslint-disable-next-line no-param-reassign
          obj.object = id;
        }
        return obj;
      });
      setPlaceObject(newPlaceObject);
    }
  };

  // COUNTER
  useEffect(() => {
    if (idSessionHasRoom) {
      axios.put(
        `${BASE_URL}/api/sessionsHasRoom/${idSessionHasRoom}/modifications`,
        {
          count: GameUsers.count,
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GameUsers.count]);

  // Progession treshold
  useEffect(() => {
    dispatch({ type: 'RESET_PROGRESSION' });
  }, [dispatch]);

  useEffect(() => {
    const duration = 1.5 * 1000;
    const animationEnd = Date.now() + duration;
    const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

    if (progression === 100) {
      const interval = setInterval(() => {
        const timeLeft = animationEnd - Date.now();

        if (timeLeft <= 0) {
          return clearInterval(interval);
        }

        const particleCount = 500 * (timeLeft / duration);
        // since particles fall down, start a bit higher than random
        confetti({
          ...defaults,
          particleCount,
          origin: { x: randomInRange(0.1, 0.4), y: Math.random() - 0.2 },
        });
        confetti({
          ...defaults,
          particleCount,
          origin: { x: randomInRange(0.6, 0.9), y: Math.random() - 0.2 },
        });

        return 0;
      }, 250);
    }
  }, [progression]);

  useStartMessageOrQuestionary(
    currentStep,
    idSessionHasRoom,
    startMessage,
    startQuestionnary
  );

  const clickOnGame = () => {
    dispatch({ type: 'INCREMENT_COUNTER' });
  };

  useEffect(() => {
    dispatch({
      type: 'CLICKED_MOUSE_DOWN',
    });
    setTimeout(() => {
      dispatch({
        type: 'CLICKED_MOUSE_UP',
      });
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GameUsers.count]);

  const updateMousePosition = (ev) => {
    dispatch({
      type: 'PLACE_CURSOR',
      payload: {
        x: ev.clientX,
        y: ev.clientY,
      },
    });
  };

  useEffect(() => {
    dispatch({
      type: 'OPEN_MODAL',
      payload: t('modalIntroBreakRoom', { returnObjects: true }),
    });
  }, [dispatch, t]);

  useEffect(() => {
    document
      .getElementById('BreakRoom-BKG')
      .addEventListener('mouseup', updateMousePosition);
    return () => window.removeEventListener('mouseup', updateMousePosition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="BreakRoomRPS" id="BreakRoom-BKG">
      <Audio
        sound={obesityTheme}
        loop
        id="Obesity-Theme"
        muted={!GameUsers.music}
      />
      <Audio sound={click} condition={!MouseClicked && GameUsers.soundtrack} />
      <div
        className={MouseClicked ? 'cursor-clicked' : 'Hide'}
        style={{ top: GameUsers.cursor.y, left: GameUsers.cursor.x }}
      >
        <p>+1</p>
      </div>
      {modalIsOpen && (
        <Modal game={game.id} modalBackgroundColor={modalBackgroundColor} />
      )}
      {modalZoomIsOpen && <ModalZoom />}
      {modalRobotIsOpen && <ModalRobot />}
      {modalEnigmaIsOpen && (
        <ModalEnigma
          placeObject={placeObject}
          setPlaceObject={setPlaceObject}
          HandleDrop={HandleDrop}
        />
      )}
      {zoomIsOpen && <Zoom />}
      <header>
        <Steps />
        <Hud />
      </header>
      <div
        className={`${backgroundPosition} BreakRoom-img-div`}
        onClick={() => clickOnGame()}
        role="presentation"
      >
        <img src={urlRoom} alt="Salle de repos" draggable="false" />
        <ListObjects />
      </div>
      <div className="Acomplissement">
        <div className="Acomplissement-bar">
          <Progression />
        </div>
        <div className="BottomInventory">
          <ObjectInInventory />
          <Inventory />
        </div>
      </div>
      <p />
      <Chevrons position={backgroundPosition} setPosition={setBackgroundPosition} />
    </div>
  );
}

const mapStateToProps = (state) => ({
  game: state.game,
  modalIsOpen: state.Modal.modal.isOpen,
  currentRoom: state.Room.currentRoomId,
  idSessionHasRoom: state.GameUsers.idSessionHasRoom,
  startMessage: state.Steps.startMessage,
  currentStep: state.Steps.currentStep,
  startQuestionnary: state.Steps.startQuestionnary,
  zoomIsOpen: state.Modal.zoom.isOpen,
  GameUsers: state.GameUsers,
  modalZoomIsOpen: state.Modal.modalZoom.isOpen,
  allObjects: state.Objects.AllObjects,
  modalRobotIsOpen: state.Modal.modalRobot.isOpen,
  modalEnigmaIsOpen: state.Modal.modalEnigma.isOpen,
  MouseClicked: state.GameUsers.clicked,
  listRooms: state.Room.listRooms,
  progression: state.RPS.dataGame.progression,
  choiceDoc: state.RPS.dataGame.choice,
});

BreakRoomRPS.propTypes = {
  MouseClicked: PropTypes.bool.isRequired,
  game: PropTypes.shape({
    photo: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
  GameUsers: PropTypes.shape({
    soundtrack: PropTypes.bool,
    music: PropTypes.bool,
    count: PropTypes.number,
    cursor: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number,
    }),
  }).isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  currentRoom: PropTypes.number.isRequired,
  idSessionHasRoom: PropTypes.number.isRequired,
  startMessage: PropTypes.bool.isRequired,
  currentStep: PropTypes.shape({
    id: PropTypes.number,
  }),
  startQuestionnary: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  zoomIsOpen: PropTypes.bool.isRequired,
  modalZoomIsOpen: PropTypes.bool.isRequired,
  modalRobotIsOpen: PropTypes.bool.isRequired,
  modalEnigmaIsOpen: PropTypes.bool.isRequired,
  listRooms: PropTypes.shape({
    BreakRoomRPS: PropTypes.shape({
      url: PropTypes.string,
    }),
  }).isRequired,
  progression: PropTypes.number.isRequired,
};

BreakRoomRPS.defaultProps = {
  currentStep: null,
};

export default connect(mapStateToProps)(BreakRoomRPS);
