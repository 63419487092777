import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// components
import Button from 'components/GameBoard/ReusableComponents/Actions/Button';
import LightEnigma from 'components/GameBoard/Emotion/Multi/Enigma/LightEnigma/LightEnigma';

// selectors
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';
import { selectModalZoom } from 'components/Reducers/Modal/Modal';
import { selectSession } from 'components/Reducers/session';

// scss
import styles from 'components/GameBoard/Emotion/Multi/Enigma/LightEnigma/Spot.module.scss';

// utils
import { animationConfetti, changeStatusOfObject } from 'utils/utilityFunctions';

// constants
import { listSpotlight } from 'components/GameBoard/Emotion/Multi/constant';

const Spot = () => {
  const { t } = useTranslation(['emotion-multi', 'common']);
  const dispatch = useDispatch();

  const session = useSelector(selectSession);
  const { socket } = useSelector(infoGameUser);
  const room = session.id;
  const { description } = useSelector(selectModalZoom);
  const { idSessionHasRoom } = useSelector(infoGameUser);

  const [errorMessage, setErrorMessage] = useState(null);
  const [spotlight, setSpotlight] = useState(null);

  const handleMessage = () => {
    let message;
    if (description?.objectContent.images) {
      const objectIndex = description?.objectContent.images.findIndex(
        (object) => object.type === 'clicked'
      );
      message = description?.objectContent.images[objectIndex].message;
    }

    return message;
  };

  const resetChoice = () => {
    setErrorMessage(null);
    setSpotlight(null);
  };

  const submitSpot = () => {
    resetChoice();

    const status = null;
    changeStatusOfObject(
      description.objectContent,
      status,
      idSessionHasRoom,
      dispatch,
      socket,
      room
    );

    if (spotlight === 'red') {
      const responsesSocket = {
        room,
      };
      socket?.emit('send_close_modal_zoom', responsesSocket);
      dispatch({
        type: 'CLOSE_MODAL_ZOOM',
      });

      socket?.emit('send_start_message_prevention', responsesSocket);
      dispatch({
        type: 'START_MESSAGE_PREVENTION',
      });

      return animationConfetti();
    }
    const errorContent = t('spotlight.error');
    return setErrorMessage(errorContent);
  };

  const handleSpotlight = (spot) => {
    setSpotlight(spot.slug);
    const status = spot.slug;
    changeStatusOfObject(
      description.objectContent,
      status,
      idSessionHasRoom,
      dispatch,
      socket,
      room
    );
  };

  return (
    <div className={styles.spot}>
      <div className={styles.description}>
        <p>{errorMessage || handleMessage()}</p>
      </div>
      <div className={styles.remote}>
        <div className={styles.buttons}>
          {listSpotlight.map((spot) => {
            return (
              <button
                key={spot.slug}
                type="button"
                className={`${styles.action} ${styles[`${spot.slug}`]}`}
                onClick={() => handleSpotlight(spot)}
                aria-label={t('buttonFunction.validate', { ns: 'common' })}
              />
            );
          })}
        </div>
        <Button
          type="action"
          onClick={() => (errorMessage ? resetChoice() : submitSpot())}
          title={
            errorMessage
              ? t('buttonFunction.retry', { ns: 'common' })
              : t('buttonFunction.validate', { ns: 'common' })
          }
        />
      </div>
      <LightEnigma />
    </div>
  );
};

export default Spot;
