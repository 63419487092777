import { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectSession } from 'components/Reducers/session';
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';

import CrosswordBoxe from 'components/GameBoard/Emotion/Multi/Enigma/Crossword/CrosswordBoxe';

import 'components/GameBoard/Emotion/Multi/Enigma/Crossword/CrosswordGame.css';

const CrosswordBoxes = ({ clues, grid, setGrid, handleFinishCrossword }) => {
  const session = useSelector(selectSession);
  const { socket } = useSelector(infoGameUser);
  const room = session.id;

  const initClueData = (data) => {
    return {
      answer: data.answer,
      correct: 0,
      solved: data.number === 0,
    };
  };
  const [answers, setAnswers] = useState({
    across: clues.across.map((clue) => {
      return initClueData(clue);
    }),
    down: clues.down.map((clue) => {
      return initClueData(clue);
    }),
  });

  const [correctLetters, setCorrectLetters] = useState(0);

  const handleCorrectGuess = (cluesToUpdate, event) => {
    const newCluesToUpdate = cluesToUpdate;
    newCluesToUpdate.map((clue) => {
      const clueIndex = clue.number - 1;

      const newAnswers = { ...answers };
      newAnswers[clue.direction][clueIndex].correct += 1;
      if (
        newAnswers[clue.direction][clueIndex].correct ===
        newAnswers[clue.direction][clueIndex].answer.length
      ) {
        newAnswers[clue.direction][clueIndex].solved = true;
      }
      return setAnswers(newAnswers);
    });
    const acrossWordFinish = answers.across.find((clue) => !clue.solved);
    const downWordFinish = answers.down.find((clue) => !clue.solved);

    if (downWordFinish === undefined && acrossWordFinish === undefined) {
      const responsesSocket = {
        room,
      };
      socket?.emit('send_emotion_multi_is_finish_crossword', responsesSocket);
      handleFinishCrossword(event);
    }
  };

  const handleIncorrectGuess = () => {
    if (correctLetters > 0) {
      setCorrectLetters(correctLetters - 1);
    }
  };

  return (
    <div className="boxes">
      {grid.map((box) => (
        <CrosswordBoxe
          key={box.id}
          box={box}
          answers={answers}
          onCorrect={handleCorrectGuess}
          onIncorrect={handleIncorrectGuess}
          setGrid={setGrid}
          grid={grid}
        />
      ))}
    </div>
  );
};

CrosswordBoxes.propTypes = {
  clues: PropTypes.shape({
    across: PropTypes.arrayOf(PropTypes.objectOf),
    down: PropTypes.arrayOf(PropTypes.objectOf),
  }).isRequired,
  grid: PropTypes.arrayOf(PropTypes.objectOf).isRequired,
  setGrid: PropTypes.func.isRequired,
  handleFinishCrossword: PropTypes.func.isRequired,
};

export default CrosswordBoxes;
