import { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';

// selector functions
import { selectSession } from 'components/Reducers/session';

// components
import ScoreboardItem from 'components/GameBoard/ReusableComponents/Scoreboard/ScoreboardItem';

const BASE_URL = process.env.REACT_APP_BASE_URL;

function ScoreboardTop10({ status, gameId }) {
  const [players, setPlayers] = useState([]);
  const session = useSelector(selectSession);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/scores/top5/${gameId}`)
      .then((response) => response.data)
      .then((data) => setPlayers(data));
  }, [gameId]);

  return (
    <div className={status ? 'Show' : 'Hide'}>
      {players.length !== 0 &&
        players
          .slice(0, 5)
          .map((item, index) => (
            <ScoreboardItem
              key={item.id}
              id={index}
              style={item.session_id === session.id}
              rank={item.ranking}
              pseudo={item.pseudo}
              count={item.score}
              time={item.time}
            />
          ))}
    </div>
  );
}

const mapStateToProps = (state) => ({
  gameId: state.game.id,
});

ScoreboardTop10.propTypes = {
  gameId: PropTypes.number.isRequired,
  status: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(ScoreboardTop10);
