import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import axios from 'axios';
import { motion } from 'framer-motion';

// utils
import { retrieveQuestionnary } from 'utils/utilityFunctions';

// css
import 'assets/css/components/GameBoard/ReusableComponents/Steps.css';
import { selectSteps } from 'components/Reducers/Steps/Steps';
import { selectInfoGame } from 'components/Reducers/game';
import { selectModal } from 'components/Reducers/Modal/Modal';

function Steps({
  steps,
  dispatch,
  currentStep,
  startQuestionnary,
  listRoom,
  gameUsers,
  gameId,
  userId,
  isAdvice,
  session,
  stepsLoading,
}) {
  const { messages, isScoreBoard } = useSelector(selectSteps);
  const game = useSelector(selectInfoGame);

  const modalIsOpen = useSelector(selectModal).modal.isOpen;

  useEffect(() => {
    // when a user finish a room, we need to retrieve
    // if it's the last room and if all steps are found of all gmae
    const currentStepFinish = steps.list.filter((step) => !step.isFound);
    const indexLastStep = steps.list.length - 1;
    const lastStepsFinish = steps?.list[indexLastStep]?.isFound;

    const lastRoomIndex = Object.keys(listRoom).length - 1;
    const isLastRoomActive =
      listRoom[Object.keys(listRoom)[lastRoomIndex]].isActive;
    const indexLastMessage = messages.length - 1;
    const finalMessageId = messages[indexLastMessage].id;
    if (
      currentStepFinish.length === 0 &&
      steps.list.length !== 0 &&
      startQuestionnary === 0 &&
      isLastRoomActive &&
      lastStepsFinish &&
      finalMessageId === steps.list[indexLastStep].id
    ) {
      if (!isAdvice && !stepsLoading) {
        dispatch({
          type: 'PAUSE_TIMER',
          payload: true,
        });
        const url = `${process.env.REACT_APP_BASE_URL}/api/scores/scoreboard`;
        const dataScoreboard = {
          score: gameUsers.gameScore.present,
          count: gameUsers.count,
          game_id: gameId,
          session_id: session.id,
          user_id: userId,
          pseudo: gameUsers.userDescription.firstname,
          time: gameUsers.time,
        };

        if (gameUsers.time !== '00:00:00' || gameUsers.time !== 0) {
          axios.post(url, dataScoreboard);
        }
        // TO FINISH START EVALUATION
        // if (game.game_type === 'multi') {
        //   if (!isAdvice && !isScoreBoard) {
        //     dispatch({
        //       type: 'START_EVALUATION',
        //     });
        //   }
        // } else
        if (!isAdvice) {
          dispatch({
            type: 'START_SCOREBOARD',
          });
        }
      }
    }
  }, [
    dispatch,
    game.game_type,
    gameId,
    gameUsers.count,
    gameUsers.gameScore.present,
    gameUsers.time,
    gameUsers.userDescription.firstname,
    isAdvice,
    isScoreBoard,
    listRoom,
    messages,
    session.id,
    startQuestionnary,
    steps.list,
    stepsLoading,
    userId,
  ]);

  // START QUESTIONNARY OR PREVENTION MESSAGE //
  useEffect(() => {
    if (currentStep && currentStep.messages) {
      const step = steps.list.find(
        (stepElement) => stepElement.id === currentStep.id
      );
      const isMessage = step.startMessage;
      const isQuestionnary = step.startQuestionnary;
      const { isFound } = step;
      if (isMessage && !isQuestionnary && !isFound && !modalIsOpen) {
        dispatch({
          type: 'PAUSE_TIMER',
          payload: true,
        });

        dispatch({
          type: 'START_MESSAGE_PREVENTION',
        });
      }

      if (steps.startQuestionnary) {
        retrieveQuestionnary(currentStep.id, dispatch);
      }

      /* IsMessage is set to 0 when isQuestionnary is set to 1 so the condition
         seems never filled
           TODO refacto */
      if (isMessage && isQuestionnary && !isFound) {
        retrieveQuestionnary(currentStep.id, dispatch, 'start');
      }

      if (isMessage && isQuestionnary && !isFound) {
        dispatch({
          type: 'PAUSE_TIMER',
          payload: true,
        });
        dispatch({
          type: 'START_QUESTIONNARY',
        });
      }
    }
  }, [
    currentStep,
    dispatch,
    modalIsOpen,
    startQuestionnary,
    steps?.list,
    steps?.startQuestionnary,
  ]);
  // END START QUESTIONNARY OR PREVENTION MESSAGE //

  const handleImage = (description) => {
    if (description.isFound) {
      return description.imageInventory;
    }
    return description.imageNotFound;
  };

  const handleImageZoom = (description) => {
    if (description.isFound) {
      return description.imageFoundZoom;
    }
    return description.imageNotFound;
  };

  const openModal = (dataModal) => {
    dispatch({
      type: 'OPEN_MODAL',
      payload: dataModal,
    });
  };

  return (
    <motion.div className="Steps">
      <div className="Steps-content">
        <p>Etapes</p>
        {steps.list.map((step) => {
          const dataModal = {
            image: handleImageZoom(step),
          };
          return (
            <button
              key={step.id}
              className="Steps-content-card"
              type="button"
              onClick={() => openModal(dataModal)}
            >
              <img src={handleImage(step)} alt={step.title} key={step.id} />
            </button>
          );
        })}
      </div>
    </motion.div>
  );
}

const mapStateToProps = (state) => ({
  steps: state.Steps,
  currentRoom: state.Room.currentRoomId,
  idSessionHasRoom: state.GameUsers.idSessionHasRoom,
  currentStep: state.Steps.currentStep,
  questions: state.questionnary.list,
  startQuestionnary: state.Steps.startQuestionnary,
  listRoom: state.Room.listRooms,
  gameUsers: state.GameUsers,
  gameId: state.game.id,
  userId: state.user.id,
  isAdvice: state.Steps.isAdvice,
  timerRoom: state.GameUsers.time,
  session: state.session,
  stepsLoading: state.Steps.loading,
});

Steps.propTypes = {
  stepsLoading: PropTypes.bool.isRequired,
  steps: PropTypes.shape({
    list: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        isFound: PropTypes.number,
      })
    ),
    startQuestionnary: PropTypes.bool,
  }).isRequired,
  currentStep: PropTypes.shape({
    isFound: PropTypes.number,
    messages: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
    id: PropTypes.number,
  }),
  dispatch: PropTypes.func.isRequired,
  startQuestionnary: PropTypes.number.isRequired,
  listRoom: PropTypes.shape({}).isRequired,
  gameUsers: PropTypes.shape({
    gameScore: PropTypes.objectOf(PropTypes.number),
    count: PropTypes.number,
    time: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    userDescription: PropTypes.shape({
      firstname: PropTypes.string,
    }),
  }).isRequired,
  gameId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  isAdvice: PropTypes.bool.isRequired,
  session: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};

Steps.defaultProps = {
  currentStep: null,
};

export default connect(mapStateToProps)(Steps);
