import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// components
import Button from 'components/GameBoard/ReusableComponents/Actions/Button';
import PlutchikWheel from 'components/GameBoard/ReusableComponents/PlutchikWheel';

// selectors
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';

// scss
import styles from 'components/GameBoard/ReusableComponents/Multi/Shared/Questionnary/Type/PlutchikWheelType/PlutchikWheelType.module.scss';

function PlutchikWheelType({
  question,
  activeIndex,
  handleAnswer,
  handleReturn,
  doubleResponses,
  isStoryline,
}) {
  const { t } = useTranslation('common');

  const { socket } = useSelector(infoGameUser);

  const [clickedPlutchikEmotion, setClickedPlutchikEmotion] = useState(null);
  const [secondClickedPlutchikEmotion, setSecondClickedPlutchikEmotion] =
    useState(null);
  const [isWaiting, setIsWaiting] = useState(false);

  useEffect(() => {
    socket?.on('receive_is_waiting', (data) => {
      setIsWaiting(data.isWait);
    });
    socket?.on('receive_reser_clicked_emotion_plutchickwheel', () => {
      setClickedPlutchikEmotion(null);
      setSecondClickedPlutchikEmotion(null);
    });
  }, [socket]);

  const handleResponse = (event) => {
    if (doubleResponses) {
      if (clickedPlutchikEmotion) {
        return setSecondClickedPlutchikEmotion(event);
      }
    }
    return setClickedPlutchikEmotion(event);
  };

  const handleNext = () => {
    if (
      (doubleResponses && clickedPlutchikEmotion && secondClickedPlutchikEmotion) ||
      (clickedPlutchikEmotion && !doubleResponses)
    ) {
      return (
        <Button
          onClick={() => {
            handleAnswer(
              clickedPlutchikEmotion,
              question,
              null,
              secondClickedPlutchikEmotion,
              setClickedPlutchikEmotion,
              setSecondClickedPlutchikEmotion
            );
            setIsWaiting(true);
          }}
          title={t('buttonFunction.next')}
          buttonType="action"
        />
      );
    }
    return null;
  };

  const handleResponses = () => {
    return (
      <div className={styles.responses}>
        {clickedPlutchikEmotion && (
          <div className={styles.response}>
            <p>{clickedPlutchikEmotion?.title}</p>
            <Button
              buttonType="action"
              title="X"
              onClick={() => setClickedPlutchikEmotion(null)}
            />
          </div>
        )}
        {secondClickedPlutchikEmotion && (
          <div className={styles.response}>
            <p>{secondClickedPlutchikEmotion?.title}</p>
            <Button
              buttonType="action"
              title="X"
              onClick={() => setSecondClickedPlutchikEmotion(null)}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={styles['plutchik-wheel-type']}>
      <h4>{question.title}</h4>
      <div className={`${styles.content} ${isStoryline && styles.storyline}`}>
        <div className={styles.card}>
          {question.image && (
            <img
              className={`${doubleResponses && styles.double}`}
              src={question.image}
              alt="emotion react"
            />
          )}
          {doubleResponses && handleResponses()}
        </div>
        <PlutchikWheel
          setSelectedEmotion={(event) => handleResponse(event)}
          responses={question.responses}
          primaryDyads={isStoryline}
          combinePetals={!isStoryline}
        />
      </div>
      {activeIndex !== 0 && (
        <Button
          buttonType="action"
          title={t('buttonFunction.return')}
          onClick={() => handleReturn()}
        />
      )}
      {isWaiting && isStoryline && <p>{t('multi.visio.waitFriend')}</p>}
      {handleNext()}
    </div>
  );
}

PlutchikWheelType.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    image: PropTypes.string,
    is_debriefing: PropTypes.number,
    level: PropTypes.number,
    maximum: PropTypes.string,
    minimum: PropTypes.string,
    response_type: PropTypes.string,
    responses: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        level: PropTypes.number,
        weighting: PropTypes.number,
      })
    ),
  }).isRequired,
  activeIndex: PropTypes.number.isRequired,
  handleAnswer: PropTypes.func.isRequired,
  handleReturn: PropTypes.func.isRequired,
  doubleResponses: PropTypes.bool,
  isStoryline: PropTypes.bool,
};

PlutchikWheelType.defaultProps = {
  doubleResponses: false,
  isStoryline: false,
};

export default PlutchikWheelType;
