import styles from 'components/Joyride/Steps.module.scss';
import backgroundMovingSteps from 'components/Joyride/constants/backgroundMovingSteps';
import commonSteps from './constants/commonSteps';
import inventoryStep from './constants/inventoryStep';
import chatbotStep from './constants/chatbotStep';

/**
 * Tutorial steps for Obesity game
 * @param {func} t -translation function from i18next
 * @returns {Object[]}
 */
const stepsObesity = (t) => {
  // get constants steps from constants file
  const inventoryImportedStep = inventoryStep(t);
  const chatbotImportedStep = chatbotStep(t);
  const commonImportedSteps = commonSteps(
    t,
    `dans la ${t('room1', { ns: 'obesity' })}`,
    `d'${t('narrator', { ns: 'obesity' })}`,
    t('mission', { ns: 'obesity' })
  );
  const backgroundMovingImportedSteps = backgroundMovingSteps(
    t,
    `dans la ${t('room1', { ns: 'obesity' })}`
  );

  // Insert the background moving steps (chevrons) into the common steps
  backgroundMovingImportedSteps.forEach((step) => {
    commonImportedSteps.splice(commonImportedSteps.length - 1, 0, step);
  });

  // Insert step presentation robot Alexo
  commonImportedSteps.splice(1, 0, {
    target: 'body',
    content: (
      <div className={styles['main-content']}>
        <h2>Voici {t('narrator', { ns: 'obesity' })}</h2>
        <p>Le robot d&apos;{t('character', { ns: 'obesity' })}</p>
        <div className={styles['main-content']}>
          <img
            style={{ margin: 20, width: 100 }}
            src="https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FObesity%2Fassets%2Fobjects%2Frobot.svg?alt=media&token=30b58f7e-f4f1-4fd4-95bb-936360d3278e"
            alt="Tricky"
          />
        </div>{' '}
      </div>
    ),
    placement: 'center',
  });

  // Insert inventory step in commons steps
  commonImportedSteps.splice(2, 0, inventoryImportedStep);
  // Insert Chatbot step in commons steps
  commonImportedSteps.splice(
    commonImportedSteps.length - 1,
    0,
    chatbotImportedStep
  );
  return commonImportedSteps;
};
export default stepsObesity;
