import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// utils
import { playSound } from 'utils/utilityFunctions';

// selectors
import { getResponses } from 'API/questionnary';
// assets
import 'assets/css/components/GameBoard/ReusableComponents/Questionnary/Note.css';
import * as images from 'img/vaccination-solo/index';

const Note = ({ index, currentQuestionId, handleAnswer }) => {
  const [arrayResponses, setArrayResponses] = useState([]);
  useEffect(() => {
    async function fetchResponses() {
      const data = await getResponses(currentQuestionId);
      setArrayResponses(data);
    }
    fetchResponses();
  }, [currentQuestionId]);

  const [indexNote, setIndexNote] = useState(null);
  const radioButtonSound =
    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FCommun%2Fsounds%2Fnote-bip.mp3?alt=media&token=fa9cec21-467a-4ddd-9acf-9cab87288b8e';

  return (
    <div className="Note" key={index}>
      {arrayResponses.map((responseContent) => {
        return (
          <button
            type="button"
            onClick={() => {
              playSound(radioButtonSound);
              handleAnswer(responseContent.id, responseContent);
              setIndexNote(responseContent.id);
            }}
            key={responseContent.id}
            className="Note_content"
          >
            <img
              src={
                indexNote === responseContent.id
                  ? images.radioClicked
                  : images.radioIdle
              }
              role="presentation"
              alt="radioIdle"
              draggable="false"
            />
            <input
              type="radio"
              id={responseContent.id}
              name={responseContent.title}
              value={responseContent.id}
            />
            <label htmlFor={responseContent.id}>{responseContent.title}</label>
          </button>
        );
      })}
    </div>
  );
};

Note.propTypes = {
  answers: PropTypes.shape({
    response_id: PropTypes.number,
  }).isRequired,
  currentQuestionId: PropTypes.number,
  index: PropTypes.number.isRequired,
  handleAnswer: PropTypes.func.isRequired,
};

Note.defaultProps = {
  currentQuestionId: null,
};

export default Note;
