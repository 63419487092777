import { useSelector } from 'react-redux';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import LockerCode from 'components/GameBoard/OffreSocle/Enigmas/LockerCode';
import OffreSocleModal from 'components/GameBoard/OffreSocle/Enigmas/OffreSocleModal';
import { referringDoctorOffice } from 'components/GameBoard/OffreSocle/constants';
import { selectOffreSocle } from 'components/Reducers/offreSocle';

const { padlock } = referringDoctorOffice;

const Locker = ({ objectClicked, setObjectClicked }) => {
  const { t } = useTranslation('offreSocle', 'common');

  const lockerMessage = t('padlock', { returnObjects: true });
  const { status } = useSelector(selectOffreSocle).locker[objectClicked.id];

  const [isUnlockClicked, setIsUnlockClicked] = useState(false);

  return !isUnlockClicked && status === 'unanswered' ? (
    <OffreSocleModal
      objectImage={padlock}
      objectMessage={lockerMessage}
      onClick={() => setIsUnlockClicked(true)}
      buttonTitle={t('buttonFunction.unlock', { ns: 'common' })}
    />
  ) : (
    <LockerCode objectClicked={objectClicked} setObjectClicked={setObjectClicked} />
  );
};

Locker.propTypes = {
  objectClicked: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  setObjectClicked: PropTypes.func.isRequired,
};

export default Locker;
