import { BiRun } from 'react-icons/bi';
import { GiPartyPopper, GiMuscleUp } from 'react-icons/gi';
// TO REFACTO SCSS AND TRANSLATION
import 'assets/css/components/GameBoard/Obesity/Enigma/Phone/Health.css';

function Health() {
  return (
    <div className="Health">
      <p className="Health_title Health_firstTitle">Santé</p>
      <div className="Health_bottom">
        <p className="Health_title Health_secondTitle">Mon activité</p>
        <div className="Health_bottom-contents">
          <img
            className="Health_bottom-contents-graph"
            src="https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FObesity%2Fassets%2Fobjects%2Fhealth.png?alt=media&token=6e9c411a-b539-4f73-be12-7e2d7e470f0f"
            alt="health activity"
          />
          <div className="Health_botton-contents-description">
            <p className="Health_botton-contents-title">
              Taux d&apos;activité par rapport au mois précédent.
            </p>
            <p className="Health_botton-contents-subtitle Health_title">
              NOVEMBRE 2018
            </p>
            <div className="Health_content">
              <p className="Health_title">Régression de 45%</p>
            </div>
          </div>
        </div>
        <div className="Health_bar" />
        <div className="Health_activities">
          <div className="Health_activity">
            <BiRun size={30} style={{ color: 'white' }} />
            <p>course</p>
            <p className="Health_activity-pourcent">45%</p>
          </div>
          <div className="Health_activity">
            <GiPartyPopper size={30} style={{ color: 'white' }} />
            <p>sorties</p>
            <p className="Health_activity-pourcent">25%</p>
          </div>

          <div className="Health_activity">
            <GiMuscleUp size={30} style={{ color: 'white' }} />
            <p>musculation</p>
            <p className="Health_activity-pourcent">30%</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Health;
