import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// components
import DrawerOffice from 'components/GameBoard/Emotion/Multi/Enigma/Drawer/Drawer';
import DrawerDuck from 'components/GameBoard/TDE/Enigma/DrawerDuck';
import Lamp from 'components/GameBoard/TDE/Enigma/Lamp';
import Code from 'components/GameBoard/TDE/Enigma/Code';
import DrawerMorse from 'components/GameBoard/TDE/Enigma/DrawerMorse';
import RemoteControl from 'components/GameBoard/TDE/Enigma/RemoteControl';
import Grass from 'components/GameBoard/TDE/Enigma/Grass';
import Hatch from 'components/GameBoard/TDE/Enigma/Hatch';
import Button from 'components/GameBoard/ReusableComponents/Actions/Button';
import Spot from 'components/GameBoard/Emotion/Multi/Enigma/LightEnigma/Spot';

// constants
import {
  deskOfficeMultiEmotionEnigma,
  remoteSpotBedroomEmotionMultiEnigma,
} from 'components/GameBoard/ReusableComponents/Objects/constants';

// assets
import 'assets/css/components/GameBoard/ReusableComponents/Modal/ModalZoom.css';

// selectors
import { selectSession } from 'components/Reducers/session';
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';

const idDrawerDuck = 141;
const idLamp = 147;
const idCodeLight = 149;
const idDrawerMorse = 159;
const idRemoteControl = 163;
const idGrass = 180;
const idScreenFridge = 192;
const idHatch = 223;

function ModalZoom({
  dispatch,
  image,
  objectContent,
  description,
  buttonDescription,
}) {
  const { t } = useTranslation('common');

  const session = useSelector(selectSession);
  const { socket } = useSelector(infoGameUser);
  const room = session.id;

  const handleObjectContent = () => {
    let messageContent;
    let imageContent;
    let altMessageContent;

    if (objectContent.images) {
      const objectIndex = objectContent.images.findIndex(
        (objectElement) => objectElement.type === 'clicked'
      );

      messageContent = objectContent.images[objectIndex].message;
      imageContent = objectContent.images[objectIndex].image;
      altMessageContent = objectContent.images[objectIndex].name;

      return (
        <div className="Modal_description-content">
          <div className="Modal_description-text">
            <p>{messageContent}</p>
          </div>
          <div className="Modal_description-image">
            <img src={imageContent} alt={altMessageContent} />
          </div>
        </div>
      );
    }

    return null;
  };
  const handleGame = () => {
    switch (objectContent.id) {
      case idDrawerDuck:
        return <DrawerDuck objectContent={objectContent} image={image} />;
      case idLamp:
        return <Lamp objectContent={objectContent} image={image} />;
      case idCodeLight:
        return <Code objectContent={objectContent} image={image} />;
      case idDrawerMorse:
        return <DrawerMorse objectContent={objectContent} image={image} />;
      case idRemoteControl:
        return (
          <RemoteControl
            objectContent={objectContent}
            image={image}
            description={description}
          />
        );
      case idGrass:
        return <Grass objectContent={objectContent} image={image} />;
      case idHatch:
        return <Hatch objectContent={objectContent} image={image} />;
      case idScreenFridge:
        return <div>ok</div>;
      case deskOfficeMultiEmotionEnigma:
        return <DrawerOffice />;
      case remoteSpotBedroomEmotionMultiEnigma:
        return <Spot />;
      default:
        return handleObjectContent();
    }
  };
  const responsesSocket = { room };

  const handleCloseModalButton = () => {
    socket?.emit('send_close_modal_zoom', responsesSocket);
    socket?.emit('send_close_zoom', responsesSocket);
    dispatch({
      type: 'CLOSE_MODAL_ZOOM',
    });
    dispatch({
      type: 'CLOSE_ZOOM',
    });
  };

  return (
    <div
      className={`Modal${
        objectContent.id === idRemoteControl ||
        objectContent.id === remoteSpotBedroomEmotionMultiEnigma
          ? 'NotZoom'
          : 'Zoom'
      }`}
    >
      <div className="ModalZoom_description-content">
        <div className="ModalZoom_description-image">{handleGame()}</div>
      </div>
      <div>
        <Button
          className="ButtonAction Return"
          title={t('buttonFunction.return')}
          buttonType="return"
          onClick={() => handleCloseModalButton()}
        />
        {buttonDescription && (
          <Button
            buttonType="action"
            title={buttonDescription.title}
            onClick={() => {
              buttonDescription.onClick();
              handleCloseModalButton();
            }}
          />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  image: state.Modal.modalZoom.description.image,
  objectContent: state.Modal.modalZoom.description.objectContent,
  buttonDescription: state.Modal.modalZoom.description.buttonDescription,
  description: state.Modal.modalZoom.description,
});

ModalZoom.propTypes = {
  image: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  objectContent: PropTypes.shape({
    id: PropTypes.number,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        message: PropTypes.string,
        name: PropTypes.string,
        image: PropTypes.string,
      })
    ),
  }).isRequired,
  buttonDescription: PropTypes.shape({
    onClick: PropTypes.func,
    title: PropTypes.string,
  }),
  description: PropTypes.shape({
    message: PropTypes.string,
  }).isRequired,
};

ModalZoom.defaultProps = {
  buttonDescription: null,
};

export default connect(mapStateToProps)(ModalZoom);
