import { useState } from 'react';
import PropTypes from 'prop-types';

import { connect, useDispatch, useSelector } from 'react-redux';
import ScoreboardTop10 from 'components/GameBoard/ReusableComponents/Scoreboard/ScoreboardTop10';
import ScoreboardByShots from 'components/GameBoard/ReusableComponents/Scoreboard/ScoreboardByShots';
import MyScoreboard from 'components/GameBoard/ReusableComponents/Scoreboard/MyScoreboard';

import 'components/GameBoard/ReusableComponents/Scoreboard/Scoreboard.css';
import { animationConfetti, playSound } from 'utils/utilityFunctions';
import { useTranslation } from 'react-i18next';
import { selectInfoGame } from 'components/Reducers/game';
import { trophySound } from 'components/GameBoard/ReusableComponents/constants';
import { gameIdSoloOffreSocle } from 'components/constants';

const Scoreboard = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['offreSocle', 'common']);
  const { id } = useSelector(selectInfoGame);

  const [byShots, setByshots] = useState(false);
  const [byTime, setByTime] = useState(false);
  const [myScore, setmyScore] = useState(true);
  const [TopTen, setTopTen] = useState(false);

  const handleClickByShots = () => {
    setByshots(true);
    setByTime(false);
    setmyScore(false);
    setTopTen(false);
  };

  const handleClickByTime = () => {
    setByshots(false);
    setByTime(true);
    setmyScore(false);
    setTopTen(true);
  };

  const handleTop10 = () => {
    setTopTen(true);
    setByTime(true);
    setmyScore(false);
  };

  const handleMyTop = () => {
    setmyScore(true);
    setTopTen(false);
    setByTime(false);
    setByshots(false);
  };

  const endMessageCommon = t('endMessage', {
    returnObjects: true,
    ns: 'common',
  });
  const endMessageOffreSocle = t('endMessage', {
    returnObjects: true,
  });

  const handleEndMessageOfRoom = () => {
    if (id === gameIdSoloOffreSocle) {
      return endMessageOffreSocle.text;
    }
    return '';
  };
  const FinishTheGame = () => {
    playSound(trophySound);
    animationConfetti();
    playSound(endMessageCommon.audio, 1);
    setTimeout(() => {
      if (id === gameIdSoloOffreSocle) {
        playSound(endMessageOffreSocle.audio, 1);
      }
    }, endMessageCommon.duration);
    const descriptionModal = {
      type: 'intro',
      messages: [
        {
          description: `${endMessageCommon.text} ${handleEndMessageOfRoom()}`,
        },
      ],
      action: {
        onClick: () => {
          window.open(process.env.REACT_APP_BASE_URL_DJANGO, '_self');
        },
      },
    };
    dispatch({
      type: 'OPEN_MODAL',
      payload: descriptionModal,
    });
    dispatch({
      type: 'STOP_SCOREBOARD',
    });
  };

  return (
    <div className="ModalTrickyVacc-Background">
      <div className="ModalTrickyVacc-Blur" />
      <div className="ModalTrickyVacc-Container-Scoreboard">
        <div className="ModalTrickyVacc-Container-Scores">
          <div className="ModalTrickyVacc-Bravo">
            <h2>SCORE BOARD{!myScore && ' #5'}</h2>
          </div>
          <div className="ModalTrickyVacc-Scoreboard-Container">
            <div className="ModalTrickyVacc-Scoreboard-info">
              <div className="col20-Vacc">Rang</div>
              <div className="col40-Vacc">Joueurs</div>
              {!myScore ? (
                <>
                  <div
                    className={`col20-Vacc ${byTime ? '' : 'score-selected'}`}
                    onClick={handleClickByShots}
                    onKeyDown={handleClickByShots}
                    tabIndex={0}
                    role="button"
                  >
                    Score
                  </div>
                  <div
                    className={`col20-Vacc ${byShots ? '' : 'score-selected'}`}
                    onClick={handleClickByTime}
                    onKeyDown={handleClickByTime}
                    tabIndex={0}
                    role="button"
                  >
                    Temps
                  </div>
                </>
              ) : (
                <div className="col20-Vacc">Score</div>
              )}
            </div>
            <ScoreboardTop10 status={TopTen} />
            <ScoreboardByShots status={byShots} />
            <MyScoreboard status={myScore} />
          </div>
          {!TopTen && myScore && (
            <div>
              <button
                className="ButtonAction VaccAction"
                type="button"
                onClick={handleTop10}
              >
                Afficher le Top 5
              </button>
            </div>
          )}
          {!myScore && (
            <div>
              <button
                className="ButtonAction VaccAction"
                type="button"
                onClick={handleMyTop}
              >
                Revenir
              </button>
            </div>
          )}
          <div>
            <button
              className="ButtonAction VaccActionInstall"
              type="button"
              onClick={FinishTheGame}
            >
              Continuer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  GameUsers: state.GameUsers,
  sessionName: state.GameUsers.sessionName,
});

Scoreboard.propTypes = {
  GameUsers: PropTypes.shape({
    displayScores: PropTypes.bool,
    scoreboard10: PropTypes.bool,
  }).isRequired,
};

export default connect(mapStateToProps)(Scoreboard);
