import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Components
import Dialogue from 'components/GameBoard/OffreSocle/Enigmas/Dialogue';
// Selector functions
import { selectObject } from 'components/Reducers/GameData/fetchObjects';
// Constants & style
import styles from 'components/GameBoard/OffreSocle/Enigmas/AnswerPhone.module.scss';
import {
  frontDeskOffice,
  referringDoctorOffice,
} from 'components/GameBoard/OffreSocle/constants';
import { receptionistPhone } from 'components/GameBoard/ReusableComponents/Objects/constants';
import DialogueButtons from 'components/GameBoard/OffreSocle/Enigmas/ReceptionnistDialogueButtons';
import OffreSocleModal from 'components/GameBoard/OffreSocle/Enigmas/OffreSocleModal';
import Audio from 'components/GameBoard/ReusableComponents/Actions/Audio';

const { receptionnistFace } = frontDeskOffice;
const { directorFace } = referringDoctorOffice;

const soundPhone =
  'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FOffre%20Socle%2Fassets%2FRoom%201%2Fsounds%2FCOMTelph_Sonnerie%20de%20telephone%20fixe%204%20(ID%200253)_LS.mp3?alt=media&token=0f1c084f-3aec-4cd4-913b-a13ff7d095e3';

const AnswerPhone = () => {
  const { t } = useTranslation('common', 'offreSocle');
  const dialogueText = t('receptionistDialogue', {
    returnObjects: true,
    ns: 'offreSocle',
  });

  const phone = useSelector((state) => selectObject(state, receptionistPhone));
  const phoneObject = phone.images.find((object) => object.type === 'clicked');

  const [isPhoneAnswered, setIsPhoneAnswered] = useState(false);
  return !isPhoneAnswered ? (
    <>
      <Audio sound={soundPhone} loop volume="0.2" />
      <OffreSocleModal
        objectImage={phoneObject.image}
        objectMessage={phoneObject.message}
        onClick={() => setIsPhoneAnswered(true)}
        buttonTitle={t('buttonFunction.answerPhone')}
        isAnimate
      />
    </>
  ) : (
    <div className={styles['dialogue-container']}>
      <Dialogue
        dialogueText={dialogueText}
        character1={directorFace}
        character2={receptionnistFace}
        dialogueButtons={<DialogueButtons objectToBeChecked={phone} />}
      />
    </div>
  );
};

export default AnswerPhone;
