import PropTypes from 'prop-types';
import { useEffect } from 'react';

// components
import Button from 'components/GameBoard/ReusableComponents/Actions/Button';

// styles
import styles from 'components/GameBoard/ReusableComponents/Enigma/Drawer/DrawerInputs.module.scss';
import { selectSession } from 'components/Reducers/session';
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';
import { useSelector } from 'react-redux';

const DrawerInputs = ({ handleGoodCode, submitCode, code, setCode, title }) => {
  const session = useSelector(selectSession);
  const { socket } = useSelector(infoGameUser);
  const room = session.id;

  useEffect(() => {
    socket?.on('receive_emotion_multi_inputs_drawer', (data) => {
      setCode(data.value);
    });
  }, [setCode, socket]);

  const handleChange = (e) => {
    if (setCode) {
      let arrayValue = e.target.value.split('');
      const arrayDifferenceEmpty = Array(code.length - e.target.value.length).fill(
        ''
      );

      if (arrayDifferenceEmpty) {
        arrayValue = arrayValue.concat(arrayDifferenceEmpty);
      }
      const responsesSocket = {
        value: arrayValue,
        room,
      };
      socket?.emit('send_emotion_multi_inputs_drawer', responsesSocket);
      setCode(arrayValue);
    }
  };

  // TO REFACTO
  return (
    <div className={styles.drawer}>
      <div className={styles.inputs}>
        <input
          type="text"
          maxLength={handleGoodCode.length}
          onChange={(e) => {
            handleChange(e);
          }}
        />
        <div className={styles.forms}>
          {code.map((number) => (
            <div className={styles.number}>{number}</div>
          ))}
        </div>
      </div>
      {submitCode && (
        <Button
          className={styles.submit}
          buttonType="action"
          title={title}
          onClick={(event) => submitCode(event)}
        />
      )}
    </div>
  );
};

DrawerInputs.propTypes = {
  handleGoodCode: PropTypes.string.isRequired,
  submitCode: PropTypes.func.isRequired,
  code: PropTypes.string.isRequired,
  setCode: PropTypes.func.isRequired,
  title: PropTypes.string,
};

DrawerInputs.defaultProps = {
  title: '',
};

export default DrawerInputs;
