import PropTypes from 'prop-types';
import './Actions.css';
import styled, { css } from 'styled-components';
import { handleBackgroundImages } from 'utils/utilityFunctions';
import { buttonBackgroundUrl } from 'components/GameBoard/Emotion/Solo/constants';
import { motion } from 'framer-motion';

const ButtonStyle = styled(motion.button)`
  /* General style */
  text-align: center;
  color: #fff;
  padding: 1vw 1vw;
  z-index: 10;
  border-style: solid;

  /* Specific style */
  ${(props) =>
    props.buttonType === 'action' &&
    css`
      border-radius: 0.5vw;
      margin: 1vw;
      background-image: linear-gradient(180deg, #7a00ff, #5a00bb);
      border-color: #270051;
      border-width: 1px;
      :hover {
        background-image: linear-gradient(180deg, #460090, #370072);
      }
      :active {
        background-image: linear-gradient(180deg, #370072, #270050);
      }
      ${props.additionalStyle}
    `}
  /* Specific style */
    ${(props) =>
    props.buttonType === 'return' &&
    css`
      margin: 15px;
      border-radius: 10px;
      border-width: 2px;
      background: transparent;
      border-color: #fff;
      :hover {
        background: rgba(255, 255, 255, 0.2);
      }
      :active {
        background: rgba(255, 255, 255, 0.4);
      }
      ${props.additionalStyle}
    `}
    /* Specific style */
    ${(props) =>
    props.buttonType === 'emotion' &&
    css`
      border: none;
      padding: 0;
      background: transparent;
      background-image: ${handleBackgroundImages(buttonBackgroundUrl)};
      background-repeat: no-repeat;
      background-size: contain;
      width: 138px;
      height: 42px;
      color: ${props.theme.tealBlue};
      font-family: 'EvilEmpireRegular';
      ${props.additionalStyle};
    `}
    /* Specific style */
    ${({ theme, buttonType, additionalStyle }) =>
    buttonType === 'offreSocle' &&
    css`
      background-color: ${theme.purpleHeart};
      border: none;
      border-radius: 10px;
      color: white;
      margin: 15px;
      font-weight: 500;
      font-size: 1.25rem;
      line-height: 28px;
      &:hover {
        color: ${theme.grapeJelly};
        background-color: ${theme.whitePurple};
      }
      ${additionalStyle};
    `};
`;

function Button({ onClick, title, buttonType, additionalStyle, disabled }) {
  return (
    <ButtonStyle
      buttonType={buttonType}
      type="button"
      onClick={onClick}
      additionalStyle={additionalStyle}
      alt={title}
      disabled={disabled}
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
    >
      {title}
    </ButtonStyle>
  );
}

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  // So far, only 4 possible types of buttons. Could be extended in the future
  buttonType: PropTypes.oneOf(['action', 'return', 'emotion', 'offreSocle']),
  additionalStyle: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  ),
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  buttonType: 'return',
  additionalStyle: undefined,
  disabled: false,
};

export default Button;
