// scss
import styles from 'components/GameBoard/Emotion/Multi/Enigma/Desktop/PageScreen/NoteApplication.module.scss';

// constants
import { notePaperImage } from 'components/GameBoard/Emotion/Multi/constant';

const VideoApplication = () => {
  return (
    <div className={styles['note-application']}>
      <img alt="memo" src={notePaperImage} />
    </div>
  );
};

export default VideoApplication;
