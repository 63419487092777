const remoteMorseImages = {
  background:
    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTDE%2Fassets%2Fobjects%2Fremote%2FBG.svg?alt=media&token=85daf1e8-396a-4af4-98f2-42465ed6df29',
  short:
    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTDE%2Fassets%2Fobjects%2Fremote%2FCOURT.svg?alt=media&token=ae795004-a0ad-4472-bde2-39551f43727e',
  long: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTDE%2Fassets%2Fobjects%2Fremote%2FLONG.svg?alt=media&token=178ab56f-41ae-4a3e-acee-ff50dcdef06f',
  erase:
    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTDE%2Fassets%2Fobjects%2Fremote%2FFAUX.svg?alt=media&token=874981dd-28df-422d-85b6-9564fb804f72',
  validate:
    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTDE%2Fassets%2Fobjects%2Fremote%2FVRAI.svg?alt=media&token=ba3decfa-43d5-4fbf-b5de-8a69b86245f9',
};

const remoteMorseText = {
  error:
    "Oups, votre enchaînement n'est pas le bon. Il faut peut être récupérer un élément de votre inventaire.",
  garlandDisplaying:
    'Observez la guirlande, on dirait bien que son clignotement ressemble à votre morse ! Patientez ...',
  congrats:
    'Félicitation! Votre enchaînement vient de permettre d’ouvrir une trappe dans la chambre. allez vite voir ce qu’il s’y trouve.',
};

export { remoteMorseImages, remoteMorseText };
