import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';

import Audio from '../../ReusableComponents/Actions/Audio';
import morseSound from '../../../../sound/TDE/morse.ogg';

import garlandDay from '../../../../img/tde/guirlande-day.svg';
import garlandNight from '../../../../img/tde/guirlande-night.svg';
import garlandOn from '../../../../img/tde/guirlande allumé.svg';

import openHatchSound from '../../../../sound/Vaccination/vacc-unlockchest.ogg';
import '../../../../assets/css/components/GameBoard/TDE/Enigma/Garland.css';
import { remoteMorseText as text } from './constants';

function Garland({
  soundtrack,
  modalZoom,
  dispatch,
  allObjects,
  idSessionHasRoom,
}) {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [openHatch, setOpenHatch] = useState(false);

  const handleCheckedObject = (id, index) => {
    axios
      .put(`${BASE_URL}/api/sessionsHasRoomHasObject/${idSessionHasRoom}`, {
        object_id: id,
        isChecked: 1,
      })
      .then(() => {
        dispatch({
          type: 'CLICKED_OBJECT',
          payload: {
            index,
            isChecked: 1,
            type: 'checked',
          },
        });
        dispatch({
          type: 'REMOVE_OF_INVENTORY',
          payload: id,
        });
      });
  };
  const changeStatus = (id, index) => {
    axios
      .put(`${BASE_URL}/api/sessionsHasRoomHasObject/${idSessionHasRoom}`, {
        object_id: id,
        status: 'open',
      })
      .then(() => {
        dispatch({
          type: 'CLICKED_OBJECT',
          payload: {
            index,
            status: 'open',
            type: 'used',
          },
        });
        dispatch({
          type: 'REMOVE_OF_INVENTORY',
          payload: id,
        });
      });
  };

  const handleSwitch = () => {
    if (modalZoom.description.objectContent.chaining.length > 0) {
      const codeMorseUser = JSON.stringify(
        modalZoom.description.objectContent.chaining
      );
      const goodCodeMorse = JSON.stringify([
        2000, 500, 2000, 500, 500, 500, 2000, 500, 500, 2000, 2000, 2000,
      ]);

      const isGoodCode = codeMorseUser === goodCodeMorse;

      if (codeMorseUser && isGoodCode) {
        dispatch({
          type: 'MODIFY_MODAL_ZOOM',
          payload: text.congrats,
        });

        setOpenHatch(true);
        const idCodeMorse = 160;
        const idLamp = 147;
        const idRemoteControl = 163;
        const idHatch = 176;

        const objectIndexCodeMorse = allObjects.findIndex(
          (objectElement) => objectElement.id === idCodeMorse
        );
        const objectIndexLamp = allObjects.findIndex(
          (objectElement) => objectElement.id === idLamp
        );
        const objectIndexRemoteControl = allObjects.findIndex(
          (objectElement) => objectElement.id === idRemoteControl
        );

        const objectIndexHatch = allObjects.findIndex(
          (objectElement) => objectElement.id === idHatch
        );

        handleCheckedObject(idCodeMorse, objectIndexCodeMorse);
        handleCheckedObject(idLamp, objectIndexLamp);
        handleCheckedObject(idRemoteControl, objectIndexRemoteControl);
        changeStatus(idHatch, objectIndexHatch);
        setTimeout(() => {
          dispatch({
            type: 'CLOSE_MODAL_ZOOM',
          });
        }, 4500);
      } else {
        dispatch({
          type: 'MODIFY_MODAL_ZOOM',
          payload: text.error,
        });
        setTimeout(() => {
          dispatch({
            type: 'CLOSE_MODAL_ZOOM',
          });
        }, 3500);
      }
      dispatch({
        type: 'RESET_CHAINING_GARLAND',
      });
      dispatch({
        type: 'MUTE_MUSIC',
        payload: true,
      });
    }
  };

  useEffect(() => {
    if (
      modalZoom.description.objectContent &&
      modalZoom.description.objectContent.chaining
    ) {
      const { chaining } = modalZoom.description.objectContent;
      chaining.map((time, index) => {
        let sum = 0;
        for (let i = 0; i < index; i += 1) {
          sum += chaining[i];
        }
        return setTimeout(
          () => {
            dispatch({
              type: 'MUTE_MUSIC',
              payload: false,
            });
            dispatch({
              type: 'MODIFY_MODAL_ZOOM',
              payload: text.garlandDisplaying,
            });
            dispatch({
              type: 'SWITCH_GARLAND',
              payload: time,
            });
            setTimeout(() => {
              dispatch({
                type: 'SWITCH_GARLAND',
                payload: undefined,
              });
            }, time);
          },
          index !== 0 ? sum + 500 * index : 0
        );
      });
      let endTime = 0;
      for (let i = 0; i < chaining.length; i += 1) {
        endTime += chaining[i];
      }
      setTimeout(() => {
        handleSwitch();
      }, endTime + chaining.length * 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    modalZoom.description.objectContent &&
      modalZoom.description.objectContent.chaining,
  ]);
  const switchedOn =
    modalZoom.description.objectContent &&
    (modalZoom.description.objectContent.switchLight === 500 ||
      modalZoom.description.objectContent.switchLight === 2000);
  return (
    <div className="garland-container">
      <Audio sound={openHatchSound} condition={openHatch && soundtrack} />
      <Audio sound={morseSound} loop muted={!switchedOn} />
      <img
        src={
          allObjects.filter((item) => item.name === 'Interrupteur') &&
          allObjects.filter((item) => item.name === 'Interrupteur')[0] &&
          allObjects.filter((item) => item.name === 'Interrupteur')[0].status ===
            'closed'
            ? garlandDay
            : garlandNight
        }
        className="garlands-idle"
        alt="Guirlande de la chambre"
      />
      {switchedOn && (
        <img
          src={garlandOn}
          className="garlands-switchedon"
          alt="Guirlande allumée de la chambre"
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  modalZoom: state.Modal.modalZoom,
  allObjects: state.Objects.AllObjects,
  idSessionHasRoom: state.GameUsers.idSessionHasRoom,
  soundtrack: state.GameUsers.soundtrack,
});

Garland.propTypes = {
  modalZoom: PropTypes.shape({
    description: PropTypes.shape({
      image: PropTypes.string,
      objectContent: PropTypes.shape({
        switchLight: PropTypes.number,
        chaining: PropTypes.arrayOf(PropTypes.number),
      }),
    }),
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  allObjects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
  idSessionHasRoom: PropTypes.number,
  soundtrack: PropTypes.bool.isRequired,
};

Garland.defaultProps = {
  idSessionHasRoom: null,
};

export default connect(mapStateToProps)(Garland);
