import styles from 'components/Joyride/Steps.module.scss';
import { imagesFooter } from 'components/GameBoard/ReusableComponents/Multi/constants';

const commonMultiSteps = (t) => {
  const content = (title, description) => (
    <div className={styles['main-content']}>
      <h2>{title}</h2>
      <p>{description}</p>
    </div>
  );
  const steps = [
    {
      target: '#video-wrapper',
      content: (
        <div className={styles['main-content']}>
          <h2>{t('joyride.players.title', { ns: 'common-multi' })}</h2>
          <p>{t('joyride.players.description', { ns: 'common-multi' })}</p>
          <p>{t('joyride.players.boost', { ns: 'common-multi' })}</p>
          <div className={styles['camera-icons']}>
            <img src={imagesFooter.micro} alt="micro" />
            <img src={imagesFooter.video} alt="camera" />
          </div>
        </div>
      ),
      placement: 'left',
    },
    {
      target: 'body',
      content: content(
        t('joyride.goodLuck.title'),
        t('joyride.goodLuck.description', { ns: 'common-multi' })
      ),
      placement: 'center',
    },
  ];
  return steps;
};
export default commonMultiSteps;
