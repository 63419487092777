import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// components
import PlutchikWheelType from 'components/GameBoard/ReusableComponents/Multi/Shared/Questionnary/Type/PlutchikWheelType/PlutchikWheelType';
import Choice from 'components/GameBoard/ReusableComponents/Multi/Shared/Questionnary/Type/Choice/Choice';
import Textarea from 'components/GameBoard/ReusableComponents/Multi/Shared/Questionnary/Type/Textarea/Textarea';
import Note from 'components/GameBoard/ReusableComponents/Multi/Shared/Questionnary/Type/Note/Note';

// utils
import {
  linkAnswerBetweenQuestion,
  passToTheNextUser,
  retrieveQuestionnaryDebrief,
  retrieveSurvey,
} from 'utils/utilityFunctions';

// selectors
import { selectQuestionnary } from 'components/Reducers/questionnary';
import { selectUser } from 'components/Reducers/user';
import { selectSession } from 'components/Reducers/session';
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';

import styles from 'components/GameBoard/ReusableComponents/Multi/Shared/Evaluation/StoryLine/ContentQuestion.module.scss';

const ContentQuestion = ({
  idSurvey,
  handleNextStories,
  errors,
  isControlButton,
}) => {
  const dispatch = useDispatch();
  const { list, userHasSurveyId } = useSelector(selectQuestionnary);
  const [indexQuestion, setIndexQuestion] = useState(0);
  const { currentUser, participants } = useSelector(selectUser);
  const session = useSelector(selectSession);
  const { socket } = useSelector(infoGameUser);
  const room = session.id;

  useEffect(() => {
    retrieveQuestionnaryDebrief(idSurvey, dispatch);
    retrieveSurvey(idSurvey, dispatch);
  }, [dispatch, idSurvey]);

  useEffect(() => {
    socket.on('receive_index_question', (data) => {
      setIndexQuestion(data.index);
    });
  }, [socket]);

  const { isMainUser, type } = participants[Object.keys(currentUser)[0]];

  const handleAnswer = (answer, question, isNew) => {
    const nextIndexQuestion = indexQuestion + 1;
    // Next question
    if (isMainUser || type === 'admin') {
      let responsesSocket = { room };
      if (nextIndexQuestion < list.length) {
        if (
          errors !== null &&
          indexQuestion <= 2 &&
          errors[indexQuestion].id !== answer.id
        ) {
          const descriptionModal = errors[indexQuestion];
          responsesSocket = { ...responsesSocket, descriptionModal };
          socket?.emit('send_open_modal', responsesSocket);
          dispatch({
            type: 'OPEN_MODAL',
            payload: descriptionModal,
          });
        } else {
          responsesSocket = { ...responsesSocket, index: nextIndexQuestion };
          socket?.emit('send_index_question', responsesSocket);
          setIndexQuestion(nextIndexQuestion);
        }
      } else {
        responsesSocket = { ...responsesSocket, index: 0 };
        socket?.emit('send_index_question', responsesSocket);
        setIndexQuestion(0);
        handleNextStories();
      }
      const mainUserId = Object.keys(participants).find(
        (participant) => participants[participant].isMainUser
      );
      const nextCounter = participants[mainUserId].counter - 1;
      const responses = {
        mainUserId,
        nextCounter,
        room,
      };
      passToTheNextUser(participants, responses, socket, dispatch, mainUserId);
    }
    // link user, question, survey, response but if response don't exist we need to
    // create response and link between response and question
    linkAnswerBetweenQuestion(
      answer,
      question.id,
      userHasSurveyId,
      dispatch,
      isNew
    );
  };
  const handleReturn = () => {
    const nextIndexQuestion = indexQuestion - 1;
    const responsesSocket = {
      room,
      index: nextIndexQuestion,
    };
    socket?.emit('send_index_question', responsesSocket);
    setIndexQuestion(nextIndexQuestion);
  };

  const handleResponseType = () => {
    switch (list[indexQuestion].response_type) {
      case 'Plutchikwheel':
        return (
          <PlutchikWheelType
            question={list[indexQuestion]}
            activeIndex={indexQuestion}
            handleAnswer={handleAnswer}
            handleReturn={handleReturn}
            isStoryline
          />
        );
      case 'Choice':
        return (
          <Choice
            question={list[indexQuestion]}
            activeIndex={indexQuestion}
            handleAnswer={handleAnswer}
            handleReturn={handleReturn}
          />
        );
      case 'Textarea':
        return (
          <Textarea
            question={list[indexQuestion]}
            activeIndex={indexQuestion}
            handleAnswer={handleAnswer}
            handleReturn={handleReturn}
          />
        );
      case 'Note':
        return (
          <Note
            question={list[indexQuestion]}
            activeIndex={indexQuestion}
            handleAnswer={handleAnswer}
            handleReturn={handleReturn}
            isControlButton={isControlButton}
          />
        );
      default:
        return null;
    }
  };

  return <div className={styles.content}>{handleResponseType()}</div>;
};

ContentQuestion.propTypes = {
  idSurvey: PropTypes.number.isRequired,
  handleNextStories: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf({
    id: PropTypes.number,
    text: PropTypes.string,
  }),
  isControlButton: PropTypes.bool,
};

ContentQuestion.defaultProps = {
  errors: null,
  isControlButton: false,
};

export default ContentQuestion;
