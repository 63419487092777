import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import styles from 'components/GameBoard/ReusableComponents/Multi/Shared/Questionnary/Type/Note/Note.module.scss';
import Button from 'components/GameBoard/ReusableComponents/Actions/Button';
import { getResponses } from 'API/questionnary';

const Note = ({
  question,
  activeIndex,
  handleAnswer,
  handleReturn,
  isControlButton,
}) => {
  const { t } = useTranslation('common');

  const [arrayResponses, setArrayResponses] = useState([]);

  useEffect(() => {
    async function fetchResponses() {
      const data = await getResponses(question.id);
      setArrayResponses(data);
    }
    fetchResponses();
  }, [question.id]);

  const [selectChoice, setSelectChoice] = useState(null);
  const numberOfResponse =
    arrayResponses.length % 2 ? arrayResponses.length - 1 : arrayResponses.length;
  const middleChoice = Math.round(numberOfResponse / 2);

  const handleChoice = () => {
    if (!selectChoice) {
      return arrayResponses[middleChoice];
    }
    return selectChoice;
  };

  return (
    <div className={styles.note}>
      <h4 className={styles.question}>{question.title}</h4>
      <div className={styles['list-response']}>
        <p className={styles['response-minimum']}>{question.minimum}</p>
        <div className={styles.responses}>
          {arrayResponses.map((response, index) => (
            <div key={response.id}>
              <div className={styles.bar} />
              <button
                className={styles.response}
                type="button"
                onClick={() => setSelectChoice(response)}
                aria-label="Response"
              />
              {((selectChoice === null && index === middleChoice) ||
                selectChoice === response) && (
                <div className={styles['select-choice']} />
              )}
            </div>
          ))}
        </div>
        <p className={styles['response-maximum']}>{question.maximum}</p>
      </div>
      {activeIndex !== 0 && (
        <Button
          buttonType="action"
          title={t('buttonFunction.return')}
          onClick={() => handleReturn()}
        />
      )}
      {!isControlButton && (
        <Button
          buttonType="action"
          title={t('buttonFunction.next')}
          onClick={() => {
            handleAnswer(handleChoice(), question);
            setSelectChoice(null);
          }}
        />
      )}
    </div>
  );
};

Note.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    image: PropTypes.string,
    is_debriefing: PropTypes.number,
    level: PropTypes.number,
    maximum: PropTypes.string,
    minimum: PropTypes.string,
    response_type: PropTypes.string,
    responses: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        level: PropTypes.number,
        weighting: PropTypes.number,
      })
    ),
  }).isRequired,
  activeIndex: PropTypes.number.isRequired,
  handleAnswer: PropTypes.func.isRequired,
  handleReturn: PropTypes.func.isRequired,
  isControlButton: PropTypes.bool,
};

Note.defaultProps = {
  isControlButton: false,
};

export default Note;
