import styles from 'components/Joyride/Steps.module.scss';
import commonSteps from './constants/commonSteps';

const stepsRPS = (t) => {
  const content = (title, description) => (
    <div className={styles['main-content']}>
      <h2>{title}</h2>
      <p>{description}</p>
    </div>
  );

  // get constants steps from constants file
  const commonImportedSteps = commonSteps(
    t,
    null,
    null,
    t('mission', { ns: 'rps' })
  );

  // Steps that only concerns RPS :
  const stepsOnlyRPS = [
    {
      target: '.numberPatient',
      content: content(
        t('joyride.waitingLine.title', { ns: 'rps' }),
        t('joyride.waitingLine.description', { ns: 'rps' })
      ),
      placement: 'bottom',
    },
    {
      target: '.InterfaceGame_button-information',
      content: content(
        t('joyride.informations.title', { ns: 'rps' }),
        t('joyride.informations.description', { ns: 'rps' })
      ),
      placement: 'bottom',
    },
    {
      target: '.InterfaceGame_actions-contents-left-top',
      content: content(
        t('joyride.patient.title', { ns: 'rps' }),
        t('joyride.patient.description', { ns: 'rps' })
      ),
      placement: 'right',
    },

    {
      target: '.InterfaceGame_actions-contents-bottom',
      content: content(
        t('joyride.takeCare.title', { ns: 'rps' }),
        t('joyride.takeCare.description', { ns: 'rps' })
      ),
      placement: 'left',
    },

    {
      target: '.InterfaceGame_actions-contents-right',
      content: content(
        t('joyride.stressBar.title', { ns: 'rps' }),
        t('joyride.stressBar.description', { ns: 'rps' })
      ),
      placement: 'left',
    },
    {
      target: '.InterfaceGame_actions-helps',
      content: (
        <div className={styles['main-content']}>
          <h2>{t('joyride.help.title', { ns: 'rps' })}</h2>
          <p>{t('joyride.help.description', { ns: 'rps' })}</p>
          <p>
            <strong>{t('joyride.help.boost1', { ns: 'rps' })}</strong>{' '}
            {t('joyride.help.boost1Description', { ns: 'rps' })}{' '}
            <strong>{t('joyride.help.boost2', { ns: 'rps' })}</strong>{' '}
            {t('joyride.help.boost2Description', { ns: 'rps' })}
          </p>
        </div>
      ),
      placement: 'left',
    },
    {
      target: '.WelcomeRPS_background',
      content: content(
        t('joyride.goodLuck.title'),
        t('joyride.goodLuck.description', { ns: 'rps' })
      ),
      placement: 'center',
    },
  ];

  // Insert steps that only concerns RPS to commons steps
  stepsOnlyRPS.forEach((step) => {
    commonImportedSteps.splice(commonImportedSteps.length - 1, 0, step);
  });
  // take off good luck common step
  commonImportedSteps.pop();
  return commonImportedSteps;
};

export default stepsRPS;
