import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

// Components
import Button from 'components/GameBoard/ReusableComponents/Actions/Button';
import DialogueRow from 'components/GameBoard/OffreSocle/Enigmas/DialogueRow';
// Style
import styles from 'components/GameBoard/OffreSocle/Enigmas/DuerpFound.module.scss';
// Constants
import { referringDoctorOffice } from 'components/GameBoard/OffreSocle/constants';
import { offreSocleLocker3Id } from 'components/GameBoard/ReusableComponents/Objects/constants';
// Selector functions & API
import { selectObjectIndex } from 'components/Reducers/GameData/fetchObjects';
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';
import { putSessionsHasRoomHasObject } from 'API/sessionsHasRoomHasObject';

const { directorFace } = referringDoctorOffice;
const { content, message } = referringDoctorOffice.locker[offreSocleLocker3Id];

const DuerpFound = ({ setObjectClicked }) => {
  const { t } = useTranslation('common');

  const dispatch = useDispatch();
  const objectClickedIndex = useSelector((state) =>
    selectObjectIndex(state, offreSocleLocker3Id)
  );
  const { idSessionHasRoom } = useSelector(infoGameUser);

  const [isImageLoaded, setIsImageLoaded] = useState(false);

  return (
    <motion.div className={styles['modal-container']}>
      {isImageLoaded && (
        <DialogueRow
          characterImage={directorFace}
          direction="row"
          dialogueText={message}
        />
      )}
      <img src={content} alt="" width="97%" onLoad={() => setIsImageLoaded(true)} />
      {isImageLoaded && (
        <Button
          buttonType="offreSocle"
          title={t('buttonFunction.continue')}
          onClick={() => {
            dispatch({
              type: 'CLICKED_OBJECT',
              payload: {
                index: objectClickedIndex,
                type: 'checked',
                isChecked: 1,
              },
            });
            putSessionsHasRoomHasObject(idSessionHasRoom, {
              object_id: offreSocleLocker3Id,
              isChecked: 1,
            });
            setObjectClicked(false);
          }}
        />
      )}
    </motion.div>
  );
};

DuerpFound.propTypes = {
  setObjectClicked: PropTypes.func.isRequired,
};

export default DuerpFound;
