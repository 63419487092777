import PropTypes from 'prop-types';
import styles from 'components/Introduction/StartPage.module.scss';
import { useTranslation } from 'react-i18next';

function ErrorPage({ errorMessage }) {
  const { t } = useTranslation('common');
  if (errorMessage) {
    <div className={styles.background}>
      <p>{errorMessage}</p>
    </div>;
  }
  return (
    <div className={styles.background}>
      <p>{t('error.invalidLink')}</p>
    </div>
  );
}
ErrorPage.propTypes = {
  errorMessage: PropTypes.string.isRequired,
};
export default ErrorPage;
