import { useState, useRef, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

// Selector functions
import { selectSteps } from 'components/Reducers/Steps/Steps';
import { selectRoom } from 'components/Reducers/Room';
import { selectSession } from 'components/Reducers/session';
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';
import { selectModal } from 'components/Reducers/Modal/Modal';
import { selectOffreSocle } from 'components/Reducers/offreSocle';
import {
  selectObject,
  selectObjectIndex,
} from 'components/Reducers/GameData/fetchObjects';

// Components
import Hud from 'components/GameBoard/ReusableComponents/HUD/Hud';
import ListObjects from 'components/GameBoard/ReusableComponents/Objects/ListObjects';
import Modal from 'components/GameBoard/ReusableComponents/Modal/Modal';
import ModalEnigma from 'components/GameBoard/ReusableComponents/Modal/ModalEnigma';
import RoomContainer from 'components/GameBoard/ReusableComponents/RoomContainer';
import Steps from 'components/GameBoard/ReusableComponents/Steps';
import Button from 'components/GameBoard/ReusableComponents/Actions/Button';
import Dialogue from 'components/GameBoard/OffreSocle/Enigmas/Dialogue';
import DialogueRow from 'components/GameBoard/OffreSocle/Enigmas/DialogueRow';
import OffreSocleModal2 from 'components/GameBoard/OffreSocle/Enigmas/OffreSocleModal2';
// Utils
import useInitGame from 'utils/useInitGame';
import {
  useStartMessageOrQuestionary,
  clickCount,
  checkedObject,
  playSound,
} from 'utils/utilityFunctions';
import useWindowResize from 'utils/useWindowResize';
// Constant & style
import styles from 'components/GameBoard/OffreSocle/Rooms/ReferringDoctorOffice.module.scss';
import {
  doctorOfficeSoundRoom,
  referringDoctorOffice,
  ergonomistSoundRoom,
  volume,
} from 'components/GameBoard/OffreSocle/constants';
import {
  doctorPhone,
  doctorComputer,
} from 'components/GameBoard/ReusableComponents/Objects/constants';
import Audio from 'components/GameBoard/ReusableComponents/Actions/Audio';
import ChatBot from 'components/GameBoard/ReusableComponents/ChatBot/ChatBot';
import RoomName from 'components/GameBoard/ReusableComponents/HUD/RoomName/RoomName';

const {
  backgroundMaxWidth,
  doctorFace,
  directorFace,
  correctNumber1,
  correctNumber2,
} = referringDoctorOffice;

const ReferringDoctorOffice = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['offreSocle', 'common']);
  const dialogueText = t('doctorDialogue2', {
    returnObjects: true,
  });
  // Select data from the store
  const { currentRoomId, listRooms } = useSelector(selectRoom);
  const session = useSelector(selectSession);
  const modalIsOpen = useSelector(selectModal).modal.isOpen;
  const { currentStep, startQuestionnary, startMessage, list } =
    useSelector(selectSteps);
  const firstMessageFound = list?.[0]?.isFound;
  const { idSessionHasRoom, music } = useSelector(infoGameUser);
  const modalEnigmaIsOpen = useSelector(selectModal).modalEnigma.isOpen;
  const currentRoom = Object.values(listRooms).find(
    (roomObject) => roomObject.id === currentRoomId
  );
  const { phoneNumber, isVideoConfAnswered } = useSelector(selectOffreSocle);
  const doctorPhoneObject = useSelector((state) =>
    selectObject(state, doctorPhone)
  );
  const doctorPhoneIndex = useSelector((state) =>
    selectObjectIndex(state, doctorPhoneObject?.id)
  );
  const doctorComputerObject = useSelector((state) =>
    selectObject(state, doctorComputer)
  );

  const doctorComputerObjectImage = doctorComputerObject?.images?.find(
    (imageContent) => imageContent.type === 'enigma-ended'
  );

  const doctorPhoneObjectImageEndCall = doctorPhoneObject?.images?.find(
    (imageContent) => imageContent.type === 'end_call'
  );

  const doctorPhoneObjectImageCall = doctorPhoneObject?.images?.find(
    (imageContent) => imageContent.type === 'call'
  );

  const { enigmaStep } = useSelector(selectOffreSocle);

  const [backgroundImageDimensions, setBackgroundImageDimensions] = useState({
    width: 0,
    height: 0,
  });

  const [isDialogueEnded, setIsDialogueEnded] = useState(false);
  const [isDoctorTalking, setIsDoctorTalking] = useState(false);
  const [isSpecialistCalled, setIsSpecialistCalled] = useState(false);

  // This ref is connected to the image background
  const backgroundImageRef = useRef();

  const backgroundUrl = currentRoom.url;

  // Initiate the session, the room, the objects in the room and the prevention messages
  useInitGame(currentRoomId, session.id);

  // Save the prevention message/questionary step in database and store and launch it
  useStartMessageOrQuestionary(
    currentStep,
    idSessionHasRoom,
    startMessage,
    startQuestionnary
  );

  const handleResize = useCallback(() => {
    setBackgroundImageDimensions({
      width: backgroundImageRef.current.offsetWidth,
      height: backgroundImageRef.current.offsetHeight,
    });
  }, []);

  useWindowResize(backgroundImageRef, handleResize);

  useEffect(() => {
    if (!!firstMessageFound && isDoctorTalking) {
      playSound(doctorComputerObjectImage?.sound);
    }
  }, [doctorComputerObjectImage?.sound, firstMessageFound, isDoctorTalking]);

  const handlePhoneValidation = () => {
    if (!isSpecialistCalled) {
      if (phoneNumber === correctNumber1 || phoneNumber === correctNumber2) {
        playSound(doctorPhoneObjectImageCall?.sound);
        return (
          <div className={styles['bubble-and-button-container']}>
            <motion.div className={styles['bubble-container']}>
              <DialogueRow
                characterImage={doctorFace}
                direction="row"
                dialogueText={t('rightPhoneNumber', { returnObjects: true })}
                smallView
              />
            </motion.div>
            <Button
              title={t('buttonFunction.continue', { ns: 'common' })}
              buttonType="offreSocle"
              onClick={() => setIsSpecialistCalled(true)}
              additionalStyle={{
                width: 'fit-content',
                margin: '0',
                alignSelf: 'center',
                bottom: '0',
                position: 'absolute',
              }}
            />
          </div>
        );
      }
      playSound(doctorPhoneObjectImageEndCall?.sound);

      return (
        <OffreSocleModal2
          text={t('wrongNumberModalText')}
          subText={t('wrongNumberModalSubtext')}
          buttonTitle={t('buttonFunction.retry', { ns: 'common' })}
          onClick={() => {
            dispatch({ type: 'SET_PHONE_NUMBER', payload: '' });
            dispatch({
              type: 'OPEN_MODAL_ENIGMA',
              payload: { content: doctorPhoneObject },
            });
          }}
        />
      );
    }

    return (
      <OffreSocleModal2
        text={t('congratulation')}
        subText={t('ergonomistCalled')}
        buttonTitle={t('buttonFunction.continue', { ns: 'common' })}
        onClick={() =>
          checkedObject(
            doctorPhoneObject,
            doctorPhoneIndex,
            idSessionHasRoom,
            dispatch
          )
        }
      />
    );
  };

  return (
    <RoomContainer
      id="container"
      imgDimensions={backgroundImageDimensions}
      isBackgroundFixed
      maxWidth={backgroundMaxWidth}
      initial={{ opacity: 0.5, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.7 }}
      exit={{ opacity: 0.5, scale: 0 }}
    >
      <Audio
        sound={
          enigmaStep !== 'companyView' ? doctorOfficeSoundRoom : ergonomistSoundRoom
        }
        loop
        muted={!music}
        volume={volume}
      />
      {/* Allow the display of the HUD in the company view and not in the other enigmas */}
      <header className={enigmaStep === 'companyView' && styles.header}>
        <Steps />
        <Hud />
        <RoomName />
      </header>
      <img
        src={backgroundUrl}
        width="100%"
        height="100%"
        alt={t('frontDeskOffice')}
        id="img-background"
        onLoad={(event) => {
          const { width, height } = event.target;
          setBackgroundImageDimensions({
            width,
            height,
          });
        }}
        ref={backgroundImageRef}
        onClick={(event) => {
          clickCount(dispatch, event);
        }}
        onKeyDown={(event) => {
          clickCount(dispatch, event);
        }}
        role="presentation"
      />
      <ListObjects />
      <ChatBot />
      {/* Overlay for blurring eveything (background image + objects) */}
      {isDoctorTalking && <div className={styles.blur} />}
      {modalIsOpen && <Modal />}
      {modalEnigmaIsOpen && <ModalEnigma isButtonHidden />}
      {!!firstMessageFound && !isDialogueEnded && isVideoConfAnswered && (
        <div className={styles['dialogue-container']}>
          <div className={styles['dialogue-position']}>
            <Dialogue
              dialogueText={dialogueText}
              character2={directorFace}
              character1={doctorFace}
              directionStart="row-reverse"
              dialogueButtons={
                <Button
                  title={t('buttonFunction.continue', { ns: 'common' })}
                  buttonType="offreSocle"
                  additionalStyle={{ width: '95%' }}
                  onClick={() => {
                    setIsDialogueEnded(true);
                    setIsDoctorTalking(true);
                  }}
                />
              }
            />
          </div>
        </div>
      )}
      {!!firstMessageFound && isDoctorTalking && (
        <div className={styles['bubble-and-button-container']}>
          <motion.div className={styles['bubble-container']}>
            <DialogueRow
              characterImage={doctorFace}
              direction="row"
              dialogueText={t('doctorNotebook', { returnObjects: true })}
            />
          </motion.div>
          <Button
            title={t('buttonFunction.continue', { ns: 'common' })}
            buttonType="offreSocle"
            additionalStyle={{
              width: 'fit-content',
              margin: '0',
              alignSelf: 'center',
              position: 'absolute',
              bottom: '0',
            }}
            onClick={() => {
              setIsDoctorTalking(false);
            }}
          />
        </div>
      )}
      {phoneNumber && !modalIsOpen && handlePhoneValidation()}
    </RoomContainer>
  );
};

export default ReferringDoctorOffice;
