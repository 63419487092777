/* eslint-disable no-param-reassign */
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

// components
import Inventory from 'components/GameBoard/ReusableComponents/Inventory';
import Modal from 'components/GameBoard/ReusableComponents/Modal/Modal';
import Steps from 'components/GameBoard/ReusableComponents/Steps';
import ListObjects from 'components/GameBoard/ReusableComponents/Objects/ListObjects';
import Zoom from 'components/GameBoard/ReusableComponents/Modal/Zoom';
import ModalZoom from 'components/GameBoard/ReusableComponents/Modal/ModalZoom';
import ModalEnigma from 'components/GameBoard/ReusableComponents/Modal/ModalEnigma';
import Hud from 'components/GameBoard/ReusableComponents/HUD/Hud';
import Audio from 'components/GameBoard/ReusableComponents/Actions/Audio';
import Scoreboard from 'components/GameBoard/ReusableComponents/Scoreboard/Scoreboard';
import ChatBot from 'components/GameBoard/ReusableComponents/ChatBot/ChatBot';

// selector functions
import { selectSession } from 'components/Reducers/session';

// utils & constants
import useInitGame from 'utils/useInitGame';
import { useStartMessageOrQuestionary } from 'utils/utilityFunctions';
import modalBackgroundColor from 'components/GameBoard/TMS/constants';

// css
import 'assets/css/components/GameBoard/TMS/Rooms/RestaurantTMS.css';

// assets
import tmsTheme from '../../../../sound/Take-my-Hand.mp3';
import click from '../../../../sound/click-ongame.mp3';

const BASE_URL = process.env.REACT_APP_BASE_URL;

function RestaurantTMS({
  modalIsOpen,
  currentRoom,
  dispatch,
  startMessage,
  currentStep,
  startQuestionnary,
  idSessionHasRoom,
  zoomIsOpen,
  modalZoomIsOpen,
  count,
  modalEnigmaIsOpen,
  listRooms,
  gameUsers,
  MouseClicked,
  isScoreBoard,
}) {
  const session = useSelector(selectSession);

  useInitGame(currentRoom, session.id, listRooms);
  const { t } = useTranslation('tms');

  useEffect(() => {
    dispatch({
      type: 'OPEN_MODAL',
      payload: t('modalIntroTablet', { returnObjects: true }),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // COUNTER
  useEffect(() => {
    if (idSessionHasRoom) {
      axios.put(
        `${BASE_URL}/api/sessionsHasRoom/${idSessionHasRoom}/modifications`,
        {
          count,
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  useStartMessageOrQuestionary(
    currentStep,
    idSessionHasRoom,
    startMessage,
    startQuestionnary
  );

  const urlRoom = listRooms.RestaurantTMS.url;

  const clickOnGame = () => {
    dispatch({ type: 'INCREMENT_COUNTER' });
  };

  useEffect(() => {
    dispatch({
      type: 'CLICKED_MOUSE_DOWN',
    });
    setTimeout(() => {
      dispatch({
        type: 'CLICKED_MOUSE_UP',
      });
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameUsers.count]);

  const updateMousePosition = (ev) => {
    const offset = document
      .querySelector('#RestaurantTMS-BKG')
      .getBoundingClientRect();
    dispatch({
      type: 'PLACE_CURSOR',
      payload: {
        x: ev.pageX - offset.left,
        y: ev.pageY - offset.top,
      },
    });
  };

  useEffect(() => {
    document
      .getElementById('RestaurantTMS-BKG')
      .addEventListener('mouseup', updateMousePosition);
    return () => window.removeEventListener('mouseup', updateMousePosition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="RestaurantTMS" id="RestaurantTMS-BKG">
      <Audio
        sound={tmsTheme}
        loop
        id="TMS-Restaurant-Theme"
        muted={!gameUsers.music}
      />
      <Audio sound={click} condition={!MouseClicked && gameUsers.soundtrack} />
      <div
        className={MouseClicked ? 'cursor-clicked' : 'Hide'}
        style={{ top: gameUsers.cursor.y, left: gameUsers.cursor.x }}
      >
        <p>+1</p>
      </div>
      {!isScoreBoard && (
        <header>
          <Inventory />
          <Steps />
          <Hud />
        </header>
      )}
      <div
        className="RestaurantTMS-img-div"
        onClick={() => clickOnGame()}
        role="presentation"
      >
        <div className="CorridorTMS_head" />
        <img
          className="RestaurantTMS_background"
          src={urlRoom}
          alt="Fond TMS"
          draggable="false"
        />
      </div>
      {modalIsOpen && <Modal modalBackgroundColor={modalBackgroundColor} />}
      {modalZoomIsOpen && <ModalZoom />}
      {zoomIsOpen && <Zoom />}
      {modalEnigmaIsOpen && <ModalEnigma />}
      {isScoreBoard && <Scoreboard />}
      <ListObjects />
      <ChatBot />
    </div>
  );
}

const mapStateToProps = (state) => ({
  game: state.game,
  modalIsOpen: state.Modal.modal.isOpen,
  currentRoom: state.Room.currentRoomId,
  idSessionHasRoom: state.GameUsers.idSessionHasRoom,
  startMessage: state.Steps.startMessage,
  currentStep: state.Steps.currentStep,
  startQuestionnary: state.Steps.startQuestionnary,
  zoomIsOpen: state.Modal.zoom.isOpen,
  modalZoomIsOpen: state.Modal.modalZoom.isOpen,
  allObjects: state.Objects.AllObjects,
  isAdvice: state.Steps.isAdvice,
  count: state.GameUsers.count,
  modalEnigmaIsOpen: state.Modal.modalEnigma.isOpen,
  listRooms: state.Room.listRooms,
  gameUsers: state.GameUsers,
  MouseClicked: state.GameUsers.clicked,
  isScoreBoard: state.Steps.isScoreBoard,
});

RestaurantTMS.propTypes = {
  MouseClicked: PropTypes.bool.isRequired,
  game: PropTypes.shape({
    photo: PropTypes.string,
  }).isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  currentRoom: PropTypes.number.isRequired,
  idSessionHasRoom: PropTypes.number,
  startMessage: PropTypes.number.isRequired,
  currentStep: PropTypes.shape({
    id: PropTypes.number,
  }),
  startQuestionnary: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  zoomIsOpen: PropTypes.bool.isRequired,
  modalZoomIsOpen: PropTypes.bool.isRequired,
  count: PropTypes.number.isRequired,
  modalEnigmaIsOpen: PropTypes.bool.isRequired,
  listRooms: PropTypes.shape({
    RestaurantTMS: PropTypes.shape({
      url: PropTypes.string,
    }),
  }).isRequired,
  gameUsers: PropTypes.shape({
    soundtrack: PropTypes.bool,
    music: PropTypes.bool,
    count: PropTypes.number,
    cursor: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number,
    }),
  }).isRequired,
  isScoreBoard: PropTypes.bool.isRequired,
};

RestaurantTMS.defaultProps = {
  idSessionHasRoom: null,
  currentStep: null,
};

export default connect(mapStateToProps)(RestaurantTMS);
